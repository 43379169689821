import axios from "axios";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import config from "../../../config";

const schema = yup.object().shape({
  tc: yup
    .string()
    .required("TC Kimlik Numarası zorunludur.")
    .matches(
      /^[0-9]{11}$/,
      "11 haneli geçerli bir TC Kimlik Numarası giriniz."
    ),
});

const DoctorPatientsAppointments = () => {
  const uRegister = useSelector((state) => state.uRegister);
  const [doktorPatientsAppointment, setDoktorPatientsAppointment] = useState(
    []
  );
  const [tc, setTc] = useState();
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await schema.validate({ tc }, { abortEarly: false });
      const response = await axios.get(
        `${config.API_BASE_URL}randevular/hasta?pol_vergi_no=${uRegister.id}&hasta_tc=${tc}&d_diploma_no=${uRegister.d_diploma_no}`
      );
      setErrors({});
      if (response.data.length > 0) {
        setDoktorPatientsAppointment(
          response.data.filter(
            (doktor) => doktor["d_diploma_no"] === uRegister.d_diploma_no
          )
        );
      } else {
        // toast.error("Kayıt bulunamadı!");
        setDoktorPatientsAppointment([]);
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
      } else {
        console.error(error);
      }
    }
  };

  const formatTcNumber = (inputTc) => {
    inputTc = inputTc.replace(/\D/g, "").slice(0, 11); // Sadece rakam karakterlerini tutar
    const match = inputTc.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `${match[1]}${match[2]}${match[3]}${match[4]}${match[5]}`;
    }
    return inputTc; // Eğer format uygun değilse, inputa girilen değeri olduğu gibi geri döndürür
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // let updatedInfo = { ...personalInfo, [name]: value };
    if (name === "tc") {
      const formattedTc = formatTcNumber(value);
      // updatedInfo.hasta_tc = formattedTc;

      setTc(formattedTc);
    }

    // setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
    // setPersonalInfo(updatedInfo);
  };

  return (
    <div>
      <div className="grid grid-cols-1 place-items-center my-9">
        {/* TC numarası ile sorgulama 👇🏻 */}
        <form className="flex justify-between items-center w-[40rem]">
          <label className="label-text text-center">HASTA TC KİMLİK NO</label>
          <input
            type="text"
            className="input input-bordered w-full max-w-xs"
            value={tc}
            name="tc"
            maxLength={"11"}
            // onChange={(e) => setTc(e.target.value)}
            onChange={handleChange}
          />
          <button
            type="submit"
            className="btn btn-sm btn-outline btn-primary rounded-full capitalize"
            onClick={handleSubmit}
          >
            Randevu Sorgula
          </button>
        </form>
        {errors.tc && (
          <p className="input-error-message text-red mt-2">{errors.tc}</p>
        )}
      </div>

      {/* patients appointments table 👇🏻 */}
      <div className="overflow-x-auto ">
        <table className="table w-full z-0">
          {/* <!-- head --> */}
          <thead className="text-gray">
            <tr>
              <th className="text-[16px]">HASTA</th>
              <th className="text-[16px]">CİNSİYET</th>
              <th className="text-[16px]">TARİH</th>
              <th className="text-[16px]">SAAT</th>
              <th className="text-[16px]">AÇIKLAMALAR</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {doktorPatientsAppointment?.length > 0 ? (
            doktorPatientsAppointment?.map((randevu) => (
              <tbody>
                {/* <!-- row 1 --> */}
                <tr className="border-b border-[#e9e9e9] leading-10">
                  <td>
                    {randevu.hasta.hasta_adi} {randevu.hasta.hasta_soyadi}
                  </td>
                  <td>{randevu.hasta.cinsiyet}</td>
                  <td>{randevu.randevu_tarih}</td>
                  <td>{randevu.randevu_baslangic_saati}</td>
                  <td>{randevu.sikayet_notu}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-[24px]">...</td>
                </tr>
              </tbody>
            ))
          ) : (
            <tbody>
              <tr>
                <td
                  className="capitalize p-2 shadow-lg mb-4 text-center"
                  colSpan={6}
                >
                  randevu bilgisi bulunamadı!
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default DoctorPatientsAppointments;
