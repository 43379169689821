import React, { useEffect, useState } from "react";

const Checkbox = ({ saat, gun, setSearchDoctor, searchDoctor }) => {
  const [isChecked, setIsChecked] = useState(0);

  useEffect(() => {
    setIsChecked(searchDoctor[gun] === 1);
  }, [gun, searchDoctor]);

  const handleCheckboxChange = () => {
    setSearchDoctor({ ...searchDoctor, [gun]: isChecked ? 0 : 1 }); // altta tersi oluşacağı için burada ters yazdık
    setIsChecked(!isChecked);
  };
  const degil = {
    height: "8px",
    width: "20px",
    border: "1px solid blue",
    borderRadius: "15px",
    padding: "4px 6px",
  };
  const secili = {
    height: "8px",
    width: "20px",
    borderRadius: "15px",
    border: "1px solid red",
    backgroundColor: "blue",
    color: "white",
    padding: "4px 6px",
  };
  return (
    <div className=" ">
      <label htmlFor={gun} style={isChecked ? secili : degil}>
        {saat}
      </label>
      <input
        style={{ display: "none" }}
        className="d-none"
        type="checkbox"
        id={gun}
        name="myCheckbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
    </div>
  );
};

export default Checkbox;
