import React, { useState } from "react";
import NavBar from "../../../components/dashboard/NavBar";
import Footer from "../../../components/dashboard/Footer";
import RegisterModal from "../../../components/dashboard/RegisterModal";
import LoginModal from "../../../components/dashboard/LoginModal";
import ForgotPassword from "../../../components/dashboard/ForgotPassword";
import AddPatientPopUp from "../../../components/dashboard/AddPatientPopUp";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Communication = () => {
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const [success, setSuccess] = useState(false);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [file_path, setFile_path] = useState("");

  const { toggleModalPatient } = useSelector((state) => state.toggleM);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      // const response = await addContact(formData);
      // console.log("Gönderme başarılı:", response);
      setSuccess(true);

      event.target.reset();

      setFirst_name("");
      setLast_name("");
      setEmail("");
      setPhone("");
      setTitle("");
      setMessage("");
      setFile_path("");
    } catch (error) {
      console.error("Gönderim hatası:", error);
      setSuccess("not success");
    }
  };

  // her sayfa yüklendiğinde scroll pozisyonunu başa alacaktır.
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <div className="h-full relative pt-10 ">
      <NavBar setShowLogin={setShowLogin} />
      <ScrollToTop />
      <section
        className="relative ml-5 md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
        id="contact"
      >
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">
              "BİZE ULAŞIN
            </h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
              Bizimle İletişime Geçin Size dönüş yapalım
            </h3>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
            <div className="lg:col-span-8">
              <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                <form onSubmit={handleSubmit}>
                  <div className="grid lg:grid-cols-12 lg:gap-6">
                    <div className="lg:col-span-6 mb-5">
                      <input
                        name="first_name"
                        id="first_name"
                        type="text"
                        className="form-input"
                        placeholder="Adınız"
                        required={true}
                        value={first_name}
                        onChange={(e) => setFirst_name(e.target.value)}
                      />
                    </div>
                    <div className="lg:col-span-6 mb-5">
                      <input
                        name="last_name"
                        id="last_name"
                        type="text"
                        className="form-input"
                        placeholder="Soyadınız"
                        required={true}
                        value={last_name}
                        onChange={(e) => setLast_name(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-12 lg:gap-6">
                    <div className="lg:col-span-6 mb-5">
                      <input
                        name="email"
                        id="email"
                        type="email"
                        className="form-input"
                        placeholder="Email"
                        required={true}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="lg:col-span-6 mb-5">
                      <input
                        name="phone"
                        id="phone"
                        type="phone"
                        className="form-input"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Telefon"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1">
                    <div className="mb-5">
                      <input
                        name="title"
                        id="title"
                        className="form-input"
                        placeholder="Konu"
                        required={true}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="mb-5">
                      <textarea
                        name="message"
                        id="message"
                        className="form-input textarea h-28"
                        placeholder="Mesaj"
                        required={true}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="mb-5">
                      <label htmlFor="file_path" className="form-label">
                        Dosya Yükle
                      </label>
                      <input
                        type="file"
                        name="file_path"
                        id="file_path"
                        className="form-input"
                        value={file_path}
                        onChange={(e) => setFile_path(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      className="btn bg-[#5616f5] hover:bg-[#0C098D] border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center mr-3"
                    >
                      Mesaj Gönder
                    </button>
                    {success === true ? (
                      <p className="text-orange-500 text-sm">
                        Mesaj Gönderme işlemi Başarılı!
                      </p>
                    ) : (
                      ""
                    )}
                    {success === "not success" && (
                      <p className="text-orange-500 text-sm">
                        Mesaj Gönderme işlemi Başarısız
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className="lg:col-span-4">
              <div className="lg:ms-8">
                <div className="flex">
                  <div className="icons text-center mx-auto">
                    {/* <Unicons.UilPhone className="rounded text-2xl dark:text-white mb-0" /> */}
                  </div>

                  <div className="flex-1 ms-6">
                    <h5 className="text-lg dark:text-white mb-2 font-medium">
                      Telefon
                    </h5>
                    <Link href="+90 212 563 36 36" className="text-slate-400">
                      +90 212 563 36 36
                    </Link>
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="icons text-center mx-auto">
                    {/* <Unicons.UilEnvelope className="rounded text-2xl dark:text-white mb-0" /> */}
                  </div>

                  <div className="flex-1 ms-6">
                    <h5 className="text-lg dark:text-white mb-2 font-medium">
                      Email
                    </h5>
                    <Link href="dentreys@gmail.com" className="text-slate-400">
                      dentreys@gmail.com
                    </Link>
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="icons text-center mx-auto">
                    {/* <Unicons.UilMapMarker className="rounded text-2xl dark:text-white mb-0" /> */}
                  </div>

                  <div className="flex-1 ms-6">
                    <h5 className="text-lg dark:text-white mb-2 font-medium">
                      Adres
                    </h5>
                    <p className="text-slate-400 mb-2">Küçükyalı / İstanbul</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {showRegister ? (
        <RegisterModal
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
        />
      ) : null}
      {showLogin ? (
        <LoginModal
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}
      {showForgotPassword ? (
        <ForgotPassword
          setShowLogin={setShowLogin}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}
      {toggleModalPatient && <AddPatientPopUp />}{" "}
      {/* Yeni hasta kaydetme modalını çaşırır */}
    </div>
  );
};

export default Communication;
