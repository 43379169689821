import React, { useState } from "react";
import NavBar from "../../../components/dashboard/NavBar";
import Footer from "../../../components/dashboard/Footer";
import RegisterModal from "../../../components/dashboard/RegisterModal";
import LoginModal from "../../../components/dashboard/LoginModal";
import ForgotPassword from "../../../components/dashboard/ForgotPassword";
import AddPatientPopUp from "../../../components/dashboard/AddPatientPopUp";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Security = () => {
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const { toggleModalPatient } = useSelector((state) => state.toggleM);

  // her sayfa yüklendiğinde scroll pozisyonunu başa alacaktır.
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <div className="h-full relative pt-10 ">
      <NavBar setShowLogin={setShowLogin} />
      <ScrollToTop />
      <div className="container m-auto w-4/5 h-full">
        <div className="mt-10 h-[80vh]">
          <div className="lg:col-span-7">
            {/* <div className="lg:ms-7"> */}
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
              Gizlilik
            </h3>
            <h4 className="text-slate-400 text-xl dark:text-slate-300 max-w-2xl">
              Gizlilik
            </h4>
            <p className="text-slate-400 dark:text-slate-300 max-w-2xl">
              Açıklama
            </p>
          </div>
        </div>
      </div>
      <Footer />
      {showRegister ? (
        <RegisterModal
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
        />
      ) : null}
      {showLogin ? (
        <LoginModal
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}
      {showForgotPassword ? (
        <ForgotPassword
          setShowLogin={setShowLogin}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}
      {toggleModalPatient && <AddPatientPopUp />}{" "}
      {/* Yeni hasta kaydetme modalını çaşırır */}
    </div>
  );
};

export default Security;
