import React, { useRef } from "react";
import { ilData } from "../../../helpers/ilData";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import config from "../../../config";

const InfoPatientForm = ({
  queryTC,
  setQueryTC,
  setPersonalInfo,
  personalInfo,
  setShowInfoOfPatient,
  setShowInfoOfPatientForm,
}) => {
  const ilceRef = useRef(null); // il ilk seçildiğinde ilçe geliyor ama onchange çalışmadığından  ilçe bilgisi alınmıyor bu şekilde alacağız
  const uRegister = useSelector((state) => state.uRegister);

  const handleChange = (e) => {
    setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await axios.post(`${config.API_BASE_URL}hasta_uye`, {
      hasta_tc: queryTC,
      hasta_adi: personalInfo.firstName,
      hasta_soyadi: personalInfo.lastName,
      hasta_baba_adi: personalInfo.dadyName,
      hasta_il: personalInfo.city,
      hasta_ilce: ilceRef.current.value,
      hasta_tel: personalInfo.phone,
      hasta_dogum_tarihi: personalInfo.birthDay,
      cinsiyet: personalInfo.gender,
    });

    if (data !== 400) {
      toast.success("Bilgiler kaydedildi");
      toast.info("Randevu işleminize devam edebilirsiniz.");
      setShowInfoOfPatient(true);
      setShowInfoOfPatientForm(false);
    }
  };
  return (
    <section className="px-1 py-5 mt-8">
      <article>
        <h3>
          Girilen TC kimlik numarasına ait bir kayıt bulunamadı. Lütfen Randevu
          almak için aşağıdaki formu doldurun.
        </h3>
        <h1 className="text-22 mt-8 mb-4">Hasta Bilgileri</h1>
      </article>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-6">
          <div className="flex justify-between gap-8">
            <div className="w-full">
              <label className="label">
                <span className="label-text">
                  Adınız<sup>*</sup>
                </span>
              </label>
              <input
                type="text"
                name="firstName"
                value={personalInfo?.firstName}
                onChange={handleChange}
                required
                className="input input-bordered  w-full "
                autoFocus
              />
            </div>
            <div className="w-full">
              <label className="label">
                <span className="label-text">
                  Soyadınız<sup>*</sup>
                </span>
              </label>
              <input
                type="text"
                name="lastName"
                value={personalInfo?.lastName}
                onChange={handleChange}
                required
                className="input input-bordered  w-full "
              />
            </div>
            <div className="w-full">
              <label className="label">
                <span className="label-text">
                  TC Kimlik Numaranız<sup>*</sup>
                </span>
              </label>
              <input
                type="text"
                name="tc"
                value={queryTC}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Sadece rakam girişine izin vermek için düzenli ifade kullanılır
                  const numericInput = inputValue.replace(/\D/g, "");
                  // En fazla 11 haneli TC Kimlik numarasına izin ver
                  if (numericInput.length <= 11) {
                    setQueryTC(numericInput);
                  }
                }}
                required
                className="input input-bordered  w-full "
              />
            </div>
          </div>
          <div className="flex justify-between gap-8">
            <div className="w-full">
              <label className="label">
                <span className="label-text">
                  Baba Adı<sup>*</sup>
                </span>
              </label>
              <input
                type="text"
                name="dadyName"
                value={personalInfo?.dadyName}
                onChange={handleChange}
                required
                className="input input-bordered  w-full "
              />
            </div>
            <div className="w-full">
              <label className="label">
                <span className="label-text">
                  Doğum Tarihi<sup>*</sup>
                </span>
              </label>
              <input
                type="date"
                name="birthDay"
                value={personalInfo?.birthDay}
                onChange={handleChange}
                required
                className="input input-bordered  w-full "
              />
            </div>
            <div className="w-full">
              <label className="label">
                <span className="label-text">
                  Telefon<sup>*</sup>
                </span>
              </label>
              <input
                type="text"
                name="phone"
                value={personalInfo?.phone}
                onChange={handleChange}
                required
                className="input input-bordered  w-full "
              />
            </div>
          </div>
          <div className="flex justify-between gap-8">
            <div className="w-full">
              <label className="label">
                <span className="label-text">
                  Email<sup>*</sup>
                </span>
              </label>
              <input
                type="email"
                name="email"
                value={personalInfo?.email}
                onChange={handleChange}
                required
                className="input input-bordered  w-full "
              />
            </div>
            <div className="w-full">
              <label className="label">
                <span className="label-text">
                  Cinsiyet<sup>*</sup>
                </span>
              </label>
              <select
                name="gender"
                value={personalInfo?.gender}
                onChange={handleChange}
                required
                className="select select-bordered text-14 font-normal focus:border-blue1 w-full "
              >
                <option disabled>Seçiniz</option>
                <option value="K">K</option>
                <option value="E">E</option>
              </select>
            </div>
            <div className="w-full">
              <label className="label">
                <span className="label-text">
                  İl<sup>*</sup>
                </span>
              </label>
              <select
                name="city"
                value={personalInfo?.city}
                onChange={handleChange}
                required
                className="select select-bordered text-14 font-normal focus:border-blue1 w-full "
              >
                <option disabled>İl Seçiniz</option>
                {ilData.map((il, i) => (
                  <option value={il["text"]} key={i}>
                    {il["text"]}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full">
              <label className="label">
                <span className="label-text">
                  İlçe<sup>*</sup>
                </span>
              </label>
              <select
                ref={ilceRef}
                name="town"
                value={personalInfo?.town}
                onChange={handleChange}
                required
                className=" select select-bordered text-14 font-normal focus:border-blue1 w-full "
              >
                {ilData
                  .filter((data) => data["text"] === personalInfo?.city)
                  .map((ilce) =>
                    ilce["districts"].map((e, i) => (
                      <option value={e["text"]} key={i}>
                        {e["text"]}
                      </option>
                    ))
                  )}
              </select>
            </div>
          </div>
        </div>
        {/*footer*/}
        <div className="flex items-center justify-end mx-4 mt-4 p-2 rounded-b">
          <button
            className="bg-blue1 text-white capitalize btn btn-sm rounded-3xl hover:bg-blue1"
            type="submit"
            // onClick={handleSubmit}
          >
            Bilgileri Kaydet
          </button>
        </div>
      </form>
    </section>
  );
};

export default InfoPatientForm;
