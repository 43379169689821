import React, { useEffect, useState } from "react";
import { useRef } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Controller,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import config from "../../../config";

const DoctorsForRecord = ({
  setDoctor,
  date,
  setInfo,
  polyclinic,
  polyclinicDoctors,
  setShowClock,
  setShowOzet,
  setDoctorClocks,
  handleScrollToTopClick,
  setClock,
}) => {
  const [openTab, setOpenTab] = useState(999);
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();
  const uRegister = useSelector((state) => state.uRegister);

  const getDoctorClocks = async (diploma_no) => {
    const { data } = await axios.post(
      `${config.API_BASE_URL}randevu_al_saat`,
      {
        d_diploma_no: diploma_no,
        alinan_tarih: date.length > 10 ? date.slice(0, 10) : date, //date uzun tarih olabilir
      }
    );

    setDoctorClocks(data);
    setShowClock(true);
    handleScrollToTopClick("saatler");
  };

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);
  // console.log(polyclinicDoctors);
  return (
    <section className="">
      <article className="flex justify-between px-1 mx-auto h-8">
        {/* <h1>Doktorlarımız</h1> */}
        <article className="flex gap-3">
          <button
            className="bg-white w-10 h-10 flex items-center justify-center rounded-full"
            ref={prevRef}
          >
            <HiOutlineArrowLeft className="" />
          </button>
          <button
            className="bg-white w-10 h-10 flex items-center justify-center rounded-full"
            ref={nextRef}
          >
            <HiOutlineArrowRight className="" />
          </button>
        </article>
      </article>
      <div className="p-1 my-10 mx-auto">
        <Swiper
          className="flex flex-row gap-5"
          spaceBetween={15}
          // slidesPerView="auto"
          slidesPerView={5}
          // onSlideChange={() => console.log("slide change")}
          onSwiper={setSwiper}
          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
            Controller,
            Autoplay,
          ]}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          autoplay={false}
          loop={false}
          // updateOnWindowResize
          // observer
          // observeParents
        >
          {polyclinicDoctors.length > 0 ? (
            polyclinicDoctors?.map((doktor, index) => (
              <SwiperSlide
                className={` ${
                  openTab === index
                    ? "card w-1/5 bg-gradient-to-r  from-blue1 to-blue2 text-white cursor-pointer"
                    : "card w-1/5 bg-white cursor-pointer"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setInfo(
                    "Randevu saati seçiniz. Kırmızı işaretliler dolu saatlerdir!"
                  );
                  setOpenTab(index);
                  setDoctor(doktor);
                  setShowOzet(true);
                  setShowClock(false);
                  getDoctorClocks(doktor.d_diploma_no);
                  // setClock("");
                  // console.log(setClock);
                }}
                key={index}
              >
                {/* https://www.seekpng.com/png/full/855-8557328_headshot-of-dr-male-dentist-png.png */}
                <figure className="pt-5">
                  <img
                    src={
                      doktor.d_resim
                        ? doktor.d_resim
                        : "https://w7.pngwing.com/pngs/488/261/png-transparent-female-doctor-3d-illustration-physician-nursing-health-care-hospital-the-doctor-miscellaneous-image-file-formats-service-thumbnail.png"
                    }
                    alt={doktor.d_adi + doktor.d_soyadi}
                    className="w-36 h-36 rounded-full object-contain border border-lightGray bg-container"
                  />
                </figure>
                <div className="text-center py-3">
                  <h2 className="text-center text-xl font-semibold line-clamp-2 truncate  h-16 p-2">
                    {doktor.d_adi + " " + doktor.d_soyadi}
                  </h2>
                  <p
                    className={` ${
                      openTab === index
                        ? "text-white text-center"
                        : "text-blue1 text-center"
                    }`}
                  >
                    {doktor.d_unvan}
                  </p>
                </div>
              </SwiperSlide>
            ))
          ) : (
            <p>Doktor bulunamadı</p>
          )}
        </Swiper>
      </div>
    </section>
  );
};

export default DoctorsForRecord;
