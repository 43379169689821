import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../../config";

const TodayDoctorAppointments = () => {
  const uRegister = useSelector((state) => state.uRegister);

  const [doktorDateAppointment, setDoktorDateAppointment] = useState([]);
  const [goster, setgoster] = useState(false);

  useEffect(() => {
    const getAppointment = async () => {
      setgoster(false);
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}randevular/bugun?pol_vergi_no=${uRegister.id}&d_diploma_no=${uRegister.d_diploma_no}`
        );

        if (!response.data.status !== 400) {
          setDoktorDateAppointment(response.data.randevular);
          setgoster(true);
        } else {
          setgoster(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAppointment();
  }, []);

  return (
    <div className="overflow-x-auto ">
      <table className="table table-auto w-full z-0 ">
        {/* <!-- head --> */}
        <thead className="text-gray ">
          <tr>
            <th className="text-[16px]">HASTA</th>
            <th className="text-[16px]">CİNSİYET</th>
            <th className="text-[16px]">TARİH</th>
            <th className="text-[16px]">SAAT</th>
            <th className="text-[16px]">AÇIKLAMALAR</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        {doktorDateAppointment?.length > 0 ? (
          doktorDateAppointment?.map((randevu) => (
            <tbody>
              {/* <!-- row 1 --> */}
              <tr className="border-b border-[#e9e9e9] leading-10">
                <td>
                  {randevu.hasta.hasta_adi} {randevu.hasta.hasta_soyadi}
                </td>
                <td>{randevu.hasta.cinsiyet}</td>
                <td>{randevu.randevu_tarih}</td>
                <td>{randevu.randevu_baslangic_saati}</td>
                <td>{randevu.sikayet_notu}</td>
                <td></td>
                <td></td>
                <td className="text-[24px]"> ... </td>
              </tr>
            </tbody>
          ))
        ) : (
          <tbody>
            <tr>
              <td
                className="capitalize p-2 shadow-lg mb-4 text-center"
                colSpan={6}
              >
                Bugüne ait randevu bilgisi bulunamadı!
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default TodayDoctorAppointments;
