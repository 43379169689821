import React, { useEffect, useState } from "react";

const CheckBox = ({
  saat,
  saatler,
  setSaatler,
  date,
  selectedDoctor,
  setSelectedSaatler,
  selectedSaatler,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  // console.log("date", date); // date 2023-12-16
  // console.log("selectedDoctor", selectedDoctor);

  // useEffect(() => {
  //   setIsChecked(saatler[saat] === 1);
  // }, [saat, saatler]);
  useEffect(() => {
    setIsChecked(selectedSaatler.includes(saat));
  }, [saat, selectedSaatler]);

  const handleCheckboxChange = () => {
    if (saatler[saat] === 2) {
      return; // Do not change if saat is disabled
    }
    const [year, month, day] = date
      .split("-")
      .map((part) => parseInt(part, 10));
    const selectedDate = new Date(year, month - 1, day);
    const [hours, minutes] = saat.split(":").map((part) => parseInt(part, 10));

    // Seçilen saat ve tarihi birleştir
    selectedDate.setHours(hours);
    selectedDate.setMinutes(minutes);

    const currentTime = new Date();
    const isPastTime = selectedDate < currentTime;

    // console.log("selectedDate", selectedDate);
    // console.log("currentTime", currentTime);
    // console.log("date", date);
    if (isPastTime) {
      alert("Geçmiş bir zaman dilimi seçemezsiniz!");
      return;
    }
    if (!selectedDoctor) {
      alert("Önce Doktor Seçiniz!");
      return;
    }

    const updatedSaatler = { ...saatler };

    if (!isChecked) {
      Object.keys(updatedSaatler).forEach((key) => {
        if (updatedSaatler[key] === 1) {
          updatedSaatler[key] = 0; // Uncheck other saatler with value 1
        }
        updatedSaatler[saat] = 1; // Check the selected saat
        if (updatedSaatler[key] === 2) {
        }
      });
    }
    // Birden fazla saat seçimine imkan tanımak için.
    if (selectedSaatler.includes(saat)) {
      // Saat zaten seçili, kaldır
      const updatedSaatler = selectedSaatler.filter(
        (selected) => selected !== saat
      );
      setSelectedSaatler(updatedSaatler);
    } else {
      // Saati seç
      setSelectedSaatler([...selectedSaatler, saat]);
    }

    setSaatler(updatedSaatler);
    setIsChecked(!isChecked);
  };

  const degil = {
    width: "11px",
    border: "1px solid blue",
    borderRadius: "15px",
    padding: "2px 4px",
    cursor: "pointer",
    fontWeight: "bold",
    userSelect: "none",
  };
  const dissabled = {
    width: "11px",
    border: "1px solid white",
    borderRadius: "15px",
    padding: "2px 4px",
    cursor: "pointer",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "red",
    userSelect: "none",
  };

  const secili = {
    width: "11px",
    borderRadius: "15px",
    border: "1px solid red",
    backgroundColor: "blue",
    color: "white",
    padding: "2px 4px",
    cursor: "pointer",
    fontWeight: "bold",
    userSelect: "none",
  };

  return (
    <div>
      <label
        htmlFor={saat}
        style={isChecked ? secili : saatler[saat] === 2 ? dissabled : degil}
      >
        {saat}
      </label>
      <input
        style={{ display: "none" }}
        className="d-none"
        type="checkbox"
        id={saat}
        disabled={saatler[saat] === 2}
        name="myCheckbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
    </div>
  );
};

export default CheckBox;
