import React from "react";
import { useSelector } from "react-redux";

const DoctorHeader = () => {
  const uRegister = useSelector((state) => state.uRegister);

  // Numarayı 0(555) 636 63 36 formatına çevir
  const formatPhoneNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/\D/g, ""); // Sadece sayısal karakterleri alır
    const match = phoneNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `0(${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
    }
    return phoneNumber; // Eğer format uygun değilse, orijinal değeri döndürür
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-12 flex-col gap-0 xl:gap-6 mt-2  py-1 justify-between">
      {/* clinic header infos */}
      <div className="flex justify-start items-center px-5 py-2 col-span-6">
        <img
          width={100}
          src={uRegister.pol_logo} //{uRegister.pol_logo}
          alt={uRegister.pol_adi}
        />
        <div className="text-2xl text-Sky-700 ml-6">
          <h1 className="font-bold" style={{ fontSize: "2rem" }}>
            {uRegister.pol_adi}
          </h1>
          <h2>POLİKLİNİĞİ</h2>
        </div>
      </div>

      <div className="xs:col-span-6 lg:col-span-3 p-3">
        <h3 className="text-xl text-slate-500">ADRES</h3>
        <p>{uRegister.adres}</p>
        <p className="">
          {" "}
          {uRegister.pol_ilce} / {uRegister.pol_il}
        </p>
      </div>
      <div className="xs:col-span-6  lg:col-span-3 p-3">
        <h3 className="text-xl text-slate-500">TELEFON</h3>
        {/* {uRegister.d_tel} */}
        {formatPhoneNumber(uRegister.d_tel)}
      </div>
    </div>
  );
};

export default DoctorHeader;
