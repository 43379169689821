import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../../config";

const TodayAppointments = () => {
  const uRegister = useSelector((state) => state.uRegister);
  const [doktorAppointment, setDoktorAppointment] = useState([]);

  const todayAppointmentList = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}randevular/bugun?pol_vergi_no=${uRegister.pol_vergi_no}`,
        {}
      );

      if (response.data.randevular.length > 0) {
        setDoktorAppointment(response.data.randevular);
      } else {
        // toast.error("Kayıt bulunamadı!");
        setDoktorAppointment([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    todayAppointmentList();
  }, []);

  return (
    //! appointments table 👇🏻
    <div className="overflow-x-auto ">
      <table className="table w-full z-0">
        {/* <!-- head --> */}
        <thead className="text-gray">
          <tr>
            <th className="pl-20 text-[16px]">DOKTOR</th>
            <th className="text-[16px]">ÜNVAN</th>
            <th className="text-[16px]">HASTA</th>
            <th className="text-[16px]">TARİH</th>
            <th className="text-[16px]">SAAT</th>
            <th className="text-[16px]">AÇIKLAMALAR</th>
          </tr>
        </thead>
        {doktorAppointment?.length > 0 ? (
          doktorAppointment?.map((randevu) => (
            <tbody>
              {/* <!-- row 1 --> */}
              <tr className="border-b border-[#e9e9e9] leading-10">
                <td>
                  <div className="flex items-center space-x-3 gap-3">
                    <div className="avatar">
                      <div className="rounded-full w-10">
                        <img
                          src={randevu.doktor.d_resim}
                          alt="Doktor Avatar"
                          className="object-top"
                        />
                      </div>
                    </div>
                    <div>
                      {randevu.doktor.d_adi} {randevu.doktor.d_soyadi}
                    </div>
                  </div>
                </td>
                <td></td>
                <td>
                  {" "}
                  {randevu.hasta.hasta_adi} {randevu.hasta.hasta_soyadi}
                </td>
                <td>{randevu.randevu_tarih}</td>
                <td>{randevu.randevu_baslangic_saati}</td>
                <td>{randevu.sikayet_notu}</td>
              </tr>
            </tbody>
          ))
        ) : (
          <tbody>
            <tr>
              <td
                className="capitalize p-2 shadow-lg mb-4 text-center"
                colSpan={6}
              >
                Bugüne ait randevu bilgisi bulunamadı!
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default TodayAppointments;
