import axios from "axios";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import config from "../../../config";

const schema = yup.object().shape({
  tc: yup
    .string()
    .required("TC Kimlik Numarası zorunludur.")
    .matches(
      /^[0-9]{11}$/,
      "11 haneli Geçerli bir TC Kimlik Numarası giriniz."
    ),
});

const PatientsAppointments = () => {
  const uRegister = useSelector((state) => state.uRegister);

  const [doktorAppointment, setDoktorAppointment] = useState();
  const [noRecordsFound, setNoRecordsFound] = useState("");
  const [tc, setTc] = useState();
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Sadece rakam girişine izin vermek için düzenli ifade kullanılır
    const numericInput = inputValue.replace(/\D/g, "");
    // En fazla 11 haneli TC Kimlik numarasına izin ver
    if (numericInput.length <= 11) {
      setTc(numericInput);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await schema.validate({ tc }, { abortEarly: false });
      const response = await axios.get(
        `${config.API_BASE_URL}randevular/hasta?pol_vergi_no=${uRegister.id}&hasta_tc=${tc}`
      );
      setErrors({});
      if (response.data.length > 0) {
        setDoktorAppointment(response.data);
        setNoRecordsFound("");
      } else {
        setNoRecordsFound(response.data.message);
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 place-items-center my-9">
        {/* TC numarası ile sorgulama 👇🏻 */}
        <form
          className="flex justify-between items-center w-[40rem]"
          onSubmit={handleSubmit}
        >
          <label className="label-text text-center">HASTA TC KİMLİK NO</label>
          <input
            type="text"
            className="input input-bordered w-full max-w-xs"
            value={tc}
            onChange={handleChange}
          />
          <button
            type="submit"
            className="btn btn-sm btn-outline btn-primary rounded-full capitalize"
          >
            Randevu Sorgula
          </button>
        </form>
        {errors.tc && (
          <p className="input-error-message text-red mt-2">{errors.tc}</p>
        )}
      </div>
      {noRecordsFound === "Kayıt bulunamadı" && (
        <p className="capitalize text-red w-[30rem] p-2 mb-4 text-center">
          Lütfen Tc Kimlik numaranızı Kontrol ederek Tekrar deneyin!
        </p>
      )}

      {/* patients appointments table 👇🏻 */}
      <div className="overflow-x-auto ">
        <table className="table w-full z-0">
          {/* <!-- head --> */}
          <thead className="text-gray">
            <tr>
              <th className="pl-20 text-[16px]">DOKTOR</th>
              <th className="text-[16px]">HASTA</th>
              <th className="text-[16px]">CİNSİYET</th>
              <th className="text-[16px]">TARİH</th>
              <th className="text-[16px]">SAAT</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {doktorAppointment?.length > 0 ? (
            noRecordsFound === "Kayıt bulunamadı" ||
            doktorAppointment?.map((randevu) => (
              <tbody>
                {/* <!-- row 1 --> */}
                <tr className="border-b border-[#e9e9e9] leading-10">
                  <td>
                    <div className="flex items-center space-x-3 gap-3">
                      <div className="avatar">
                        <div className="rounded-full w-10">
                          <img
                            src={randevu.doktor?.d_resim}
                            alt="Doktor Avatar"
                            className="object-top"
                          />
                        </div>
                      </div>
                      <div>
                        {randevu.doktor?.d_adi} {randevu?.doktor?.d_soyadi}
                      </div>
                    </div>
                  </td>
                  <td>
                    {" "}
                    {randevu.hasta.hasta_adi} {randevu.hasta.hasta_soyadi}
                  </td>
                  <td>{randevu.hasta.cinsiyet}</td>
                  <td>{randevu.randevu_tarih}</td>
                  <td>{randevu.randevu_baslangic_saati}</td>
                </tr>
              </tbody>
            ))
          ) : (
            <tbody>
              <tr>
                <td
                  className="capitalize p-2 shadow-lg mb-4 text-center"
                  colSpan={6}
                >
                  randevu bilgisi bulunamadı!
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default PatientsAppointments;
