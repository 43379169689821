import React from "react";
import DateInput from "./DateInput";
import DateRangePickerComp from "../appointments/DateRangePickerComp";
import Range from "./Range";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "react-redux";
import config from "../../../config";

const DoctorPermitsTable = ({ doktor, pol }) => {
  const uRegister = useSelector((state) => state.uRegister);
  const [doctorPermits, setDoctorPermits] = useState();
  const [filteredDoctorPermits, setFilteredDoctorPermits] = useState();
  const [izinTuruL, setIzinTuruL] = useState(0);
  const [izinTuruListOpen, setIzinTuruListOpen] = useState(false);

  const izinTurleri = [
    { id: 0, name: "TÜMÜ" },
    { id: 1, name: "YILLIK İZİN" },
    { id: 2, name: "DOĞUM İZNİ" },
    { id: 3, name: "BABALIK İZNİ" },
    { id: 4, name: "SAĞLIK RAPORU" },
    { id: 5, name: "İDARİ İZİN" },
  ];

  const [izinTuru, setIzinTuru] = useState();
  const [state, setState] = useState([
    // bu kısım range den tarih aralığını almak için lazım
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const tarihFormatCevir = (tarih) => {
    const date = new Date(tarih);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  function hesaplaIzinGunleri(tarih1, tarih2) {
    // haftasonlarını düşer
    const tarih1Obj = new Date(tarih1);
    const tarih2Obj = new Date(tarih2);

    const gunSayisi = Math.floor(
      (tarih2Obj - tarih1Obj) / (1000 * 60 * 60 * 24)
    );

    let izinGunleri = 0;
    for (let i = 0; i <= gunSayisi; i++) {
      const tarih = new Date(tarih1Obj.getTime() + i * (1000 * 60 * 60 * 24));
      const haftaGunu = tarih.getDay(); // 0: Pazar, 1: Pazartesi, ... , 6: Cumartesi

      if (haftaGunu !== 0 && haftaGunu !== 6) {
        izinGunleri++;
      }
    }

    return izinGunleri;
  }

  function hesaplaGunSayisi(tarih1, tarih2) {
    const tarih1Obj = new Date(tarih1);
    const tarih2Obj = new Date(tarih2);

    const zamanFarki = tarih2Obj.getTime() - tarih1Obj.getTime();
    const gunSayisi = zamanFarki / (1000 * 60 * 60 * 24) + 1;

    return gunSayisi;
  }

  const getIzinler = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}klinik_doktorlar/performans/izin?pol_vergi_no=${pol}&d_diploma_no=${doktor?.d_diploma_no}`,
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      if (response.data !== 400) {
        setDoctorPermits(response.data);
        setFilteredDoctorPermits(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(doktor);
  useEffect(() => {
    // Uyguladığı tedavilerin başlıklarının  getirr  bunu burada çağırıp en alttaki komponente iletiyoruz  en alttaki komponent daha çok render olduğu için mümkün olduğunca az çağırılsın diye burada çağırdık
    getIzinler();
  }, [doktor?.d_diploma_no]);

  const handleIzinTuruSelect = (selectedIzinTuru) => {
    setIzinTuruL(selectedIzinTuru);
    setIzinTuruListOpen(false);
  };

  // console.log(filteredDoctorPermits);
  const handleIzinTuruClick = () => {
    setIzinTuruListOpen(!izinTuruListOpen);
  };

  const izinAl = async () => {
    if (
      pol === "" ||
      doktor?.d_diploma_no === "" ||
      state.startDate === null ||
      state.endDate === null ||
      izinTuru === ""
    ) {
      toast.error("Lütfen eksik bilgileri tamamlayın!");
    } else {
      const today = new Date();
      const maxDate = new Date(today.getTime() - 15 * 24 * 60 * 60 * 1000); // 15 gün öncesini al

      if (state[0].startDate < maxDate) {
        toast.error("İzin başlangıç tarihi en fazla 15 gün öncesi olmalıdır!");
        return;
      }
      const formattedStartDate = tarihFormatCevir(state[0].startDate);
      const formattedEndDate = tarihFormatCevir(state[0].endDate);

      const response = await axios.post(
        `${config.API_BASE_URL}klinik_doktorlar/performans/izinKayit?pol_vergi_no=${pol}&d_diploma_no=${doktor.d_diploma_no}&izin_baslangic_tarihi=${formattedStartDate}&izin_bitis_tarihi=${formattedEndDate}&izin_turu=${izinTuru}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      // console.log(response);
      if (!response.data.message) {
        toast.success("İzin kaydedildi");
        setIzinTuru("");
        getIzinler();
      } else {
        toast.error(response.data.message);
      }
    }
  };

  useEffect(() => {
    if (doctorPermits) {
      if (izinTuruL === 0) {
        setFilteredDoctorPermits(doctorPermits);
      } else {
        const filtrele = doctorPermits.alinanIzinler.filter(
          (item) => item?.izin_turu === izinTuruL.toString()
        );
        setFilteredDoctorPermits({ alinanIzinler: filtrele });
      }
    }
  }, [doctorPermits, izinTuruL]);

  return (
    <div className="">
      <p className="text-xl">Yeni İzin</p>
      <div className="flex flex-wrap">
        {/* <DateInput /> */}
        {/* <DateRangePickerComp/> */}
        <div>
          <div className="">
            <button
              className="bg-blue1 w-28 mr-1 text-white capitalize btn btn-sm rounded-3xl hover:bg-blue1 mb-0.5"
              onClick={() => izinAl()}
            >
              Kaydet
            </button>
            <select
              value={izinTuru}
              onChange={(e) => setIzinTuru(e.target.value)}
              className="form-select"
            >
              <option value="">Lütfen bir izin türü seçin</option>
              <option value="1">Yıllık İzin</option>
              <option value="2">Doğum İzni</option>
              <option value="3">Babalık İzni</option>
              <option value="4">Sağlık Raporu</option>
              <option value="5">İdari İzin</option>
            </select>
          </div>
          <Range state={state} setState={setState} />
        </div>
        <div className="ml-3">
          <p className="text-xl">Tüm İzinler</p>
          <table className="table font-semibold w-[50rem]">
            {/* <!-- head --> */}
            <thead>
              <tr>
                <th className="bg-blue1 text-white">NO</th>
                <th className="bg-blue1 text-white">İZİN BAŞLANGIÇ</th>
                <th className="bg-blue1 text-white">İZİN BİTİŞ</th>
                <th className="bg-blue1 text-white">İZİNLİ GÜN</th>
                <th
                  className="bg-blue1 text-white"
                  onClick={handleIzinTuruClick}
                >
                  {
                    <Select
                      className="basic-single text-blue1"
                      options={izinTurleri.map((tur) => ({
                        value: tur.id,
                        label: tur.name,
                      }))}
                      onChange={(selectedOption) => {
                        // console.log(selectedOption);
                        return handleIzinTuruSelect(selectedOption.value);
                      }}
                      classNamePrefix="select"
                      // value={filteredDoctorPermits}
                      defaultValue={{ value: "", label: "İZİN TÜRÜ" }}
                    />
                  }
                </th>
              </tr>
            </thead>

            {filteredDoctorPermits &&
              filteredDoctorPermits.alinanIzinler?.map((permit, i) => {
                // console.log(permit);
                return (
                  <tbody key={i}>
                    {/* <!-- row 1 --> */}
                    <tr>
                      <td className="font-bold">{i}</td>
                      <td>{permit.izin_baslangic_tarihi}</td>
                      <td>{permit.izin_bitis_tarihi}</td>
                      <td>
                        {hesaplaGunSayisi(
                          permit.izin_baslangic_tarihi,
                          permit.izin_bitis_tarihi
                        )}
                      </td>
                      <td>
                        {permit?.izin_turu === "1" && "YILLIK İZİN"}
                        {permit?.izin_turu === "2" && "DOĞUM İZNİ"}
                        {permit?.izin_turu === "3" && "BABALIK İZNİ"}
                        {permit?.izin_turu === "4" && "SAĞLIK RAPORU"}
                        {permit?.izin_turu === "5" && "İDARİ İZİN"}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default DoctorPermitsTable;
