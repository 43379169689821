import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";

export let dateForArray;
const AppointmentDate = ({
  setClock,
  date,
  doctor,
  setShowComplaintNote,
  doctorClocks,
  setShowOnayButton,
}) => {
  const [doktorMusaitSaatler, setDoktorMusaitSaatler] = useState([]);
  const [tamListe, setTamListe] = useState();
  const [okk, setOkk] = useState(false);

  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();

  const saatler = [
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
  ];

  const availableTimes_1 = [
    "09:00:00", //format :00 geldiği için başta böyle aldık
    "09:15:00",
    "09:30:00",
    "09:45:00",
    "10:00:00",
    "10:15:00",
    "10:30:00",
    "10:45:00",
    "11:00:00",
    "11:15:00",
    "11:30:00",
    "11:45:00",
    "12:00:00",
    "12:15:00",
    "12:30:00",
  ];

  const availableTimes_2 = [
    "13:30:00",
    "13:45:00",
    "14:00:00",
    "14:15:00",
    "14:30:00",
    "14:45:00",
    "15:00:00",
    "15:15:00",
    "15:30:00",
    "15:45:00",
    "16:00:00",
    "16:15:00",
    "16:30:00",
    "16:45:00",
    "17:00:00",
  ];
  const availableTimes_3 = [
    "18:00:00",
    "18:15:00",
    "18:30:00",
    "18:45:00",
    "19:00:00",
    "19:15:00",
    "19:30:00",
    "19:45:00",
    "20:00:00",
  ];

  // haftanın hangi günü olduğunu aldık
  function getLocalDayOfWeek(dateStr) {
    const date = new Date(dateStr + "T00:00:00");
    const daysOfWeek = [
      "pazar",
      "pazartesi",
      "sali",
      "carsamba",
      "persembe",
      "cuma",
      "cumartesi",
    ];
    return daysOfWeek[date.getDay()];
  }

  //aşağıdaki fonksiyonda oluşturulan tüm saatlerden dolu olanlarına 0 boş olanlarına 1 ekler
  const createAvailabilityObject = (doctorClocks, doktorMusaitSaatler) => {
    const result = {};

    for (let i = 0; i < doktorMusaitSaatler.length; i++) {
      result[doktorMusaitSaatler[i]] = 1;
    }

    for (let i = 0; i < doctorClocks["randevular"].length; i++) {
      const randevu_baslangic_saati =
        doctorClocks["randevular"][i]["randevu_baslangic_saati"];
      const randevu_suresi = parseInt(
        doctorClocks["randevular"][i]["randevu_suresi"]
      );
      const index = doktorMusaitSaatler.indexOf(randevu_baslangic_saati);

      if (index !== -1) {
        for (let j = 0; j < randevu_suresi; j++) {
          const saatIndex = index + j;
          if (saatIndex < doktorMusaitSaatler.length) {
            result[doktorMusaitSaatler[saatIndex]] = 0;
          }
        }
      } else {
        result[randevu_baslangic_saati] = 1;
      }
    }

    return result;
  };
  // console.log(doctorClocks);
  const gunListele = () => {
    const doctormusaitvakitler = []; // yeni liste oluşturuluyor
    const gun = getLocalDayOfWeek(doctorClocks["randevu_tarih"]);
    const dilim = doctorClocks["doktor_saatleri"]["0"][gun + "_1"];
    if (dilim === 1) {
      doctormusaitvakitler.push(...availableTimes_1); // push kullanarak saatleri yeni liste ekliyoruz
    }

    const dilim2 = doctorClocks["doktor_saatleri"]["0"][gun + "_2"];
    if (dilim2 === 1) {
      doctormusaitvakitler.push(...availableTimes_2);
    }
    const dilim3 = doctorClocks["doktor_saatleri"]["0"][gun + "_3"];
    if (dilim3 === 1) {
      doctormusaitvakitler.push(...availableTimes_3);
    }
    let sonuc = createAvailabilityObject(doctorClocks, doctormusaitvakitler);
    const newObj = {};
    for (const key in sonuc) {
      newObj[key.slice(0, -3)] = sonuc[key]; //:00 dan kurtulduk
    }

    const updatedObj = {};
    for (const key in newObj) {
      updatedObj[key] = newObj[key] === 1 ? 0 : 1;
    }

    setTamListe(updatedObj);
    setShowOnayButton(true);
  };

  useEffect(() => {
    doctorClocks.message === "Mesai saatleri bilgisine ulaşılamadı." ||
      gunListele();
  }, []);

  //     useEffect(() => {

  //         const doctorHours = async () => {
  //             const { data } = await axios.post(
  //                 `${config.API_BASE_URL}randevu_al_saat?d_diploma_no=${doctor.d_diploma_no}`, {
  //             })
  // console.log(data)
  //             if (data !== "false") {
  //                 // setShowDoctors(true)
  //             }
  //         }

  //         doctorHours();
  //     }, [doctor])

  const scrollLeft = () => {
    const element = document.getElementById("scroll-container");
    element.scrollLeft -= 400;
  };

  const scrollRight = () => {
    const element = document.getElementById("scroll-container");
    element.scrollLeft += 400;
  };

  const date1 = new Date();
  const tomorrow = new Date(date1);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const twoWeek = new Date(date1);
  twoWeek.setDate(twoWeek.getDate() + 14);

  // const bunedir = workingDay?.filter((workday) => workday.doctorId === doctor.doctorId)?.map((day) => day.dates)[0].map((i) => i[dateForArray]).filter((k) => k !== undefined && k)[0].sabah
  // console.log("tamListe", tamListe);
  const allValuesZero =
    tamListe && Object.values(tamListe).every((value) => value === 0);
  // console.log(allValuesZero);

  return (
    <section className="w-full mx-auto">
      <div className="flex  justify-end cursor-pointer gap-3">
        <button
          className="bg-white w-10 h-10 flex items-center justify-center rounded-full"
          onClick={() => scrollLeft()}
        >
          <HiOutlineArrowLeft />
        </button>
        <button
          className="bg-white w-10 h-10 flex items-center justify-center rounded-full"
          onClick={() => scrollRight()}
        >
          <HiOutlineArrowRight />
        </button>
      </div>

      <form className="w-full p-1">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between  gap-1">
            <div
              id="scroll-container"
              className="flex gap-4 overflow-x-hidden h-20"
            >
              {tamListe &&
                saatler.map((saat, index) => {
                  const [saatSaat, saatDakika] = saat.split(":");
                  const isBeforeCurrentTime =
                    currentHour > parseInt(saatSaat) ||
                    (currentHour === parseInt(saatSaat) &&
                      currentMinute >= parseInt(saatDakika));

                  const currentTime = new Date();
                  const saatTarih = new Date(date);

                  // Eğer bugünün tarihi ise ve şu anki saatinden önceyse, saatleri gösterme
                  if (
                    saatTarih.getDate() === currentTime.getDate() &&
                    isBeforeCurrentTime
                  ) {
                    return null;
                  } else {
                    // Aksi halde tüm saatleri göster

                    return (
                      <div className="h-10 my-auto" key={index}>
                        <input
                          style={{
                            backgroundColor: tamListe[saat] !== 0 && "red",
                          }}
                          type="radio"
                          id={saat}
                          value={saat}
                          disabled={tamListe[saat] !== 0}
                          name="randevuSaati"
                          className="hidden peer"
                          onChange={(e) => {
                            setClock(e.target.value);
                            setShowComplaintNote(true);
                          }}
                        />
                        <label
                          style={{
                            backgroundColor: tamListe[saat] !== 0 && "red",
                            userSelect: "none",
                          }}
                          disabled={tamListe[saat] !== 0}
                          htmlFor={saat}
                          className="cursor-pointer bg-white rounded-3xl text-16 px-8 py-3 peer-checked:bg-blue1 peer-checked:text-white peer-checked:border-white font-semibold"
                        >
                          {saat}
                        </label>
                      </div>
                    );
                  }
                })}

              {tamListe === undefined ? (
                <p className="text-white rounded-lg border my-auto p-2 bg-[#5616f5] font-bold">
                  Seçilen Doktora ait uygun randevu saati bulunamadı. Lütfen
                  farklı bir gün veya Doktor seçimi yapınız.
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default AppointmentDate;
