import axios from "axios";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import config from "../../../config";

const schema = yup.object().shape({
  tc: yup
    .string()
    .required("TC Kimlik Numarası zorunludur.")
    .matches(
      /^[0-9]{11}$/,
      "11 haneli Geçerli bir TC Kimlik Numarası giriniz."
    ),
});

const QueryWithIdentityNo = () => {
  const [patiensInfo, setPatiensInfo] = useState([]);
  const [tc, setTc] = useState();
  const [errors, setErrors] = useState({});
  const uRegister = useSelector((state) => state.uRegister);

  const handleSearchPatient = async (e) => {
    e.preventDefault();
    try {
      await schema.validate({ tc }, { abortEarly: false });
      const response = await axios.get(
        `${config.API_BASE_URL}hasta_mudehale/sorgu?hasta_tc=${tc}`,
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      setErrors({});

      if (response.data.status !== 400) {
        setPatiensInfo(response.data);
      } else {
        toast.error("Bu TC. no ya ait bir müdahale kayıt bulunamadı!");
      } // ;return dispatch(modalTogglePatient())
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
      } else {
        console.error(error);
        toast.error("Bir hata oluştu");
        setErrors({});
      }
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 place-items-center my-9">
        {/* TC numarası ile sorgulama 👇🏻 */}
        <div className="flex justify-between items-center w-[40rem]">
          <label className="label-text text-center">Hasta TC Kimlik No</label>
          <input
            type="text"
            placeholder="Hasta TC Kimlik No"
            className="input input-bordered w-full max-w-xs"
            value={tc}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Sadece rakam girişine izin vermek için düzenli ifade kullanılır
              const numericInput = inputValue.replace(/\D/g, "");
              // En fazla 11 haneli TC Kimlik numarasına izin ver
              if (numericInput.length <= 11) {
                setTc(e.target.value);
              }
            }}
          />
          <button
            onClick={(e) => handleSearchPatient(e)}
            className="btn btn-sm btn-outline btn-primary w-28 rounded-full capitalize"
          >
            Sorgula
          </button>
        </div>
        {errors.tc && (
          <p className="input-error-message text-red mt-2">{errors.tc}</p>
        )}
      </div>

      <div className="overflow-x-auto ">
        <table className="table w-full ">
          {/* <!-- head --> */}
          <thead className="text-gray">
            <tr>
              <th className="pl-20">DOKTOR ADI</th>
              <th>HASTA ADI</th>
              <th>TARİH</th>
              <th>TEDAVİ YÖNTEMİ</th>
              <th>TEDAVİ TAMAMLANDIMI</th>
            </tr>
          </thead>
          <tbody>
            {patiensInfo?.hasta_bilgileri?.length > 0 &&
              patiensInfo?.mudehale_bilgileri.length > 0 &&
              patiensInfo.mudehale_bilgileri?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="flex items-center space-x-3 gap-3">
                      <div className="avatar">
                        <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
                          <img
                            src={item.mudehale_eden_doktor?.d_resim}
                            alt="Doktor Avatar"
                          />
                        </div>
                      </div>
                      <div className="font-bold">
                        {item.mudehale_eden_doktor?.d_adi}{" "}
                        {item.mudehale_eden_doktor?.d_soyadi}
                      </div>
                    </div>
                  </td>
                  <td>
                    {patiensInfo?.hasta_bilgileri[0].hasta_adi}{" "}
                    {patiensInfo.hasta_bilgileri[0].hasta_soyadi}
                  </td>
                  <td>{item.mudehale_tarih}</td>
                  <td>{item.yontem_getir.yontem}</td>
                  <td>{item.sonlandir === 0 ? "Devam ediyor" : "Sona erdi"}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QueryWithIdentityNo;
