import React, { useState } from "react";
import { BiMessageSquareAdd } from "react-icons/bi";
import { BsCalendar3 } from "react-icons/bs";
import { MdArrowBackIosNew } from "react-icons/md";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { modalToggle, modalTogglePatient } from "../../../features/modalSlice";
import { linkHoverToggle, sidebarToggle } from "../../../features/sidebarSlice";
import AddAppointmentPopUp from "../addAppointmentsModal/AddAppointmentPopUp";
import axios from "axios";
import { userLogin } from "../../../features/userSlice";
import { logout } from "../../../features/registerSlice";
import AddPatientPopUp from "../../dashboard/AddPatientPopUp";
import { toast } from "react-toastify";
import config from "../../../config";
// import AddAppointmentsModal from '../addAppointmentsModal/AddAppointmentsModal';

const NavBar = () => {
  const open = useSelector((state) => state.open.value);

  const { toggleModal, toggleModalPatient } = useSelector(
    (state) => state.toggleM
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [dropOpen, setDropOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [personalInfo, setPersonalInfo] = useState("");

  const user = useSelector((state) => state.user);
  const uRegister = useSelector((state) => state.uRegister);
  const handleOpenClick = () => {
    dispatch(sidebarToggle());
    dispatch(linkHoverToggle());
  };

  const handleLogOut = async () => {
    dispatch(
      userLogin({
        id: "",
        email: "",
        username: "",
        token: "".token,
        kullanici_turu: "",
      })
    );
    dispatch(logout());
    navigate("/");
    const { data } = await axios.post(
      `${config.API_BASE_URL}logout`,
      {
        email: user.email,
      },
      {
        headers: {
          Authorization: "Bearer " + uRegister.token, // Authorization
        },
      }
    );
  };

  const onSearchInputChange = (e) => {
    setSearchInput(e.target.value);
    // console.log("searchInput", searchInput);
  };

  const handleSearch = (value) => {
    setSearchInput(value);
    handleClickTc();
    // Yönlendirme işlemi
    navigate("/poliklinik/islemlerimiz", { replace: true });
    // navigate("/poliklinik/islemlerimiz"); // Yönlendirilecek URL'i buraya ekleyin
    // console.log("Aranan değer:", value);
  };

  const handleClickTc = async () => {
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}hasta_uye/sorgu?hasta_tc=${searchInput}`,
        {}
      );
      const data = response.data;
      // console.log("data", data);
      if (data.status === 400) {
        toast.error("Hasta Bulunamadı!");
        setPersonalInfo({
          firstName: "",
          lastName: "",
          dadyName: "",
          birthDay: "",
          phone: "",
          email: "",
          city: "",
          town: "",
        });
        // dispatch(modalTogglePatient());
      } else {
        setPersonalInfo({
          firstName: data[0]?.hasta_adi,
          lastName: data[0].hasta_soyadi,
          dadyName: data[0]?.hasta_baba_adi,
          birthDay: data[0]?.hasta_dogum_tarihi,
          phone: data[0]?.hasta_tel,
          email: data[0]?.hasta_mail,
          city: (data[0]?.hasta_il).toLocaleUpperCase("tr-TR"),
          town: (data[0]?.hasta_ilce).toLocaleUpperCase("tr-TR"),
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Bir hata oluştu");
    }
  };
  // console.log("personalInfo", personalInfo);
  return (
    <>
      <div
        className={`navbar bg-white h-20  right-0 z-10 fixed ${
          open ? "w-[calc(100%-250px)]" : "w-[calc(100%-100px)]"
        } transition-w ease-in-out duration-500`}
      >
        <div
          className={
            open
              ? "absolute top-5 left-[-4px] bg-blue1 text-white w-5 h-10 rounded-r-full"
              : "absolute top-5 left-0 w-5 h-10   rotate-180 btn-primary rounded-l-full"
          }
          onClick={handleOpenClick}
        >
          <MdArrowBackIosNew className="text-xl " />
        </div>
        <div className="navbar-start">
          <div className="ml-6 xs:hidden xl:block">
            {/* <AddAppointmentsModal /> */}
            <button
              className="btn btn-sm btn-primary mr-3 capitalize h-10  hover:text-blue1 items-center hover:bg-white rounded-full mx-auto bg-blue1 text-white"
              onClick={() => dispatch(modalToggle())}
            >
              <BiMessageSquareAdd className="mr-1" /> Randevu Oluştur
            </button>
            {/* <button className="btn btn-sm btn-primary mr-3 capitalize h-10  hover:text-blue1 items-center hover:bg-white rounded-full mx-auto bg-blue1 text-white">
              <BsCalendar3 className="mr-1" />
              Randevu Sorgula
            </button> */}
            <button
              onClick={() => dispatch(modalTogglePatient())}
              className="btn btn-sm btn-primary mr-3 capitalize h-10  hover:text-blue1 items-center hover:bg-white rounded-full mx-auto bg-blue1 text-white"
            >
              <BiMessageSquareAdd className="mr-1" />
              Hasta Kayıt
            </button>
          </div>
        </div>

        <div className="navbar-end flex flex-row ">
          <div className="dropdown ml-6 xl:hidden">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
            <ul
              tabIndex={0}
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li onClick={() => dispatch(modalToggle())}>
                <Link>
                  <BiMessageSquareAdd className="mr-1" />
                  Randevu Oluştur
                </Link>
              </li>
              <li>
                <Link>
                  <BsCalendar3 className="mr-1" />
                  Randevu Sorgula
                </Link>
              </li>
            </ul>
          </div>
          {/* <div tabIndex={0} className="collapse group md:hidden">
          <div className="collapse-title bg-primary text-primary-content group-focus:bg-secondary group-focus:text-secondary-content btn btn-ghost btn-circle">
            <button className="">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
            </button>
          </div>
          <div className="collapse-content bg-primary text-primary-content group-focus:bg-secondary group-focus:text-secondary-content">
            <p>tabIndex={0} attribute is necessary to make the div focusable</p>
          </div>
        </div> */}
          <div className="form-control relative xs:hidden md:block">
            <form action="" type="submit">
              <input
                type="text"
                id="searchInput"
                name="searchInput"
                placeholder="TC No ile Hasta Ara"
                className="input input-bordered rounded-3xl"
                value={searchInput}
                // onChange={onSearchInputChange}
                onChange={(e) => {
                  const enteredValue = e.target.value;
                  const onlyNumbers = /^[0-9\b]+$/;

                  if (
                    enteredValue === "" ||
                    (onlyNumbers.test(enteredValue) &&
                      enteredValue.length <= 11)
                  ) {
                    setSearchInput(enteredValue);
                  }
                }}
                pattern="\d{11}"
                title="11 haneli rakam girmelisiniz"
              />
              <button
                type="submit"
                className="btn btn-ghost btn-circle absolute right-0"
                onClick={(e) => {
                  e.preventDefault();
                  handleSearch(searchInput);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
            </form>
          </div>
          <div className="dropdown dropdown-end ml-3 flex items-center">
            <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full avatar">
                <img src={uRegister.pol_logo} alt={uRegister.pol_adi} />
              </div>
            </label>
            <span className="text-center xs:hidden md:block">
              {user.username}
            </span>
            <div className="dropdown dropdown-end">
              <div
                tabIndex={0}
                className="btn btn-ghost"
                onClick={() => setDropOpen(!dropOpen)}
              >
                {dropOpen ? (
                  <RiArrowDropUpLine className="text-3xl" />
                ) : (
                  <RiArrowDropDownLine className="text-3xl" />
                )}
              </div>
              <ul
                tabIndex={0}
                className="divide-y menu dropdown-content p-2 shadow bg-base-100 rounded-box w-52 mt-4"
              >
                <div className="">
                  {/* Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700"  */}
                  <p
                    className="text-gray-300 block px-4 py-2 text-sm"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-0"
                  >
                    Kullanıcı Menüsü
                  </p>
                </div>
                <div>
                  <li>
                    <Link to="/poliklinik/profilim">Profilim</Link>
                  </li>
                  <li>
                    <Link to="/poliklinik/yardim">Yardım</Link>
                  </li>
                  <li className="logout">
                    <button
                      onClick={handleLogOut}
                      className="btn btn-outline btn-error btn-circle w-20"
                    >
                      Çıkış
                    </button>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {toggleModal && <AddAppointmentPopUp />}
      {toggleModalPatient && <AddPatientPopUp />}{" "}
      {/* Yeni hasta kaydetme modalını çaşırır */}
    </>
  );
};

export default NavBar;
