import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/dashboard/Footer";
import NavBar from "../../components/dashboard/NavBar";
import dashboardIntro from "../../assets/dashboardIntro.png";
import dashboardDoctor from "../../assets/dashboard_doctor.png";
import dashboardCalendar from "../../assets/dashboard_calendar.png";
import dashboardClinic from "../../assets/dashboard_clinic.png";
import LoginModal from "../../components/dashboard/LoginModal";
import RegisterModal from "../../components/dashboard/RegisterModal";
import ForgotPassword from "../../components/dashboard/ForgotPassword";
import { useSelector } from "react-redux";

const MainPage = () => {
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  // Tıklandığında scroll üste kaysın
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Sayfanın pürüzsüzce kaydırılmasını sağlar.
    });
  };
  return (
    <div className="h-full relative">
      <NavBar setShowLogin={setShowLogin} />
      <div className="bg-white h-[100vh] w-full">
        <div className="h-[110rem] w-full bg-container absolute -z-10 "> </div>
        <section className="flex pt-24 w-[100%] items-center min-h-screen z-20 lg:px-20 bg-transparent xs:flex-col lg:flex-row lg:justify-evenly mb-10  ">
          <div className="lg:w-1/2 xl:w-auto xs:w-5/6 xs:text-center lg:text-left  pb-10 z-20">
            <p className=" text-44 text-mainPageGray leading-[52px]">
              <span className="font-bold text-blue1 block ">
                Dentreys Randevu Sistemi
              </span>
              <span className="">çok yakında hizmetinizde</span>
            </p>
            <p className="text-18 lg:max-w-xl mt-5">
              6000 den fazla hekim ve 900 den fazla diş kliniği ile dentreys
              kullanıcıları artık daha mutlu ve daha huzurlu. Üstelik gülerken
              çekimser olmak zorunda değil.
            </p>
            <div className="flex xs:flex-col lg:flex-row mt-6 xs:items-center gap-5">
              <Link
                to="/randevu-sorgula"
                onClick={scrollToTop}
                style={{ border: "1px solid #3beaf5" }}
                className="h-10 w-44  text-white tracking-[.5px] bg-turquaz flex justify-center items-center btn rounded-full  hover:bg-white hover:text-turquaz duration-300 "
              >
                Randevu Sorgula
              </Link>
              <Link
                to="/randevu-al"
                onClick={scrollToTop}
                style={{ border: "1px solid #ff5acd" }}
                className="h-10 w-44  text-white tracking-[.5px] bg-pink flex justify-center items-center btn rounded-full  hover:bg-white hover:text-pink"
              >
                Randevu Al
              </Link>
            </div>
          </div>
          <div className="w-1/2 h-[500px] min-w-[600px] max-w-[600px] overflow-hidden relative xs:hidden md:block select-none z-20">
            <img
              style={{}}
              className="absolute -left-6 top-5  min-h-[500px] min-w-[750px] pl-5"
              src={dashboardIntro}
              alt="dashboardIntro"
            />
          </div>
        </section>
        <section className="w-[100%]  px-[120px] bg-container z-20 sm:mt-14 lg:mt-28 pb-12">
          <div className="text-center mb-24">
            <h2 className="text-mainPageGray text-[40px] font-bold leading-normal pb-10 tracking-[-.5px]">
              Dentrey Nasıl Çalışır?
            </h2>
            <p className="xs:w-5/6 lg:w-1/2 m-auto text-center text-gray leading-normal text-18 font-light">
              Dentrey ile çok kolay bir şekilde üye olma zorunluluğu olmadan{" "}
              <br /> istediğiniz klinikten istediğiniz zaman randevu
              alabilirsiniz.
            </p>
          </div>
          <div className="flex justify-between text-center xs:flex-col lg:flex-row pb-20 px-12">
            <div>
              <img className="mx-auto mb-10" src={dashboardDoctor} alt="" />
              <h3 className="text-[24px] text-mainPageGray font-bold tracking-[-.5px] mb-5">
                Hekiminizi <span className="font-light">Seçin</span>
              </h3>
              <p className="text-[16px] text-mainPageP pb-5 leading-6">
                Yüzlerce diş hekimi arasından <br /> sizin için en uygun olanı
                seçin.
              </p>
            </div>
            <div>
              <img className="mx-auto mb-10" src={dashboardCalendar} alt="" />
              <h3 className="text-[24px] text-mainPageGray font-bold tracking-[-.5px] mb-5">
                Randevunuzu <span className="font-light">Planlayın</span>
              </h3>
              <p className="text-[16px] text-mainPageP pb-5 leading-6">
                Sizin için en uygun zamanı seçerek <br /> tedavi zamanını
                kendiniz planlayın.
              </p>
            </div>
            <div>
              <img className="mx-auto mb-10" src={dashboardClinic} alt="" />
              <h3 className="text-[24px] text-mainPageGray font-bold tracking-[-.5px] mb-5">
                Tedaviye <span className="font-light">Başlayın</span>
              </h3>
              <p className="text-[16px] text-mainPageP pb-5 leading-6">
                Kendi belirlediğiniz doktora, <br />
                kendi belirlediğiniz zamanda tedavi olun.
              </p>
            </div>
          </div>
        </section>
        <svg
          className="svg-intro-bottom absolute bottom-0 left-0 w-full h-full z-10"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path
            d="M95,0 Q90,90 0,100 L100,100 100,0 Z"
            fill="#f1f6fb"
            fillOpacity="1"
          ></path>
        </svg>
        <Footer />
      </div>

      {showRegister ? (
        <RegisterModal
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
        />
      ) : null}

      {showLogin ? (
        <LoginModal
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}

      {showForgotPassword ? (
        <ForgotPassword
          setShowLogin={setShowLogin}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}
    </div>
  );
};

export default MainPage;
