
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useRef, useState } from "react";
import tr from "date-fns/locale/tr";

const Range = ({state, setState}) => {

    const refOne = useRef(null);

    return (
        <div ref={refOne} className="calendarWrap">
            <DateRange
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                rangeColors={["#5616f5", "#7d28eb"]}
                locale={tr}
                className=" border-2 border-gray"
            />
        </div>
    );
};

export default Range;
