import React, { useState } from "react";
import { BiMessageSquareAdd } from "react-icons/bi";
import { BsCalendar3 } from "react-icons/bs";
import { MdArrowBackIosNew } from "react-icons/md";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { sidebarToggle } from "../../features/sidebarSlice";
import { linkHoverToggle } from "../../features/sidebarSlice";
import AddAppointmentPopUp from "../polyclinic/addAppointmentsModal/AddAppointmentPopUp";
import AddAppointmentPopUpDoctor from "../doctor/doctorAppointmentsModal/AddAppointmentPopUpDoctor";
import axios from "axios";
import { userLogin } from "../../features/userSlice";
import { logout } from "../../features/registerSlice";
import { toast } from "react-toastify";
import AddPatientPopUp from "../dashboard/AddPatientPopUp";
import {
  modalToggleDoctor,
  modalTogglePatient,
} from "../../features/modalSlice";
import config from "../../config";


const NavBar = () => {
  const open = useSelector((state) => state.open.value);
  const dispatch = useDispatch();
  const [dropOpen, setDropOpen] = useState(false);
  const { toggleModal, toggleModalDoctor, toggleModalPatient } = useSelector(
    (state) => state.toggleM
  );
  const uRegister = useSelector((state) => state.uRegister);
  const user = useSelector((state) => state.user);

  const handleOpenClick = () => {
    dispatch(sidebarToggle());
    dispatch(linkHoverToggle());
  };
  const navigate = useNavigate();
  const handleLogOut = async () => {
    // console.log(uRegister);
    dispatch(
      userLogin({
        id: "",
        email: "",
        username: "",
        token: "".token,
        kullanici_turu: "",
      })
    );
    dispatch(logout());
    const { data } = await axios.post(
      `${config.API_BASE_URL}logout`,
      {
        email: user.email,
      },
      {
        headers: {
          Authorization: "Bearer " + uRegister.token, // Authorization
        },
      }
    );
    toast.success("Çıkış yapıldı");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  return (
    <>
      <div
        className={`navbar bg-white h-20  right-0 z-10 fixed ${
          open ? "w-[calc(100%-250px)]" : "w-[calc(100%-100px)]"
        } transition-w ease-in-out duration-500`}
      >
        <div
          className={
            open
              ? "absolute top-5 left-[-4px] bg-blue1 text-white w-5 h-10 rounded-r-full"
              : "absolute top-5 left-0 w-5 h-10   rotate-180 btn-primary rounded-l-full"
          }
          onClick={handleOpenClick}
        >
          <MdArrowBackIosNew className="text-xl " />
        </div>
        <div className="navbar-start">
          <div className="ml-6 xs:hidden xl:block">
            <button
              onClick={() => dispatch(modalToggleDoctor())}
              className="btn btn-sm btn-primary mr-3 capitalize h-10  hover:text-blue1 items-center hover:bg-white rounded-full mx-auto bg-blue1 text-white"
            >
              <BiMessageSquareAdd className="mr-1" />
              Randevu Oluştur
            </button>
            {/* <button className="btn btn-sm btn-outline mr-3  capitalize hover:text-blue1 items-center hover:bg-white rounded-full mx-auto bg-blue1 text-white h-10">
              <BsCalendar3 className="mr-1" />
              Randevu Sorgula
            </button> */}
            <button
              onClick={() => dispatch(modalTogglePatient())}
              className="btn btn-sm btn-primary mr-3 capitalize h-10  hover:text-blue1 items-center hover:bg-white rounded-full mx-auto bg-blue1 text-white"
            >
              <BiMessageSquareAdd className="mr-1" />
              Hasta Kayıt
            </button>
          </div>
        </div>

        <div className="navbar-end flex flex-row ">
          <div className="dropdown ml-6 xl:hidden">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
            <ul
              tabIndex={0}
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <Link>
                  <BiMessageSquareAdd className="mr-1" /> Randevu Oluştur
                </Link>
              </li>
              <li>
                <Link>
                  <BsCalendar3 className="mr-1" />
                  Randevu Sorgula
                </Link>
              </li>
            </ul>
          </div>
          {/* <div tabIndex={0} className="collapse group md:hidden">
          <div className="collapse-title bg-primary text-primary-content group-focus:bg-secondary group-focus:text-secondary-content btn btn-ghost btn-circle">
            <button className="">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
            </button>
            </div>
            <div className="collapse-content bg-primary text-primary-content group-focus:bg-secondary group-focus:text-secondary-content">
            <p>tabIndex={0} attribute is necessary to make the div focusable</p>
            </div>
        </div> */}
          <div className="form-control relative xs:hidden md:block">
            {/* hasta ara inputu silinidi */}
            {/* <input
                            type="text"
                            placeholder="Hasta Ara"
                            className="input input-bordered rounded-3xl"
                        />
                        <button className="btn btn-ghost btn-circle absolute right-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </button> */}
          </div>
          <div className="dropdown dropdown-end ml-3 flex items-center">
            <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full avatar">
                <img
                  style={{ objectFit: "contain" }}
                  src={uRegister.d_resim}
                  alt={uRegister.d_adi}
                />
              </div>
            </label>
            <span className="text-center xs:hidden md:block">
              {user.username}
            </span>
            <div className="dropdown dropdown-end">
              <div
                tabIndex={0}
                className="btn btn-ghost"
                onClick={() => setDropOpen(!dropOpen)}
              >
                {dropOpen ? (
                  <RiArrowDropUpLine className="text-3xl" />
                ) : (
                  <RiArrowDropDownLine className="text-3xl" />
                )}
              </div>
              <ul
                tabIndex={0}
                className="divide-y menu dropdown-content p-2 shadow bg-base-100 rounded-box w-52 mt-4"
              >
                <div className="">
                  {/* Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700"  */}
                  <p
                    className="text-gray-300 block px-4 py-2 text-sm"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-0"
                  >
                    Kullanıcı Menüsü
                  </p>
                </div>
                <div>
                  <li>
                    <Link to="/poliklinik/profilim">Profilim</Link>
                  </li>
                  <li>
                    <Link to="/poliklinik/yardim">Yardım</Link>
                  </li>
                  <li className="logout">
                    <div
                      onClick={handleLogOut}
                      className="btn btn-outline btn-error btn-circle w-20"
                    >
                      {" "}
                      Çıkış
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {toggleModal && <AddAppointmentPopUp />}{" "}
      {/* polikliniklerden randevu al modalını çaşırır */}
      {toggleModalDoctor && <AddAppointmentPopUpDoctor />}{" "}
      {/* doktorlar randevu al modalını çaşırır */}
      {toggleModalPatient && <AddPatientPopUp />}{" "}
      {/* Yeni hasta kaydetme modalını çaşırır */}
    </>
  );
};

export default NavBar;
