import React, { useState } from "react";
import PatientInformation from "./PatientInformation";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import config from "../../../../config";


const schema = yup.object().shape({
  tc: yup
    .string()
    .required("TC Kimlik Numarası zorunludur.")
    .matches(
      /^[0-9]{11}$/,
      "11 haneli geçerli bir TC Kimlik Numarası giriniz."
    ),
});
const AddFinancialAccount = ({ setShowInfo }) => {
  const uRegister = useSelector((state) => state.uRegister);

  const [tc, setTc] = useState();
  const [patientInfo, setPatientInfo] = useState("");
  const [openProcess, setOpenProcess] = useState([]);
  const [endedProcess, setEndedProcess] = useState([]);
  const [tumTedaviYontemler, setTumTedaviYontemler] = useState();
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowInfo(1);
  };

  const handleGetAppointment = async (dDate) => {
    // dDate.preventDefault();
    try {
      //önce POLİKLİNİK TÜM DOKTORLARINI GETİR
      await schema.validate({ tc }, { abortEarly: false });
      const resp = await axios.post(
        `${config.API_BASE_URL}hesap_defteri/hasta_sorgula?hasta_tc=${tc}&pol_vergi_no=${uRegister.id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      const open = await axios.get(
        `${config.API_BASE_URL}hesap_defteri/devameden?hasta_tc=${tc}&pol_vergi_no=${uRegister.id}`,
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      const ended = await axios.get(
        `${config.API_BASE_URL}hesap_defteri/sonlandirilanlar?hasta_tc=${tc}&pol_vergi_no=${uRegister.id}`,
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      const tedaviYontemler = await axios.get(
        `${config.API_BASE_URL}tedavi/yontem`
      );
      setErrors({});

      setOpenProcess(open.data);
      setEndedProcess(ended.data);

      const result = {};
      tedaviYontemler.data.forEach((obj) => {
        result[obj.id] = obj.yontem;
      });

      setTumTedaviYontemler(result);

      if ("hasta_bilgileri" in resp.data) {
        setPatientInfo(resp.data.hasta_bilgileri[0]);
      } else {
        toast.success("Bu T.C No'ya kayıtlı hasta bulunmuyor.");
        setPatientInfo("");
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 place-items-center my-9 ">
        {/* TC numarası ile sorgulama 👇🏻 */}
        <form
          onSubmit={handleSubmit}
          className="flex justify-between items-center w-[40rem]"
        >
          <label className="label-text text-center mb-2 sm:mb-0 sm:mr-2">
            HASTA TC KİMLİK NO
          </label>
          <input
            type="text"
            placeholder="Hasta TC Kimlik No"
            className="input input-bordered input-sm w-full sm:max-w-xs mb-2 sm:mb-0 sm:mr-2"
            value={tc}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Sadece rakam girişine izin vermek için düzenli ifade kullanılır
              const numericInput = inputValue.replace(/\D/g, "");
              // console.log(numericInput);
              // En fazla 11 haneli TC Kimlik numarasına izin ver
              if (numericInput.length <= 11) {
                setTc(numericInput);
              }
            }}
          />
          <button
            type="submit"
            className="btn btn-sm w-full sm:w-auto btn-outline btn-primary rounded-full capitalize"
            onClick={handleGetAppointment}
          >
            Sorgula
          </button>
        </form>
        {errors.tc && (
          <p className="input-error-message text-red mt-2 ml-16">{errors.tc}</p>
        )}
      </div>
      <PatientInformation
        tumTedaviYontemler={tumTedaviYontemler}
        openProcess={openProcess}
        endedProcess={endedProcess}
        patientInfo={patientInfo}
      />
    </div>
  );
};

export default AddFinancialAccount;
