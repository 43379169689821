import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Controller } from "swiper";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import config from "../../../config";

const schema = yup.object().shape({
  dipNo: yup
    .string()
    .required("Diploma Numarası zorunludur.")
    .matches(/^[0-9]{10}$/, "10 haneli Geçerli bir Diploma Numarası giriniz."),
});

const DoctorsAppointments = () => {
  const uRegister = useSelector((state) => state.uRegister);
  const [doktorAppointment, setDoktorAppointment] = useState();
  const [doctors, setDoctors] = useState();
  const [dipNo, setDipNo] = useState();
  const [noRecordsFound, setNoRecordsFound] = useState("");

  const [openTab, setOpenTab] = useState(0);
  const widthScreen = window.innerWidth;
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  const getDoctors = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}poliklinikteki_doktorlar?pol_vergi_no=${uRegister.id}`
      );
      response.data.length > 0 && setDoctors(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate({ dipNo }, { abortEarly: false });
      const response = await axios.get(
        `${config.API_BASE_URL}randevular/doktor?pol_vergi_no=${uRegister.id}&d_diploma_no=${dipNo}`
      );
      setErrors({});

      if (response.data.length > 0) {
        setDoktorAppointment(response.data);
        setOpenTab(dipNo);
      } else {
        setOpenTab(dipNo);
        toast.error("Doktor bilgileri bulunmuyor!");
        setDoktorAppointment("");
        setNoRecordsFound(response.data.message);
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
      } else {
        console.error(error);
        toast.error("Bir hata oluştu");
      }
    }
  };
  const getSelectedDoctor = async (diplomaNo) => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}randevular/doktor?pol_vergi_no=${uRegister.id}&d_diploma_no=${diplomaNo}`
      );

      if (response.data.length > 0) {
        setDoktorAppointment(response.data);
        setOpenTab(diplomaNo);
        setNoRecordsFound("");
      } else {
        setOpenTab(diplomaNo);
        // toast.error("Hasta bilgileri bulunmuyor!");
        setDoktorAppointment("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDoctors();
  }, []);

  return (
    <div>
      {/* Diploma numarası ile sorgulama 👇🏻 */}
      <div className="flex justify-between">
        <form
          onSubmit={handleSubmit}
          className="flex justify-between items-center"
        >
          <input
            type="text"
            value={dipNo}
            className="input input-bordered w-full max-w-xs"
            placeholder="Diploma no giriniz"
            onChange={(e) => setDipNo(e.target.value)}
          />
          <button
            type="submit"
            className="btn-primary ml-3 btn btn-active btn-sm bg-blue1 gap-2 rounded-full hover:bg-white hover:text-blue1 capitalize px-8"
          >
            Doktor Ara
          </button>
        </form>

        <div className="flex justify-between items-center gap-2">
          <button
            ref={prevRef}
            className="bg-container w-10 h-10 flex items-center justify-center rounded-full"
          >
            <HiOutlineArrowLeft />
          </button>
          <button
            ref={nextRef}
            className="bg-container w-10 h-10 flex items-center justify-center rounded-full"
          >
            <HiOutlineArrowRight />
          </button>
        </div>
      </div>
      {/* {noRecordsFound === "Kayıt bulunamadı" && (
        <p className="capitalize text-red w-[30rem] p-2 mb-4 text-center">
          Lütfen Diploma numarasını Kontrol ederek Tekrar deneyin!
        </p>
      )} */}
      {errors.dipNo && (
        <p className="input-error-message text-red mt-2">{errors.dipNo}</p>
      )}
      <Swiper
        className="flex flex-row gap-5 my-7"
        spaceBetween={25}
        slidesPerView={
          widthScreen < 768
            ? 1
            : widthScreen < 1050
            ? 2
            : widthScreen < 1400
            ? 3
            : widthScreen < 1800
            ? 4
            : 5
        }
        slidesPerGroup={2}
        onSwiper={setSwiper}
        modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
        navigation={{
          prevEl: prevRef?.current,
          nextEl: nextRef?.current,
        }}
        updateOnWindowResize
        observer
        observeParents
      >
        {doctors &&
          doctors?.map((doktor) => (
            <SwiperSlide>
              <div
                className={` ${
                  openTab === doktor.d_diploma_no
                    ? "card  bg-gradient-to-r  from-blue1 to-blue2 text-white"
                    : "card bg-container"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  getSelectedDoctor(doktor.d_diploma_no);
                }}
              >
                <figure className="pt-5">
                  <img
                    src={doktor.d_resim}
                    alt="Shoes"
                    className="w-36 h-36 rounded-full object-contain bg-white border border-lightGray"
                  />
                </figure>
                <div className="text-center py-3">
                  <h2 className="text-center text-xl font-semibold">
                    {doktor.d_adi} {doktor.d_soyadi}
                  </h2>
                  <p
                    className={` ${
                      openTab === doktor.d_diploma_no
                        ? "text-white text-center"
                        : "text-blue1 text-center"
                    }`}
                  >
                    {doktor.d_unvan}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>

      {/* Hasta tablosu 👇🏻 */}
      <div className="overflow-x-auto ">
        <table className="table w-full z-0">
          <thead className="text-gray">
            <tr>
              <th className="pl-20 text-[16px]">DOKTOR</th>
              <th className="text-[16px]">HASTA</th>
              <th className="text-[16px]">CİNSİYET</th>
              <th className="text-[16px]">TARİH</th>
              <th className="text-[16px]">SAAT</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {doktorAppointment?.length > 0 ? (
              doktorAppointment?.map((randevu) => {
                // console.log(randevu);
                return (
                  <tr className="border-b border-[#e9e9e9] leading-10">
                    <td>
                      <div className="flex items-center space-x-3 gap-3">
                        <div className="avatar">
                          <div className="rounded-full w-10">
                            <img
                              src={randevu.doktor.d_resim}
                              alt="Doktor Avatar"
                              className="object-top"
                            />
                          </div>
                        </div>
                        <div>
                          {randevu.doktor.d_adi} {randevu.doktor.d_soyadi}
                        </div>
                      </div>
                    </td>
                    <td>
                      {" "}
                      {randevu.hasta.hasta_adi} {randevu.hasta.hasta_soyadi}
                    </td>
                    <td>{randevu.hasta.cinsiyet}</td>
                    <td>{randevu.randevu_tarih}</td>
                    <td>{randevu.randevu_baslangic_saati}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  className="capitalize p-2 shadow-lg mb-4 text-center"
                  colSpan={6}
                >
                  Seçilen doktora ait randevu bilgisi bulunamadı!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DoctorsAppointments;
