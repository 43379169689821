import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: "",
    token: "",
    email: "",
    username: "",
    kullanici_turu: "",
    loading:false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userLogin: (state, { payload }) => {
            state.id = payload.id
            state.token = payload.token
            state.email = payload.email
            state.username = payload.username
            state.kullanici_turu = payload.kullanici_turu
        },
        setLoading:(state, { payload }) => {
            state.loading = payload.status
        }
    },
});

export const { userLogin,setLoading } = userSlice.actions;

export default userSlice.reducer