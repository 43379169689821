import React from "react";
import DoctorTreatments from "./DoctorTreatments";
import DoctorIstatistik from "./DoctorIstatistik";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import config from "../../../config";

const DoctorInformation = ({ doktorInfo, doktorlar, selectedDoctor }) => {
  const uRegister = useSelector((state) => state.uRegister);

  const [tedaviBasliklar, setTedaviBasliklar] = useState(); // alt kısımda çıkan tedavi başlıkları
  const [goster, setGoster] = useState(false);
  const [istatistik, setIstatistik] = useState([
    {
      id: 1,
      istatistikAdi: "Bakılan Hasta",
      istatistikVeri: [
        { zamanAdı: "Bugün", sayı: 0 },
        { zamanAdı: "Bu Hafta", sayı: 0 },
        { zamanAdı: "Bu Ay", sayı: 0 },
        { zamanAdı: "Bu Yıl", sayı: 0 },
        { zamanAdı: "Tüm Zamanlar", sayı: 0 },
      ],
    },
    {
      id: 2,
      istatistikAdi: "Yapılan İşlem",
      istatistikVeri: [
        { zamanAdı: "Bugün", sayı: 0 },
        { zamanAdı: "Bu Hafta", sayı: 0 },
        { zamanAdı: "Bu Ay", sayı: 0 },
        { zamanAdı: "Bu Yıl", sayı: 0 },
        { zamanAdı: "Tüm Zamanlar", sayı: 0 },
      ],
    },
    {
      id: 3,
      istatistikAdi: "Kullanılan İzin",
      istatistikVeri: [
        { zamanAdı: "Bugün", sayı: 0 },
        { zamanAdı: "Bu Hafta", sayı: 0 },
        { zamanAdı: "Bu Ay", sayı: 0 },
        { zamanAdı: "Bu Yıl", sayı: 0 },
        { zamanAdı: "Tüm Zamanlar", sayı: 0 },
      ],
    },
    {
      id: 4,
      istatistikAdi: "Toplam Mesai",
      istatistikVeri: [
        { zamanAdı: "Bugün", sayı: 0 },
        { zamanAdı: "Bu Hafta", sayı: 0 },
        { zamanAdı: "Bu Ay", sayı: 0 },
        { zamanAdı: "Bu Yıl", sayı: 0 },
        { zamanAdı: "Tüm Zamanlar", sayı: 0 },
      ],
    },
  ]);

  const updateIstatistik = (birlesikVeriler, sira, guncelle = null) => {
    // Verileri birleştir
    setGoster(false);

    // Bugün için sayıyı hesapla
    const bugununSayisi = birlesikVeriler.filter((veri) => {
      const veriTarih = new Date(veri.randevu_tarih);
      const bugun = new Date();

      return (
        veriTarih.toISOString().split("T")[0] ===
        bugun.toISOString().split("T")[0]
      );
    }).length;

    // Bu hafta için sayıyı hesapla
    const buHaftaninSayisi = birlesikVeriler.filter((veri) => {
      const veriTarih = new Date(veri.randevu_tarih);
      const bugun = new Date();

      const haftaBaslangici = new Date(
        bugun.getFullYear(),
        bugun.getMonth(),
        bugun.getDate() - bugun.getDay()
      );
      const haftaBitisi = new Date(
        bugun.getFullYear(),
        bugun.getMonth(),
        bugun.getDate() - bugun.getDay() + 6
      );

      return veriTarih >= haftaBaslangici && veriTarih <= haftaBitisi;
    }).length;

    // Bu ay için sayıyı hesapla
    const buAyinSayisi = birlesikVeriler.filter((veri) => {
      const veriTarih = new Date(veri.randevu_tarih);
      const bugun = new Date();

      return (
        veriTarih.getMonth() === bugun.getMonth() &&
        veriTarih.getFullYear() === bugun.getFullYear()
      );
    }).length;

    // Bu yıl için sayıyı hesapla
    const buYilinSayisi = birlesikVeriler.filter((veri) => {
      const veriTarih = new Date(veri.randevu_tarih);
      const bugun = new Date();

      return veriTarih.getFullYear() === bugun.getFullYear();
    }).length;

    // Tüm zamanlar için sayıyı hesapla
    const tumZamanlarinSayisi = birlesikVeriler.length;

    setIstatistik((prevIstatistik) => {
      const updatedIstatistik = prevIstatistik.map((istatistik) => {
        if (istatistik.id === sira + 1) {
          const updatedIstatistikVeri = istatistik.istatistikVeri.map(
            (veri) => {
              switch (veri.zamanAdı) {
                case "Bugün":
                  return { ...veri, sayı: bugununSayisi };
                case "Bu Hafta":
                  return { ...veri, sayı: buHaftaninSayisi };
                case "Bu Ay":
                  return { ...veri, sayı: buAyinSayisi };
                case "Bu Yıl":
                  return { ...veri, sayı: buYilinSayisi };
                case "Tüm Zamanlar":
                  return { ...veri, sayı: tumZamanlarinSayisi };
                default:
                  return veri;
              }
            }
          );
          return { ...istatistik, istatistikVeri: updatedIstatistikVeri };
        } else {
          return istatistik;
        }
      });
      return updatedIstatistik;
    });

    if (guncelle) {
      setTimeout(() => {
        setGoster(true);
      }, 10);
    }
  };
  useEffect(() => {
    // dok tedavilerin başlıklarının  getirr  bunu burada çağırıp en alttaki komponente iletiyoruz  en alttaki komponent daha çok render olduğu için mümkün olduğunca az çağırılsın diye burada çağırdık
    const getBaslik = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}klinik_doktorlar/performans/baslik`,
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );

        if (response.data !== 400) {
          setTedaviBasliklar(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getBaslik();
  }, []);

  useEffect(() => {
    const getBakilanHasta = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}klinik_doktorlar/performans/hasta?pol_vergi_no=${uRegister.id}&d_diploma_no=${doktorInfo?.d_diploma_no}`,
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );
        const birlesikVeriler = [
          ...response.data.randevuVerilenHastalar,
          ...response.data.direkTedavi,
        ];
        updateIstatistik(birlesikVeriler, 0);
      } catch (error) {
        console.log(error);
      }
    };

    const getYapilanIslem = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}klinik_doktorlar/performans/islem?pol_vergi_no=${uRegister.id}&d_diploma_no=${doktorInfo?.d_diploma_no}`,
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );

        // console.log(response?.data);
        const birlesikVeriler = {
          mudehaleEdilenHastalar: response?.data?.mudehaleEdilenHastalar.map(
            (veri) => ({
              ...veri,
              randevu_tarih: veri.mudehale_tarih, //yukarıdaki fonksiyona uydurmak için mudehale_tarih i randevu_tarih e çevirdik
              mudehale_tarih: undefined,
            })
          ),
        };
        updateIstatistik(birlesikVeriler.mudehaleEdilenHastalar, 1);
      } catch (error) {
        console.log(error);
      }
    };

    const getIzin = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}klinik_doktorlar/performans/izin?pol_vergi_no=${uRegister.id}&d_diploma_no=${doktorInfo?.d_diploma_no}`,
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );

        const birlesikVeriler = {
          alinanIzinler: response?.data?.alinanIzinler.map((veri) => ({
            ...veri,
            randevu_tarih: veri.izin_baslangic_tarihi,
            mudehale_tarih: undefined,
          })),
        };
        updateIstatistik(birlesikVeriler.alinanIzinler, 2);
      } catch (error) {
        console.log(error);
      }
    };

    const getMesai = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}klinik_doktorlar/performans/mesai?pol_vergi_no=${uRegister.id}&d_diploma_no=${doktorInfo?.d_diploma_no}`,
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );
        const birlesikVeriler = {
          mesaiTarihleri: response.data.mesaiTarihleri.map((veri) => ({
            ...veri,
            randevu_tarih: veri.mesai_baslangic,
            mudehale_tarih: undefined,
          })),
        };
        updateIstatistik(birlesikVeriler.mesaiTarihleri, 3, 1); // 3.paremtre komponentin gösterilmesi için işaret
      } catch (error) {
        console.log(error);
      }
    };
    getBakilanHasta();
    getYapilanIslem();
    getIzin();
    getMesai();
  }, [doktorInfo]);
  console.log(doktorInfo);
  return (
    <div>
      <div>
        {/* <-----Hekim Performans ----->👇🏻 */}
        <div className="flex flex-row gap-10 pt-10">
          {/* <-----Hekim Resmi ----->👇🏻 */}
          <figure>
            <img
              src={doktorInfo ? doktorInfo?.d_resim : doktorlar[0]?.d_resim}
              alt="Shoes"
              className="w-56 h-auto rounded-full object-contain bg-container"
            />
          </figure>
          {/* <-----Hekim Bilgileri ----->👇🏻 */}
          <div className="w-full ">
            <h1 className="text-2xl font-bold mb-3 capitalize">
              {doktorInfo ? doktorInfo?.d_adi : doktorlar[0]?.d_adi}{" "}
              {doktorInfo ? doktorInfo?.d_soyadi : doktorlar[0]?.d_soyadi}
            </h1>
            <div className="flex flex-row gap-10">
              <div className="w-2/5">
                <p className="bg-base-200 rounded-lg p-2 text-gray">
                  {doktorInfo ? doktorInfo?.d_unvan : doktorlar[0]?.d_unvan}
                </p>
                <p className="pt-1 font-bold">
                  Email:{" "}
                  <span className="text-blue1 font-normal line-clamp-2">
                    {doktorInfo ? doktorInfo?.d_mail : doktorlar[0]?.d_mail}
                  </span>
                </p>
                <p className="pt-1 font-bold">
                  Mezuniyet Tarihi :{" "}
                  <span className="font-normal">
                    {doktorInfo
                      ? doktorInfo?.mezuniyet_tarihi
                      : doktorlar[0]?.mezuniyet_tarihi}
                  </span>
                </p>
              </div>
              <div className="w-1/5">
                <p className="bg-base-200 rounded-lg p-2 text-gray">
                  {doktorInfo ? doktorInfo?.mezuniyet : doktorlar[0]?.mezuniyet}
                </p>
                <p className="pt-1 font-bold">
                  Telefon:{" "}
                  <span className="text-blue1 font-normal">
                    {doktorInfo ? doktorInfo?.d_tel : doktorlar[0]?.d_tel}
                  </span>
                </p>
                <p className="pt-1 font-bold">
                  Cinsiyet:{" "}
                  <span className="font-normal">
                    {doktorInfo ? doktorInfo?.cinsiyet : doktorlar[0]?.cinsiyet}
                  </span>
                </p>
              </div>
              <div className="w-1/3">
                <p className="bg-base-200 rounded-lg p-2 text-gray">
                  {doktorInfo
                    ? doktorInfo?.mesai_baslangic
                    : doktorlar[0]?.mesai_baslangic}
                  'de çalışmaya başladı
                </p>
                <p className="pt-1 font-bold">
                  Adres:{" "}
                  <span className="font-normal">
                    {doktorInfo ? doktorInfo?.d_adres : doktorlar[0]?.d_adres}
                  </span>
                </p>
                <p className="pt-1 font-bold">
                  İşbaşı:{" "}
                  <span className="font-normal">
                    {doktorInfo
                      ? doktorInfo?.mesai_baslangic
                      : doktorlar[0]?.mesai_baslangic}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <-----Hekim Performans Grafikleri ----->👇🏻 */}
        <div
          style={{ minHeight: "300px" }}
          className="flex flex-row gap-4 w-full py-10"
        >
          {istatistik &&
            goster &&
            istatistik?.map((istatis, index) => {
              return <DoctorIstatistik istatistik={istatis} key={index} />;
            })}
        </div>
        {/* <-----Uyguladığı Tedaviler ----->👇🏻 */}
        {doktorInfo?.d_diploma_no && (
          <DoctorTreatments
            tedaviBasliklar={tedaviBasliklar}
            dipNo={doktorInfo.d_diploma_no}
            pol={uRegister.id}
          />
        )}
      </div>
    </div>
  );
};

export default DoctorInformation;
