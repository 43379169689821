import React, { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo2 from "../../assets/logo/Logo-kontur.png";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../features/userSlice";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../config";

const NavBar = ({ setShowLogin }) => {
  const [activePage, setActivePage] = useState("");
  let location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const [bgColor, setBgColor] = useState(false);
  const [linkClicked, setLinkClicked] = useState(false);

  const changeBgColor = () => {
    if (window.scrollY > 0) {
      setBgColor(true);
    } else {
      setBgColor(false);
    }
  };
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uRegister = useSelector((state) => state.uRegister);

  const handleLogOut = async () => {
    dispatch(
      userLogin({
        id: "",
        email: "",
        username: "",
        token: "".token,
        kullanici_turu: "",
      })
    );
    await axios.post(
      `${config.API_BASE_URL}logout`,
      {
        email: user.email,
      },
      {
        headers: {
          Authorization: "Bearer " + uRegister.token, // Authorization
        },
      }
    );

    toast.success("Çıkış yapıldı");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };
  // Tıklandığında scroll üste kaysın
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Sayfanın pürüzsüzce kaydırılmasını sağlar.
    });
    setLinkClicked(true);
  };
  useEffect(() => {
    changeBgColor();
    setActivePage(location.pathname);
    // adding the event when scroll change
    window.addEventListener("scroll", changeBgColor);
  }, []);

  return (
    <div
      style={
        bgColor
          ? {
              backgroundColor: "white",
              boxShadow: "0 4px 10px 0 rgba(0,0,0,.03)",
            }
          : { backgroundColor: "transparent" }
      }
      className="navbar w-full top-0 left-0 flex justify-center h-[50px] md:h-[70px] fixed z-50 transition-all duration-300  hover:bg-container  "
    >
      <div className="container w-5/6">
        <div className="flex-1">
          <Link to="/">
            {/* <div className="flex  items-center ">
                            <BiPlusMedical className="text-primary" />
                            <h1 className=" text-28 text-black font-light">
                                Dent
                                <span className="text-gray2 font-bold">
                                    rey
                                </span>
                            </h1>
                        </div> */}

            <img className="w-40" src={logo2} alt="" />
          </Link>
        </div>
        <div className="xs:hidden lg:block">
          <Link
            to="/randevu-sorgula"
            onClick={scrollToTop}
            className={`mx-5 hover:text-blue1 text-darkGray font-medium text-13 tracking-wider ${
              activePage === "/randevu-sorgula" && "text-blue1"
            }`}
          >
            Randevu Sorgula
          </Link>
          <a
            href="/randevu-al"
            onClick={scrollToTop}
            className={`mx-5 scroll-to-top-button hover:text-blue1 text-darkGray font-medium text-13 tracking-wider ${
              activePage === "/randevu-al" && "text-blue1"
            }`}
          >
            Randevu Al
          </a>
          <Link
            to="/poliklinikler"
            className={`mx-5 hover:text-blue1 text-darkGray font-medium text-13 tracking-wider ${
              activePage === "/poliklinikler" && "text-blue1"
            }`}
          >
            Poliklinikler
          </Link>
          {user.kullanici_turu === 1 ? (
            <button
              htmlFor="my-modal-4"
              style={{
                textTransform: "none",
                boxShadow: "0 0 30px -12px #5616f5",
              }}
              className="h-8 w-28 hover:text-blue1 items-center  hover:bg-white rounded-full mr-3 bg-blue1 text-white border-2 border-blue1 hover:border-blue1"
              type="button"
              onClick={() => navigate("/poliklinik")}
            >
              Polikliniğim
            </button>
          ) : user.kullanici_turu === 2 ? (
            <button
              htmlFor="my-modal-4"
              style={{
                textTransform: "none",
                boxShadow: "0 0 30px -12px #5616f5",
                padding: "2px 10px",
              }}
              className=" hover:text-blue1 items-center  hover:bg-white rounded-full mr-3 bg-blue1 text-white border-2 border-blue1 hover:border-blue1"
              type="button"
              onClick={() => navigate("/doktorlar")}
            >
              Doktor Sayfam
            </button>
          ) : null}
          {user.email ? (
            <button
              htmlFor="my-modal-4"
              style={{
                textTransform: "none",
                boxShadow: "0 0 30px -12px #5616f5",
              }}
              className="h-8 w-28 hover:text-blue1 items-center  hover:bg-white rounded-full mr-3 bg-blue1 text-white border-2 border-blue1 hover:border-blue1"
              type="button"
              onClick={() => handleLogOut()}
            >
              Çıkış Yap
            </button>
          ) : (
            <button
              htmlFor="my-modal-4"
              style={{
                textTransform: "none",
                boxShadow: "0 0 30px -12px #5616f5",
              }}
              className="h-8 w-28 hover:text-blue1 items-center  hover:bg-white rounded-full mr-3 bg-blue1 text-white border-2 border-blue1 hover:border-blue1"
              type="button"
              onClick={() => setShowLogin(true)}
            >
              Giriş Yap
            </button>
          )}
        </div>

        <div
          className={`lg:hidden w-full absolute left-0 right-0 transition-all ease-in-out duration-500 top-[50px] md:top-[70px] overflow-hidden ${
            menuOpen ? "h-64" : "h-0"
          }`}
        >
          <ul tabIndex={0} className=" bg-base-100 p-2">
            <li className="m-4">
              <Link
                to="/randevu-sorgula"
                className="hover:text-blue1 h-4"
                onClick={scrollToTop}
              >
                Randevu Sorgula
              </Link>
            </li>
            <hr style={{ color: "rgb(229 231 235)" }} />
            <li className="m-4">
              <Link
                to="/randevu-al"
                className="hover:text-blue1  my-2"
                onClick={scrollToTop}
              >
                Randevu Al
              </Link>
            </li>
            <hr style={{ color: "rgb(229 231 235)" }} />
            <li className="m-4">
              <Link to="/poliklinikler" className="hover:text-blue1  my-2">
                Poliklinikler
              </Link>
            </li>
            <hr style={{ color: "rgb(229 231 235)" }} />

            <div className="text-center">
              <button
                style={{
                  textTransform: "none",
                  boxShadow: "0 0 30px -12px #5616f5",
                }}
                className="h-8 w-40 m-4 text-blue1 items-center bg-white rounded-full mx-auto hover:bg-blue1 hover:text-white"
                type="button"
                onClick={() => setShowLogin(true)}
              >
                Giriş Yap
              </button>
            </div>
          </ul>
        </div>
        <FiMenu
          className="lg:hidden text-3xl cursor-pointer"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        />
      </div>
    </div>
  );
};

export default NavBar;
