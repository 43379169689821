import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: "",
    token: "",
    pol_vergi_no: "",
    pol_adi: "",
    pol_adres: "",
    pol_tel: "",
    pol_mail: "",
    pol_il: "",
    pol_ilce: "",
    pol_logo: "",
    d_id: "",
    d_adi: "",
    d_soyadi: "",
    d_diploma_no: "",
    cinsiyet: "",
    d_tel: "",
    d_mail: "",
    d_resim: "",
    d_adres: "",
    mezuniyet: "",
    mezuniyet_tarihi: "",
    mesai_baslangic: "",
    mesai_bitis: "",
    d_unvan: ""
};

export const userSlice = createSlice({
    name: 'uRegister',
    initialState,
    reducers: {
        userRegister: (state, { payload }) => {
            state.id = payload.id
            state.token = payload.token
            state.pol_vergi_no = payload.pol_vergi_no
            state.pol_adi = payload.pol_adi
            state.pol_adres = payload.pol_adres
            state.pol_tel = payload.pol_tel
            state.pol_mail = payload.pol_mail
            state.pol_il = payload.pol_il
            state.pol_ilce = payload.pol_ilce
            state.pol_logo = payload.pol_logo
        },
        doctorRegister: (state, { payload }) => {
            state.d_id = payload.id;
            state.d_adi = payload.d_adi;
            state.d_soyadi = payload.d_soyadi;
            state.d_diploma_no = payload.d_diploma_no;
            state.pol_vergi_no = payload.pol_vergi_no;
            state.cinsiyet = payload.cinsiyet;
            state.d_tel = payload.d_tel;
            state.d_mail = payload.d_mail;
            state.d_resim = payload.d_resim;
            state.d_adres = payload.d_adres;
            state.mezuniyet = payload.mezuniyet;
            state.mezuniyet_tarihi = payload.mezuniyet_tarihi;
            state.mesai_baslangic = payload.mesai_baslangic;
            state.mesai_bitis = payload.mesai_bitis;
            state.d_unvan = payload.d_unvan;
        },
        logout: (state) => {
            state.id = ""
            state.token = ""
            state.pol_vergi_no = ""
            state.pol_adi = ""
            state.pol_adres = ""
            state.pol_tel = ""
            state.pol_mail = ""
            state.pol_il = ""
            state.pol_ilce = ""
            state.pol_logo = ""
            state.d_id = "";
            state.d_adi = "";
            state.d_soyadi = "";
            state.d_diploma_no = "";
            state.cinsiyet = "";
            state.d_tel = "";
            state.d_mail = "";
            state.d_resim = "";
            state.d_adres = "";
            state.mezuniyet = "";
            state.mezuniyet_tarihi = "";
            state.mesai_baslangic = "";
            state.mesai_bitis = "";
            state.d_unvan = "";
        }
    },
});

export const { userRegister, doctorRegister, logout } = userSlice.actions;

export default userSlice.reducer