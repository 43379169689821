import React from "react";
import { useSelector } from "react-redux";

const ClinicHeader = () => {
  const uRegister = useSelector((state) => state.uRegister);

  // Numarayı 0(555) 636 63 36 formatına çevir
  const formatPhoneNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/\D/g, ""); // Sadece sayısal karakterleri alır
    const match = phoneNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `0(${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
    }
    return phoneNumber; // Eğer format uygun değilse, orijinal değeri döndürür
  };

  return (
    <div className="flex flex-row mt-1 gap-20 py-2 bg-white">
      <div className="flex basis-4/6 justify-start items-center px-10 py-4">
        <img width={100} src={uRegister.pol_logo} alt={uRegister.pol_adi} />
        <div className="text-2xl text-Sky-700 ml-8">
          <h1 className="font-bold" style={{ fontSize: "2rem" }}>
            {uRegister.pol_adi}
          </h1>
          <h2>POLİKLİNİĞİ</h2>
        </div>
      </div>
      <div className="basis-3/12">
        <h3 className="font-bold text-gray">ADRES</h3>
        <p>
          {uRegister.pol_adres} <br />
        </p>
        <p className="font-medium  pr-10">
          {uRegister.pol_ilce} / {uRegister.pol_il}
        </p>
      </div>
      <div className="basis-2/12">
        <h3 className="font-bold text-gray">TELEFON</h3>
        <p>{formatPhoneNumber(uRegister.pol_tel)}</p>
        <p>{uRegister.pol_tel2}</p>
        <p>{uRegister.pol_tel3}</p>
      </div>
    </div>
  );
};

export default ClinicHeader;
