import axios from "axios";
import React, { useState } from "react";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../features/userSlice";
import { toast } from "react-toastify";
import { doctorRegister, userRegister } from "../../features/registerSlice";
import * as yup from "yup";
import config from "../../config";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Geçerli bir e-posta adresi giriniz.")
    .required("E-posta adresi zorunludur."),
  password: yup
    .string()
    .required("Lütfen geçerli bir Password giriniz")
    // .matches(
    //   /^[a-zA-Z0-9+*\/-]*$/,
    //   "Sadece +, -, *, / özel karakterler kullanılabilir."
    // )
    .notOneOf(
      ["+", "-", "*", "/"],
      "+, -, *, / Bu özel Karakterler Kullanılamaz"
    ),
});

const LoginModal = ({
  setShowRegister,
  setShowLogin,
  setShowForgotPassword,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRemember, setIsRemember] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const uRegister = useSelector((state) => state.uRegister);

  const close = () => {
    setShowLogin(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await schema.validate({ email, password }, { abortEarly: false });
      const response = await axios.post(
        `${config.API_BASE_URL}loginPost`,
        {
          email: email,
          password: password,
        }
        // {
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     Authorization: "Bearer " + uRegister.token, // Authorization
        //   },
        // }
      );
      setErrors({});
      // console.log(response.data);
      if (response.data) {
        // console.log("response.data", response.data);
        const token = response.data.token.token;

        dispatch(
          userLogin({
            id: response.data.id,
            email: response.data.email,
            username: response.data.username,
            token: token,
            kullanici_turu: response.data.kullanici_turu,
          })
        );

        let myClinic = [];
        // kullanici_turu = 1 klinik girişi.
        // kullanici_turu = 2 doktor girişi.
        if (response.data.kullanici_turu === 1) {
          const resp = await axios.get(
            `${config.API_BASE_URL}pol_uye_index`,
            {
              headers: {
                // Authorization: "Bearer " + token, // Authorization
              },
            }
          );
          // console.log("resp.data", resp.data);
          if (resp.data) {
            myClinic = resp.data.filter(
              (item) => item.pol_mail === response.data.email
            );

            if (myClinic.length === 0) {
              return toast.error("Giriş yapılamadı! Poliklinik bulunamadı!");
            }

            const {
              pol_vergi_no,
              pol_adi,
              pol_adres,
              pol_tel,
              pol_mail,
              pol_il,
              pol_ilce,
              pol_logo,
            } = myClinic[0];

            dispatch(
              userRegister({
                id: pol_vergi_no,
                token: token,
                pol_vergi_no: pol_vergi_no,
                pol_adi: pol_adi,
                pol_adres: pol_adres,
                pol_tel: pol_tel,
                pol_mail: pol_mail,
                pol_il: pol_il,
                pol_ilce: pol_ilce,
                pol_logo: pol_logo,
              })
            );
          }

          toast.success("Giriş başarılı");

          setTimeout(() => {
            navigate("/poliklinik");
          }, 500);
        } else {
          if (response.data) {
            const token = response.data.token.token;
            dispatch(
              userLogin({
                id: response.data.id,
                email: response.data.email,
                username: response.data.username,
                token: token,
                kullanici_turu: response.data.kullanici_turu,
              })
            );

            const resp = await axios.post(
              `${config.API_BASE_URL}dok_uye/doktorProfil`,
              {
                d_mail: response.data.email,
              },
              {
                headers: {
                  Authorization: "Bearer " + token, // Authorization
                },
              }
            );

            dispatch(
              doctorRegister({
                d_id: resp.data[0].id,
                d_adi: resp.data[0].d_adi,
                d_soyadi: resp.data[0].d_soyadi,
                d_diploma_no: resp.data[0].d_diploma_no,
                pol_vergi_no: resp.data[0].pol_vergi_no,
                cinsiyet: resp.data[0].cinsiyet,
                d_tel: resp.data[0].d_tel,
                d_mail: resp.data[0].d_mail,
                d_resim: resp.data[0].d_resim,
                d_adres: resp.data[0].d_adres,
                mezuniyet: resp.data[0].mezuniyet,
                mezuniyet_tarihi: resp.data[0].mezuniyet_tarihi,
                mesai_baslangic: resp.data[0].mesai_baslangic,
                mesai_bitis: resp.data[0].mesai_bitis,
                d_unvan: resp.data[0].d_unvan,
              })
            );
            // console.log(resp.data[0].klinik_bilgileri.token);
            dispatch(
              userRegister({
                id: resp.data[0].klinik_bilgileri.pol_vergi_no,
                token: token,
                pol_vergi_no: resp.data[0].klinik_bilgileri.pol_vergi_no,
                pol_adi: resp.data[0].klinik_bilgileri.pol_adi,
                pol_adres: resp.data[0].klinik_bilgileri.pol_adres,
                pol_tel: resp.data[0].klinik_bilgileri.pol_tel,
                pol_mail: resp.data[0].klinik_bilgileri.pol_mail,
                pol_il: resp.data[0].klinik_bilgileri.pol_il,
                pol_ilce: resp.data[0].klinik_bilgileri.pol_ilce,
                pol_logo: resp.data[0].klinik_bilgileri.pol_logo,
              })
            );

            toast.success(
              "Giriş başarılı hoş geldiniz: " +
                resp.data[0].d_adi +
                " " +
                resp.data[0].d_soyadi
            );

            setTimeout(() => {
              navigate("/doktorlar");
            }, 2000);
          } //if response.data
        }
      } else {
        toast.error("Giriş yapılamadı! Bilgilerinizi kontrol ediniz.");
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      }
      // console.log(error);
      toast.error(
        "E-mail veya Password hatalı. Bilgileri kontrol ederek tekrar giriş yapınız."
      );
    }
  };
  // console.log(uRegister);
  return (
    <>
      <div className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed xs:inset-x-[2.5vw] sm:inset-x-[calc(50vw-14rem)] lg:inset-x-[calc(50vw-30rem)] inset-y-[calc(50vh-216px)] min-h-max min-w-max z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-row xs:w-[95vw] sm:w-[28rem] lg:w-[60rem]  bg-white outline-none focus:outline-none">
            <section className="w-1/2 bg-pink text-white p-10 xs:hidden lg:block rounded-l-lg">
              <h2 className="text-[24px] font-bold pb-5">Dentrey'e Üye Ol!</h2>
              <p className="text-[38px] font-light py-5">
                Hemen bize katılın
                <span className="font-bold"> ÜCRETSİZ </span>
                kullanın
              </p>
              <ul
                style={{ listStyleType: { BsCheck2 } }}
                className="font-light text-14 list-inside ]"
              >
                <li>
                  <BsCheck2 className="inline-block mr-2" />
                  Hasta Randevu Sistemi
                </li>
                <li>
                  <BsCheck2 className="inline-block mr-2" />
                  Hasta Kayıt Altyapısı
                </li>
                <li>
                  <BsCheck2 className="inline-block mr-2" />
                  Poliklinik Yönetimi
                </li>
              </ul>
              <button
                style={{
                  textTransform: "none",
                  boxShadow: "0 0 30px -12px #5616f5",
                }}
                className="h-10 w-28 mt-10 p-[10px 30px] text-14 tracking-[.5px] font-bold text-white items-center border-2 border-white rounded-full mr-3 hover:bg-white hover:text-blue1"
                type="button"
                onClick={() => {
                  setShowRegister(true);
                  setShowLogin(false);
                }}
              >
                Kaydol
              </button>
            </section>
            <div className="p-10 xs:w-full lg:w-1/2">
              <h2 className="text-[24px] font-bold">Üye Girişi</h2>

              <form
                action="#"
                className=" py-[20px]"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="flex-row pb-5">
                  <label
                    htmlFor="email"
                    className="form-label text-formGray text-14"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="py-2 w-full  border-b-[1.5px] border-b-formGray shadow-sm text-base transition-all duration-1000 outline-none focus:border-blue1"
                  />
                  {errors.email && (
                    <p className="input-error-message text-red">
                      {errors.email}
                    </p>
                  )}
                </div>
                <div className="flex-row">
                  <label
                    htmlFor="password"
                    className="form-label text-formGray text-14"
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    type="password"
                    placeholder=""
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="py-2 w-full  border-b-[1.5px] border-b-formGray shadow-sm text-base transition-all duration-1000 outline-none focus:border-blue1"
                  />
                  {errors.password && (
                    <p className="input-error-message text-red">
                      {errors.password}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <div className="form-control">
                    <label className="label cursor-pointer">
                      <input
                        value={isRemember}
                        onClick={() => setIsRemember(!isRemember)}
                        type="checkbox"
                        className="checkbox checkbox-primary"
                        checked={isRemember}
                        readOnly
                      />
                      <span className="label-text pl-2">Beni hatırla</span>
                    </label>
                  </div>
                  <p
                    className="btn-ghost font-bold text-pink hover:text-blue1 hover:bg-white cursor-pointer"
                    onClick={() => {
                      setShowForgotPassword(true);
                      setShowLogin(false);
                    }}
                  >
                    Şifremi unuttum
                  </p>
                </div>
                <div className="flex justify-between mt-7">
                  <button
                    style={{
                      textTransform: "none",
                      boxShadow: "0 0 30px -12px #5616f5",
                    }}
                    className="lg:hidden h-10 w-28  p-[10px 30px]  text-14 tracking-[.5px] font-bold text-white bg-pink items-center border-2 border-white rounded-full  hover:bg-white hover:text-pink hover:border-pink"
                    type="button"
                    onClick={() => {
                      setShowRegister(true);
                      setShowLogin(false);
                    }}
                  >
                    Kaydol
                  </button>
                  <button
                    style={{
                      textTransform: "none",
                      boxShadow: "0 0 30px -12px #5616f5",
                    }}
                    className="h-10 w-28 p-[10px 30px] text-14 tracking-[.5px] font-bold bg-blue1 text-white items-center border-2 border-white rounded-full  hover:bg-white hover:text-blue1 hover:border-blue1"
                    type="submit"
                    onClick={(e) => {
                      // setShowLogin(true);
                      // setShowRegister(false);
                      handleSubmit(e);
                    }}
                  >
                    Giriş Yap
                  </button>
                  <button
                    style={{
                      textTransform: "none",
                      boxShadow: "0 0 30px -12px #5616f5",
                    }}
                    className="h-10 w-28  p-[10px 30px] text-14 tracking-[.5px] font-bold bg-[#dadbdd] text-[#5616f5] items-center border-2 border-white rounded-full  hover:bg-white hover:text-blue1 hover:border-blue1"
                    type="submit"
                    onClick={close}
                  >
                    Vazgeç
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => close()}
        className="opacity-25 fixed inset-0 z-40 bg-black h-full w-full"
      ></div>
    </>
  );
};

export default LoginModal;
