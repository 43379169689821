import React from "react";
import { ilData } from "../../../helpers/ilData";
import axios from "axios";
import { useSelector } from "react-redux";
import config from "../../../config";

const CitiySelect = ({ place, setPlace }) => {
  const uRegister = useSelector((state) => state.uRegister);
  const handleChangeIl = async (e) => {
    setPlace({ ...place, [e.target.name]: e.target.value }); // ilçe kısmı için lazım
    const { data } = await axios.get(
      `${config.API_BASE_URL}il_klinikleri?pol_il=${e.target.value}`
    );

    if (data !== "false") {
      // setPolyclinics(data)
      // setInfo("İlçe seçiniz");
    }
  };

  const handleChange = async (e) => {
    setPlace({ ...place, [e.target.name]: e.target.value });
    const { data } = await axios.get(
      `${config.API_BASE_URL}ilce_klinikleri?pol_il=${place.city}&pol_ilce=${e.target.value}`
    );
    if (data !== "false") {
      // setPolyclinics(data)
      // setInfo("Poliklinik seçiniz");
    }
  };

  return (
    <article className="flex gap-3">
      <select
        name="city"
        id="city"
        className=" select select-sm w-52 transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
        value={place.city}
        onChange={handleChangeIl}
      >
        <option value="">İl Seçiniz</option>
        {ilData.map((il, i) => (
          <option value={il["text"]} key={i}>
            {il["text"]}
          </option>
        ))}
      </select>
      <select
        name="town"
        id="town"
        className="select select-sm w-52 transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
        value={place.town}
        onChange={handleChange}
      >
        <option value="">İlçe Seçiniz</option>
        <option value="MERKEZ">MERKEZ</option>
        {ilData
          .filter((data) => data["text"] === place.city)

          .map((ilce) =>
            ilce["districts"].map((e, i) => (
              <option value={e["text"]} key={i}>
                {e["text"]}
              </option>
            ))
          )}
      </select>
    </article>
  );
};

export default CitiySelect;
