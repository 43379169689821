export const policlinicProfile = {
    pol_adi: "İNCİ DİŞ",
    pol_logo:
        "https://ozeldent42.com/tema/genel/uploads/hizmetler/icon/implant.png",
    pol_il: "İSTANBUL",
    pol_ilce: "FATİH",
    pol_adres: "Cumhuriyet Mahallesi Sağlık Caddesi Kat:2 No:5",
    pol_tel: "+90 222 222 22 22",
    pol_tel2: "+90 222 222 22 22",
    pol_tel3: "+90 222 222 22 22",
    doktorlar: [
        {
            diploma_no: 2000000000,
            doktor_adi: "Egemen",
            doktor_soyadi: "Yılmaz",
            doktor_unvan: "Başhekim",
            doktor_photo:
                "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
            doktor_mesai: {
                pazartesi_1: 1,
                pazartesi_2: 1,
                pazartesi_3: 0,
                sali_1: 1,
                sali_2: 1,
                sali_3: 0,
                carsamba_1: 0,
                carsamba_2: 1,
                carsamba_3: 0,
                persembe_1: 1,
                persembe_2: 1,
                persembe_3: 0,
                cuma_1: 0,
                cuma_2: 1,
                cuma_3: 0,
                cumartesi_1: 0,
                cumartesi_2: 0,
                cumartesi_3: 0,
                pazar_1: 0,
                pazar_2: 0,
                pazar_3: 0,
            },
        },
        {
            diploma_no: 3000000000,
            doktor_adi: "Çelebi",
            doktor_soyadi: "Doğan",
            doktor_unvan: "Ortodonti Uzmanı",
            doktor_photo:
                "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
            doktor_mesai: {
                pazartesi_1: 1,
                pazartesi_2: 1,
                pazartesi_3: 0,
                sali_1: 1,
                sali_2: 1,
                sali_3: 0,
                carsamba_1: 1,
                carsamba_2: 1,
                carsamba_3: 0,
                persembe_1: 1,
                persembe_2: 1,
                persembe_3: 0,
                cuma_1: 0,
                cuma_2: 1,
                cuma_3: 0,
                cumartesi_1: 0,
                cumartesi_2: 0,
                cumartesi_3: 0,
                pazar_1: 0,
                pazar_2: 0,
                pazar_3: 0,
            },
        },
        {
            diploma_no: 3000000000,
            doktor_adi: "Zeynep",
            doktor_soyadi: "Kara",
            doktor_unvan: "Diş Hekimi",
            doktor_photo:
                "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
            doktor_mesai: {
                pazartesi_1: 1,
                pazartesi_2: 1,
                pazartesi_3: 0,
                sali_1: 1,
                sali_2: 1,
                sali_3: 0,
                carsamba_1: 1,
                carsamba_2: 1,
                carsamba_3: 0,
                persembe_1: 1,
                persembe_2: 1,
                persembe_3: 0,
                cuma_1: 0,
                cuma_2: 1,
                cuma_3: 0,
                cumartesi_1: 0,
                cumartesi_2: 0,
                cumartesi_3: 0,
                pazar_1: 0,
                pazar_2: 0,
                pazar_3: 0,
            },
        },
        {
            diploma_no: 3000000000,
            doktor_adi: "Mikail",
            doktor_soyadi: "Çelikbaş",
            doktor_unvan: "Diş Hekimi",
            doktor_photo:
                "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
            doktor_mesai: {
                pazartesi_1: 1,
                pazartesi_2: 1,
                pazartesi_3: 0,
                sali_1: 1,
                sali_2: 1,
                sali_3: 0,
                carsamba_1: 1,
                carsamba_2: 1,
                carsamba_3: 0,
                persembe_1: 1,
                persembe_2: 1,
                persembe_3: 0,
                cuma_1: 0,
                cuma_2: 1,
                cuma_3: 0,
                cumartesi_1: 0,
                cumartesi_2: 0,
                cumartesi_3: 0,
                pazar_1: 0,
                pazar_2: 0,
                pazar_3: 0,
            },
        },
        {
            diploma_no: 3000000000,
            doktor_adi: "Seray",
            doktor_soyadi: "Kiraz",
            doktor_unvan: "Diş Hekimi",
            doktor_photo:
                "https://wellwomanclinics.com/upload/6238de3da7b98.png",
            doktor_mesai: {
                pazartesi_1: 1,
                pazartesi_2: 1,
                pazartesi_3: 0,
                sali_1: 1,
                sali_2: 1,
                sali_3: 0,
                carsamba_1: 1,
                carsamba_2: 1,
                carsamba_3: 0,
                persembe_1: 1,
                persembe_2: 1,
                persembe_3: 0,
                cuma_1: 0,
                cuma_2: 1,
                cuma_3: 0,
                cumartesi_1: 0,
                cumartesi_2: 0,
                cumartesi_3: 0,
                pazar_1: 0,
                pazar_2: 0,
                pazar_3: 0,
            },
        },
        {
            diploma_no: 3000000000,
            doktor_adi: "Ahmet",
            doktor_soyadi: "Yakup",
            doktor_unvan: "Diş Hekimi",
            doktor_photo:
                "https://down.imgspng.com/download/0720/doctor_PNG16041.png",
            doktor_mesai: {
                pazartesi_1: 1,
                pazartesi_2: 1,
                pazartesi_3: 0,
                sali_1: 1,
                sali_2: 1,
                sali_3: 0,
                carsamba_1: 1,
                carsamba_2: 1,
                carsamba_3: 0,
                persembe_1: 1,
                persembe_2: 1,
                persembe_3: 0,
                cuma_1: 0,
                cuma_2: 1,
                cuma_3: 0,
                cumartesi_1: 0,
                cumartesi_2: 0,
                cumartesi_3: 0,
                pazar_1: 0,
                pazar_2: 0,
                pazar_3: 0,
            },
        },
        {
            diploma_no: 3000000000,
            doktor_adi: "Yavuz",
            doktor_soyadi: "Çelik",
            doktor_unvan: "Diş Hekimi",
            doktor_photo:
                "https://www.pngplay.com/wp-content/uploads/7/Doctor-Standing-Background-PNG-Image.png",
            doktor_mesai: {
                pazartesi_1: 1,
                pazartesi_2: 1,
                pazartesi_3: 0,
                sali_1: 1,
                sali_2: 1,
                sali_3: 0,
                carsamba_1: 1,
                carsamba_2: 1,
                carsamba_3: 0,
                persembe_1: 1,
                persembe_2: 1,
                persembe_3: 0,
                cuma_1: 0,
                cuma_2: 1,
                cuma_3: 0,
                cumartesi_1: 0,
                cumartesi_2: 0,
                cumartesi_3: 0,
                pazar_1: 0,
                pazar_2: 0,
                pazar_3: 0,
            },
        },
    ],
};

export const doktorlar = [
    {
        id: 0,
        name: "Egemen Yılmaz",
        img: "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
        email: "egemenyilmaz@email.com",
        phone: "0542 123 45 67",
        gender: "Erkek",
        ünvan: "Başhekim",
        adres: "Cumhuriyet Mah. 18. Cad. No:6 Merkez/Sivas",
        işbaşı: "01 Ocak 2022",
        doğum: "14 Mart 1980",
        üniversite: "Hacettepe Üniversitesi",
        izinler: [
            {
                id: 1,
                başlangıç: "3 Ocak 2022, Pazartesi",
                bitiş: "7 Ocak 2022, Cuma",
                gün: "5 Gün",
                türü: "Yıllık İzin",
            },
            {
                id: 2,
                başlangıç: "24 Şubat 2022, Perşembe",
                bitiş: "25 Şubat 2022, Cuma",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 3,
                başlangıç: "16 Mart 2022, Çarşamba",
                bitiş: "17 Mart 2022, Perşembe",
                gün: "2 Gün",
                türü: "Doktor Raporu",
            },
            {
                id: 4,
                başlangıç: "18 Nisan 2022, Pazartesi",
                bitiş: "20 Nisan 2022, Çarşamba",
                gün: "3 Gün",
                türü: "Babalık izni",
            },
            {
                id: 5,
                başlangıç: "18 Mayıs 2022, Çarşamba",
                bitiş: "19 Mayıs 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 6,
                başlangıç: "24 Haziran 2022, Cuma",
                bitiş: "24 Haziran 2022, Cuma",
                gün: "1 Gün",
                türü: "Doğum günü izni",
            },
        ],
        patients: [
            {
                id: 1,
                hastaAdı: "Barış Korkmaz",
                gender: "Erkek",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Kanal tedavisine devam edilecek",
            },
            {
                id: 2,
                hastaAdı: "Yusuf Kal",
                gender: "Erkek",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:00",
                descriptions: "Diş Eti Absesi ve Periodontal Abseler",
            },
            {
                id: 3,
                hastaAdı: "Ayşe Sağ",
                gender: "Kadın",
                randevuTarihi: "24.12.2022",
                randevuSaati: "09:30",
                descriptions: "Sağ arka 5 diş çekilecek",
            },
            {
                id: 4,
                hastaAdı: "Fatma Yakut",
                gender: "Kadın",
                randevuTarihi: "24.12.2022",
                randevuSaati: "14:30",
                descriptions: "Diş taşı temizliği yapılacak",
            },
            {
                id: 5,
                hastaAdı: "Ali Sağır",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "13:30",
                descriptions: "",
            },
        ],
        istatistik: [
            {
                id: 1,
                istatistikAdi: "Bakılan Hasta",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 14 },
                    { zamanAdı: "Bu Hafta", sayı: 44 },
                    { zamanAdı: "Bu Ay", sayı: 74 },
                    { zamanAdı: "Bu Yıl", sayı: 85 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 182 },
                ],
            },
            {
                id: 2,
                istatistikAdi: "Yapılan İşlem",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 8 },
                    { zamanAdı: "Bu Hafta", sayı: 21 },
                    { zamanAdı: "Bu Ay", sayı: 45 },
                    { zamanAdı: "Bu Yıl", sayı: 85 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 120 },
                ],
            },
            {
                id: 3,
                istatistikAdi: "Kullanılan İzin",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 4 },
                    { zamanAdı: "Bu Hafta", sayı: 4 },
                    { zamanAdı: "Bu Ay", sayı: 14 },
                    { zamanAdı: "Bu Yıl", sayı: 41 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 85 },
                ],
            },
            {
                id: 4,
                istatistikAdi: "Toplam Mesai",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 8 },
                    { zamanAdı: "Bu Hafta", sayı: 10 },
                    { zamanAdı: "Bu Ay", sayı: 42 },
                    { zamanAdı: "Bu Yıl", sayı: 100 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 124 },
                ],
            },
        ],
        uyguladıtedavi: [
            {
                id: 1,
                müdahaleKategori: "Cerrahi",
                müdahaleIstatistik: [
                    {müdahaleAdı: "Diş Çekimi", müdahaleSayı: 250},
                    { müdahaleAdı: "İmplant", müdahaleSayı: 140 },
                    { müdahaleAdı: "Gömülü Diş", müdahaleSayı: 80 },
                    { müdahaleAdı: "Çene kırığı", müdahaleSayı: 20 },
                    { müdahaleAdı: "Damak", müdahaleSayı: 40 },
                ],
            },
            {
                id: 2,
                müdahaleKategori: "Tedavi",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 14 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 145 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 421 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 180 },
                ],
            },
            {
                id: 3,
                müdahaleKategori: "Ortodonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 139 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 215 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 751 },
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 41 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 257 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 789 },
                    { müdahaleAdı: "Sallama", müdahaleSayı: 455 },
                    { müdahaleAdı: "Kasnakatma", müdahaleSayı: 142 },
                    { müdahaleAdı: "Entegre", müdahaleSayı: 425 },
                    { müdahaleAdı: "Ondoitasyon", müdahaleSayı: 125 },
                    { müdahaleAdı: "Pufrize", müdahaleSayı: 131 },
                ],
            },
            {
                id: 4,
                müdahaleKategori: "Radyoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Çene", müdahaleSayı: 112 },
                    { müdahaleAdı: "Diş", müdahaleSayı: 131 },
                    { müdahaleAdı: "Ağız", müdahaleSayı: 445 },
                ],
            },
            {
                id: 5,
                müdahaleKategori: "Fenoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 45 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 12 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 452 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 80 },
                ],
            },
            {
                id: 6,
                müdahaleKategori: "Ağız Bakım",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Beyazlatma", müdahaleSayı: 73 },
                    { müdahaleAdı: "Diş Taşı", müdahaleSayı: 786 },
                    { müdahaleAdı: "Diş Bakım", müdahaleSayı: 454 },
                ],
            },
            {
                id: 7,
                müdahaleKategori: "Cleoronti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 42 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 86 },
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 42 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 585 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 58 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 98 },
                ],
            },
            {
                id: 8,
                müdahaleKategori: "Ceromonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 42 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 422 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 475 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 74 },
                ],
            },
        ],
    },
    {
        id: 1,
        name: "Çelebi Doğan",
        img: "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
        email: "celebidogan@email.com",
        phone: "0542 142 42 67",
        gender: "Erkek",
        ünvan: "Ortodonti Uzmanı",
        adres: "Atatürk Mah. 321. Cad. No:13 Merkez/Ankara",
        işbaşı: "21 Mart 2022",
        doğum: "21 Mart 1985",
        üniversite: "Ankara Üniversitesi",
        izinler: [
            {
                id: 1,
                başlangıç: "11 Ocak 2022, Salı",
                bitiş: "14 Ocak 2022, Cuma",
                gün: "4 Gün",
                türü: "Yıllık İzin",
            },
            {
                id: 2,
                başlangıç: "2 Şubat 2022, Çarşamba",
                bitiş: "3 Şubat 2022, Perşembe",
                gün: "2 Gün",
                türü: "Doktor Raporu",
            },
            {
                id: 3,
                başlangıç: "28 Mart 2022, Pazartesi",
                bitiş: "29 Mart 2022, Salı",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 4,
                başlangıç: "20 Nisan 2022, Çarşamba",
                bitiş: "21 Nisan 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 5,
                başlangıç: "18 Mayıs 2022, Çarşamba",
                bitiş: "19 Mayıs 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 6,
                başlangıç: "27 Haziran 2022, Pazartesi",
                bitiş: "27 Haziran 2022, Pazartesi",
                gün: "1 Gün",
                türü: "İdari İzin",
            },
        ],
        patients: [
            {
                id: 1,
                hastaAdı: "Yusuf Solmaz",
                gender: "Erkek",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Damak yarılması",
            },
            {
                id: 2,
                hastaAdı: "Tuğba Kılıç",
                gender: "Kadın",
                randevuTarihi: "23.12.2022",
                randevuSaati: "09:30",
                descriptions: "Diş teli yapımı",
            },
            {
                id: 3,
                hastaAdı: "Mehmet Şahin",
                gender: "Erkek",
                randevuTarihi: "23.12.2022",
                randevuSaati: "13:30",
                descriptions: "Sol arka 2 diş çekilecek",
            },
            {
                id: 4,
                hastaAdı: "Yavuz Akar",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "09:30",
                descriptions: "Diş beyazlatma yapılacak",
            },
            {
                id: 5,
                hastaAdı: "Yakup Seyyar",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "13:30",
                descriptions: "Çene düzeltme",
            },
        ],
        istatistik: [
            {
                id: 1,
                istatistikAdi: "Bakılan Hasta",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 21 },
                    { zamanAdı: "Bu Hafta", sayı: 42 },
                    { zamanAdı: "Bu Ay", sayı: 52 },
                    { zamanAdı: "Bu Yıl", sayı: 85 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 254 },
                ],
            },
            {
                id: 2,
                istatistikAdi: "Yapılan İşlem",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 12 },
                    { zamanAdı: "Bu Hafta", sayı: 24 },
                    { zamanAdı: "Bu Ay", sayı: 34 },
                    { zamanAdı: "Bu Yıl", sayı: 53 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 150 },
                ],
            },
            {
                id: 3,
                istatistikAdi: "Kullanılan İzin",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 1 },
                    { zamanAdı: "Bu Hafta", sayı: 7 },
                    { zamanAdı: "Bu Ay", sayı: 24 },
                    { zamanAdı: "Bu Yıl", sayı: 87 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 120 },
                ],
            },
            {
                id: 4,
                istatistikAdi: "Toplam Mesai",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 18 },
                    { zamanAdı: "Bu Hafta", sayı: 41 },
                    { zamanAdı: "Bu Ay", sayı: 52 },
                    { zamanAdı: "Bu Yıl", sayı: 200 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 321 },
                ],
            },
        ],
        uyguladıtedavi: [
            {
                id: 1,
                müdahaleKategori: "Cerrahi",
                müdahaleIstatistik: [
                    {
                        müdahaleAdı: "Diş Çekimi",
                        müdahaleSayı: 241,
                    },
                    { müdahaleAdı: "İmplant", müdahaleSayı: 253 },

                    { müdahaleAdı: "Gömülü Diş", müdahaleSayı: 875 },
                    { müdahaleAdı: "Çene kırığı", müdahaleSayı: 758 },
                    { müdahaleAdı: "Damak", müdahaleSayı: 7 },
                ],
            },
            {
                id: 2,
                müdahaleKategori: "Tedavi",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 47 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 58 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 74 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 412 },
                ],
            },
            {
                id: 3,
                müdahaleKategori: "Ortodonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 75 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 69 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 78 },
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 786 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 456 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 435 },
                    { müdahaleAdı: "Sallama", müdahaleSayı: 95 },
                    { müdahaleAdı: "Kasnakatma", müdahaleSayı: 25 },
                    { müdahaleAdı: "Entegre", müdahaleSayı: 34 },
                    { müdahaleAdı: "Ondoitasyon", müdahaleSayı: 521 },
                    { müdahaleAdı: "Pufrize", müdahaleSayı: 434 },
                ],
            },
            {
                id: 4,
                müdahaleKategori: "Radyoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Çene", müdahaleSayı: 455 },
                    { müdahaleAdı: "Diş", müdahaleSayı: 351 },
                    { müdahaleAdı: "Ağız", müdahaleSayı: 354 },
                ],
            },
            {
                id: 5,
                müdahaleKategori: "Fenoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 12 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 213 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 89 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 785 },
                ],
            },
            {
                id: 6,
                müdahaleKategori: "Ağız Bakım",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Beyazlatma", müdahaleSayı: 45 },
                    { müdahaleAdı: "Diş Taşı", müdahaleSayı: 345 },
                    { müdahaleAdı: "Diş Bakım", müdahaleSayı: 342 },
                ],
            },
            {
                id: 7,
                müdahaleKategori: "Cleoronti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 842 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 768 },
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 123 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 542 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 5824 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 68 },
                ],
            },
            {
                id: 8,
                müdahaleKategori: "Ceromonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 412 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 120 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 705 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 12 },
                ],
            },
        ],
    },
    {
        id: 2,
        name: "Zeynep Kara",
        img: "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
        email: "zeynepkara@email.com",
        phone: "0541 454 23 89",
        gender: "Kadın",
        ünvan: "Diş Hekimi",
        adres: "Yavuz Selim Mah. 123. Cad. No:6 Merkez/İstanbul",
        işbaşı: "28 Şubat 2020",
        doğum: "13 Temmuz 1990",
        üniversite: "Hacettepe Üniversitesi",
        izinler: [
            {
                id: 1,
                başlangıç: "4 Ocak 2022, Salı",
                bitiş: "8 Ocak 2022, Cuma",
                gün: "4 Gün",
                türü: "Yıllık İzin",
            },
            {
                id: 2,
                başlangıç: "17 Şubat 2022, Çarşamba",
                bitiş: "19 Şubat 2022, Perşembe",
                gün: "3 Gün",
                türü: "Doktor Raporu",
            },
            {
                id: 3,
                başlangıç: "4 Mart 2022, Pazartesi",
                bitiş: "5 Mart 2022, Salı",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 4,
                başlangıç: "18 Nisan 2022, Çarşamba",
                bitiş: "19 Nisan 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 5,
                başlangıç: "24 Mayıs 2022, Çarşamba",
                bitiş: "25 Mayıs 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 6,
                başlangıç: "4 Haziran 2022, Pazartesi",
                bitiş: "4 Haziran 2022, Pazartesi",
                gün: "1 Gün",
                türü: "İdari İzin",
            },
        ],
        patients: [
            {
                id: 1,
                hastaAdı: "Asuman Yılmaz",
                gender: "Kadın",
                randevuTarihi: "22.12.2022",
                randevuSaati: "10:00",
                descriptions: "Diş eti kanaması",
            },
            {
                id: 2,
                hastaAdı: "Faruk Kırılmaz",
                gender: "Erkek",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:30",
                descriptions: "Dolgu",
            },
            {
                id: 3,
                hastaAdı: "Ayşe Sabah",
                gender: "Kadın",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:30",
                descriptions: "Kaplama",
            },
            {
                id: 4,
                hastaAdı: "Mustafa Fırtına",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "10:30",
                descriptions: "Kanal tedavisi",
            },
            {
                id: 5,
                hastaAdı: "Meryem Tari",
                gender: "Kadın",
                randevuTarihi: "24.12.2022",
                randevuSaati: "13:30",
                descriptions: "Diş yapıştırma",
            },
        ],
        istatistik: [
            {
                id: 1,
                istatistikAdi: "Bakılan Hasta",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 32 },
                    { zamanAdı: "Bu Hafta", sayı: 35 },
                    { zamanAdı: "Bu Ay", sayı: 64 },
                    { zamanAdı: "Bu Yıl", sayı: 195 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 341 },
                ],
            },
            {
                id: 2,
                istatistikAdi: "Yapılan İşlem",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 11 },
                    { zamanAdı: "Bu Hafta", sayı: 25 },
                    { zamanAdı: "Bu Ay", sayı: 31 },
                    { zamanAdı: "Bu Yıl", sayı: 48 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 118 },
                ],
            },
            {
                id: 3,
                istatistikAdi: "Kullanılan İzin",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 0 },
                    { zamanAdı: "Bu Hafta", sayı: 4 },
                    { zamanAdı: "Bu Ay", sayı: 14 },
                    { zamanAdı: "Bu Yıl", sayı: 25 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 87 },
                ],
            },
            {
                id: 4,
                istatistikAdi: "Toplam Mesai",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 8 },
                    { zamanAdı: "Bu Hafta", sayı: 40 },
                    { zamanAdı: "Bu Ay", sayı: 64 },
                    { zamanAdı: "Bu Yıl", sayı: 198 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 415 },
                ],
            },
        ],
        uyguladıtedavi: [
            {
                id: 1,
                müdahaleKategori: "Cerrahi",
                müdahaleIstatistik: [
                    {
                        müdahaleAdı: "Diş Çekimi",
                        müdahaleSayı: 194,
                    },
                    { müdahaleAdı: "İmplant", müdahaleSayı: 241 },

                    { müdahaleAdı: "Gömülü Diş", müdahaleSayı: 741 },
                    { müdahaleAdı: "Çene kırığı", müdahaleSayı: 344 },
                    { müdahaleAdı: "Damak", müdahaleSayı: 452 },
                ],
            },
            {
                id: 2,
                müdahaleKategori: "Tedavi",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 45 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 78 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 458 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 435 },
                ],
            },
            {
                id: 3,
                müdahaleKategori: "Ortodonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 74 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 95 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 45 },
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 864 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 532 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 314 },
                    { müdahaleAdı: "Sallama", müdahaleSayı: 105 },
                    { müdahaleAdı: "Kasnakatma", müdahaleSayı: 34 },
                    { müdahaleAdı: "Entegre", müdahaleSayı: 54 },
                    { müdahaleAdı: "Ondoitasyon", müdahaleSayı: 858 },
                    { müdahaleAdı: "Pufrize", müdahaleSayı: 341 },
                ],
            },
            {
                id: 4,
                müdahaleKategori: "Radyoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Çene", müdahaleSayı: 541 },
                    { müdahaleAdı: "Diş", müdahaleSayı: 258 },
                    { müdahaleAdı: "Ağız", müdahaleSayı: 97 },
                ],
            },
            {
                id: 5,
                müdahaleKategori: "Fenoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 78 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 454 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 358 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 967 },
                ],
            },
            {
                id: 6,
                müdahaleKategori: "Ağız Bakım",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Beyazlatma", müdahaleSayı: 423 },
                    { müdahaleAdı: "Diş Taşı", müdahaleSayı: 123 },
                    { müdahaleAdı: "Diş Bakım", müdahaleSayı: 378 },
                ],
            },
            {
                id: 7,
                müdahaleKategori: "Cleoronti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 613 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 712 },
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 4523 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 546 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 1323 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 456 },
                ],
            },
            {
                id: 8,
                müdahaleKategori: "Ceromonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 45 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 56 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 12 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 34 },
                ],
            },
        ],
    },
    {
        id: 3,
        name: "Mikail Çelikbaş",
        img: "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
        email: "mikailcelikbas@email.com",
        phone: "0532 422 45 58",
        gender: "Erkek",
        ünvan: "Diş Hekimi",
        adres: "Barbaros Mah. 23. Cad. No:12 Merkez/Bolu",
        işbaşı: "14 Nisan 2022",
        doğum: "22 Ağustos 1991",
        üniversite: "Boğaziçi Üniversitesi",
        izinler: [
            {
                id: 1,
                başlangıç: "8 Ocak 2022, Salı",
                bitiş: "12 Ocak 2022, Cuma",
                gün: "5 Gün",
                türü: "Yıllık İzin",
            },
            {
                id: 2,
                başlangıç: "11 Şubat 2022, Çarşamba",
                bitiş: "12 Şubat 2022, Perşembe",
                gün: "2 Gün",
                türü: "Doktor Raporu",
            },
            {
                id: 3,
                başlangıç: "17 Mart 2022, Pazartesi",
                bitiş: "19 Mart 2022, Salı",
                gün: "3 Gün",
                türü: "İdari İzin",
            },
            {
                id: 4,
                başlangıç: "4 Nisan 2022, Çarşamba",
                bitiş: "5 Nisan 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 5,
                başlangıç: "6 Mayıs 2022, Çarşamba",
                bitiş: "7 Mayıs 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 6,
                başlangıç: "1 Haziran 2022, Pazartesi",
                bitiş: "14 Haziran 2022, Pazartesi",
                gün: "14 Gün",
                türü: "Yıllık İzin",
            },
        ],
        patients: [
            {
                id: 1,
                hastaAdı: "Furkan Kaçmaz",
                gender: "Erkek",
                randevuTarihi: "22.12.2022",
                randevuSaati: "11:00",
                descriptions: "Dolgu",
            },
            {
                id: 2,
                hastaAdı: "Zeynep Yavız",
                gender: "Kadın",
                randevuTarihi: "23.12.2022",
                randevuSaati: "08:30",
                descriptions: "Diş çekimi",
            },
            {
                id: 3,
                hastaAdı: "Ayşegül Topçu",
                gender: "Kadın",
                randevuTarihi: "23.12.2022",
                randevuSaati: "15:30",
                descriptions: "Dolgu",
            },
            {
                id: 4,
                hastaAdı: "Ekrem İnan",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "14:30",
                descriptions: "Kanal tedavisi",
            },
            {
                id: 5,
                hastaAdı: "Özkan Uğurlu",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "17:30",
                descriptions: "Diş yapıştırma",
            },
        ],
        istatistik: [
            {
                id: 1,
                istatistikAdi: "Bakılan Hasta",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 41 },
                    { zamanAdı: "Bu Hafta", sayı: 57 },
                    { zamanAdı: "Bu Ay", sayı: 86 },
                    { zamanAdı: "Bu Yıl", sayı: 257 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 451 },
                ],
            },
            {
                id: 2,
                istatistikAdi: "Yapılan İşlem",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 19 },
                    { zamanAdı: "Bu Hafta", sayı: 32 },
                    { zamanAdı: "Bu Ay", sayı: 42 },
                    { zamanAdı: "Bu Yıl", sayı: 58 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 107 },
                ],
            },
            {
                id: 3,
                istatistikAdi: "Kullanılan İzin",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 1 },
                    { zamanAdı: "Bu Hafta", sayı: 7 },
                    { zamanAdı: "Bu Ay", sayı: 21 },
                    { zamanAdı: "Bu Yıl", sayı: 45 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 150 },
                ],
            },
            {
                id: 4,
                istatistikAdi: "Toplam Mesai",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 4 },
                    { zamanAdı: "Bu Hafta", sayı: 12 },
                    { zamanAdı: "Bu Ay", sayı: 123 },
                    { zamanAdı: "Bu Yıl", sayı: 452 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 682 },
                ],
            },
        ],
        uyguladıtedavi: [
            {
                id: 1,
                müdahaleKategori: "Cerrahi",
                müdahaleIstatistik: [
                    {
                        müdahaleAdı: "Diş Çekimi",
                        müdahaleSayı: 123,
                    },
                    { müdahaleAdı: "İmplant", müdahaleSayı: 42 },

                    { müdahaleAdı: "Gömülü Diş", müdahaleSayı: 456 },
                    { müdahaleAdı: "Çene kırığı", müdahaleSayı: 736 },
                    { müdahaleAdı: "Damak", müdahaleSayı: 789 },
                ],
            },
            {
                id: 2,
                müdahaleKategori: "Tedavi",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 32 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 456 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 876 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 1231 },
                ],
            },
            {
                id: 3,
                müdahaleKategori: "Ortodonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 42 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 4536 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 789 },
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 787 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 5723 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 656 },
                    { müdahaleAdı: "Sallama", müdahaleSayı: 34 },
                    { müdahaleAdı: "Kasnakatma", müdahaleSayı: 786 },
                    { müdahaleAdı: "Entegre", müdahaleSayı: 12 },
                    { müdahaleAdı: "Ondoitasyon", müdahaleSayı: 35 },
                    { müdahaleAdı: "Pufrize", müdahaleSayı: 564 },
                ],
            },
            {
                id: 4,
                müdahaleKategori: "Radyoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Çene", müdahaleSayı: 213 },
                    { müdahaleAdı: "Diş", müdahaleSayı: 354 },
                    { müdahaleAdı: "Ağız", müdahaleSayı: 456 },
                ],
            },
            {
                id: 5,
                müdahaleKategori: "Fenoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 123 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 753 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 456 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 698 },
                ],
            },
            {
                id: 6,
                müdahaleKategori: "Ağız Bakım",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Beyazlatma", müdahaleSayı: 410 },
                    { müdahaleAdı: "Diş Taşı", müdahaleSayı: 453 },
                    { müdahaleAdı: "Diş Bakım", müdahaleSayı: 453 },
                ],
            },
            {
                id: 7,
                müdahaleKategori: "Cleoronti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 435 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 456 },
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 212 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 453 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 213 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 54 },
                ],
            },
            {
                id: 8,
                müdahaleKategori: "Ceromonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 67 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 89 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 76 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 89 },
                ],
            },
        ],
    },
    {
        id: 4,
        name: "Seray Kiraz",
        img: "https://wellwomanclinics.com/upload/6238de3da7b98.png",
        email: "seraykiraz@email.com",
        phone: "0542 123 45 67",
        gender: "Kadın",
        ünvan: "Diş Hekimi",
        adres: "Yavuz Selim Mah. 123. Cad. No:6 Merkez/İstanbul",
        işbaşı: "22 Temmuz 2020",
        doğum: "12 Mayıs 1992",
        üniversite: "Ankara Üniversitesi",
        izinler: [
            {
                id: 1,
                başlangıç: "10 Ocak 2022, Salı",
                bitiş: "18 Ocak 2022, Cuma",
                gün: "8 Gün",
                türü: "Yıllık İzin",
            },
            {
                id: 2,
                başlangıç: "7 Şubat 2022, Çarşamba",
                bitiş: "9 Şubat 2022, Perşembe",
                gün: "3 Gün",
                türü: "Doktor Raporu",
            },
            {
                id: 3,
                başlangıç: "1 Mart 2022, Pazartesi",
                bitiş: "5 Mart 2022, Salı",
                gün: "5 Gün",
                türü: "Yıllık İzin",
            },
            {
                id: 4,
                başlangıç: "8 Nisan 2022, Çarşamba",
                bitiş: "9 Nisan 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 5,
                başlangıç: "17 Mayıs 2022, Çarşamba",
                bitiş: "18 Mayıs 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 6,
                başlangıç: "21 Haziran 2022, Pazartesi",
                bitiş: "28 Haziran 2022, Pazartesi",
                gün: "4 Gün",
                türü: "Yıllık İzin",
            },
        ],
        patients: [
            {
                id: 1,
                hastaAdı: "Deniz Gezgin",
                gender: "Erkek",
                randevuTarihi: "22.12.2022",
                randevuSaati: "09:00",
                descriptions: "Diş eti iltihabı",
            },
            {
                id: 2,
                hastaAdı: "Fidan İşlem",
                gender: "Kadın",
                randevuTarihi: "23.12.2022",
                randevuSaati: "14:30",
                descriptions: "Diş çekimi",
            },
            {
                id: 3,
                hastaAdı: "Sultan Mert",
                gender: "Kadın",
                randevuTarihi: "23.12.2022",
                randevuSaati: "15:30",
                descriptions: "Kanal tedavisi",
            },
            {
                id: 4,
                hastaAdı: "Davut Korkmaz",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "13:30",
                descriptions: "Diş temizliği",
            },
            {
                id: 5,
                hastaAdı: "Uğur Çubuk",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "15:30",
                descriptions: "Dolgu",
            },
        ],
        istatistik: [
            {
                id: 1,
                istatistikAdi: "Bakılan Hasta",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 24 },
                    { zamanAdı: "Bu Hafta", sayı: 34 },
                    { zamanAdı: "Bu Ay", sayı: 45 },
                    { zamanAdı: "Bu Yıl", sayı: 687 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 846 },
                ],
            },
            {
                id: 2,
                istatistikAdi: "Yapılan İşlem",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 12 },
                    { zamanAdı: "Bu Hafta", sayı: 24 },
                    { zamanAdı: "Bu Ay", sayı: 45 },
                    { zamanAdı: "Bu Yıl", sayı: 67 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 97 },
                ],
            },
            {
                id: 3,
                istatistikAdi: "Kullanılan İzin",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 0 },
                    { zamanAdı: "Bu Hafta", sayı: 4 },
                    { zamanAdı: "Bu Ay", sayı: 17 },
                    { zamanAdı: "Bu Yıl", sayı: 34 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 124 },
                ],
            },
            {
                id: 4,
                istatistikAdi: "Toplam Mesai",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 3 },
                    { zamanAdı: "Bu Hafta", sayı: 9 },
                    { zamanAdı: "Bu Ay", sayı: 98 },
                    { zamanAdı: "Bu Yıl", sayı: 345 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 571 },
                ],
            },
        ],
        uyguladıtedavi: [
            {
                id: 1,
                müdahaleKategori: "Cerrahi",
                müdahaleIstatistik: [
                    {
                        müdahaleAdı: "Diş Çekimi",
                        müdahaleSayı: 241,
                    },
                    { müdahaleAdı: "İmplant", müdahaleSayı: 54 },

                    { müdahaleAdı: "Gömülü Diş", müdahaleSayı: 345 },
                    { müdahaleAdı: "Çene kırığı", müdahaleSayı: 786 },
                    { müdahaleAdı: "Damak", müdahaleSayı: 451 },
                ],
            },
            {
                id: 2,
                müdahaleKategori: "Tedavi",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 45 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 134 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 466 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 453 },
                ],
            },
            {
                id: 3,
                müdahaleKategori: "Ortodonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 23 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 256 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 435 },
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 435 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 466 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 879 },
                    { müdahaleAdı: "Sallama", müdahaleSayı: 314 },
                    { müdahaleAdı: "Kasnakatma", müdahaleSayı: 894 },
                    { müdahaleAdı: "Entegre", müdahaleSayı: 34 },
                    { müdahaleAdı: "Ondoitasyon", müdahaleSayı: 83 },
                    { müdahaleAdı: "Pufrize", müdahaleSayı: 987 },
                ],
            },
            {
                id: 4,
                müdahaleKategori: "Radyoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Çene", müdahaleSayı: 435 },
                    { müdahaleAdı: "Diş", müdahaleSayı: 1253 },
                    { müdahaleAdı: "Ağız", müdahaleSayı: 135 },
                ],
            },
            {
                id: 5,
                müdahaleKategori: "Fenoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 789 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 46 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 387 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 177 },
                ],
            },
            {
                id: 6,
                müdahaleKategori: "Ağız Bakım",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Beyazlatma", müdahaleSayı: 752 },
                    { müdahaleAdı: "Diş Taşı", müdahaleSayı: 7258 },
                    { müdahaleAdı: "Diş Bakım", müdahaleSayı: 789 },
                ],
            },
            {
                id: 7,
                müdahaleKategori: "Cleoronti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 978 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 278 },
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 213 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 389 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 1728 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 872 },
                ],
            },
            {
                id: 8,
                müdahaleKategori: "Ceromonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 782 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 89 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 768 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 354 },
                ],
            },
        ],
    },
    {
        id: 5,
        name: "Ahmet Yakup",
        img: "https://down.imgspng.com/download/0720/doctor_PNG16041.png",
        email: "ahmetyakup@email.com",
        phone: "0542 321 47 85",
        gender: "Erkek",
        ünvan: "Diş Hekimi",
        adres: "Cumhuriyet Mah. Lizbon. Cad. No:74 Merkez/Ankara",
        işbaşı: "14 Şubat 2022",
        doğum: "25 Mart 1985",
        üniversite: "Ankara Üniversitesi",
        izinler: [
            {
                id: 1,
                başlangıç: "11 Ocak 2022, Salı",
                bitiş: "14 Ocak 2022, Cuma",
                gün: "4 Gün",
                türü: "Yıllık İzin",
            },
            {
                id: 2,
                başlangıç: "2 Şubat 2022, Çarşamba",
                bitiş: "3 Şubat 2022, Perşembe",
                gün: "2 Gün",
                türü: "Doktor Raporu",
            },
            {
                id: 3,
                başlangıç: "28 Mart 2022, Pazartesi",
                bitiş: "29 Mart 2022, Salı",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 4,
                başlangıç: "20 Nisan 2022, Çarşamba",
                bitiş: "21 Nisan 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 5,
                başlangıç: "18 Mayıs 2022, Çarşamba",
                bitiş: "19 Mayıs 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 6,
                başlangıç: "27 Haziran 2022, Pazartesi",
                bitiş: "27 Haziran 2022, Pazartesi",
                gün: "1 Gün",
                türü: "İdari İzin",
            },
        ],
        patients: [
            {
                id: 1,
                hastaAdı: "Yusuf Solmaz",
                gender: "Erkek",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Damak yarılması",
            },
            {
                id: 2,
                hastaAdı: "Tuğba Kılıç",
                gender: "Kadın",
                randevuTarihi: "23.12.2022",
                randevuSaati: "09:30",
                descriptions: "Diş teli yapımı",
            },
            {
                id: 3,
                hastaAdı: "Mehmet Şahin",
                gender: "Erkek",
                randevuTarihi: "23.12.2022",
                randevuSaati: "13:30",
                descriptions: "Sol arka 2 diş çekilecek",
            },
            {
                id: 4,
                hastaAdı: "Yavuz Akar",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "09:30",
                descriptions: "Diş beyazlatma yapılacak",
            },
            {
                id: 5,
                hastaAdı: "Yakup Seyyar",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "13:30",
                descriptions: "Çene düzeltme",
            },
        ],
        istatistik: [
            {
                id: 1,
                istatistikAdi: "Bakılan Hasta",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 21 },
                    { zamanAdı: "Bu Hafta", sayı: 42 },
                    { zamanAdı: "Bu Ay", sayı: 52 },
                    { zamanAdı: "Bu Yıl", sayı: 85 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 254 },
                ],
            },
            {
                id: 2,
                istatistikAdi: "Yapılan İşlem",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 12 },
                    { zamanAdı: "Bu Hafta", sayı: 24 },
                    { zamanAdı: "Bu Ay", sayı: 34 },
                    { zamanAdı: "Bu Yıl", sayı: 53 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 150 },
                ],
            },
            {
                id: 3,
                istatistikAdi: "Kullanılan İzin",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 1 },
                    { zamanAdı: "Bu Hafta", sayı: 7 },
                    { zamanAdı: "Bu Ay", sayı: 24 },
                    { zamanAdı: "Bu Yıl", sayı: 87 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 120 },
                ],
            },
            {
                id: 4,
                istatistikAdi: "Toplam Mesai",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 18 },
                    { zamanAdı: "Bu Hafta", sayı: 41 },
                    { zamanAdı: "Bu Ay", sayı: 52 },
                    { zamanAdı: "Bu Yıl", sayı: 200 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 321 },
                ],
            },
        ],
        uyguladıtedavi: [
            {
                id: 1,
                müdahaleKategori: "Cerrahi",
                müdahaleIstatistik: [
                    {
                        müdahaleAdı: "Diş Çekimi",
                        müdahaleSayı: 241,
                    },
                    { müdahaleAdı: "İmplant", müdahaleSayı: 253 },

                    { müdahaleAdı: "Gömülü Diş", müdahaleSayı: 875 },
                    { müdahaleAdı: "Çene kırığı", müdahaleSayı: 758 },
                    { müdahaleAdı: "Damak", müdahaleSayı: 7 },
                ],
            },
            {
                id: 2,
                müdahaleKategori: "Tedavi",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 47 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 58 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 74 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 412 },
                ],
            },
            {
                id: 3,
                müdahaleKategori: "Ortodonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 75 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 69 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 78 },
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 786 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 456 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 435 },
                    { müdahaleAdı: "Sallama", müdahaleSayı: 95 },
                    { müdahaleAdı: "Kasnakatma", müdahaleSayı: 25 },
                    { müdahaleAdı: "Entegre", müdahaleSayı: 34 },
                    { müdahaleAdı: "Ondoitasyon", müdahaleSayı: 521 },
                    { müdahaleAdı: "Pufrize", müdahaleSayı: 434 },
                ],
            },
            {
                id: 4,
                müdahaleKategori: "Radyoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Çene", müdahaleSayı: 455 },
                    { müdahaleAdı: "Diş", müdahaleSayı: 351 },
                    { müdahaleAdı: "Ağız", müdahaleSayı: 354 },
                ],
            },
            {
                id: 5,
                müdahaleKategori: "Fenoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 12 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 213 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 89 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 785 },
                ],
            },
            {
                id: 6,
                müdahaleKategori: "Ağız Bakım",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Beyazlatma", müdahaleSayı: 45 },
                    { müdahaleAdı: "Diş Taşı", müdahaleSayı: 345 },
                    { müdahaleAdı: "Diş Bakım", müdahaleSayı: 342 },
                ],
            },
            {
                id: 7,
                müdahaleKategori: "Cleoronti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 842 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 768 },
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 123 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 542 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 5824 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 68 },
                ],
            },
            {
                id: 8,
                müdahaleKategori: "Ceromonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 412 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 120 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 705 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 12 },
                ],
            },
        ],
    },
    {
        id: 6,
        name: "Yavuz Çelik",
        img: "https://www.pngplay.com/wp-content/uploads/7/Doctor-Standing-Background-PNG-Image.png",
        email: "yavuzcelik@email.com",
        phone: "0545 857 87 98",
        gender: "Erkek",
        ünvan: "Diş Hekimi",
        adres: "Yavuz Selim Mah. 786. Cad. No:6 Merkez/İstanbul",
        işbaşı: "14 Şubat 2020",
        doğum: "28 Haziran 1990",
        üniversite: "Hacettepe Üniversitesi",
        izinler: [
            {
                id: 1,
                başlangıç: "4 Ocak 2022, Salı",
                bitiş: "8 Ocak 2022, Cuma",
                gün: "4 Gün",
                türü: "Yıllık İzin",
            },
            {
                id: 2,
                başlangıç: "17 Şubat 2022, Çarşamba",
                bitiş: "19 Şubat 2022, Perşembe",
                gün: "3 Gün",
                türü: "Doktor Raporu",
            },
            {
                id: 3,
                başlangıç: "4 Mart 2022, Pazartesi",
                bitiş: "5 Mart 2022, Salı",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 4,
                başlangıç: "18 Nisan 2022, Çarşamba",
                bitiş: "19 Nisan 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 5,
                başlangıç: "24 Mayıs 2022, Çarşamba",
                bitiş: "25 Mayıs 2022, Perşembe",
                gün: "2 Gün",
                türü: "İdari İzin",
            },
            {
                id: 6,
                başlangıç: "4 Haziran 2022, Pazartesi",
                bitiş: "4 Haziran 2022, Pazartesi",
                gün: "1 Gün",
                türü: "İdari İzin",
            },
        ],
        patients: [
            {
                id: 1,
                hastaAdı: "Asuman Yılmaz",
                gender: "Kadın",
                randevuTarihi: "22.12.2022",
                randevuSaati: "10:00",
                descriptions: "Diş eti kanaması",
            },
            {
                id: 2,
                hastaAdı: "Faruk Kırılmaz",
                gender: "Erkek",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:30",
                descriptions: "Dolgu",
            },
            {
                id: 3,
                hastaAdı: "Ayşe Sabah",
                gender: "Kadın",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:30",
                descriptions: "Kaplama",
            },
            {
                id: 4,
                hastaAdı: "Mustafa Fırtına",
                gender: "Erkek",
                randevuTarihi: "24.12.2022",
                randevuSaati: "10:30",
                descriptions: "Kanal tedavisi",
            },
            {
                id: 5,
                hastaAdı: "Meryem Tari",
                gender: "Kadın",
                randevuTarihi: "24.12.2022",
                randevuSaati: "13:30",
                descriptions: "Diş yapıştırma",
            },
        ],
        istatistik: [
            {
                id: 1,
                istatistikAdi: "Bakılan Hasta",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 32 },
                    { zamanAdı: "Bu Hafta", sayı: 35 },
                    { zamanAdı: "Bu Ay", sayı: 64 },
                    { zamanAdı: "Bu Yıl", sayı: 195 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 341 },
                ],
            },
            {
                id: 2,
                istatistikAdi: "Yapılan İşlem",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 11 },
                    { zamanAdı: "Bu Hafta", sayı: 25 },
                    { zamanAdı: "Bu Ay", sayı: 31 },
                    { zamanAdı: "Bu Yıl", sayı: 48 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 118 },
                ],
            },
            {
                id: 3,
                istatistikAdi: "Kullanılan İzin",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 0 },
                    { zamanAdı: "Bu Hafta", sayı: 4 },
                    { zamanAdı: "Bu Ay", sayı: 14 },
                    { zamanAdı: "Bu Yıl", sayı: 25 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 87 },
                ],
            },
            {
                id: 4,
                istatistikAdi: "Toplam Mesai",
                istatistikVeri: [
                    { zamanAdı: "Bugün", sayı: 8 },
                    { zamanAdı: "Bu Hafta", sayı: 40 },
                    { zamanAdı: "Bu Ay", sayı: 64 },
                    { zamanAdı: "Bu Yıl", sayı: 198 },
                    { zamanAdı: "Tüm Zamanlar", sayı: 415 },
                ],
            },
        ],
        uyguladıtedavi: [
            {
                id: 1,
                müdahaleKategori: "Cerrahi",
                müdahaleIstatistik: [
                    {
                        müdahaleAdı: "Diş Çekimi",
                        müdahaleSayı: 194,
                    },
                    { müdahaleAdı: "İmplant", müdahaleSayı: 241 },

                    { müdahaleAdı: "Gömülü Diş", müdahaleSayı: 741 },
                    { müdahaleAdı: "Çene kırığı", müdahaleSayı: 344 },
                    { müdahaleAdı: "Damak", müdahaleSayı: 452 },
                ],
            },
            {
                id: 2,
                müdahaleKategori: "Tedavi",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 45 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 78 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 458 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 435 },
                ],
            },
            {
                id: 3,
                müdahaleKategori: "Ortodonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 74 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 95 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 45 },
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 864 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 532 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 314 },
                    { müdahaleAdı: "Sallama", müdahaleSayı: 105 },
                    { müdahaleAdı: "Kasnakatma", müdahaleSayı: 34 },
                    { müdahaleAdı: "Entegre", müdahaleSayı: 54 },
                    { müdahaleAdı: "Ondoitasyon", müdahaleSayı: 858 },
                    { müdahaleAdı: "Pufrize", müdahaleSayı: 341 },
                ],
            },
            {
                id: 4,
                müdahaleKategori: "Radyoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Çene", müdahaleSayı: 541 },
                    { müdahaleAdı: "Diş", müdahaleSayı: 258 },
                    { müdahaleAdı: "Ağız", müdahaleSayı: 97 },
                ],
            },
            {
                id: 5,
                müdahaleKategori: "Fenoloji",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 78 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 454 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 358 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 967 },
                ],
            },
            {
                id: 6,
                müdahaleKategori: "Ağız Bakım",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Beyazlatma", müdahaleSayı: 423 },
                    { müdahaleAdı: "Diş Taşı", müdahaleSayı: 123 },
                    { müdahaleAdı: "Diş Bakım", müdahaleSayı: 378 },
                ],
            },
            {
                id: 7,
                müdahaleKategori: "Cleoronti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Gevşetme", müdahaleSayı: 613 },
                    { müdahaleAdı: "Uydurma", müdahaleSayı: 712 },
                    { müdahaleAdı: "Telgerme", müdahaleSayı: 4523 },
                    { müdahaleAdı: "Telleme", müdahaleSayı: 546 },
                    { müdahaleAdı: "Sıkma", müdahaleSayı: 1323 },
                    { müdahaleAdı: "Uyarlama", müdahaleSayı: 456 },
                ],
            },
            {
                id: 8,
                müdahaleKategori: "Ceromonti",
                müdahaleIstatistik: [
                    { müdahaleAdı: "Diagnoz", müdahaleSayı: 45 },
                    { müdahaleAdı: "Konservatif", müdahaleSayı: 56 },
                    { müdahaleAdı: "Endodonti", müdahaleSayı: 12 },
                    { müdahaleAdı: "Pedodonti", müdahaleSayı: 34 },
                ],
            },
        ],
    },
];

export const randevular = [
    {
        id: 1,
        hastaAdı: "Barış Korkmaz",
        gender: "Bay",
        doktorAdı: "Egemen Yılmaz",
        doktorİmg:
            "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "10:30",
        descriptions: "Kanal tedavisine devam edilecek",
        tc: 11111111111,
    },
    {
        id: 2,
        hastaAdı: "Yusuf Kal",
        gender: "Bay",
        doktorAdı: "Egemen Yılmaz",
        doktorİmg:
            "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "11:00",
        descriptions: "Diş Eti Absesi ve Periodontal Abseler",
        tc: 11111111112,
    },
    {
        id: 3,
        hastaAdı: "Ayşe Sağ",
        gender: "Bayan",
        doktorAdı: "Egemen Yılmaz",
        doktorİmg:
            "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "11:30",
        descriptions: "Sağ arka 5 diş çekilecek",
        tc: 11111111113,
    },
    {
        id: 4,
        hastaAdı: "Fatma Yakut",
        gender: "Bayan",
        doktorAdı: "Çelebi Doğan",
        doktorİmg: "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "12:30",
        descriptions: "Diş taşı temizliği yapılacak",
        tc: 11111111114,
    },
    {
        id: 5,
        hastaAdı: "Ali Sağır",
        gender: "Bay",
        doktorAdı: "Çelebi Doğan",
        doktorİmg: "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "14:30",
        descriptions: "Diş beyazlatma",
        tc: 11111111115,
    },
    {
        id: 6,
        hastaAdı: "Yusuf Solmaz",
        gender: "Bay",
        doktorAdı: "Çelebi Doğan",
        doktorİmg: "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "09:30",
        descriptions: "Diş çekimi",
        tc: 11111111116,
    },
    {
        id: 7,
        hastaAdı: "Tuğba Kılıç",
        gender: "Bayan",
        doktorAdı: "Zeynep Kara",
        doktorİmg:
            "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "10:30",
        descriptions: "Diş teli yapımı",
        tc: 11111111117,
    },
    {
        id: 8,
        hastaAdı: "Mehmet Bakır",
        gender: "Bay",
        doktorAdı: "Zeynep Kara",
        doktorİmg:
            "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "11:00",
        descriptions: "Diş teli yapımı",
        tc: 11111111118,
    },
    {
        id: 9,
        hastaAdı: "Bahar Öge",
        gender: "Bayan",
        doktorAdı: "Zeynep Kara",
        doktorİmg:
            "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "13:00",
        descriptions: "Dolgu yapımı",
        tc: 11111111119,
    },
    {
        id: 10,
        hastaAdı: "Yağmur Yılmaz",
        gender: "Bayan",
        doktorAdı: "Mikail Çelikbaş",
        doktorİmg:
            "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "08:30",
        descriptions: "Dolgu yapımı",
        tc: 11111111120,
    },
    {
        id: 11,
        hastaAdı: "Mehmed Şahin",
        gender: "Bay",
        doktorAdı: "Mikail Çelikbaş",
        doktorİmg:
            "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "09:30",
        descriptions: "Diş çekimi",
        tc: 11111111121,
    },
    {
        id: 12,
        hastaAdı: "Selma Şahin",
        gender: "Bayan",
        doktorAdı: "Mikail Çelikbaş",
        doktorİmg:
            "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "10:00",
        descriptions: "Kanal tedavisi",
        tc: 11111111122,
    },
    {
        id: 13,
        hastaAdı: "Mahmud Kal",
        gender: "Bay",
        doktorAdı: "Seray Kiraz",
        doktorİmg: "https://wellwomanclinics.com/upload/6238de3da7b98.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "10:00",
        descriptions: "Diş çekimi",
        tc: 11111111123,
    },
    {
        id: 14,
        hastaAdı: "Sultan Aslan",
        gender: "Bayan",
        doktorAdı: "Seray Kiraz",
        doktorİmg: "https://wellwomanclinics.com/upload/6238de3da7b98.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "11:00",
        descriptions: "Kaplama",
        tc: 11111111124,
    },
    {
        id: 15,
        hastaAdı: "Ayşegül Bakkal",
        gender: "Bayan",
        doktorAdı: "Seray Kiraz",
        doktorİmg: "https://wellwomanclinics.com/upload/6238de3da7b98.png",
        randevuTarihi: "23.12.2022",
        randevuSaati: "11:30",
        descriptions: "Kaplama",
        tc: 11111111125,
    },
];

export const doktoraGoreRandevu = [
    {
        id: 0,
        doktorAdı: "Egemen Yılmaz",
        doktorİmg:
            "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
        doktorUnvan: "Başhekim",
        randevular: [
            {
                id: 1,
                hastaAdı: "Barış Korkmaz",
                gender: "Bay",
                doktorAdı: "Egemen Yılmaz",
                doktorİmg:
                    "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:30",
                descriptions: "Kanal tedavisine devam edilecek",
                tc: 11111111111,
            },
            {
                id: 2,
                hastaAdı: "Yusuf Kal",
                gender: "Erkek",
                doktorAdı: "Egemen Yılmaz",
                doktorİmg:
                    "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Diş Eti Absesi ve Periodontal Abseler",
                tc: 11111111112,
            },
            {
                id: 3,
                hastaAdı: "Ayşe Sağ",
                gender: "Bayan",
                doktorAdı: "Egemen Yılmaz",
                doktorİmg:
                    "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:30",
                descriptions: "Sağ arka 5 diş çekilecek",
                tc: 11111111113,
            },
        ],
    },
    {
        id: 1,
        doktorAdı: "Çelebi Doğan",
        doktorİmg: "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
        doktorUnvan: "Ortodonti Uzmanı",
        randevular: [
            {
                id: 1,
                hastaAdı: "Fatma Yakut",
                gender: "Kadın",
                doktorAdı: "Çelebi Doğan",
                doktorİmg:
                    "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "12:30",
                descriptions: "Diş taşı temizliği yapılacak",
                tc: 11111111114,
            },
            {
                id: 2,
                hastaAdı: "Ali Sağır",
                gender: "Erkek",
                doktorAdı: "Çelebi Doğan",
                doktorİmg:
                    "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "14:30",
                descriptions: "Diş beyazlatma",
                tc: 11111111115,
            },
            {
                id: 3,
                hastaAdı: "Yusuf Solmaz",
                gender: "Erkek",
                doktorAdı: "Çelebi Doğan",
                doktorİmg:
                    "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "09:30",
                descriptions: "Diş çekimi",
                tc: 11111111116,
            },
        ],
    },
    {
        id: 2,
        doktorAdı: "Zeynep Kara",
        doktorİmg:
            "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
        doktorUnvan: "Diş Hekimi",
        randevular: [
            {
                id: 1,
                hastaAdı: "Tuğba Kılıç",
                gender: "Kadın",
                doktorAdı: "Zeynep Kara",
                doktorİmg:
                    "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:30",
                descriptions: "Diş teli yapımı",
                tc: 11111111117,
            },
            {
                id: 2,
                hastaAdı: "Mehmet Bakır",
                gender: "Erkek",
                doktorAdı: "Zeynep Kara",
                doktorİmg:
                    "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Diş teli yapımı",
                tc: 11111111118,
            },
            {
                id: 3,
                hastaAdı: "Bahar Öge",
                gender: "Kadın",
                doktorAdı: "Zeynep Kara",
                doktorİmg:
                    "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "13:00",
                descriptions: "Dolgu yapımı",
                tc: 11111111119,
            },
        ],
    },
    {
        id: 3,
        doktorAdı: "Mikail Çelikbaş",
        doktorİmg:
            "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
        doktorUnvan: "Diş Hekimi",
        randevular: [
            {
                id: 1,
                hastaAdı: "Yağmur Yılmaz",
                gender: "Kadın",
                doktorAdı: "Mikail Çelikbaş",
                doktorİmg:
                    "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "08:30",
                descriptions: "Dolgu yapımı",
                tc: 11111111120,
            },
            {
                id: 2,
                hastaAdı: "Mehmed Şahin",
                gender: "Erkek",
                doktorAdı: "Mikail Çelikbaş",
                doktorİmg:
                    "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "09:30",
                descriptions: "Diş çekimi",
                tc: 11111111121,
            },
            {
                id: 3,
                hastaAdı: "Selma Şahin",
                gender: "Kadın",
                doktorAdı: "Mikail Çelikbaş",
                doktorİmg:
                    "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:00",
                descriptions: "Kanal tedavisi",
                tc: 11111111122,
            },
        ],
    },
    {
        id: 21,
        doktorAdı: "Seray Kiraz",
        doktorİmg: "https://wellwomanclinics.com/upload/6238de3da7b98.png",
        doktorUnvan: "Diş Hekimi",
        randevular: [
            {
                id: 1,
                hastaAdı: "Mahmud Kal",
                gender: "Erkek",
                doktorAdı: "Seray Kiraz",
                doktorİmg:
                    "https://wellwomanclinics.com/upload/6238de3da7b98.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:00",
                descriptions: "Diş çekimi",
                tc: 11111111123,
            },
            {
                id: 2,
                hastaAdı: "Sultan Aslan",
                gender: "Kadın",
                doktorAdı: "Seray Kiraz",
                doktorİmg:
                    "https://wellwomanclinics.com/upload/6238de3da7b98.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Kaplama",
                tc: 11111111124,
            },
            {
                id: 3,
                hastaAdı: "Ayşegül Bakkal",
                gender: "Kadın",
                doktorAdı: "Seray Kiraz",
                doktorİmg:
                    "https://wellwomanclinics.com/upload/6238de3da7b98.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:30",
                descriptions: "Kaplama",
                tc: 11111111125,
            },
        ],
    },
    {
        id: 4,
        doktorAdı: "Egemen Yılmaz",
        doktorİmg:
            "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
        doktorUnvan: "Başhekim",
        randevular: [
            {
                id: 1,
                hastaAdı: "Barış Korkmaz",
                gender: "Bay",
                doktorAdı: "Egemen Yılmaz",
                doktorİmg:
                    "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:30",
                descriptions: "Kanal tedavisine devam edilecek",
                tc: 11111111111,
            },
            {
                id: 2,
                hastaAdı: "Yusuf Kal",
                gender: "Erkek",
                doktorAdı: "Egemen Yılmaz",
                doktorİmg:
                    "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Diş Eti Absesi ve Periodontal Abseler",
                tc: 11111111112,
            },
            {
                id: 3,
                hastaAdı: "Ayşe Sağ",
                gender: "Bayan",
                doktorAdı: "Egemen Yılmaz",
                doktorİmg:
                    "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:30",
                descriptions: "Sağ arka 5 diş çekilecek",
                tc: 11111111113,
            },
        ],
    },
    {
        id: 5,
        doktorAdı: "Çelebi Doğan",
        doktorİmg: "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
        doktorUnvan: "Ortodonti Uzmanı",
        randevular: [
            {
                id: 1,
                hastaAdı: "Fatma Yakut",
                gender: "Kadın",
                doktorAdı: "Çelebi Doğan",
                doktorİmg:
                    "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "12:30",
                descriptions: "Diş taşı temizliği yapılacak",
                tc: 11111111114,
            },
            {
                id: 2,
                hastaAdı: "Ali Sağır",
                gender: "Erkek",
                doktorAdı: "Çelebi Doğan",
                doktorİmg:
                    "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "14:30",
                descriptions: "Diş beyazlatma",
                tc: 11111111115,
            },
            {
                id: 3,
                hastaAdı: "Yusuf Solmaz",
                gender: "Erkek",
                doktorAdı: "Çelebi Doğan",
                doktorİmg:
                    "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "09:30",
                descriptions: "Diş çekimi",
                tc: 11111111116,
            },
        ],
    },
    {
        id: 6,
        doktorAdı: "Zeynep Kara",
        doktorİmg:
            "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
        doktorUnvan: "Diş Hekimi",
        randevular: [
            {
                id: 1,
                hastaAdı: "Tuğba Kılıç",
                gender: "Kadın",
                doktorAdı: "Zeynep Kara",
                doktorİmg:
                    "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:30",
                descriptions: "Diş teli yapımı",
                tc: 11111111117,
            },
            {
                id: 2,
                hastaAdı: "Mehmet Bakır",
                gender: "Erkek",
                doktorAdı: "Zeynep Kara",
                doktorİmg:
                    "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Diş teli yapımı",
                tc: 11111111118,
            },
            {
                id: 3,
                hastaAdı: "Bahar Öge",
                gender: "Kadın",
                doktorAdı: "Zeynep Kara",
                doktorİmg:
                    "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "13:00",
                descriptions: "Dolgu yapımı",
                tc: 11111111119,
            },
        ],
    },
    {
        id: 7,
        doktorAdı: "Mikail Çelikbaş",
        doktorİmg:
            "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
        doktorUnvan: "Diş Hekimi",
        randevular: [
            {
                id: 1,
                hastaAdı: "Yağmur Yılmaz",
                gender: "Kadın",
                doktorAdı: "Mikail Çelikbaş",
                doktorİmg:
                    "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "08:30",
                descriptions: "Dolgu yapımı",
                tc: 11111111120,
            },
            {
                id: 2,
                hastaAdı: "Mehmed Şahin",
                gender: "Erkek",
                doktorAdı: "Mikail Çelikbaş",
                doktorİmg:
                    "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "09:30",
                descriptions: "Diş çekimi",
                tc: 11111111121,
            },
            {
                id: 3,
                hastaAdı: "Selma Şahin",
                gender: "Kadın",
                doktorAdı: "Mikail Çelikbaş",
                doktorİmg:
                    "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:00",
                descriptions: "Kanal tedavisi",
                tc: 11111111122,
            },
        ],
    },
    {
        id: 8,
        doktorAdı: "Seray Kiraz",
        doktorİmg: "https://wellwomanclinics.com/upload/6238de3da7b98.png",
        doktorUnvan: "Diş Hekimi",
        randevular: [
            {
                id: 1,
                hastaAdı: "Mahmud Kal",
                gender: "Erkek",
                doktorAdı: "Seray Kiraz",
                doktorİmg:
                    "https://wellwomanclinics.com/upload/6238de3da7b98.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:00",
                descriptions: "Diş çekimi",
                tc: 11111111123,
            },
            {
                id: 2,
                hastaAdı: "Sultan Aslan",
                gender: "Kadın",
                doktorAdı: "Seray Kiraz",
                doktorİmg:
                    "https://wellwomanclinics.com/upload/6238de3da7b98.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Kaplama",
                tc: 11111111124,
            },
            {
                id: 3,
                hastaAdı: "Ayşegül Bakkal",
                gender: "Kadın",
                doktorAdı: "Seray Kiraz",
                doktorİmg:
                    "https://wellwomanclinics.com/upload/6238de3da7b98.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:30",
                descriptions: "Kaplama",
                tc: 11111111125,
            },
        ],
    },
    {
        id: 9,
        doktorAdı: "Egemen Yılmaz",
        doktorİmg:
            "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
        doktorUnvan: "Başhekim",
        randevular: [
            {
                id: 1,
                hastaAdı: "Barış Korkmaz",
                gender: "Bay",
                doktorAdı: "Egemen Yılmaz",
                doktorİmg:
                    "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:30",
                descriptions: "Kanal tedavisine devam edilecek",
                tc: 11111111111,
            },
            {
                id: 2,
                hastaAdı: "Yusuf Kal",
                gender: "Erkek",
                doktorAdı: "Egemen Yılmaz",
                doktorİmg:
                    "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Diş Eti Absesi ve Periodontal Abseler",
                tc: 11111111112,
            },
            {
                id: 3,
                hastaAdı: "Ayşe Sağ",
                gender: "Bayan",
                doktorAdı: "Egemen Yılmaz",
                doktorİmg:
                    "https://www.pngall.com/wp-content/uploads/2018/05/Doctor-PNG-File-Download-Free.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:30",
                descriptions: "Sağ arka 5 diş çekilecek",
                tc: 11111111113,
            },
        ],
    },
    {
        id: 10,
        doktorAdı: "Çelebi Doğan",
        doktorİmg: "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
        doktorUnvan: "Ortodonti Uzmanı",
        randevular: [
            {
                id: 1,
                hastaAdı: "Fatma Yakut",
                gender: "Kadın",
                doktorAdı: "Çelebi Doğan",
                doktorİmg:
                    "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "12:30",
                descriptions: "Diş taşı temizliği yapılacak",
                tc: 11111111114,
            },
            {
                id: 2,
                hastaAdı: "Ali Sağır",
                gender: "Erkek",
                doktorAdı: "Çelebi Doğan",
                doktorİmg:
                    "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "14:30",
                descriptions: "Diş beyazlatma",
                tc: 11111111115,
            },
            {
                id: 3,
                hastaAdı: "Yusuf Solmaz",
                gender: "Erkek",
                doktorAdı: "Çelebi Doğan",
                doktorİmg:
                    "https://down.imgspng.com/download/0720/doctor_PNG15959.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "09:30",
                descriptions: "Diş çekimi",
                tc: 11111111116,
            },
        ],
    },
    {
        id: 11,
        doktorAdı: "Zeynep Kara",
        doktorİmg:
            "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
        doktorUnvan: "Diş Hekimi",
        randevular: [
            {
                id: 1,
                hastaAdı: "Tuğba Kılıç",
                gender: "Kadın",
                doktorAdı: "Zeynep Kara",
                doktorİmg:
                    "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:30",
                descriptions: "Diş teli yapımı",
                tc: 11111111117,
            },
            {
                id: 2,
                hastaAdı: "Mehmet Bakır",
                gender: "Erkek",
                doktorAdı: "Zeynep Kara",
                doktorİmg:
                    "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Diş teli yapımı",
                tc: 11111111118,
            },
            {
                id: 3,
                hastaAdı: "Bahar Öge",
                gender: "Kadın",
                doktorAdı: "Zeynep Kara",
                doktorİmg:
                    "https://gcdentalworld.com/wp-content/uploads/2015/11/doktorka.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "13:00",
                descriptions: "Dolgu yapımı",
                tc: 11111111119,
            },
        ],
    },
    {
        id: 12,
        doktorAdı: "Mikail Çelikbaş",
        doktorİmg:
            "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
        doktorUnvan: "Diş Hekimi",
        randevular: [
            {
                id: 1,
                hastaAdı: "Yağmur Yılmaz",
                gender: "Kadın",
                doktorAdı: "Mikail Çelikbaş",
                doktorİmg:
                    "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "08:30",
                descriptions: "Dolgu yapımı",
                tc: 11111111120,
            },
            {
                id: 2,
                hastaAdı: "Mehmed Şahin",
                gender: "Erkek",
                doktorAdı: "Mikail Çelikbaş",
                doktorİmg:
                    "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "09:30",
                descriptions: "Diş çekimi",
                tc: 11111111121,
            },
            {
                id: 3,
                hastaAdı: "Selma Şahin",
                gender: "Kadın",
                doktorAdı: "Mikail Çelikbaş",
                doktorİmg:
                    "https://esendent.com.tr/wp-content/uploads/2022/07/engin-boruk.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:00",
                descriptions: "Kanal tedavisi",
                tc: 11111111122,
            },
        ],
    },
    {
        id: 13,
        doktorAdı: "Seray Kiraz",
        doktorİmg: "https://wellwomanclinics.com/upload/6238de3da7b98.png",
        doktorUnvan: "Diş Hekimi",
        randevular: [
            {
                id: 1,
                hastaAdı: "Mahmud Kal",
                gender: "Erkek",
                doktorAdı: "Seray Kiraz",
                doktorİmg:
                    "https://wellwomanclinics.com/upload/6238de3da7b98.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "10:00",
                descriptions: "Diş çekimi",
                tc: 11111111123,
            },
            {
                id: 2,
                hastaAdı: "Sultan Aslan",
                gender: "Kadın",
                doktorAdı: "Seray Kiraz",
                doktorİmg:
                    "https://wellwomanclinics.com/upload/6238de3da7b98.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:00",
                descriptions: "Kaplama",
                tc: 11111111124,
            },
            {
                id: 3,
                hastaAdı: "Ayşegül Bakkal",
                gender: "Kadın",
                doktorAdı: "Seray Kiraz",
                doktorİmg:
                    "https://wellwomanclinics.com/upload/6238de3da7b98.png",
                randevuTarihi: "23.12.2022",
                randevuSaati: "11:30",
                descriptions: "Kaplama",
                tc: 11111111125,
            },
        ],
    },
];
