import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Controller } from "swiper";
import { BsThreeDotsVertical } from "react-icons/bs";
import { SlCalender, SlClock } from "react-icons/sl";
// import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { TbDental } from "react-icons/tb";
import axios from "axios";
import config from "../../../config";
import { useSelector } from "react-redux";

const DoctorsOur = ({ doktorlar }) => {
  const uRegister = useSelector((state) => state.uRegister);
  // console.log("doktorlar", doktorlar);
  // console.log("data", data); data'da props olarak gelmişti. Karıtığı için sildim.
  const widthScreen = window.innerWidth;
  const [swiper, setSwiper] = useState();
  const [doktorlarIsActive, setDoktorlarIsActive] = useState([]);
  const prevRef = useRef();
  const nextRef = useRef();
  //   const { mesai? } = data[0][0].doktor;

  const getAppointment = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}klinik_doktorlar/${uRegister.id}`,
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );

      setDoktorlarIsActive(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAppointment();
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);
  // console.log("doktorlarIsActive", doktorlarIsActive);
  return (
    <div>
      <div className="mt-4 flex justify-between mb-4 px-2">
        <h1 className="font-bold">HEKİMLERİMİZ</h1>
        <article className="flex gap-3">
          <button
            ref={prevRef}
            className="bg-container w-10 h-10 flex items-center justify-center rounded-full"
          >
            <HiOutlineArrowLeft />
          </button>
          <button
            ref={nextRef}
            className="bg-container w-10 h-10 flex items-center justify-center rounded-full"
          >
            <HiOutlineArrowRight />
          </button>
        </article>
      </div>
      <Swiper
        className="grid grid-cols-5 gap-5"
        spaceBetween={25}
        slidesPerView={
          widthScreen < 768
            ? 1
            : widthScreen < 1050
            ? 2
            : widthScreen < 1400
            ? 3
            : widthScreen < 1800
            ? 4
            : 5
        }
        slidesPerGroup={2}
        // onSlideChange={() => console.log("slide change")}
        onSwiper={setSwiper}
        modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
        navigation={{
          prevEl: prevRef?.current,
          nextEl: nextRef?.current,
        }}
        updateOnWindowResize
        observer
        observeParents
      >
        {doktorlarIsActive?.map(
          (doktors, index) =>
            doktors.is_active === 1 && (
              <SwiperSlide key={index}>
                <div
                  className="card bg-container mb-4 flex items-center"
                  style={{ minWidth: "200px" }}
                >
                  <div className="relative flex flex-col justify-center items-center mt-4 md:">
                    <img
                      src={doktors?.d_resim}
                      alt="profile"
                      className="rounded-full w-32 h-32 object-contain border border-lightGray bg-white"
                    />
                    <h2 className="text-center mt-4 font-bold">
                      {doktors?.d_adi} {doktors?.d_soyadi}
                    </h2>
                    <p className="text-blue2">{doktors?.d_unvan}</p>
                    {/* <BsThreeDotsVertical className="absolute right-0 top-2 w-10" /> */}
                  </div>
                  <div className="card-body">
                    <div className="flex justify-between mx-8 opacity-50">
                      {/* <TbDental className="text-xl font-bold text-primary-focus" />
                    <TbDental
                      style={{ opacity: "0.1" }}
                      className="text-xl  font-bold"
                    />
                    <SlCalender className="ml-7" />
                    <SlClock className="mr-9" /> */}
                    </div>
                    <div
                      className="flex justify-center"
                      style={{
                        minWidth: "220px",
                        border: "solid 1px rgb(153, 109, 212, 0.3)",
                        borderRadius: "10px",
                      }}
                    >
                      <table
                        className=""
                        style={{
                          minWidth: "220px",
                          border: "none",
                        }}
                      >
                        <tbody className="">
                          <tr
                            className=""
                            style={{
                              borderBottom: "solid 1px rgb(153, 109, 212, 0.3)",
                            }}
                          >
                            <td
                              className="text-center"
                              style={{
                                borderBottom:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                                borderRight:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              <tr
                                className="h-9 "
                                style={{
                                  borderBottom:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                }}
                              ></tr>
                              <tr
                                className="mx-4 my-2"
                                style={{
                                  borderTop:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  height: "0.2rem",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Pazartesi
                              </tr>
                            </td>
                            <td className="text-center">
                              <tr
                                style={{
                                  borderBottom:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "33.3%",
                                    height: "3.5rem",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}
                                >
                                  SB <br />
                                  <p
                                    className="italic "
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    (09:00 - 12:00)
                                  </p>
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "33.3%",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    height: "3.5rem",
                                  }}
                                >
                                  ÖGL <br />
                                  <p
                                    className="italic "
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    (13:00 - 16:30)
                                  </p>
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    width: "33.3%",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    height: "3.5rem",
                                  }}
                                >
                                  AKŞ
                                  <br />
                                  <p
                                    className="italic "
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    (18:00 - 23:00)
                                  </p>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "33.3%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktors?.mesai?.["pazartesi_1"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold "
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "33.3%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktors?.mesai?.["pazartesi_2"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold "
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    width: "33.3%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktors?.mesai?.["pazartesi_3"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-5" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-5"
                                    />
                                  )}
                                </td>
                              </tr>
                            </td>
                          </tr>
                          <tr
                            className=""
                            style={{
                              borderBottom: "solid 1px rgb(153, 109, 212, 0.3)",
                            }}
                          >
                            <td
                              className=" text-center"
                              style={{
                                borderRight:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              Salı
                            </td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "33.8%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["sali_1"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "32.5%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["sali_2"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  width: "33.4%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["sali_3"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                            </div>
                          </tr>
                          <tr
                            className=""
                            style={{
                              borderBottom: "solid 1px rgb(153, 109, 212, 0.3)",
                            }}
                          >
                            <td
                              className=" text-center"
                              style={{
                                borderRight:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              Çarşamba
                            </td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "33.8%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["carsamba_1"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "32.5%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["carsamba_2"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  width: "33.4%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["carsamba_3"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                            </div>
                          </tr>
                          <tr
                            className=""
                            style={{
                              borderBottom: "solid 1px rgb(153, 109, 212, 0.3)",
                            }}
                          >
                            <td
                              className=" text-center"
                              style={{
                                borderRight:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              Perşembe
                            </td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "33.8%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["persembe_1"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "32.5%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["persembe_2"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  width: "33.4%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["persembe_3"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                            </div>
                          </tr>
                          <tr
                            className=""
                            style={{
                              borderBottom: "solid 1px rgb(153, 109, 212, 0.3)",
                            }}
                          >
                            <td
                              className=" text-center"
                              style={{
                                borderRight:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              Cuma
                            </td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "33.8%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["cuma_1"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "32.5%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["cuma_2"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>

                              <td
                                className="text-center"
                                style={{
                                  width: "33.4%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["cuma_3"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                            </div>
                          </tr>
                          <tr
                            className=""
                            style={{
                              borderBottom: "solid 1px rgb(153, 109, 212, 0.3)",
                            }}
                          >
                            <td
                              className=" text-center"
                              style={{
                                borderRight:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              Cumartesi
                            </td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "33.8%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["cumartesi_1"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "32.5%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["cumartesi_2"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  width: "33.4%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["cumartesi_3"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                            </div>
                          </tr>
                          <tr className="">
                            <td
                              className=" text-center"
                              style={{
                                borderRight:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              Pazar
                            </td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "33.8%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["pazar_1"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  width: "32.5%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["pazar_2"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  width: "33.4%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {doktors?.mesai?.["pazar_3"] ? (
                                  <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                ) : (
                                  <TbDental
                                    style={{
                                      opacity: "0.1",
                                      alignItems: "center",
                                    }}
                                    className="text-xl  font-bold h-6"
                                  />
                                )}
                              </td>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <div
                  className="flex justify-center gap-8 pb-4"
                  style={{
                    minWidth: "220px",
                  }}
                >
                  <button
                    className="btn btn-ghost bg-blue1 bg-opacity-10 text-blue1 capitalize hover:bg-blue1 hover:text-white"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                      setSelectedDoctor(doktor.d_diploma_no);
                 
                    }}
                  >
                    <AiOutlineCheck className="mr-2" /> Performans
                  </button>
                  <button
                    className="btn btn-ghost bg-red bg-opacity-10 text-red capitalize hover:bg-red hover:text-white"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(3);
                    }}
                  >
                    <AiOutlineClose className="mr-2" /> İzinler
                  </button>
                </div> */}
                </div>
              </SwiperSlide>
            )
        )}
      </Swiper>
    </div>
  );
};

export default DoctorsOur;
