import React, { useEffect, useState } from 'react';

const CheckBox = ({ saat, saatler, setSaatler }) => {
  const [isChecked, setIsChecked] = useState(false);


  useEffect(() => {
    setIsChecked(saatler[saat] === 1);
  }, [saat, saatler]);

  const handleCheckboxChange = () => {
    const updatedSaatler = { ...saatler };
    const selectedSaatIndex = Object.keys(updatedSaatler).findIndex((key) => key === saat);
  
    if (isChecked) {
      updatedSaatler[saat] = 0; // Uncheck the current saat
    } else {
      const selectedSaatKeys = Object.keys(updatedSaatler).filter(
        (key) => updatedSaatler[key] === 1
      );
      let start = selectedSaatIndex;
      let end = selectedSaatIndex;
  
      // Find the next selected saat (if any) in the forward direction
      for (let i = selectedSaatIndex + 1; i < Object.keys(updatedSaatler).length; i++) {
        if (updatedSaatler[Object.keys(updatedSaatler)[i]] === 1) {
          end = i;
          break;
        }
      }
  
      // Find the previous selected saat (if any) in the backward direction
      for (let i = selectedSaatIndex - 1; i >= 0; i--) {
        if (updatedSaatler[Object.keys(updatedSaatler)[i]] === 1) {
          start = i;
          break;
        }
      }
  
      // Check all the saatler between start and end, except those with value 2
      for (let i = start; i <= end; i++) {
        const key = Object.keys(updatedSaatler)[i];
        if (updatedSaatler[key] !== 2) {
          updatedSaatler[key] = 1;
        }
      }
  
      // Additional check: If a saat in between the selected saatler is unchecked, uncheck all saatler
      if (selectedSaatKeys.length > 1) {
        let isConsecutive = true;
        for (let i = start + 1; i < end; i++) {
          if (updatedSaatler[Object.keys(updatedSaatler)[i]] !== 1) {
            isConsecutive = false;
            break;
          }
        }
        if (!isConsecutive) {
          Object.keys(updatedSaatler).forEach((key) => {
            if (updatedSaatler[key] === 1) {
              updatedSaatler[key] = 0;
            }
          });
        }
      }
    }
  
    setSaatler(updatedSaatler);
    setIsChecked(!isChecked);
  };
  

  const degil = {
    width: '12px',
    border: '1px solid blue',
    borderRadius: '15px',
    padding: '2px 4px',
    cursor: 'pointer',
    fontWeight:"bold",
    userSelect:"none"
  };
  const dissabled = {
    width: '12px',
    border: '1px solid white',
    borderRadius: '15px',
    padding: '2px 4px',
    cursor: 'pointer',
    fontWeight:"bold",
    color: 'white',
    backgroundColor:"red",
    userSelect:"none"
  };

  const secili = {
    width: '12px',
    borderRadius: '15px',
    border: '1px solid red',
    backgroundColor: 'blue',
    color: 'white',
    padding: '2px 4px',
    cursor: 'pointer',
    fontWeight:"bold",
    userSelect:"none"
  };

  return (
    <div>
      <label htmlFor={saat} style={isChecked ? secili : saatler[saat] === 2 ? dissabled : degil}>
        {saat}
      </label>
      <input
        style={{ display: 'none' }}
        className="d-none"
        type="checkbox"
        id={saat}
        disabled={saatler[saat] === 2}
        name="myCheckbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
    </div>
  );
};

export default CheckBox;
