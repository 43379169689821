import React from "react";
import DateRangePickerComp from "./DateRangePickerComp";
import { useState } from "react";
import { addDays } from "date-fns";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "../../../config";

const DateRange = () => {
  const uRegister = useSelector((state) => state.uRegister);
  const [doktorAppointment, setDoktorAppointment] = useState([]);
  //* date state

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const getAppointment = async () => {
      const startDate = new Date(range[0].startDate);
      startDate.setHours(23, 59, 59, 999); // Saat, dakika, saniye ekleyerek günün bir önceki gün olarak gelmesini engelledik

      const endDate = new Date(range[0].endDate);
      endDate.setHours(23, 59, 59, 999); // Saat, dakika, saniye ve milisaniye değerlerini sona ayarla

      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];

      try {
        const response = await axios.get(
          `${config.API_BASE_URL}randevular/tarih?tarih_1=${formattedStartDate}&tarih_2=${formattedEndDate}&pol_vergi_no=${uRegister.id}`
        );
        if (!response.data.message) {
          setDoktorAppointment(response.data);
        } else {
          //   toast.error("Bu tarih aralığında kayıt bulunamadı!");
          setDoktorAppointment([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAppointment();
  }, [range, uRegister]);

  return (
    <div>
      <div className="flex justify-center my-8">
        {/* The button to open modal */}
        <label
          htmlFor="my-modal"
          className="btn-primary ml-3 btn btn-active btn-sm bg-blue1 gap-2 rounded-full hover:bg-white hover:text-blue1 capitalize px-8"
        >
          Tarih Seç
        </label>

        {/* Put this part before </body> tag */}
        <input type="checkbox" id="my-modal" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box w-11/12 max-w-5xl">
            <div className="flex justify-center">
              <DateRangePickerComp range={range} setRange={setRange} />
            </div>
            <div className="modal-action">
              <label
                htmlFor="my-modal"
                className="btn-primary mr-3 btn btn-active btn-sm bg-blue1 gap-2 rounded-full hover:bg-white hover:text-blue1 capitalize"
              >
                İptal
              </label>
              <label
                htmlFor="my-modal"
                className="btn-primary mr-3 btn btn-active btn-sm bg-blue1 gap-2 rounded-full hover:bg-white hover:text-blue1 capitalize"
              >
                Onayla
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* patients appointments table 👇🏻 */}
      <div className="overflow-x-auto ">
        <table className="table w-full z-0">
          {/* <!-- head --> */}
          <thead className="text-gray">
            <tr>
              <th className="pl-20 text-[16px]">DOKTOR</th>
              <th className="text-[16px]">HASTA</th>
              <th className="text-[16px]">CİNSİYET</th>
              <th className="text-[16px]">TARİH</th>
              <th className="text-[16px]">SAAT</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {doktorAppointment?.length > 0 ? (
              doktorAppointment?.map((randevu) => (
                <tr className="border-b border-[#e9e9e9] leading-10">
                  <td>
                    <div className="flex items-center space-x-3 gap-3">
                      <div className="avatar">
                        <div className="rounded-full w-10">
                          <img
                            src={randevu.doktor.d_resim}
                            alt="Doktor Avatar"
                            className="object-top"
                          />
                        </div>
                      </div>
                      <div>
                        {randevu.doktor.d_adi} {randevu.doktor.d_soyadi}
                      </div>
                    </div>
                  </td>
                  <td>
                    {" "}
                    {randevu.hasta.hasta_adi} {randevu.hasta.hasta_soyadi}
                  </td>
                  <td>{randevu.hasta.cinsiyet}</td>
                  <td>{randevu.randevu_tarih}</td>
                  <td>{randevu.randevu_baslangic_saati}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  className="capitalize p-2 shadow-lg mb-4 text-center"
                  colSpan={5}
                >
                  Bu tarih aralığında kayıt bulunamadı!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DateRange;
