import React from "react";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import DoctorsCard from "./DoctorsCard";
import { useSelector } from "react-redux";
import DoctorsSlider from "./DoctorsSlider";

const DoctorsTitle = ({ data }) => {
  const policlinicProfile = useSelector((state) => state.polData.value);


  return (
    <div className="bg-white mt-7 p-3">
      <div className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
      </div>
      <DoctorsSlider doktorlar={policlinicProfile.doktorlar} data={data} />
    </div>
  );
};

export default DoctorsTitle;
