import React, { useState } from "react";
import FirstStepAddAppointment from "./FirstStepAddAppointment";
import SecondStepAddAppointment from "./SecondStepAddAppointment";
import ThirdStepAddAppointment from "./ThirdStepAddAppointment";
import { ilData } from "../../../helpers/ilData";
import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "../../../features/modalSlice";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../../config";

const AddAppointmentPopUp = () => {
  const initialSaatler = {
    "09:00": "",
    "09:15": "",
    "09:30": "",
    "09:45": "",
    "10:00": "",
    "10:15": "",
    "10:30": "",
    "10:45": "",
    "11:00": "",
    "11:15": "",
    "11:30": "",
    "11:45": "",
    "12:00": "",
    "12:15": "",
    "13:30": "",
    "13:45": "",
    "14:00": "",
    "14:15": "",
    "14:30": "",
    "14:45": "",
    "15:00": "",
    "15:15": "",
    "15:30": "",
    "15:45": "",
    "16:00": "",
    "16:15": "",
    "18:00": "",
    "18:15": "",
    "18:30": "",
    "18:45": "",
    "19:00": "",
    "19:15": "",
    "19:30": "",
    "19:45": "",
  };

  const uRegister = useSelector((state) => state.uRegister);
  const date1 = new Date();
  date1.setHours(date1.getHours() + 3); // UTC+3 saat dilimine ayarlanır
  const thisDay = date1.toISOString();
  const [polyclinicDoctors, setPolyclinicDoctors] = useState([]);
  const [queryTc, setQueryTc] = useState("");
  const [saatler, setSaatler] = useState(initialSaatler);
  const [selectedSaatler, setSelectedSaatler] = useState([]);

  const [stepper, setStepper] = useState(1);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [date, setDate] = useState(thisDay.slice(0, 10));
  const [clock, setClock] = useState("");
  const [clockStep, setClockStep] = useState(); /// randevu kayıtta randevuda kaçtane 15 dk nın kapatıldığını gönderiyoruz

  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    dadyName: "",
    birthDay: "",
    phone: "",
    email: "",
    city: "",
    town: "",
  });

  const initializeState = () => {
    setQueryTc("");
    setSaatler(initialSaatler);
    setSelectedDoctor("");
    setDate(thisDay.slice(0, 10));
    setClock("");
    setClockStep();
    setPersonalInfo({
      firstName: "",
      lastName: "",
      dadyName: "",
      birthDay: "",
      phone: "",
      email: "",
      city: "",
      town: "",
    });
  };

  const dispatch = useDispatch();

  //izinli doktorların seçili  tarihe  uyanlarının diploma no  listesi ni verecek fonksiyon

  function getDiplomaListForDate(date, izinListesi, doctors) {
    const tarih = new Date(date);
    const diplomaListesi = [];
    if (izinListesi.length > 0) {
      //izin listesinde doktor varsa ele yoksa doctors listesinin aynısını PolyclinicDoctors a güncelle
      for (const izin of izinListesi) {
        const izinBaslangicTarihi = new Date(izin.izin_baslangic_tarihi);
        const izinBitisTarihi = new Date(izin.izin_bitis_tarihi);

        if (tarih >= izinBaslangicTarihi && tarih <= izinBitisTarihi) {
          diplomaListesi.push(izin.d_diploma_no);
        }
      }

      // izinlileri çıkaralım
      setPolyclinicDoctors(
        doctors.filter(
          (doctor) => !diplomaListesi.includes(Number(doctor.d_diploma_no))
        )
      );
    } else {
      setPolyclinicDoctors([...doctors]);
    }
  }

  const getNoDoctors = async (dDate) => {
    //önce  POLİKLİNİK TÜM  DOKTORLARINI GETİR

    const resp = await axios.get(
      `${config.API_BASE_URL}poliklinikteki_doktorlar?pol_vergi_no=${uRegister.id}`
    ); // pol vergino id olarak kaydedildi

    setDate(dDate);

    // şimdi İZİNLİ DOKTORLARI ALALIM
    const { data } = await axios.post(`${config.API_BASE_URL}izinleriGetir`, {
      pol_vergi_no: uRegister.id,
      alinan_tarih: dDate,
    });

    // getDiplomaListForDate(tarih, izinlidoktor listesi ,tüm doktor listesi)
    getDiplomaListForDate(dDate, data, resp.data); // ŞİMDİDE İZİNLİ DOKTORLARI DOKTORLAR LİSTESİNDE SİLELİM
  };

  useEffect(() => {
    getNoDoctors(thisDay.slice(0, 10));
  }, []);

  const handleSubmitFirst = (e) => {
    e.preventDefault();
    setStepper(2);
  };
  const handleSubitSecond = (e) => {
    e.preventDefault();

    setStepper(3);
  };
  const handleSubmitBackward = (e) => {
    e.preventDefault();
    setStepper(stepper > 1 ? stepper - 1 : 1);
  };
  const handleRecordPatient = async (e) => {
    e.preventDefault();
    try {
      if (uRegister?.id && selectedDoctor && queryTc && date && clock) {
        const { data } = await axios.post(
          `${config.API_BASE_URL}randevu_kaydet`,
          {
            pol_vergi_no: uRegister.id,
            d_diploma_no: selectedDoctor,
            hasta_tc: queryTc,
            randevu_tarih: date.length > 0 ? date.slice(0, 10) : date,
            randevu_baslangic_saati: clock,
            sikayet_notu: "belirtilrmedi",
            // randevu_suresi: 1,
            randevu_suresi: selectedSaatler.length,
            randevu_turu: 0,
          }
        );

        if (data.id) {
          toast.success("Randevunuz Oluşturuldu.");
          dispatch(modalToggle());
        } else {
          toast.error("Randevunuz Oluşturulamadı.");
        }
      } else {
        toast.error("Lütfen tüm gerekli alanları doldurun!");
      }
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Randevu oluşturma hatası:", error);
      toast.error("Randevu oluşturulamadı.");
    }
  };
  useEffect(() => {
    return () => {
      initializeState();
    };
  }, []);

  return (
    <>
      <div className="flex justify-start items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative mx-auto my-auto max-w-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none xs:w-[80vw] md:w-[65rem] ml-[4rem]">
            {/*header*/}
            <div className="flex items-center justify-between bg-blue1 rounded-t py-2">
              <h3 className="ml-4 text-white font-semibold">
                Poliklinik Yeni Randevu Oluştur
              </h3>
              <button
                className="text-xl mr-5 text-white"
                onClick={() => dispatch(modalToggle())}
              >
                <span className="text-3xl text-white">×</span>
              </button>
            </div>
            <div className="block">
              <div className="relative p-3 flex-auto xs:1/6">
                <div className="px-4 sm:px-0">
                  {/* <!-- component --> */}
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-row md:items-center w-3/4 mt-6 md:gap-10">
                      <div className="flex items-center relative">
                        <div
                          onClick={() => setStepper(1)}
                          className={`cursor-pointer rounded-full transition duration-500 ease-in-out h-12 w-12 border-2 border-blue1 flex items-center justify-center text-xl ${
                            stepper === 1 && "bg-blue1 text-white"
                          }`}
                        >
                          1
                        </div>
                        <div className="xs:hidden md:block absolute top-0 -ml-6 text-center mt-14 whitespace-nowrap  text-blue1">
                          Hasta Bilgileri
                        </div>
                      </div>
                      <div className="flex-auto md:border-t-2 border-blue1 transition duration-500 ease-in-out mx-1"></div>
                      <div className="flex items-center relative">
                        <div
                          onClick={() => setStepper(2)}
                          className={`cursor-pointer rounded-full transition duration-500 ease-in-out h-12 w-12 border-2 border-blue1 flex items-center justify-center text-xl  ${
                            stepper === 2 && "bg-blue1 text-white"
                          }`}
                        >
                          2
                        </div>
                        <div className="xs:hidden md:block absolute top-0 -ml-8 text-center mt-14 whitespace-nowrap  text-blue1">
                          Randevu Bilgileri
                        </div>
                      </div>
                      <div className="flex-auto md:border-t-2 border-blue1 transition duration-500 ease-in-out mx-1"></div>
                      <div className="flex items-center relative">
                        <div
                          onClick={() => setStepper(3)}
                          className={`cursor-pointer rounded-full transition duration-500 ease-in-out h-12 w-12 border-2 border-blue1 flex items-center justify-center text-xl ${
                            stepper === 3 && "bg-blue1 text-white"
                          }`}
                        >
                          3
                        </div>
                        <div className="xs:hidden md:block absolute top-0 -ml-8 text-center mt-14 whitespace-nowrap text-blue1">
                          Önizleme ve Onay
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {stepper === 1 ? (
                <FirstStepAddAppointment
                  handleSubmit={handleSubmitFirst}
                  setPersonalInfo={setPersonalInfo}
                  personalInfo={personalInfo}
                  queryTc={queryTc}
                  setQueryTc={setQueryTc}
                />
              ) : stepper === 2 ? (
                <SecondStepAddAppointment
                  handleSubmit={handleSubitSecond}
                  handleSubmitBackward={handleSubmitBackward}
                  setSelectedDoctor={setSelectedDoctor}
                  selectedDoctor={selectedDoctor}
                  getNoDoctors={getNoDoctors}
                  polyclinicDoctors={polyclinicDoctors}
                  date={date}
                  setDate={setDate}
                  clock={clock}
                  setClock={setClock}
                  setClockStep={setClockStep}
                  saatler={saatler}
                  setSaatler={setSaatler}
                  setSelectedSaatler={setSelectedSaatler}
                  selectedSaatler={selectedSaatler}
                />
              ) : (
                stepper === 3 && (
                  <ThirdStepAddAppointment
                    handleSubmit={null}
                    handleSubmitBackward={handleSubmitBackward}
                    personalInfo={personalInfo}
                    setSelectedDoctor={setSelectedDoctor}
                    selectedDoctor={selectedDoctor}
                    polyclinicDoctors={polyclinicDoctors}
                    date={date}
                    clock={clock}
                    handleRecordPatient={handleRecordPatient}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        // onClick={() => setModalToggle(false)}
        className="opacity-25 fixed inset-0 z-40 bg-black"
      ></div>
    </>
  );
};

export default AddAppointmentPopUp;
