import React, { useEffect, useState } from "react";
import { SlNote } from "react-icons/sl";
import AddProcessModal from "./AddProcessModal";

import { useSelector } from "react-redux";
import { CustomTooltip } from "../../tooltip";

const PatientProfile = ({
  handleSearchPatients,
  patient,
  tedaviBaslik,
  searchPatient,
}) => {
  // tedavi başlıkları alınıyor ,
  // patient içinde hasta bilgileri ve müdahale bilgileri geliyor
  // diş seçildiğinde hmüdahale bilgilerinde varsa bu seçilen diş filtreleniyor
  // bu dişin diş no su kullanılarak  , filtrelenen datanın içinden tedavi_baslik  ile tedavi_baslik id alınıyor ve tedavi yöntemi buna göre çağırılıyor,
  // bu bilgiler ile aşağıdaki tabloda detayında kullanılıyor

  const uRegister = useSelector((state) => state.uRegister);

  const [showModal, setShowModal] = useState(false);
  const [disDetay, setDisDetay] = useState(0);
  const [openTab, setOpenTab] = useState(1);
  const [filteredPatient, setfilteredPatient] = useState([]);
  const [editInfo, setEditInfo] = useState(false);

  const teethColor = (e) => {
    // cls 5 geçmişte işlem görmüş , 4 çekilmiş , 3 devam , 1 bilinmiyor
    const islemler = patient.mudehale_bilgileri
      ? patient.mudehale_bilgileri
      : [];
    const devamEden = islemler?.filter(
      (item) => item.dis_no === e && item.sonlandir === 0
    );
    const bitmis = islemler?.filter(
      (item) => item.dis_no === e && item.sonlandir === 1
    );
    const cekilmis = islemler?.filter(
      (item) => item.dis_no === e && item.tedavi_yontem === 5
    ); // çekilmiş dişin id si şimdilik yok
    if (cekilmis.length > 0) {
      return "cls-4";
    } else if (devamEden.length > 0) {
      return "cls-3";
    } else if (bitmis.length > 0) {
      return "cls-5";
    } else return "cls-1";
  };

  const handleClick = (e) => {
    setDisDetay(e.target.parentNode.parentNode.id);
    // console.log(disDetay);
  };

  useEffect(() => {
    patient?.mudehale_bilgileri &&
      setfilteredPatient(patient?.mudehale_bilgileri); // ilk açılış için listeyi genel olarak gösterelim
  }, [patient]);

  return (
    <div>
      <div className="bg-white">
        <div className="py-10 px-20 font-bold text-xl">HASTA BİLGİLERİ</div>
        <table className="z-0 table w-[98%] mx-auto text-center ">
          {/* <!-- head --> */}
          <thead>
            <tr>
              <th>TC KİMLİK NO</th>
              <th>İSİM</th>
              <th>BABA ADI</th>
              <th>DOĞUM TARİHİ</th>
              <th>E-POSTA</th>
              <th>TELEFON</th>
              <th>ADRES</th>
            </tr>
          </thead>
          <tbody className="text-center text-mainPageP font-thin">
            {/* <!-- row 1 --> */}
            <tr>
              <td className="font-bold">
                {patient?.hasta_bilgileri[0]?.hasta_tc}
              </td>
              <td>
                {patient?.hasta_bilgileri[0]?.hasta_adi}{" "}
                {patient?.hasta_bilgileri[0]?.hasta_soyadi}
              </td>
              <td>{patient?.hasta_bilgileri[0]?.hasta_baba_adi}</td>
              <td>{patient?.hasta_bilgileri[0]?.hasta_dogum_tarihi}</td>
              <td>{patient?.hasta_bilgileri[0]?.hasta_mail}</td>
              <td>{patient?.hasta_bilgileri[0]?.hasta_tel}</td>
              <td>{patient?.hasta_bilgileri[0]?.hasta_adres}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="bg-[#F1F6FB] h-6"></div>
      <div className="bg-white my-5">
        <div className="px-20 font-bold text-xl text-center">
          Tedavi Eklemek İçin Diş Seçiniz
        </div>
        <div className="w-4/6 h-full mx-auto max-h-auto">
          <div className="grid grid-cols-2 gap-4 border-dashed border-b-2 border-b-gray pb-2 mt-10">
            <div className="flex justify-center gap-4 border-dashed border-r-2 border-r-gray pr-4 ">
              <div className="flex flex-col justify-between items-center gap-4 box-border ml-[-2rem]">
                <div className="">
                  <div>Molars</div>
                </div>
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col-reverse">
                    <div
                      className={
                        disDetay === 1
                          ? "text-center font-bold bg-blue1 text-white mt-4 border border-white rounded-full w-10 h-10 pt-2 ml-4"
                          : "text-center mt-4"
                      }
                    >
                      18
                    </div>
                    <svg
                      id="ust-1"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 39.96 86.02"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("ust-1")}
                          d="m37.71,49.54c-.65,1.29-.88,2.59-.68,4.15.1.86.33,1.79.68,2.85,1,3,3,15,1,21s-4,10-11,7c-12,4-15-3-15-3-3,0-9-1-10-4s-5-12.36,0-20.68c0,0-2-27.32,2-38.32S9.92,3.43,11.71,2.54c2-1,3,0,3,3v21.14c0,2,.3,3.99,1,5.86.04.12.09.24.14.35l1.77-27.01c.08-1.14.47-2.25,1.2-3.14.35-.42.73-.72,1.05-.58.84.38,1.84.38,1.84,4.38s1,9,2,13,2.83,11.58,2.83,11.58c.11-.5.17-1.03.17-1.58,0-4-1-20-3-25s2-6,5-1c0,0,11,17,11,26s-1,18-2,20Z"
                        />
                        <path
                          className="cls-2"
                          d="m37.71,56.54c1,3,3,15,1,21s-4,10-11,7-6-6-10-6-2,3-5,3-9-1-10-4-5-12.36,0-20.68c0,0-2-27.32,2-38.32S9.92,3.43,11.71,2.54c2-1,3,0,3,3v21.14c0,2,.3,3.99,1,5.86.04.12.09.24.14.35.76,1.88,1.98,3.65,3.86,3.65,3.45,0,6.16-2.23,6.83-5.42.11-.5.17-1.03.17-1.58,0-4-1-20-3-25s2-6,5-1c0,0,11,17,11,26s-1,18-2,20c-.65,1.29-.88,2.59-.68,4.15.1.86.33,1.79.68,2.85Z"
                        />
                        <path
                          className="cls-2"
                          d="m2.71,56.86s8,1.68,11-.32,6-6,12-4,11.32,1.15,11.32,1.15"
                        />
                        <path
                          className="cls-2"
                          d="m15.85,32.89l1.77-27.01c.08-1.14.47-2.25,1.2-3.14.35-.42.73-.72,1.05-.58.84.38,1.84.38,1.84,4.38s1,9,2,13,2.83,11.58,2.83,11.58"
                        />
                        <path className="cls-2" d="m12.71,81.54s3,7,15,3" />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col-reverse">
                    <div
                      className={
                        disDetay === 2
                          ? "text-center font-bold bg-blue1 text-white mt-4 border border-white rounded-full w-10 h-10 pt-2 ml-4"
                          : "text-center mt-4"
                      }
                    >
                      17
                    </div>
                    <svg
                      id="ust-2"
                      className="-ml-[3px] cursor-pointer p-[1px] opacity-80 hover:opacity-100"
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48.92 98.64"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("ust-2")}
                          d="m45.2,60.99s5,14,3,26-12,12-15,11-4-3-4-3c0,0-4,2-7,2-2.05,0-3.63-.93-5.69-1.52-.97-.28-2.04-.48-3.31-.48-4,0-4-8-4-8,0,0-5,2-8-4s2-12,3.12-15.02c1.13-3.02,2.88-10.98,2.88-10.98,0,0-2-20-1.55-29.03C6.11,18.92,17.2-.01,19.2.99s2,4.14,2,4.14v13.86s-.03.49-.07,1.33c0,0,6.07-2.33,6.07-9.33V1.94c0-1.29,1.41-2.12,2.52-1.45,1.58.95,3.35,2.58,3.49,5.14-.01-.24-.01-.45-.01-.64,0-3,1.45-3.55,4-1,5,5,7,7,9,14,1.98,6.93,2,21,1.35,25.35-.64,4.35-2.35,17.65-2.35,17.65Z"
                        />
                        <path
                          className="cls-2"
                          d="m48.2,86.99c-2,12-12,12-15,11s-4-3-4-3c0,0-4,2-7,2-2.05,0-3.63-.93-5.69-1.52-.97-.28-2.04-.48-3.31-.48-4,0-4-8-4-8,0,0-5,2-8-4s2-12,3.12-15.02c1.13-3.02,2.88-10.98,2.88-10.98,0,0-2-20-1.55-29.03C6.11,18.92,17.2-.01,19.2.99s2,4.14,2,4.14v13.86s-.03.49-.07,1.33c-.23,4.39-.93,18.31-.93,21.67,0,4,4,5,7.51,1.35s6.49-19.35,6.49-22.35c0-2.72-.82-11.2-.98-14.99,0-.13,0-.25-.01-.37-.01-.24-.01-.45-.01-.64,0-3,1.45-3.55,4-1,5,5,7,7,9,14,1.98,6.93,2,21,1.35,25.35-.64,4.35-2.35,17.65-2.35,17.65,0,0,5,14,3,26Z"
                        />
                        <path
                          className="cls-2"
                          d="m7.2,56.99s7-1,13,2,9,2,13,1,10,0,12,1"
                        />
                        <path
                          className="cls-2"
                          d="m33.22,6c0-.13,0-.25-.01-.37-.14-2.56-1.91-4.19-3.49-5.14-1.11-.67-2.52.16-2.52,1.45v9.05c0,7-6.07,9.33-6.07,9.33"
                        />
                        <path className="cls-2" d="m6.2,80.99s3,2,3,6" />
                        <path
                          className="cls-2"
                          d="m16.51,95.47s1.69-4.48,3.69-5.48,6-1,9,5"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">16</div>
                    <svg
                      id="ust-3"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 45.17 111.61"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("ust-3")}
                          d="m44.84,73.9c.38,17.98-.68,23.68-2.59,28.46-2,5-4,9-8,9-1.85,0-4.67-3.53-6.65-6.41,0,0-.35,2.41-2.35,2.41s-6-1-8-4c-2,4-5,2-10,2s-5-1-6-4-1-9-1-16,4-17.54,4-17.54c-1-4.55,0-25.46,0-30.46S10.25,1.36,14.25.36s4,5,4,5v10.91l4.33-9.31s3.67-6.6,5.67-2.6,0,14,1,18c.97,3.9,4.95,9.7,5.16,10,.72-5.49.82-20.64.84-24.88,0-.91.4-1.81,1.17-2.31,2.14-1.36,3.12,3.05,4.83,8.19.3.9.59,1.89.87,2.94,1.91,7.08,2.73,14.41,2.6,21.74-.14,8.17-.26,21.08.06,33.32.02.87.04,1.72.06,2.54Z"
                        />
                        <path
                          className="cls-2"
                          d="m42.25,102.36c-2,5-4,9-8,9-1.85,0-4.67-3.53-6.65-6.41-.43-.61-.82-1.2-1.15-1.71-.64-.97-1.08-1.69-1.2-1.88-1.21-1.81-3.14-2.89-4.49-3.47-.72-.3-1.55.09-1.73.85-.2.84-.59,2.06-1.32,3.65-.14.31-.29.63-.46.97-2,4-5,2-10,2s-5-1-6-4-1-9-1-16,4-17.54,4-17.54c-1-4.55,0-25.46,0-30.46S10.25,1.36,14.25.36s4,5,4,5v14c0,2,1,20,2,23s4,9,8,7,5-11,6-16c.06-.28.11-.61.16-.99h0c.72-5.5.82-20.65.84-24.89,0-.91.4-1.81,1.17-2.31,2.14-1.36,3.12,3.05,4.83,8.19.3.9.59,1.89.87,2.94,1.91,7.08,2.73,14.41,2.6,21.74-.14,8.17-.26,21.08.06,33.32.02.87.04,1.72.06,2.54.38,17.98-.68,23.68-2.59,28.46Z"
                        />
                        <path
                          className="cls-2"
                          d="m34.41,32.37h0c-.21-.31-4.19-6.11-5.16-10.01-1-4,1-14-1-18s-5.67,2.6-5.67,2.6l-4.33,9.31"
                        />
                        <path
                          className="cls-2"
                          d="m17.25,103.36c2,3,6,4,8,4s2.35-2.41,2.35-2.41"
                        />
                        <path
                          className="cls-2"
                          d="m4.25,67.82s2.42,2.38,11.71,1.46,8.42-2.08,14.85,0,8.77,4.19,14.1,4.63"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="">
                  <div>Premolars</div>
                </div>
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">15</div>
                    <svg
                      id="ust-4"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 36.07 117.34"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("ust-4")}
                          d="m35.35,95.74c1,5.26,1.04,13.22-6.48,17.74-7.52,4.52-13.51,4.52-17.52,1.52-4-3-13.27-4-10.64-16,1.36-6.19,3.62-14.51,5.62-22.07h0c1.88-7.11,3.54-13.54,4.02-16.93.91-6.38.99-42.67,1.76-52.83.12-1.65.51-3.27,1.22-4.77.77-1.61,1.9-3.09,3.02-1.4,2,3,3,14,5,19s12,25,12,36-.93,27.22-.93,27.22c0,0,1.93,7.26,2.93,12.52Z"
                        />
                        <path
                          className="cls-2"
                          d="m28.87,113.48c-7.52,4.52-13.51,4.52-17.52,1.52-4-3-13.27-4-10.64-16,1.36-6.19,3.62-14.51,5.62-22.07h0c1.88-7.11,3.54-13.54,4.02-16.93.91-6.38.99-42.67,1.76-52.83.12-1.65.51-3.27,1.22-4.77.77-1.61,1.9-3.09,3.02-1.4,2,3,3,14,5,19s12,25,12,36-.93,27.22-.93,27.22c0,0,1.93,7.26,2.93,12.52,1,5.26,1.04,13.22-6.48,17.74Z"
                        />
                        <path
                          className="cls-2"
                          d="m32.42,83.22c-2.94.22-5.07-4.22-8.21-6.3-3.13-2.08-8.86.08-13.3,1.58-4.27,1.45-4.56-1.36-4.58-1.57h0"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">14</div>
                    <svg
                      id="ust-5"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 36.94 119.29"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("ust-5")}
                          d="m34.41,77.31c-.02,1.66.08,3.58.23,5.49.28,3.79.72,7.53.72,9.09,0,2.36,4.21,16.36-3.39,22.36-7.61,6-15.96,6-21.29,2-5.32-4-11.32-9-10.32-13s4.34-20.88,5.67-25.94c1.33-5.06,4.33-38.06,5.33-45.06s2-25,2-25c0,0,0-7,2-7s5.82,5.43,6.41,11.71c.54,5.76,3.94,27.22,4.51,36.93-.24-6.99-.92-27.34-.92-30.64,0-4-1.15-9.17-1.15-9.17,0,0-.85-3.83,2.15-2.98,3.55,1,9,24.15,10,32.15,1.37,10.96-1.91,34.12-1.95,39.06Z"
                        />
                        <path
                          className="cls-2"
                          d="m35.36,91.89c0,2.36,4.21,16.36-3.39,22.36-7.61,6-15.96,6-21.29,2-5.32-4-11.32-9-10.32-13s4.34-20.88,5.67-25.94c1.33-5.06,4.33-38.06,5.33-45.06s2-25,2-25c0,0,0-7,2-7s5.82,5.43,6.41,11.71c.54,5.76,3.94,27.22,4.51,36.93-.24-6.99-.92-27.34-.92-30.64,0-4-1.15-9.17-1.15-9.17,0,0-.85-3.83,2.15-2.98,3.55,1,9,24.15,10,32.15,1.37,10.96-1.91,34.12-1.95,39.06-.02,1.66.08,3.58.23,5.49.28,3.79.72,7.53.72,9.09Z"
                        />
                        <path
                          className="cls-2"
                          d="m6.03,77.31s2.33.94,7.33-2.06,5.48-1.09,9.74,1.46c4.26,2.54,7.81,5.65,11.54,6.1"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="">
                  <div>Canine</div>
                </div>
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">13</div>
                    <svg
                      id="ust-6"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 36.36 124.69"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("ust-6")}
                          d="m32.54,92.64c3,5,5.38,20,1.69,25s-9.8,6.58-13.74,6.79c-3.95.21-5.68-4.79-9.32-6.79-3.63-2-12.63-3-10.63-13,.99-4.95,1.98-12.83,2.85-20.99.88-8.33,1.64-16.95,2.15-23.01,1-12,5.39-44.49,3.2-53.75,0,0-.81-3.95,0-5.6.8-1.65,2.95-1.75,4.87,2.8,1.93,4.55,12.93,28.55,13.93,50.55.61,13.34,1.21,22.64,2.27,29.01.68,4.13,1.55,7.02,2.73,8.99Z"
                        />
                        <path
                          className="cls-2"
                          d="m34.23,117.64c-3.69,5-9.8,6.58-13.74,6.79-3.95.21-5.68-4.79-9.32-6.79-3.63-2-12.63-3-10.63-13,.99-4.95,1.98-12.83,2.85-20.99.88-8.33,1.64-16.95,2.15-23.01,1-12,5.39-44.49,3.2-53.75,0,0-.81-3.95,0-5.6.8-1.65,2.95-1.75,4.87,2.8,1.93,4.55,12.93,28.55,13.93,50.55.61,13.34,1.21,22.64,2.27,29.01.68,4.13,1.55,7.02,2.73,8.99,3,5,5.38,20,1.69,25Z"
                        />
                        <path
                          className="cls-2"
                          d="m3.38,83.65s4.15-5.01,6.15-6.01,7-4,11-1,5.54,5.02,9.27,7.01"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="">
                  <div>Incisors</div>
                </div>
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">12</div>
                    <svg
                      id="ust-7"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 33.35 104.85"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("ust-7")}
                          d="m32.34,95.8s3.16,8.59-3.42,8.79c-6.58.21-21.83-1.65-21.83-1.65,0,0-7.75-1.14-6.75-7.14.75-4.48,2.2-17.46,3.46-27.45.43-3.39.83-6.44,1.18-8.69,1.36-8.86,3.36-23.86,3.36-37.86,0,0,0-6-1-17-.2-2.23,1-7,6-3s5.75,16.11,7,23c2,11,6.83,40.45,7.92,45.23,1.08,4.77,4.08,25.77,4.08,25.77Z"
                        />
                        <path
                          className="cls-2"
                          d="m28.92,104.59c-6.58.21-21.83-1.65-21.83-1.65,0,0-7.75-1.14-6.75-7.14.75-4.48,2.2-17.46,3.46-27.45.43-3.39.83-6.44,1.18-8.69,1.36-8.86,3.36-23.86,3.36-37.86,0,0,0-6-1-17-.2-2.23,1-7,6-3s5.75,16.11,7,23c2,11,6.83,40.45,7.92,45.23,1.08,4.77,4.08,25.77,4.08,25.77,0,0,3.16,8.59-3.42,8.79Z"
                        />
                        <path
                          className="cls-2"
                          d="m3.8,68.35l5.83-5.49s3.59-4.15,8.65-.6c5.06,3.54,9.98,7.77,9.98,7.77"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">11</div>
                    <svg
                      id="ust-8"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 38.57 121.7"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("ust-8")}
                          d="m38.32,110.45c0,4,0,11-6,11s-23-1-23-1c0,0-10,0-9-7,.84-5.88,1.68-21.64,3.11-31.27.28-1.84.57-3.45.89-4.73,2-8,3-39,4-46S14.32,2.45,14.32,2.45c0,0,1-3,3-2s2,5,2,5c0,0,1,12,4,20s8,26,9,40c.54,7.58,1.38,14.86,2.03,19.95.55,4.31.97,7.05.97,7.05,0,0,3,14,3,18Z"
                        />
                        <path
                          className="cls-2"
                          d="m38.32,110.45c0,4,0,11-6,11s-23-1-23-1c0,0-10,0-9-7,.84-5.88,1.68-21.64,3.11-31.27.28-1.84.57-3.45.89-4.73,2-8,3-39,4-46S14.32,2.45,14.32,2.45c0,0,1-3,3-2s2,5,2,5c0,0,1,12,4,20s8,26,9,40c.54,7.58,1.38,14.86,2.03,19.95.55,4.31.97,7.05.97,7.05,0,0,3,14,3,18Z"
                        />
                        <path
                          className="cls-2"
                          d="m3.43,82.19s3.44.52,7.67-3.11,8.72-8.58,12.47-4.6c3.75,3.98,6.81,8.88,10.78,10.93"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center gap-4">
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="">
                  <div>Incisors</div>
                </div>
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">21</div>
                    <svg
                      id="ust-9"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 38.57 121.7"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("ust-9")}
                          d="m38.25,113.45c1,7-9,7-9,7,0,0-17,1-23,1S.25,114.45.25,110.45s3-18,3-18c0,0,.42-2.74.97-7.05.65-5.09,1.49-12.37,2.03-19.95,1-14,6-32,9-40s4-20,4-20c0,0,0-4,2-5s3,2,3,2c0,0,5,22,6,29s2,38,4,46c.32,1.28.61,2.89.89,4.73,1.43,9.63,2.27,25.39,3.11,31.27Z"
                        />
                        <path
                          className="cls-2"
                          d="m29.25,120.45s-17,1-23,1S.25,114.45.25,110.45s3-18,3-18c0,0,.42-2.74.97-7.05.65-5.09,1.49-12.37,2.03-19.95,1-14,6-32,9-40s4-20,4-20c0,0,0-4,2-5s3,2,3,2c0,0,5,22,6,29s2,38,4,46c.32,1.28.61,2.89.89,4.73,1.43,9.63,2.27,25.39,3.11,31.27,1,7-9,7-9,7Z"
                        />
                        <path
                          className="cls-2"
                          d="m35.14,82.19s-3.44.52-7.67-3.11-8.72-8.58-12.47-4.6c-3.75,3.98-6.81,8.88-10.78,10.93"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">22</div>
                    <svg
                      id="ust-10"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100"
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 33.35 104.85"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("ust-10")}
                          d="m33.01,95.8c1,6-6.75,7.14-6.75,7.14,0,0-15.25,1.86-21.83,1.65-6.58-.2-3.42-8.79-3.42-8.79,0,0,3-21,4.08-25.77,1.09-4.78,5.92-34.23,7.92-45.23,1.25-6.89,2-19,7-23s6.2.77,6,3c-1,11-1,17-1,17,0,14,2,29,3.36,37.86.35,2.25.75,5.3,1.18,8.69,1.26,9.99,2.71,22.97,3.46,27.45Z"
                        />
                        <path
                          className="cls-2"
                          d="m26.26,102.94s-15.25,1.86-21.83,1.65c-6.58-.2-3.42-8.79-3.42-8.79,0,0,3-21,4.08-25.77,1.09-4.78,5.92-34.23,7.92-45.23,1.25-6.89,2-19,7-23s6.2.77,6,3c-1,11-1,17-1,17,0,14,2,29,3.36,37.86.35,2.25.75,5.3,1.18,8.69,1.26,9.99,2.71,22.97,3.46,27.45,1,6-6.75,7.14-6.75,7.14Z"
                        />
                        <path
                          className="cls-2"
                          d="m29.55,68.35l-5.83-5.49s-3.59-4.15-8.65-.6-9.98,7.77-9.98,7.77"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="">
                  <div>Canine</div>
                </div>
                <div className="flex flex-col-reverse">
                  <div className="text-center mt-4">23</div>
                  <svg
                    id="ust-11"
                    className="cursor-pointer p-[1px] opacity-80 hover:opacity-100"
                    onClick={(e) => handleClick(e)}
                    data-name="Layer 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36.36 124.69"
                  >
                    <g id="Layer_3" data-name="Layer 3">
                      <path
                        className={teethColor("ust-11")}
                        d="m35.82,104.64c2,10-7,11-10.63,13-3.64,2-5.37,7-9.32,6.79-3.94-.21-10.05-1.79-13.74-6.79s-1.31-20,1.69-25c1.18-1.97,2.05-4.86,2.73-8.99,1.06-6.37,1.66-15.67,2.27-29.01,1-22,12-46,13.93-50.55C24.67-.46,26.82-.36,27.62,1.29c.81,1.65,0,5.6,0,5.6-2.19,9.26,2.2,41.75,3.2,53.75.51,6.06,1.27,14.68,2.15,23.01.87,8.16,1.86,16.04,2.85,20.99Z"
                      />
                      <path
                        className="cls-2"
                        d="m25.19,117.64c-3.64,2-5.37,7-9.32,6.79-3.94-.21-10.05-1.79-13.74-6.79s-1.31-20,1.69-25c1.18-1.97,2.05-4.86,2.73-8.99,1.06-6.37,1.66-15.67,2.27-29.01,1-22,12-46,13.93-50.55C24.67-.46,26.82-.36,27.62,1.29c.81,1.65,0,5.6,0,5.6-2.19,9.26,2.2,41.75,3.2,53.75.51,6.06,1.27,14.68,2.15,23.01.87,8.16,1.86,16.04,2.85,20.99,2,10-7,11-10.63,13Z"
                      />
                      <path
                        className="cls-2"
                        d="m32.97,83.65s-4.15-5.01-6.15-6.01-7-4-11-1-5.54,5.02-9.27,7.01"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="">
                  <div>Premolars</div>
                </div>
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">24</div>
                    <svg
                      id="ust-12"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100"
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 36.94 119.29"
                    >
                      <g>
                        <path
                          className={teethColor("ust-12")}
                          d="m36.58,103.25c1,4-5,9-10.32,13-5.33,4-13.68,4-21.29-2-7.6-6-3.39-20-3.39-22.36,0-1.56.44-5.3.72-9.09.15-1.91.25-3.83.23-5.49-.04-4.94-3.32-28.1-1.95-39.06C1.58,30.25,7.03,7.1,10.58,6.1c3-.85,2.15,2.98,2.15,2.98,0,0-1.15,5.17-1.15,9.17,0,3.3-.68,23.65-.92,30.64.57-9.71,3.97-31.17,4.51-36.93C15.76,5.68,19.58.25,21.58.25s2,7,2,7c0,0,1,18,2,25s4,40,5.33,45.06c1.33,5.06,4.67,21.94,5.67,25.94Z"
                        />
                        <path
                          className="cls-2"
                          d="m26.26,116.25c-5.33,4-13.68,4-21.29-2-7.6-6-3.39-20-3.39-22.36,0-1.56.44-5.3.72-9.09.15-1.91.25-3.83.23-5.49-.04-4.94-3.32-28.1-1.95-39.06C1.58,30.25,7.03,7.1,10.58,6.1c3-.85,2.15,2.98,2.15,2.98,0,0-1.15,5.17-1.15,9.17,0,3.3-.68,23.65-.92,30.64.57-9.71,3.97-31.17,4.51-36.93C15.76,5.68,19.58.25,21.58.25s2,7,2,7c0,0,1,18,2,25s4,40,5.33,45.06c1.33,5.06,4.67,21.94,5.67,25.94s-5,9-10.32,13Z"
                        />
                        <path
                          className="cls-2"
                          d="m30.92,77.31s-2.33.94-7.33-2.06c-5-3-5.48-1.09-9.74,1.46-4.26,2.54-7.81,5.65-11.54,6.1"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">25</div>
                    <svg
                      id="ust-13"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 36.07 117.34"
                    >
                      <g>
                        <path
                          className={teethColor("ust-13")}
                          d="m35.36,99c2.63,12-6.64,13-10.64,16-4.01,3-10,3-17.52-1.52C-.32,108.96-.28,101,.72,95.74c1-5.26,2.93-12.52,2.93-12.52,0,0-.93-16.22-.93-27.22S12.72,25,14.72,20,17.72,4,19.72,1c1.13-1.69,2.25-.21,3.02,1.4.71,1.5,1.1,3.12,1.22,4.77.77,10.16.85,46.45,1.76,52.83.48,3.39,2.14,9.82,4.02,16.92h0c2,7.57,4.26,15.89,5.62,22.08Z"
                        />
                        <path
                          className="cls-2"
                          d="m24.72,115c-4.01,3-10,3-17.52-1.52C-.32,108.96-.28,101,.72,95.74c1-5.26,2.93-12.52,2.93-12.52,0,0-.93-16.22-.93-27.22S12.72,25,14.72,20,17.72,4,19.72,1c1.13-1.69,2.25-.21,3.02,1.4.71,1.5,1.1,3.12,1.22,4.77.77,10.16.85,46.45,1.76,52.83.48,3.39,2.14,9.82,4.02,16.92h0c2,7.57,4.26,15.89,5.62,22.08,2.63,12-6.64,13-10.64,16Z"
                        />
                        <path
                          className="cls-2"
                          d="m3.65,83.22c2.94.22,5.07-4.22,8.21-6.3,3.13-2.08,8.86.08,13.3,1.58,4.27,1.45,4.56-1.36,4.58-1.57h0"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="">
                  <div>Molars</div>
                </div>
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">26</div>
                    <svg
                      id="ust-14"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 45.17 111.61"
                    >
                      <g>
                        <path
                          className={teethColor("ust-14")}
                          d="m40.92,67.82s4,10.54,4,17.54,0,13-1,16-1,4-6,4-8,2-10-2c-2,3-6,4-8,4s-2.35-2.41-2.35-2.41c-1.98,2.88-4.8,6.41-6.65,6.41-4,0-6-4-8-9-1.91-4.78-2.97-10.48-2.59-28.46.02-.82.04-1.67.06-2.54.32-12.24.2-25.15.06-33.32-.13-7.33.69-14.66,2.6-21.74.28-1.05.57-2.04.87-2.94,1.71-5.14,2.69-9.55,4.83-8.19.77.5,1.17,1.4,1.17,2.31.02,4.24.12,19.39.84,24.88.21-.3,4.19-6.1,5.16-10,1-4-1-14,1-18s5.67,2.6,5.67,2.6l4.33,9.31V5.36s0-6,4-5,10,32,10,37,1,25.91,0,30.46Z"
                        />
                        <path
                          className="cls-2"
                          d="m17.57,104.95s.35,2.41,2.35,2.41,6-1,8-4"
                        />
                        <path
                          className="cls-2"
                          d="m44.92,85.36c0,7,0,13-1,16s-1,4-6,4-8,2-10-2c-.17-.34-.32-.66-.46-.97-.73-1.59-1.12-2.81-1.32-3.65-.18-.76-1.01-1.15-1.73-.85-1.35.58-3.28,1.66-4.49,3.47-.12.19-.56.91-1.2,1.88-.33.51-.72,1.1-1.15,1.71-1.98,2.88-4.8,6.41-6.65,6.41-4,0-6-4-8-9-1.91-4.78-2.97-10.48-2.59-28.46.02-.82.04-1.67.06-2.54.32-12.24.2-25.15.06-33.32-.13-7.33.69-14.66,2.6-21.74.28-1.05.57-2.04.87-2.94,1.71-5.14,2.69-9.55,4.83-8.19.77.5,1.17,1.4,1.17,2.31.02,4.24.12,19.39.84,24.88h0c.05.39.1.72.16,1,1,5,2,14,6,16s7-4,8-7,2-21,2-23V5.36s0-6,4-5,10,32,10,37,1,25.91,0,30.46c0,0,4,10.54,4,17.54Z"
                        />
                        <path
                          className="cls-2"
                          d="m10.76,32.37h0c.21-.31,4.19-6.11,5.16-10.01,1-4-1-14,1-18s5.67,2.6,5.67,2.6l4.33,9.31"
                        />
                        <path
                          className="cls-2"
                          d="m40.92,67.82s-2.42,2.38-11.71,1.46c-9.29-.92-8.42-2.08-14.85,0-6.44,2.08-8.77,4.19-14.1,4.63"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">27</div>
                    <svg
                      id="ust-15"
                      className="-ml-[3px]  cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48.92 98.64"
                    >
                      <g>
                        <path
                          className={teethColor("ust-15")}
                          d="m44.6,67.97c1.12,3.02,6.12,9.02,3.12,15.02s-8,4-8,4c0,0,0,8-4,8-1.27,0-2.34.2-3.31.48-2.06.59-3.64,1.52-5.69,1.52-3,0-7-2-7-2,0,0-1,2-4,3s-13,1-15-11,3-26,3-26c0,0-1.71-13.3-2.35-17.65-.65-4.35-.63-18.42,1.35-25.35,2-7,4-9,9-14,2.55-2.55,4-2,4,1,0,.19,0,.4-.01.64.14-2.56,1.91-4.19,3.49-5.14,1.11-.67,2.52.16,2.52,1.45v9.05c0,7,6.07,9.33,6.07,9.33-.04-.84-.07-1.33-.07-1.33V5.13s0-3.14,2-4.14,13.09,17.93,13.55,26.97c.45,9.03-1.55,29.03-1.55,29.03,0,0,1.75,7.96,2.88,10.98Z"
                        />
                        <path
                          className="cls-2"
                          d="m47.72,82.99c-3,6-8,4-8,4,0,0,0,8-4,8-1.27,0-2.34.2-3.31.48-2.06.59-3.64,1.52-5.69,1.52-3,0-7-2-7-2,0,0-1,2-4,3s-13,1-15-11,3-26,3-26c0,0-1.71-13.3-2.35-17.65-.65-4.35-.63-18.42,1.35-25.35,2-7,4-9,9-14,2.55-2.55,4-2,4,1,0,.19,0,.4-.01.64-.01.12-.01.24-.01.37-.16,3.79-.98,12.27-.98,14.99,0,3,2.98,18.7,6.49,22.35,3.51,3.65,7.51,2.65,7.51-1.35,0-3.36-.7-17.28-.93-21.67-.04-.84-.07-1.33-.07-1.33V5.13s0-3.14,2-4.14,13.09,17.93,13.55,26.97c.45,9.03-1.55,29.03-1.55,29.03,0,0,1.75,7.96,2.88,10.98,1.12,3.02,6.12,9.02,3.12,15.02Z"
                        />
                        <path
                          className="cls-2"
                          d="m41.72,56.99s-7-1-13,2c-6,3-9,2-13,1-4-1-10,0-12,1"
                        />
                        <path
                          className="cls-2"
                          d="m15.7,6c0-.13,0-.25,0-.37.14-2.56,1.91-4.19,3.49-5.14,1.11-.67,2.52.16,2.52,1.45v9.05c0,7,6.07,9.33,6.07,9.33"
                        />
                        <path className="cls-2" d="m42.72,80.99s-3,2-3,6" />
                        <path
                          className="cls-2"
                          d="m32.41,95.47s-1.69-4.48-3.69-5.48-6-1-9,5"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col-reverse">
                    <div className="text-center mt-4">28</div>
                    <svg
                      id="ust-16"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 39.96 86.02"
                    >
                      <g>
                        <path
                          className={teethColor("ust-16")}
                          d="m37.25,77.54c-1,3-7,4-10,4,0,0-3,7-15,3-7,3-9-1-11-7s0-18,1-21c.35-1.06.58-1.99.68-2.85.2-1.56-.03-2.86-.68-4.15-1-2-2-11-2-20S11.25,3.54,11.25,3.54c3-5,7-4,5,1s-3,21-3,25c0,.55.06,1.08.17,1.58,0,0,1.83-7.58,2.83-11.58s2-9,2-13,1-4,1.84-4.38c.32-.14.7.16,1.05.58.73.89,1.12,2,1.2,3.14l1.77,27.01c.05-.11.1-.23.14-.35.7-1.87,1-3.86,1-5.86V5.54c0-3,1-4,3-3,1.79.89,3,5,7,16s2,38.32,2,38.32c5,8.32,1,17.68,0,20.68Z"
                        />
                        <path
                          className="cls-2"
                          d="m37.25,77.54c-1,3-7,4-10,4s-1-3-5-3-3,3-10,6-9-1-11-7,0-18,1-21c.35-1.06.58-1.99.68-2.85.2-1.56-.03-2.86-.68-4.15-1-2-2-11-2-20S11.25,3.54,11.25,3.54c3-5,7-4,5,1s-3,21-3,25c0,.55.06,1.08.17,1.58.67,3.19,3.38,5.42,6.83,5.42,1.88,0,3.1-1.77,3.86-3.65.05-.11.1-.23.14-.35.7-1.87,1-3.86,1-5.86V5.54c0-3,1-4,3-3,1.79.89,3,5,7,16s2,38.32,2,38.32c5,8.32,1,17.68,0,20.68Z"
                        />
                        <path
                          className="cls-2"
                          d="m37.25,56.86s-8,1.68-11-.32c-3-2-6-6-12-4-6,2-11.32,1.15-11.32,1.15"
                        />
                        <path
                          className="cls-2"
                          d="m13.42,31.12s1.83-7.58,2.83-11.58,2-9,2-13,1-4,1.84-4.38c.32-.14.7.16,1.05.58.73.89,1.12,2,1.2,3.14l1.77,27.01"
                        />
                        <path
                          className="cls-2"
                          d="m12.25,84.54c12,4,15-3,15-3"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 pt-4">
            <div className="flex justify-center gap-4 border-dashed border-r-2 border-r-gray pr-4">
              <div className="flex flex-col justify-between items-center gap-4 box-border ml-[-2rem]">
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col">
                    <div className="text-center mb-4">48</div>
                    <svg
                      id="alt-16"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 40.78 81.02"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-16")}
                          d="m36.4,27.18c2,4-1.22,26.72-2,33-1,8-15,24-17.35,19.94-2.81-4.85,2.35-10.94,4.35-15.94s2-13,2-15-1-8-5-8-5,7-6,10.17c-.82,2.61-.29,16.33-.08,21.15.05,1.02-.99,1.76-1.93,1.36-1.37-.59-3.42-2.08-5.99-5.68C-.6,61.18-.37,51.01,1.4,42.18c.85-4.24,3.14-11.36,2.58-15.25-.09-.7-.28-1.3-.58-1.75C-.6,19.18.4,12.18,1.4,6.18S7.4,1.18,8.4,2.18s3.29,1.5,3.29,1.5c.23-.15.47-.32.71-.5C16.4.18,17.4.18,20.4,1.18c1.56.52,2.74,1.4,3.9,2.24.89-.76,6.1-4.24,11.46-2.84,5.36,1.4,3.64,6.6,4.64,14.6s-4,12-4,12Z"
                        />
                        <path
                          className="cls-2"
                          d="m27.69,5.34c-1.29-.4-2.33-1.14-3.39-1.92-1.16-.84-2.34-1.72-3.9-2.24C17.4.18,16.4.18,12.4,3.18c-.24.18-.48.35-.71.5-3.53,2.38-5.29,1.5-5.29,1.5"
                        />
                        <path
                          className="cls-2"
                          d="m24.3,3.42c.89-.76,6.1-4.24,11.46-2.84s3.64,6.6,4.64,14.6-4,12-4,12c2,4-1.22,26.72-2,33-1,8-15,24-17.35,19.94-2.81-4.85,2.35-10.94,4.35-15.94s2-13,2-15-1-8-5-8-5,7-6,10.17c-.82,2.61-.29,16.33-.08,21.15.05,1.02-.99,1.76-1.93,1.36-1.37-.59-3.42-2.08-5.99-5.68C-.6,61.18-.37,51.01,1.4,42.18c.85-4.24,3.14-11.36,2.58-15.25-.09-.7-.28-1.3-.58-1.75C-.6,19.18.4,12.18,1.4,6.18S7.4,1.18,8.4,2.18s3.29,1.5,3.29,1.5"
                        />
                        <path
                          className="cls-2"
                          d="m3.91,26.9s5.49,2.28,10.49,1.28,11-6,22-1"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-center mb-4">47</div>
                    <svg
                      id="alt-15"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 44.78 95.58"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-15")}
                          d="m42.45,6.9c4,8,1,21,1,27s-1,34-1,34c0,0-3.38,22.83-14.03,27.28-1.38.58-2.88-.55-2.79-2.04.29-4.53.82-13.7.82-20.24,0-9,2-16-2-19s-6,4-6,8-1,12-1,16,3,10-1,11-8-8-9-13S1.45,49.9,1.45,44.9s1-14,1-14c0,0-3-16-2-21S8.45-.1,16.45,2.9s8.81-.85,12.4-1.93c3.6-1.07,9.6-2.07,13.6,5.93Z"
                        />
                        <path
                          className="cls-2"
                          d="m43.45,33.9c0,6-1,34-1,34,0,0-3.38,22.83-14.03,27.28-1.38.58-2.88-.55-2.79-2.04.29-4.53.82-13.7.82-20.24,0-9,2-16-2-19s-6,4-6,8-1,12-1,16,3,10-1,11-8-8-9-13S1.45,49.9,1.45,44.9s1-14,1-14c0,0-3-16-2-21S8.45-.1,16.45,2.9s8.81-.85,12.4-1.93c3.6-1.07,9.6-2.07,13.6,5.93s1,21,1,27Z"
                        />
                        <path
                          className="cls-2"
                          d="m9.55,10.52s4.75-2.24,9.42-.47,3.52-1.18,8.41-3.43c2.87-1.32,3.94-1.2,6.99-.35"
                        />
                        <path
                          className="cls-2"
                          d="m2.45,30.9s7-1,13,2,11,3,15,1,7-3,13,0"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-center mb-4">46</div>
                    <svg
                      id="alt-14"
                      className="-ml-[5px] cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 55.13 100.71"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-14")}
                          d="m53.34,5.06c1.61,3.22,2,14,1,18s-6,13-6,13v26c0,4.72-2.68,32.66-15.63,38.26-1.1.48-2.33-.39-2.29-1.59l.92-26.67s.26-9.49-5-13c-3-2-5,3-5.06,5.99-.03,1.61-.33,3.19-.82,4.72-.96,3.06-2.47,8.03-3.12,11.29-.8,3.98-.96,9.86-.99,12.09-.01.86-.33,1.71-.97,2.29-.96.86-2.64,1.22-5.04-2.38-4-6-7-13-6-21s6-30,2-37c-.34-.6-.68-1.18-1-1.76-2.13-3.74-3.87-7.03-4.69-10.9-1.12-5.33.15-10.97,3.57-15.2,1.81-2.23,4.2-4.14,7.12-4.14,0,0,7.22-4.95,16.11,1.02.32-.01.62-.02.89-.02.4,0,.82,0,1.24.01,0,0,3.8-5.94,13.76-3.01,4-1,9,2,10,4Z"
                        />
                        <path
                          className="cls-2"
                          d="m54.34,23.06c-1,4-6,13-6,13v26c0,4.72-2.68,32.66-15.63,38.26-1.1.48-2.33-.39-2.29-1.59l.92-26.67s.26-9.49-5-13c-3-2-5,3-5.06,5.99-.03,1.61-.33,3.19-.82,4.72-.96,3.06-2.47,8.03-3.12,11.29-.8,3.98-.96,9.86-.99,12.09-.01.86-.33,1.71-.97,2.29-.96.86-2.64,1.22-5.04-2.38-4-6-7-13-6-21s6-30,2-37c-.34-.6-.68-1.18-1-1.76-2.13-3.74-3.87-7.03-4.69-10.9-1.12-5.33.15-10.97,3.57-15.2,1.81-2.23,4.2-4.14,7.12-4.14,3.24,0,5.2.43,6.36.89,1.56.62,3.23.85,4.89.63,1.56-.21,3.4-.43,4.86-.5.32-.01.62-.02.89-.02.4,0,.82,0,1.24.01,1.16.02,2.37.07,3.4.11,1.55.07,3.11-.24,4.51-.92,1.5-.72,3.65-1.65,5.85-2.2,4-1,9,2,10,4,1.61,3.22,2,14,1,18Z"
                        />
                        <path
                          className="cls-2"
                          d="m11.34,3.06s7.22-4.95,16.11,1.02"
                        />
                        <path
                          className="cls-2"
                          d="m29.58,4.07s3.8-5.94,13.76-3.01"
                        />
                        <path
                          className="cls-2"
                          d="m5.33,33.31s11.11-1.76,14.06,0c2.94,1.76,3.94,2.76,7.94,1.76,4.95-1.24,5-2,11,0,5.11,1.7,10,1,10,1"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="">
                  <div>Molars</div>
                </div>
              </div>

              <div className="flex flex-col justify-between items-center gap-4">
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col">
                    <div className="text-center mb-4">45</div>
                    <svg
                      id="alt-13"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 37.25 107.1"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-13")}
                          d="m37,11.72c0,5.69-4.67,19.92-6.35,25.43-.09.3-.18.57-.25.82-1.4,4.75-2.4,9.75-2.4,11.75s1,19,0,26-7,23-10,28c-.73,1.21-4,5-7,2s2-12-1-24c-2.85-11.42-6.61-29.17-5.25-42.92.09-.94-.07-1.88-.47-2.72h-.01c-.11-.25-.25-.49-.41-.72-1.76-2.5-4.76-8.73-3.15-20.73.16-1.2.79-2.29,1.76-3.01L13,3.72S18-1.28,22,.72s5.3,5.05,9.15,5.02c3.85-.02,5.85-.02,5.85,5.98Z"
                        />
                        <path
                          className="cls-2"
                          d="m37,11.72c0,5.69-4.67,19.92-6.35,25.43-.09.3-.18.57-.25.82-1.4,4.75-2.4,9.75-2.4,11.75s1,19,0,26-7,23-10,28c-.73,1.21-4,5-7,2s2-12-1-24c-2.85-11.42-6.61-29.17-5.25-42.92.09-.94-.07-1.88-.47-2.72h-.01c-.11-.25-.25-.49-.41-.72-1.76-2.5-4.76-8.73-3.15-20.73.16-1.2.79-2.29,1.76-3.01L13,3.72S18-1.28,22,.72s5.3,5.05,9.15,5.02c3.85-.02,5.85-.02,5.85,5.98Z"
                        />
                        <path
                          className="cls-2"
                          d="m30.65,37.15c-2.35-.57-5.65-.43-7.9.82-2.24,1.25-6.75,3.75-10.47,0-3.6-3.63-7.74-2-8-1.89h0"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-center mb-4">44</div>
                    <svg
                      id="alt-12"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 34.5 109.06"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-12")}
                          d="m34.25,14.9c0,5-.82,15.13-2.41,20.06-1.59,4.94-1.59,36.94-1.59,36.94,0,0,0,11-4,23-2.09,6.27-6.46,10.21-8.09,12.05-.8.9-1.85,1.57-3.04,1.79-1.36.25-2.87-.09-2.87-2.84,0-5,1-21-1-29s-2-17-2-21-3-15-3-15l-2.73-6.82c-.17-.42-.33-.85-.47-1.28-.6-1.73-1.03-3.51-1.28-5.34-.59-4.28-1.52-11.2-1.52-12.56,0-2,0-6,4-8S14.25.9,14.25.9c0,0,2-2,7,1s9,6,9,6c0,0,4,2,4,7Z"
                        />
                        <path
                          className="cls-2"
                          d="m34.25,14.9c0,5-.82,15.13-2.41,20.06-1.59,4.94-1.59,36.94-1.59,36.94,0,0,0,11-4,23-2.09,6.27-6.46,10.21-8.09,12.05-.8.9-1.85,1.57-3.04,1.79-1.36.25-2.87-.09-2.87-2.84,0-5,1-21-1-29s-2-17-2-21-3-15-3-15l-2.73-6.82c-.17-.42-.33-.85-.47-1.28-.6-1.73-1.03-3.51-1.28-5.34-.59-4.28-1.52-11.2-1.52-12.56,0-2,0-6,4-8S14.25.9,14.25.9c0,0,2-2,7,1s9,6,9,6c0,0,4,2,4,7Z"
                        />
                        <path
                          className="cls-2"
                          d="m3.05,32.8s4.2.1,7.2,5.1,6,5,10,2,5.18-5.87,11.59-4.94"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="">
                  <div>Premolars</div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col">
                    <div className="text-center mb-4">43</div>
                    <svg
                      id="alt-11"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32.69 116.57"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-11")}
                          d="m32.33,23.82c-.25,4.56-.57,7.58-.92,9.79h0s-.01.02-.01.02c-1.02,6.5-2.32,6-3.07,17.19-1,15-2,30-2,39s-2.35,16.82-3.67,20.91c-.85,2.61-3.6,5.59-5.73,5.59-1.22,0-2.24-.96-2.6-3.5-1-7,1-21-3-34-3.07-9.98-5.17-20.98-6-31-.17-2.05-1.01-5.09-1.97-8.76t0-.01C1.97,33.67.37,26.91.33,19.82c0,0-1-8,4-11S12.33,3.82,14.33,1.82s5.82-2.31,9.41.35c3.59,2.65,9.59,3.65,8.59,21.65Z"
                        />
                        <path
                          className="cls-2"
                          d="m32.33,23.82c-.25,4.56-.57,7.58-.92,9.79h0s-.01.02-.01.02c-1.02,6.5-2.32,6-3.07,17.19-1,15-2,30-2,39s-2.35,16.82-3.67,20.91c-.85,2.61-3.6,5.59-5.73,5.59-1.22,0-2.24-.96-2.6-3.5-1-7,1-21-3-34-3.07-9.98-5.17-20.98-6-31-.17-2.05-1.01-5.09-1.97-8.76t0-.01C1.97,33.67.37,26.91.33,19.82c0,0-1-8,4-11S12.33,3.82,14.33,1.82s5.82-2.31,9.41.35c3.59,2.65,9.59,3.65,8.59,21.65Z"
                        />
                        <path
                          className="cls-2"
                          d="m3.36,39.05s.01,0,0,.01c2.34,2.67,8.07,8.76,11.97,8.76,5,0,14.12-10.38,16.07-14.19h0v-.02"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="">
                  <div>Canine</div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col">
                    <div className="text-center mb-4">42</div>
                    <svg
                      id="alt-10"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 29.05 110.49"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-10")}
                          d="m28.8,5.25c0,4.69,0,21.68-.82,28.73-.06.47-.12.89-.18,1.27-1,6-3,38-5,47s-5,21-8.28,25.12c-2.08,2.63-4.72,3.88-5.72,1.88s-1-26-1-26c0,0-3.42-38.48-4.72-50.6-.11-.99-.2-1.8-.28-2.4C1.8,22.25.8,15.25.8,15.25.8,15.25-2.2.25,5.8.25h18.78s4.22,0,4.22,5Z"
                        />
                        <path
                          className="cls-2"
                          d="m28.8,5.25c0,4.69,0,21.68-.82,28.73-.06.47-.12.89-.18,1.27-1,6-3,38-5,47s-5,21-8.28,25.12c-2.08,2.63-4.72,3.88-5.72,1.88s-1-26-1-26c0,0-3.42-38.48-4.72-50.6-.11-.99-.2-1.8-.28-2.4C1.8,22.25.8,15.25.8,15.25.8,15.25-2.2.25,5.8.25h18.78s4.22,0,4.22,5Z"
                        />
                        <path
                          className="cls-2"
                          d="m3.08,32.65c1.21,1.75,6.23,8.73,9.72,9.6,4.85,1.21,9.35.47,15.18-8.27"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-center mb-4">41</div>
                    <svg
                      id="alt-9"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 27.5 103.77"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-9")}
                          d="m27.25,6.69c0,4.64-.6,14.65-.87,18.96-.08,1.33-.26,2.64-.54,3.94-.05.24-.11.5-.17.78-.67,3.13-1.76,8.37-2.42,12.32-1,6-4,42-4,42,0,0-1,16-5.5,18.3-3.47,1.76-4.5-1.3-4.5-3.3s0-29-1-35c-.54-3.24-4.13-19.68-5.45-26.27-.39-1.91-.7-3.82-.93-5.75-.03-.24-.06-.5-.09-.77C1.19,26.99.25,18.47.25,13.69.25,13.69.25,1.69,6.25.69s16.81,0,16.81,0c0,0,4.19,0,4.19,6Z"
                        />
                        <path
                          className="cls-2"
                          d="m27.25,6.69c0,4.64-.6,14.65-.87,18.96-.08,1.33-.26,2.64-.54,3.94-.05.24-.11.5-.17.78-.67,3.13-1.76,8.37-2.42,12.32-1,6-4,42-4,42,0,0-1,16-5.5,18.3-3.47,1.76-4.5-1.3-4.5-3.3s0-29-1-35c-.54-3.24-4.13-19.68-5.45-26.27-.39-1.91-.7-3.82-.93-5.75-.03-.24-.06-.5-.09-.77C1.19,26.99.25,18.47.25,13.69.25,13.69.25,1.69,6.25.69s16.81,0,16.81,0c0,0,4.19,0,4.19,6Z"
                        />
                        <path
                          className="cls-2"
                          d="m1.77,31.89s4.48,1.8,7.48,5.8,6,4,8,2,5.84-8.65,8.42-9.32"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="">
                  <div>Incisors</div>
                </div>
              </div>
            </div>

            <div className="flex justify-center gap-4">
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col">
                    <div className="text-center mb-4">31</div>
                    <svg
                      id="alt-8"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 27.5 103.77"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-8")}
                          d="m27.25,13.69c0,4.78-.94,13.3-1.53,18.21-.03.27-.06.53-.09.77-.23,1.93-.54,3.84-.93,5.75-1.32,6.59-4.91,23.03-5.45,26.27-1,6-1,33-1,35s-1.03,5.06-4.5,3.3c-4.5-2.3-5.5-18.3-5.5-18.3,0,0-3-36-4-42-.66-3.95-1.75-9.19-2.42-12.32-.06-.28-.12-.54-.17-.78-.28-1.3-.46-2.61-.54-3.94C.85,21.34.25,11.33.25,6.69.25.69,4.44.69,4.44.69c0,0,10.81-1,16.81,0s6,13,6,13Z"
                        />
                        <path
                          className="cls-2"
                          d="m27.25,13.69c0,4.78-.94,13.3-1.53,18.21-.03.27-.06.53-.09.77-.23,1.93-.54,3.84-.93,5.75-1.32,6.59-4.91,23.03-5.45,26.27-1,6-1,33-1,35s-1.03,5.06-4.5,3.3c-4.5-2.3-5.5-18.3-5.5-18.3,0,0-3-36-4-42-.66-3.95-1.75-9.19-2.42-12.32-.06-.28-.12-.54-.17-.78-.28-1.3-.46-2.61-.54-3.94C.85,21.34.25,11.33.25,6.69.25.69,4.44.69,4.44.69c0,0,10.81-1,16.81,0s6,13,6,13Z"
                        />
                        <path
                          className="cls-2"
                          d="m25.73,31.89s-4.48,1.8-7.48,5.8c-3,4-6,4-8,2S4.41,31.05,1.83,30.37"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-center mb-4">32</div>
                    <svg
                      id="alt-7"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 29.05 110.49"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-7")}
                          d="m28.25,15.25s-1,7-2,15c-.08.6-.17,1.41-.28,2.4-1.3,12.12-4.72,50.6-4.72,50.6,0,0,0,24-1,26s-3.64.75-5.72-1.88c-3.28-4.12-6.28-16.12-8.28-25.12S2.25,41.25,1.25,35.25c-.06-.38-.12-.8-.18-1.27C.25,26.93.25,9.94.25,5.25.25.25,4.47.25,4.47.25h18.78c8,0,5,15,5,15Z"
                        />
                        <path
                          className="cls-2"
                          d="m28.25,15.25s-1,7-2,15c-.08.6-.17,1.41-.28,2.4-1.3,12.12-4.72,50.6-4.72,50.6,0,0,0,24-1,26s-3.64.75-5.72-1.88c-3.28-4.12-6.28-16.12-8.28-25.12S2.25,41.25,1.25,35.25c-.06-.38-.12-.8-.18-1.27C.25,26.93.25,9.94.25,5.25.25.25,4.47.25,4.47.25h18.78c8,0,5,15,5,15Z"
                        />
                        <path
                          className="cls-2"
                          d="m1.07,33.98c5.83,8.74,10.33,9.48,15.18,8.27,3.49-.87,8.51-7.85,9.72-9.6"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="">
                  <div>Incisors</div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="flex flex-col-reverse">
                  <div className="">
                    <div>Canine</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-center mb-4">33</div>
                    <svg
                      id="alt-6"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 h-[5rem]"
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32.69 116.57"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-6")}
                          d="m32.36,19.82c-.04,7.09-1.64,13.85-3.03,19.23h0c-.96,3.68-1.8,6.72-1.97,8.77-.83,10.02-2.93,21.02-6,31-4,13-2,27-3,34s-7,2-8.33-2.09c-1.32-4.09-3.67-11.91-3.67-20.91s-1-24-2-39c-.75-11.19-2.05-10.69-3.07-17.19h-.01v-.02c-.35-2.21-.67-5.23-.92-9.79C-.64,5.82,5.36,4.82,8.95,2.17c3.59-2.66,7.41-2.35,9.41-.35s5,4,10,7,4,11,4,11Z"
                        />
                        <path
                          className="cls-2"
                          d="m32.36,19.82c-.04,7.09-1.64,13.85-3.03,19.23h0c-.96,3.68-1.8,6.72-1.97,8.77-.83,10.02-2.93,21.02-6,31-4,13-2,27-3,34s-7,2-8.33-2.09c-1.32-4.09-3.67-11.91-3.67-20.91s-1-24-2-39c-.75-11.19-2.05-10.69-3.07-17.19h-.01v-.02c-.35-2.21-.67-5.23-.92-9.79C-.64,5.82,5.36,4.82,8.95,2.17c3.59-2.66,7.41-2.35,9.41-.35s5,4,10,7,4,11,4,11Z"
                        />
                        <path
                          className="cls-2"
                          d="m1.28,33.61h0s.01.02.01.02c1.95,3.81,11.07,14.19,16.07,14.19,3.9,0,9.63-6.09,11.97-8.76t0-.01"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col">
                    <div className="text-center mb-4">34</div>
                    <svg
                      id="alt-5"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 34.5 109.06"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-5")}
                          d="m34.25,14.9c0,1.36-.93,8.28-1.52,12.56-.25,1.83-.68,3.61-1.28,5.34-.14.43-.3.86-.47,1.28l-2.73,6.82s-3,11-3,15,0,13-2,21-1,24-1,29c0,2.75-1.51,3.09-2.87,2.84-1.19-.22-2.24-.89-3.04-1.79-1.63-1.84-6-5.78-8.09-12.05-4-12-4-23-4-23,0,0,0-32-1.59-36.94C1.07,30.03.25,19.9.25,14.9s4-7,4-7c0,0,4-3,9-6s7-1,7-1c0,0,6,4,10,6s4,6,4,8Z"
                        />
                        <path
                          className="cls-2"
                          d="m34.25,14.9c0,1.36-.93,8.28-1.52,12.56-.25,1.83-.68,3.61-1.28,5.34-.14.43-.3.86-.47,1.28l-2.73,6.82s-3,11-3,15,0,13-2,21-1,24-1,29c0,2.75-1.51,3.09-2.87,2.84-1.19-.22-2.24-.89-3.04-1.79-1.63-1.84-6-5.78-8.09-12.05-4-12-4-23-4-23,0,0,0-32-1.59-36.94C1.07,30.03.25,19.9.25,14.9s4-7,4-7c0,0,4-3,9-6s7-1,7-1c0,0,6,4,10,6s4,6,4,8Z"
                        />
                        <path
                          className="cls-2"
                          d="m31.45,32.8s-4.2.1-7.2,5.1c-3,5-6,5-10,2s-5.18-5.87-11.59-4.94"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-center mb-4">35</div>
                    <svg
                      id="alt-4"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 37.25 107.1"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-4")}
                          d="m36.54,14.63c1.61,12-1.39,18.23-3.15,20.73-.16.23-.3.47-.41.72-.41.84-.57,1.78-.48,2.72,1.36,13.75-2.4,31.5-5.25,42.92-3,12,2,21-1,24s-6.27-.79-7-2c-3-5-9-21-10-28s0-24,0-26-1-7-2.4-11.75c-.07-.25-.16-.52-.25-.82C4.92,31.64.25,17.41.25,11.72.25,5.72,2.25,5.72,6.1,5.74c3.85.03,5.15-3.02,9.15-5.02s9,3,9,3l10.53,7.9c.97.72,1.6,1.81,1.76,3.01Z"
                        />
                        <path
                          className="cls-2"
                          d="m33.39,35.36c-.16.23-.3.47-.41.72-.41.84-.57,1.78-.48,2.72,1.36,13.75-2.4,31.5-5.25,42.92-3,12,2,21-1,24s-6.27-.79-7-2c-3-5-9-21-10-28s0-24,0-26-1-7-2.4-11.75c-.07-.25-.16-.52-.25-.82C4.92,31.64.25,17.41.25,11.72.25,5.72,2.25,5.72,6.1,5.74c3.85.03,5.15-3.02,9.15-5.02s9,3,9,3l10.53,7.9c.97.72,1.6,1.81,1.76,3.01,1.61,12-1.39,18.23-3.15,20.73Z"
                        />
                        <path
                          className="cls-2"
                          d="m32.98,36.08s-4.29-1.86-8.01,1.89c-3.72,3.75-8.23,1.25-10.48,0s-5.55-1.39-7.9-.82"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="">
                  <div>Premolars</div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center gap-4">
                <div className="flex gap-4 pr-4">
                  <div className="flex flex-col">
                    <div className="text-center mb-4">36</div>
                    <svg
                      id="alt-3"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 44.78 95.58"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-3")}
                          d="m42.33,30.9s1,9,1,14-5,26-6,31-5,14-9,13-1-7-1-11-1-12-1-16-2-11-6-8-2,10-2,19c0,6.54.53,15.71.82,20.24.09,1.49-1.41,2.62-2.79,2.04-10.65-4.45-14.03-27.28-14.03-27.28,0,0-1-28-1-34S-1.67,14.9,2.33,6.9,12.33-.1,15.93.97c3.59,1.08,4.4,4.93,12.4,1.93s15,2,16,7-2,21-2,21Z"
                        />
                        <path
                          className="cls-2"
                          d="m42.33,30.9s1,9,1,14-5,26-6,31-5,14-9,13-1-7-1-11-1-12-1-16-2-11-6-8-2,10-2,19c0,6.54.53,15.71.82,20.24.09,1.49-1.41,2.62-2.79,2.04-10.65-4.45-14.03-27.28-14.03-27.28,0,0-1-28-1-34S-1.67,14.9,2.33,6.9,12.33-.1,15.93.97c3.59,1.08,4.4,4.93,12.4,1.93s15,2,16,7-2,21-2,21Z"
                        />
                        <path
                          className="cls-2"
                          d="m35.24,10.52s-4.75-2.24-9.42-.47c-4.67,1.77-3.52-1.18-8.41-3.43-2.87-1.32-3.94-1.2-6.99-.35"
                        />
                        <path
                          className="cls-2"
                          d="m42.33,30.9s-7-1-13,2c-6,3-11,3-15,1-4-2-7-3-13,0"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-center mb-4">37</div>
                    <svg
                      id="alt-2"
                      className="-ml-[2px] cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 55.13 100.7"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-2")}
                          d="m48.8,35.06c-4,7,1,29,2,37s-2,15-6,21c-2.4,3.6-4.08,3.24-5.04,2.38-.64-.58-.96-1.43-.97-2.29-.03-2.23-.19-8.11-.99-12.09-.65-3.26-2.16-8.23-3.12-11.29-.49-1.53-.79-3.11-.82-4.72-.06-2.99-2.06-7.99-5.06-5.99-5.26,3.51-5,13-5,13l.92,26.67c.04,1.2-1.19,2.07-2.29,1.59-12.95-5.6-15.63-33.54-15.63-38.26v-26S1.8,27.06.8,23.06.19,8.28,1.8,5.06C2.8,3.06,7.8.06,11.8,1.06c2.2.55,4.35,1.48,5.85,2.2,1.4.68,2.96.99,4.51.92,1.03-.04,2.24-.09,3.4-.11.42-.01.84-.01,1.24-.01.28,0,.58.01.9.02,1.46.07,3.29.29,4.85.5,1.66.22,3.33-.01,4.89-.63,1.16-.46,3.12-.89,6.36-.89,2.92,0,5.31,1.91,7.12,4.14,3.42,4.23,4.69,9.87,3.57,15.2-.82,3.87-2.56,7.16-4.69,10.9h0c-.32.59-.66,1.16-1,1.76Z"
                        />
                        <path
                          className={teethColor(18)}
                          d="m27.69,4.09s0,0,0,0c8.89-5.97,16.1-1.02,16.1-1.02"
                        />
                        <path
                          className={teethColor(18)}
                          d="m11.8,1.06c9.71-2.85,13.57,2.73,13.76,3.01h.01"
                        />
                        <path
                          className="cls-2"
                          d="m48.8,35.06c-4,7,1,29,2,37s-2,15-6,21c-2.4,3.6-4.08,3.24-5.04,2.38-.64-.58-.96-1.43-.97-2.29-.03-2.23-.19-8.11-.99-12.09-.65-3.26-2.16-8.23-3.12-11.29-.49-1.53-.79-3.11-.82-4.72-.06-2.99-2.06-7.99-5.06-5.99-5.26,3.51-5,13-5,13l.92,26.67c.04,1.2-1.19,2.07-2.29,1.59-12.95-5.6-15.63-33.54-15.63-38.26v-26S1.8,27.06.8,23.06.19,8.28,1.8,5.06C2.8,3.06,7.8.06,11.8,1.06c2.2.55,4.35,1.48,5.85,2.2,1.4.68,2.96.99,4.51.92,1.03-.04,2.24-.09,3.4-.11.42-.01.84-.01,1.24-.01.28,0,.58.01.9.02,1.46.07,3.29.29,4.85.5,1.66.22,3.33-.01,4.89-.63,1.16-.46,3.12-.89,6.36-.89,2.92,0,5.31,1.91,7.12,4.14,3.42,4.23,4.69,9.87,3.57,15.2-.82,3.87-2.56,7.16-4.69,10.9h0c-.32.59-.66,1.16-1,1.76Z"
                        />
                        <path
                          className="cls-2"
                          d="m27.69,4.09s0,0,0,0c8.89-5.97,16.1-1.02,16.1-1.02"
                        />
                        <path
                          className="cls-2"
                          d="m11.8,1.06c9.71-2.85,13.57,2.73,13.76,3.01h.01"
                        />
                        <path
                          className="cls-2"
                          d="m49.8,33.31s-11.11-1.76-14.06,0-3.94,2.76-7.94,1.76c-4.95-1.24-5-2-11,0-5.11,1.7-10,1-10,1"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-center mb-4">38</div>
                    <svg
                      id="alt-1"
                      className="cursor-pointer p-[1px] opacity-80 hover:opacity-100 "
                      onClick={(e) => handleClick(e)}
                      data-name="Layer 2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 40.78 81.02"
                    >
                      <g id="Layer_3" data-name="Layer 3">
                        <path
                          className={teethColor("alt-1")}
                          d="m4.38,27.18c-2,4,1.22,26.72,2,33,1,8,15,24,17.35,19.94,2.81-4.85-2.35-10.94-4.35-15.94s-2-13-2-15,1-8,5-8,5,7,6,10.17c.82,2.61.29,16.33.08,21.15-.05,1.02.99,1.76,1.93,1.36,1.37-.59,3.42-2.08,5.99-5.68,5-7,4.77-17.17,3-26-.85-4.24-3.14-11.36-2.58-15.25.09-.7.28-1.3.58-1.75,4-6,3-13,2-19s-6-5-7-4-3.29,1.5-3.29,1.5c-.23-.15-.47-.32-.71-.5C24.38.18,23.38.18,20.38,1.18c-1.56.52-2.74,1.4-3.9,2.24-.89-.76-6.1-4.24-11.46-2.84C-.34,1.98,1.38,7.18.38,15.18c-1,8,4,12,4,12Z"
                        />
                        <path
                          className="cls-2"
                          d="m4.38,27.18c-2,4,1.22,26.72,2,33,1,8,15,24,17.35,19.94,2.81-4.85-2.35-10.94-4.35-15.94s-2-13-2-15,1-8,5-8,5,7,6,10.17c.82,2.61.29,16.33.08,21.15-.05,1.02.99,1.76,1.93,1.36,1.37-.59,3.42-2.08,5.99-5.68,5-7,4.77-17.17,3-26-.85-4.24-3.14-11.36-2.58-15.25.09-.7.28-1.3.58-1.75,4-6,3-13,2-19s-6-5-7-4-3.29,1.5-3.29,1.5c-.23-.15-.47-.32-.71-.5C24.38.18,23.38.18,20.38,1.18c-1.56.52-2.74,1.4-3.9,2.24-.89-.76-6.1-4.24-11.46-2.84C-.34,1.98,1.38,7.18.38,15.18c-1,8,4,12,4,12Z"
                        />
                        <path
                          className="cls-2"
                          d="m34.03,5.18s-2,1-6-2C24.03.18,23.03.18,20.03,1.18s-4.59,3.32-7.29,4.16"
                        />
                        <path
                          className="cls-2"
                          d="m36.87,26.9s-5.49,2.28-10.49,1.28-11-6-22-1"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="">
                  <div>Molars</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between w-5/6 mx-auto px-20 mt-3">
          <div className="flex">
            <div className="h-6 w-6 border mr-2"></div>
            <span>Durumu bilinmiyor</span>
          </div>
          <div className="flex">
            <div className="h-6 w-6 border mr-2 bg-[#FCD355]"></div>
            <span>Tedavisi devam ediyor</span>
          </div>
          <div className="flex">
            <div className="h-6 w-6 border mr-2 bg-[#E2536E]"></div>
            <span>Eksik/Çekilmiş</span>
          </div>
          <div className="flex">
            <div className="h-6 w-6 border mr-2 bg-[#996DD4]"></div>
            <span>Geçmişte işlem görmüş</span>
          </div>
        </div>
        <div>
          {disDetay ? (
            <div className="mx-auto my-10">
              <div className="flex justify-between items-center mb-5">
                <h2>{disDetay} Nolu Diş İşlemleri</h2>
                <div>
                  <button
                    style={{ textTransform: "none" }}
                    className="h-12 w-36 text-14 tracking-[1px] font-bold bg-blue1 text-white items-center border-2 border-white rounded-full  hover:bg-white hover:text-blue1 hover:border-blue1"
                    type="button"
                    onClick={() => {
                      setfilteredPatient(patient?.mudehale_bilgileri);
                      setDisDetay(0);
                    }}
                  >
                    Genel Durum
                  </button>

                  <button
                    style={{ textTransform: "none" }}
                    className="h-12 w-36 text-14 tracking-[1px] font-bold bg-blue1 text-white items-center border-2 border-white rounded-full  hover:bg-white hover:text-blue1 hover:border-blue1"
                    type="button"
                    onClick={() => {
                      setShowModal(true);
                      setEditInfo(false);
                    }}
                  >
                    Yeni İşlem Ekle
                  </button>
                </div>
              </div>
              <table className="table w-full ">
                {/* <!-- head --> */}
                <thead className="text-gray text-center">
                  <tr>
                    <th>TEDAVİ</th>
                    <th>İŞLEM TARİHİ</th>
                    <th>NOTLAR</th>
                    <th>DURUM</th>
                    <th>GÜNCELLE</th>
                  </tr>
                </thead>

                <tbody className="text-mainPageP font-thin text-center">
                  {/* <!-- row 1 --> */}
                  {patient?.mudehale_bilgileri &&
                    patient?.mudehale_bilgileri
                      ?.filter((item) => item.dis_no === disDetay)
                      ?.map((info, index) => (
                        <tr key={index}>
                          <td>{info.yontem_getir.yontem}</td>
                          <td>{info.mudehale_tarih}</td>
                          <td>{info.doktor_notu ? info.doktor_notu : "-"}</td>
                          <td>
                            {info.sonlandir === 0
                              ? "Devam ediyor"
                              : "Sona erdi"}
                          </td>
                          {/* <td
                            className={
                              info.sonlandir === 1
                                ? "text-[#996DD4]"
                                : " text-[#FCD355]"
                            }
                          >
                            {info.durum}
                          </td> */}
                          <td>
                            <SlNote
                              className="hover:text-black text-xl mx-auto"
                              onClick={(e) => {
                                setShowModal(true);
                                setDisDetay(info.dis_no);
                                setEditInfo(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="mx-auto my-10">
              <div className="flex justify-between items-center mb-5 tex-center">
                <h2>Tedavi Tablosu</h2>
                <div>
                  {/* appointments headers 👇🏻 */}
                  <div className="tabs">
                    <p
                      className={` ${
                        openTab === 1
                          ? "tab border-b-2 border-blue1 text-blue1"
                          : "tab text-black"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setfilteredPatient(patient?.mudehale_bilgileri);
                        setOpenTab(1);
                      }}
                    >
                      Genel Durum
                    </p>
                    <p
                      className={` ${
                        openTab === 2
                          ? "tab border-b-2 border-blue1 text-blue1"
                          : "tab text-black"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setfilteredPatient(
                          patient?.mudehale_bilgileri?.filter(
                            (item) =>
                              item.dis_no.slice(0, 3) === "ust" &&
                              Number(item.dis_no.slice(4, 6)) < 9
                          )
                        );
                        setOpenTab(2);
                      }}
                    >
                      Sol Üst Dişler
                    </p>
                    <p
                      className={` ${
                        openTab === 3
                          ? "tab border-b-2 border-blue1 text-blue1 "
                          : "tab text-black"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setfilteredPatient(
                          patient?.mudehale_bilgileri?.filter(
                            (item) =>
                              item.dis_no.slice(0, 3) === "ust" &&
                              Number(item.dis_no.slice(4, 6)) > 8 &&
                              Number(item.dis_no.slice(4, 6)) < 17
                          )
                        );
                        setOpenTab(3);
                      }}
                    >
                      Sağ Üst Dişler
                    </p>
                    <p
                      className={` ${
                        openTab === 4
                          ? "tab border-b-2 border-blue1 text-blue1 "
                          : "tab text-black"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setfilteredPatient(
                          patient?.mudehale_bilgileri?.filter(
                            (item) =>
                              item.dis_no.slice(0, 3) === "alt" &&
                              Number(item.dis_no.slice(4, 6)) > 8
                          )
                        );
                        setOpenTab(4);
                      }}
                    >
                      Sol Alt Dişler
                    </p>
                    <p
                      className={`${
                        openTab === 5
                          ? "tab border-b-2 border-blue1 text-blue1 "
                          : "tab text-black"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setfilteredPatient(
                          patient?.mudehale_bilgileri?.filter(
                            (item) =>
                              item.dis_no.slice(0, 3) === "alt" &&
                              Number(item.dis_no.slice(4, 6)) < 9
                          )
                        );
                        setOpenTab(5);
                      }}
                    >
                      Sağ Alt Dişler
                    </p>
                  </div>
                  {/* appointments body 👇🏻 */}
                </div>
              </div>
              <div
                className={`${
                  openTab >= 0 ? "block" : "hidden"
                } max-h-80 overflow-auto `}
              >
                <table className="table w-full text-center">
                  {/* <!-- head --> */}
                  <thead className="text-gray">
                    <tr>
                      <th>DİŞ NO</th>
                      <th>TEDAVİ</th>
                      <th>İŞLEM TARİHİ</th>
                      <th>NOTLAR</th>
                      <th>DURUM</th>
                      <th>GÜNCELLE</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredPatient?.length > 0 &&
                      filteredPatient?.map((info, index) => (
                        <tr key={index}>
                          <td>{info.dis_no}</td>
                          <td>
                            <CustomTooltip text={info.yontem_getir.yontem} />
                          </td>
                          <td>{info.mudehale_tarih}</td>
                          {/* <td>{info.doktor_notu ? info.doktor_notu : "-"}</td> */}
                          {/* <td>
                            <span
                              onMouseEnter={toggleTooltip}
                              onMouseLeave={toggleTooltip}
                              className="relative inline-block"
                            >
                              {info.doktor_notu &&
                                (info.doktor_notu.length > 5 ? (
                                  <>
                                    {info.doktor_notu.substring(0, 5)}...
                                    {showTooltip && (
                                      <span className="absolute z-10 bg-white border border-gray-300 p-2 rounded-md shadow-md">
                                        {info.doktor_notu}
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  info.doktor_notu
                                ))}
                            </span>
                          </td> */}
                          {/* <td data-tip={info?.doktor_notu}>
                            {info?.doktor_notu && info?.doktor_notu.length > 5
                              ? `${info?.doktor_notu.substring(0, 5)}...`
                              : info?.doktor_notu || "-"}
                          </td> */}
                          <td>
                            <CustomTooltip text={info?.doktor_notu} />
                          </td>

                          <td>
                            {info.sonlandir === 0
                              ? "Devam ediyor"
                              : "Sona erdi"}
                          </td>
                          {/* <td
                            className={
                              info.sonlandir === 1
                                ? "text-[#996DD4]"
                                : " text-[#FCD355]"
                            }
                          >
                            {info.durum}
                          </td> */}
                          <td>
                            <SlNote
                              className="hover:text-black text-xl mx-auto"
                              onClick={(e) => {
                                setShowModal(true);
                                setDisDetay(info.dis_no);
                                setEditInfo(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <AddProcessModal
          handleSearchPatients={handleSearchPatients}
          searchPatient={searchPatient}
          disDetay={disDetay}
          tedaviBaslik={tedaviBaslik}
          setShowModal={setShowModal}
          editInfo={editInfo}
        />
      )}
    </div>
  );
};

export default PatientProfile;
