import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "../../../../config";

const ReportInfoGeneral = () => {
  const uRegister = useSelector((state) => state.uRegister);
  const [reportTableDataGeneral, setReportTableDataGeneral] = useState("");

  const columnsIslem = [
    {
      name: "AD SOYAD",
      selector: (row) => row.hasta_getir.hasta_adi,
      sortable: true,
      cell: (row) =>
        `${row.hasta_getir.hasta_adi} ${row.hasta_getir.hasta_soyadi}`,
    },
    {
      name: "DİŞ NO",
      selector: (row) => row.dis_no,
      sortable: true,
    },
    {
      name: "İŞLEM",
      selector: (row) => row.yontem_getir.yontem,
      sortable: true,
    },
    {
      name: "TARİH",
      selector: (row) => row.mudehale_tarih, // Sütun seçicisini fonksiyon tabanlı olarak kullanın
      sortable: true,
    },
  ];
  const columnsHesaplar = [
    {
      name: "AD SOYAD",
      selector: (row) =>
        `${row.hasta_getir.hasta_adi} ${row.hasta_getir.hasta_soyadi}`,
      sortable: true,
    },
    {
      name: "ÖDEME ŞEKLİ",
      selector: (row) => row.odeme_sekli,
      sortable: true,
      cell: (row) => (row.odeme_sekli === 0 ? "Peşin" : "Taksitli"),
    },
    {
      name: "TARİH",
      selector: (row) => row.hat,
      sortable: true,
    },
    {
      name: "TOPLAM ÜCRET",
      selector: (row) => row.toplam_ucret,
      sortable: true,
      cell: (row) => `${row.toplam_ucret} TL`,
      right: true, // Sağa hizalama
    },
  ];

  const columnsTahsilat = [
    {
      name: "AD SOYAD",
      selector: (row) =>
        `${row.hasta_getir.hasta_adi} ${row.hasta_getir.hasta_soyadi}`,
      sortable: true,
    },
    {
      name: "ÖDEME TARİHİ",
      selector: (row) => row.odeme_tarihi,
      sortable: true,
      wrap: true, // Uzun tarihleri satırı kırmadan sığdırmak için wrap özelliğini kullanıyoruz
    },
    {
      name: "ÖDENEN MİKTAR",
      selector: (row) => row.odenen_miktar,
      sortable: true,
      right: true, // Sağa hizalama
      cell: (row) => `${row.odenen_miktar} TL`,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        background: "#F2F2F2", // Başlık arka plan rengi
        color: "#333", // Başlık metin rengi
        fontWeight: "bold",
        fontFamily:
          'apple-system, Roboto, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif', // Font ailesi
      },

      cells: {
        style: {
          fontFamily:
            'apple-system, Roboto, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif', // Font ailesi
        },
      },
    },
  };

  useEffect(() => {
    // console.log(uRegister);
    const handleGetAppointmentGeneral = async () => {
      try {
        //önce POLİKLİNİK TÜM DOKTORLARINI GETİR

        const resp = await axios.post(
          `${config.API_BASE_URL}raporlama/genel?pol_vergi_no=${uRegister.id}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );
        if (resp.status !== 200) {
          setReportTableDataGeneral(resp.data);
        } else {
          toast.error("Bu polikliniğe ait rapor bulunmuyor!");
        }
      } catch (error) {
        // Hata durumunda burası çalışır
        console.log(error);
        // Hata mesajını veya gerekli işlemleri burada gerçekleştirebilirsiniz
      }
    };
    handleGetAppointmentGeneral();
  }, []);

  return (
    <div className="">
      <div className="overflow-x-auto w-full my-6 bg-white p-2">
        <h3 className="p-3 ml-2">YAPILAN İŞLEMLER</h3>
        <DataTable
          columns={columnsIslem}
          customStyles={customStyles}
          data={reportTableDataGeneral?.tabloBilgileri?.[0]?.hasta_mudehale}
          pagination // Sayfalama özelliği aktif
          responsive // Tablonun duyarlı (responsive) olmasını sağlar
        />
      </div>
      <div className="overflow-x-auto w-full mb-6 bg-white p-2  z-0">
        <h3 className="p-3 ml-2">TAHSİLATLARs</h3>
        <DataTable
          columns={columnsTahsilat}
          customStyles={customStyles}
          data={reportTableDataGeneral?.tabloBilgileri?.[0]?.tahsilat_getir}
          pagination
          responsive
          // noHeader // Başlık satırını gizlemek için noHeader özelliğini kullanıyoruz
          // subHeader // Alt başlık özelliğini aktif hale getiriyoruz
          // subHeaderAlign="left" // Alt başlığı sola hizalıyoruz
          // subHeaderComponent={
          //   <strong>
          //     Toplam: {reportTableDataGeneral?.tabloBilgileri?.[0]?.tahsilat_getir.reduce((acc, item) => acc + item.odenen_miktar, 0)} TL
          //   </strong>
          // }
        />
        <strong>
          Toplam:{" "}
          {reportTableDataGeneral?.tabloBilgileri?.[0]?.tahsilat_getir.reduce(
            (acc, item) => acc + item.odenen_miktar,
            0
          )}{" "}
          TL
        </strong>
      </div>
      <div className="overflow-x-auto w-full mb-3 bg-white p-2  z-0">
        <h3 className="p-3 ml-2">HESAPLAR</h3>
        <DataTable
          columns={columnsHesaplar}
          customStyles={customStyles}
          data={reportTableDataGeneral?.tabloBilgileri?.[0]?.hesap_getir}
          pagination
          responsive
        />
        {reportTableDataGeneral?.tabloBilgileri &&
          reportTableDataGeneral.tabloBilgileri.length > 0 &&
          reportTableDataGeneral.tabloBilgileri[0]?.hesap_getir && (
            <strong>
              Toplam:{" "}
              {reportTableDataGeneral.tabloBilgileri[0].hesap_getir.reduce(
                (acc, item) => acc + item.toplam_ucret,
                0
              )}{" "}
              TL
            </strong>
          )}
      </div>
    </div>
  );
};

export default ReportInfoGeneral;
