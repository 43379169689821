import React from "react";
import { BsCalendar3, BsTelephone } from "react-icons/bs";
import { GoLocation } from "react-icons/go";

const AppointmentCard = ({ appointment, patient }) => {
  const femaleImg =
    "https://w7.pngwing.com/pngs/488/261/png-transparent-female-doctor-3d-illustration-physician-nursing-health-care-hospital-the-doctor-miscellaneous-image-file-formats-service-thumbnail.png";
  const maleImg =
    "https://www.seekpng.com/png/full/855-8557328_headshot-of-dr-male-dentist-png.png";

  const today = new Date();
  const appointmentDate = new Date(appointment.randevu_tarih);
  const timeDiff = appointmentDate.getTime() - today.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  function turkceGunBul(tarih) {
    const days = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ];

    const date = new Date(tarih);
    const dayIndex = date.getDay();

    const turkishDay = days[dayIndex];
    return turkishDay;
  }


  return (
    <div
      style={{ minWidth: "200px", maxWidth: "250px" }}
      className="card bg-white relative overflow-hidden"
    >
      {daysDiff <= 0 ? (
        <div className="absolute top-0 right-0 text-12 px-4 py-1 rounded-bl-box bg-pink text-white ">
          Geçmiş Randevu
        </div>
      ) : (
        <div className="absolute top-0 right-0 text-12 px-4 py-1 rounded-bl-box bg-blue1 text-white ">
          {daysDiff} gün sonra
        </div>
      )}

      <div className="">
        <figure className="h-32">
          <img // appointment.klinik.pol_logo
            src="https://ozeldent42.com/tema/genel/uploads/hizmetler/icon/implant.png"
            alt="profile"
            className="w-4/6 h-4/6"
            // className="p-3"
          />
        </figure>
        <div className="border-b-2 border-blue1 opacity-80 w-[90%] mx-auto"></div>
        <div className="p-4 h-2/3">
          <div className="flex items-center space-x-3 gap-3">
            <div className="avatar border-2 border-gray rounded-full">
              <div className="rounded-full w-14">
                <img //appointment.doktor.d_resim
                  src={
                    appointment.doktor?.cinsiyet === "E" ? maleImg : femaleImg
                  }
                  alt="Doktor Avatar"
                  className="object-top"
                />
              </div>
            </div>
            <div>
              <p className="text-lightGray font-light">
                {" "}
                {appointment.klinik?.pol_adi}
              </p>
              <p className="font-bold">
                {appointment.doktor?.d_adi} {appointment.doktor?.d_soyadi}
              </p>
            </div>
          </div>

          <div>
            <div className="flex justify-start gap-6 mt-2">
              <BsCalendar3 className="text-xl opacity-50 mt-2 mr-2" />
              <div>
                <p className="text-14">RANDEVU TARİHİ</p>
                <p className="text-[12px]">
                  {appointment.randevu_tarih}{" "}
                  {turkceGunBul(appointment.randevu_tarih)} /{" "}
                  {appointment.randevu_baslangic_saati}
                </p>
              </div>
            </div>
            <div className="flex justify-start gap-6 mt-2">
              <BsTelephone className="text-xl opacity-50 mt-2 mr-2" />
              <div>
                <p className="text-14">TELEFON</p>
                <p className="text-[12px]">{appointment.klinik?.pol_tel}</p>
              </div>
            </div>
            <div className="flex justify-start gap-6 mt-2">
              <GoLocation className="text-2xl opacity-50 mt-2" />
              <div>
                <p className="text-14">ADRES</p>
                <p className="text-12">
                  {appointment.klinik?.pol_ilce +
                    "/" +
                    appointment.klinik?.pol_il}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;
