import React, { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import CheckBox from "./CheckBox";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../../config";

const SecondStepAddAppointment = ({
  handleSubmit,
  handleSubmitBackward,
  setSelectedDoctor,
  selectedDoctor,
  date,
  setDate,
  clock,
  setClock,
  saatler,
  setSaatler,
  getNoDoctors,
  uRegister,
  setClockStep,
  setClockChange,
  clockChange,
}) => {
  const [isChecked, setIsChecked] = useState(true);
  const sabah = [
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
  ];
  const ogle = [
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
  ];
  const aksam = [
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
  ];

  const availableTimes_1 = [
    "09:00:00", //format :00 geldiği için başta böyle aldık
    "09:15:00",
    "09:30:00",
    "09:45:00",
    "10:00:00",
    "10:15:00",
    "10:30:00",
    "10:45:00",
    "11:00:00",
    "11:15:00",
    "11:30:00",
    "11:45:00",
    "12:00:00",
    "12:15:00",
  ];

  const availableTimes_2 = [
    "13:30:00",
    "13:45:00",
    "14:00:00",
    "14:15:00",
    "14:30:00",
    "14:45:00",
    "15:00:00",
    "15:15:00",
    "15:30:00",
    "15:45:00",
    "16:00:00",
    "16:15:00",
    "16:30:00",
    "16:45:00",
  ];
  const availableTimes_3 = [
    "18:00:00",
    "18:15:00",
    "18:30:00",
    "18:45:00",
    "19:00:00",
    "19:15:00",
    "19:30:00",
    "19:45:00",
  ];

  const handleSubmitControl = (e) => {
    const saat = handleListeSadelestir(); // saat seçimi buraya bağlı o yüzden önce burası işlemelei ki saat seçilmiş mi kontrol edelim
    setSelectedDoctor(uRegister?.d_adi + " " + uRegister?.d_soyadi);
    if (saat) {
      handleSubmit(e);
    } else {
      return toast.error("Lütfen saat seçimi yapınız");
    }
  };

  // const [tamListe, setTamListe] = useState([])

  // haftanın hangi günü olduğunu aldık
  function getLocalDayOfWeek(dateStr) {
    const date = new Date(dateStr + "T00:00:00");
    const daysOfWeek = [
      "pazar",
      "pazartesi",
      "sali",
      "carsamba",
      "persembe",
      "cuma",
      "cumartesi",
    ];
    return daysOfWeek[date.getDay()];
  }

  function updateValues(tamListe, saatlerr) {
    const updatedSaatler = { ...saatlerr };

    for (const saat in updatedSaatler) {
      if (tamListe.hasOwnProperty(saat)) {
        tamListe[saat] === 1
          ? (updatedSaatler[saat] = 2)
          : (updatedSaatler[saat] = 0); // doluysa 2 boşsa 0  2 veriyoruz çünkü yeni seçeceğimiz saatler 1 olacak , 2 ler kırmızı ve disabled olacak
      } else {
        updatedSaatler[saat] = 2; // mesaisi yoksa
      }
    }

    return updatedSaatler;
  }

  //aşağıdaki fonksiyonda oluşturulan tüm saatlerden dolu olanlarına 0 boş olanlarına 1 ekler
  const createAvailabilityObject = (doctorClocks, doktorMusaitSaatler) => {
    const result = {};

    for (let i = 0; i < doktorMusaitSaatler.length; i++) {
      result[doktorMusaitSaatler[i]] = 1;
    }

    for (let i = 0; i < doctorClocks["randevular"].length; i++) {
      const randevu_baslangic_saati =
        doctorClocks["randevular"][i]["randevu_baslangic_saati"];
      const randevu_suresi = parseInt(
        doctorClocks["randevular"][i]["randevu_suresi"]
      );
      const index = doktorMusaitSaatler.indexOf(randevu_baslangic_saati);

      if (index !== -1) {
        for (let j = 0; j < randevu_suresi; j++) {
          const saatIndex = index + j;
          if (saatIndex < doktorMusaitSaatler.length) {
            result[doktorMusaitSaatler[saatIndex]] = 0;
          }
        }
      } else {
        result[randevu_baslangic_saati] = 1;
      }
    }

    return result;
  };

  const gunListele = (cloks) => {
    // bizdeki boş saatleri karşılığını gelen dolu saatlerle doldurduk
    const doctormusaitvakitler = []; // yeni liste oluşturuluyor
    const gun = getLocalDayOfWeek(cloks["randevu_tarih"]);
    const dilim = cloks["doktor_saatleri"]["0"][gun + "_1"];
    if (dilim === 1) {
      doctormusaitvakitler.push(...availableTimes_1); // push kullanarak saatleri yeni liste ekliyoruz
    }

    const dilim2 = cloks["doktor_saatleri"]["0"][gun + "_2"];
    if (dilim2 === 1) {
      doctormusaitvakitler.push(...availableTimes_2);
    }
    const dilim3 = cloks["doktor_saatleri"]["0"][gun + "_3"];
    if (dilim3 === 1) {
      doctormusaitvakitler.push(...availableTimes_3);
    }
    let sonuc = createAvailabilityObject(cloks, doctormusaitvakitler);
    const newObj = {};
    for (const key in sonuc) {
      newObj[key.slice(0, -3)] = sonuc[key]; //:00 dan kurtulduk
    }

    const updatedObj = {};
    for (const key in newObj) {
      updatedObj[key] = newObj[key] === 1 ? 0 : 1;
    }

    setSaatler(updateValues(updatedObj, saatler)); // bizdeki boş saatleri karşılığını gelen dolu saatlerle doldurduk
    getNoDoctors(date);
  };

  const scrollLeft = (containerId) => {
    const element = document.getElementById(containerId);
    element.scrollLeft -= 200;
  };

  const scrollRight = (containerId) => {
    const element = document.getElementById(containerId);
    element.scrollLeft += 200;
  };

  // çoklu saat seçiminde arada boşluk oluştuysa ilk sıralı seçilen satler dışında ki seçimleri kaldırır
  const listeSadelestir = () => {
    let firstHour = null;
    let lastHour = null;
    let previousHour = null;
    let uptSaatler = saatler;

    // Find the first hour with value 1
    for (const hour in uptSaatler) {
      if (uptSaatler[hour] === 1) {
        firstHour = hour;
        break;
      }
    }

    // Find the previous hour before the firstHour
    let foundFirstHour = false;
    for (const hour in uptSaatler) {
      if (hour === firstHour) {
        foundFirstHour = true;
      } else if (foundFirstHour && uptSaatler[hour] === 0) {
        break;
      } else if (foundFirstHour) {
        previousHour = hour;
      }
    }

    // Find the first 0 after the previousHour or firstHour
    let foundFirstZero = false;
    for (const hour in uptSaatler) {
      if (foundFirstZero && uptSaatler[hour] === 0) {
        lastHour = hour;
        break;
      }
      if (hour === previousHour || hour === firstHour) {
        foundFirstZero = true;
      }
    }

    // Set all 1's after lastHour to 0
    let countOnes = 0;
    let setZeros = false;
    const updatedSaatler = { ...uptSaatler };
    for (const hour in uptSaatler) {
      if (setZeros) {
        updatedSaatler[hour] = 0;
      } else if (hour === lastHour) {
        setZeros = true;
      }

      if (updatedSaatler[hour] === 1) {
        countOnes++;
      }
    }

    setSaatler(updatedSaatler);
    return { firstHour, previousHour, countOnes };
  };

  const handleListeSadelestir = () => {
    const { firstHour, countOnes } = listeSadelestir();
    setClock(firstHour);
    setClockStep(countOnes);
    return firstHour;
  };

  const getDoctorClocks = async (diploma_no) => {
    const { data } = await axios.post(
      `${config.API_BASE_URL}randevu_al_saat`,
      {
        d_diploma_no: diploma_no,
        alinan_tarih: date, //date uzun tarih olabilir
      }
    );
    gunListele(data);
  };

  function getMaxMonthEndDate() {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
    return maxDate.toISOString().split("T")[0];
  }

  useEffect(() => {
    getDoctorClocks(uRegister["d_diploma_no"]);
  }, [date, uRegister["d_diploma_no"]]);
  // console.log(selectedDoctor);
  return (
    <div className="my-1 p-10">
      <div className="flex xs:justify-around md:justify-end py-2 mt-5 items-center sm:overflow-hidden sm:rounded-md mb-4">
        <h1 className="md:hidden">Randevu Bilgileri</h1>
        <div className="flex xs:flex-col md:flex-row justify-between items-center gap-4 w-3/5">
          <label className="label">
            <span className="label-text">Tarih:</span>
          </label>
          <input
            type="date"
            max={getMaxMonthEndDate()}
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
              getNoDoctors(e.target.value);
            }}
            className="input input-bordered input-sm focus:input-primary w-full max-w-xs"
          />

          <label className="label">
            <span className="label-text">Doktor:</span>
          </label>
          <input
            type="text"
            value={uRegister?.d_adi + " " + uRegister?.d_soyadi}
            // readOnly
            placeholder="Hastanın baba adı"
            className="input input-bordered input-sm focus:input-primary w-full max-w-xs"
          />
        </div>
      </div>
      <form className="w-full px-2">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between items-center gap-1 my-2">
            <h4 style={{ fontSize: "15px" }} className="w-30 mb-5 ">
              Öğleden Önce :
            </h4>
            <div className=" flex flex-wrap gap-x-1 w-5/6">
              {/* <span className="p-2 mb-4" onClick={() => scrollLeft("scroll-container-sabah")}>
                <BsFillArrowLeftCircleFill size={30} className="text-blue1" />
              </span> */}
              <div
                id="scroll-container-sabah"
                className="flex space-x-1 w-full overflow-x-hidden "
              >
                {sabah.map((item, index) => (
                  <div className="h-10 my-1" key={item}>
                    <CheckBox
                      saat={item}
                      saatler={saatler}
                      setSaatler={setSaatler}
                    />
                  </div>
                ))}
              </div>
              {/* <span className="p-2" onClick={() => scrollRight("scroll-container-sabah")}>
                <BsFillArrowRightCircleFill size={30} className="text-blue1" />
              </span> */}
            </div>
          </div>
          <div className="flex justify-between items-center gap-1 my-2">
            <h4 style={{ fontSize: "15px" }} className="w-30 mb-5">
              Öğleden Sonra :
            </h4>
            <div className=" flex flex-wrap gap-x-1 w-5/6">
              {/* <span className="p-2 mb-4" onClick={() => scrollLeft("scroll-container-ogle")}>
                <BsFillArrowLeftCircleFill size={30} className="text-blue1" />
              </span> */}
              <div
                id="scroll-container-ogle"
                className="flex space-x-1 w-full overflow-x-hidden "
              >
                {ogle.map((item, index) => (
                  <div className="h-10 my-1" key={index}>
                    <CheckBox
                      saat={item}
                      saatler={saatler}
                      setSaatler={setSaatler}
                    />
                  </div>
                ))}
              </div>
              {/* <span className="p-2" onClick={() => scrollRight("scroll-container-ogle")}>
                <BsFillArrowRightCircleFill size={30} className="text-blue1" />
              </span> */}
            </div>
          </div>
          <div className="flex justify-between items-center gap-1 my-2">
            <h4 style={{ fontSize: "15px" }} className="w-30 mb-5">
              Akşam :
            </h4>
            <div className=" flex flex-wrap gap-x-1 w-5/6">
              {/* <span className="p-2 mb-4" onClick={() => scrollLeft("scroll-container-aksam")}>
                <BsFillArrowLeftCircleFill size={30} className="text-blue1" />
              </span> */}
              <div
                id="scroll-container-aksam"
                className="flex h-15 space-x-1 w-full overflow-x-hidden "
              >
                {aksam.map((item, index) => (
                  <div className="h-10  my-1" key={index}>
                    <CheckBox
                      saat={item}
                      saatler={saatler}
                      setSaatler={setSaatler}
                    />
                  </div>
                ))}
              </div>
              {/* <span className="p-2" onClick={() => scrollRight("scroll-container-aksam")}>
                <BsFillArrowRightCircleFill size={30} className="text-blue1" />
              </span> */}
            </div>
          </div>
        </div>
        {/*footer*/}
        <div className="flex items-center justify-between mx-4 mt-7 rounded-b">
          <button
            className="bg-blue1 text-white capitalize btn btn-sm rounded-3xl hover:bg-white hover:text-blue1 hover:border-blue1"
            onClick={handleSubmitBackward}
          >
            <AiOutlineLeft className="mr-4" />
            Önceki Adım
          </button>
          <button
            className="bg-blue1 text-white capitalize btn btn-sm rounded-3xl hover:bg-white hover:text-blue1 hover:border-blue1"
            type="button"
            onClick={(e) => handleSubmitControl(e)}
          >
            Sonraki Adım
            <AiOutlineRight className="ml-4" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SecondStepAddAppointment;
