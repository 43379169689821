import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DoctorHeader from "../../../components/doctor/doctorDashboard/DoctorHeader";
import Layout from "../../../components/doctor/Layout";
import PatientProfile from "../../../components/doctor/patient/PatientProfile";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../../config";
import { modalTogglePatient } from "../../../features/modalSlice";

const initialVale = {
  id: "",
  hasta_tc: "",
  hasta_adi: "",
  hasta_soyadi: "",
  hasta_baba_adi: " ",
  hasta_il: " ",
  hasta_ilce: " ",
  hasta_tel: "",
  hasta_mail: "",
  hasta_adres: "",
  hasta_dogum_tarihi: "",
  cinsiyet: "",
};

const PatientDetail = () => {
  const uRegister = useSelector((state) => state.uRegister);
  const dispatch = useDispatch();
  const open = useSelector((state) => state.open.value);
  const [searchPatient, setSearchPatient] = useState("");
  const [goster, setGoster] = useState(false);
  const [tedaviBaslik, setTedaviBaslik] = useState([]);

  const [patientsInfo, setPatientsInfo] = useState(initialVale);

  const handleSearchPatient = async (e) => {
    e.preventDefault();

    setGoster(false);
    try {
      const resp = await axios.post(
        `${config.API_BASE_URL}hasta_uye/sorgu?hasta_tc=${searchPatient}`
      );
      if (resp.data.status !== 400) {
        try {
          const response = await axios.get(
            `${config.API_BASE_URL}hasta_mudehale/sorgu?hasta_tc=${searchPatient}`,
            {
              headers: {
                Authorization: "Bearer " + uRegister.token, // Authorization
              },
            }
          );
          if (response.data.status !== 400) {
            setPatientsInfo(response.data);
            setGoster(true);
          } else {
            setGoster(true);
            setPatientsInfo(response.data);
          }
          console.log("response", response);
        } catch (err) {
          console.log("err", err);
        }
      } else {
        dispatch(modalTogglePatient());
      }
      // console.log("resp", resp);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSearchPatients = async (e) => {
    // e.preventDefault();
    setGoster(false);
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}hasta_mudehale/sorgu?hasta_tc=${searchPatient}`,
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      // console.log(response);

      if (response.data.status !== 400) {
        setPatientsInfo(response.data);
        setGoster(true);
      } else {
        toast.error("Bu TC. no ya ait bir müdahale kayıt bulunamadı!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}klinik_doktorlar/performans/baslik`,
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );
        // response.data içerisinde alınan verileri kullanabilirsiniz
        setTedaviBaslik(response.data);
      } catch (error) {
        // Hata durumunda burası çalışır
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Layout />
      <div
        className={`absolute bg-container  mt-20 right-0 p-6 min-h-[calc(100vh-80px)] ${
          open ? "w-[calc(100%-250px)]" : "w-[calc(100%-100px)]"
        } transition-w ease-in-out duration-500`}
      >
        <div className="bg-white">
          <div className="">
            <DoctorHeader />
            <div className="flex xs:flex-col md:flex-row items-center justify-between mt-7 py-6  gap-6 bg-[#F1F6FB]">
              <h1 className="text-28">Hastalar</h1>
              <form action="" onSubmit={handleSearchPatient}>
                <input
                  type="text"
                  name="search"
                  value={searchPatient}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const onlyNumbers = /^[0-9\b]+$/;

                    if (
                      enteredValue === "" ||
                      (onlyNumbers.test(enteredValue) &&
                        enteredValue.length <= 11)
                    ) {
                      setSearchPatient(enteredValue);
                    }
                  }}
                  pattern="\d{11}"
                  title="11 haneli rakam girmelisiniz"
                  placeholder="Hasta Ara"
                  className="input input-sm input-bordered  rounded-3xl mr-3"
                  onKeyDown={(e) => e.key === "Enter" && handleSearchPatient(e)}
                />
                <button
                  className="btn btn-primary btn-sm rounded-full capitalize hover:bg-white hover:text-blue1 "
                  // onClick={handleSearchPatient}
                  type="submit"
                >
                  Ara
                </button>
              </form>
              {/* <label className="label-text text-center">Hasta TC Kimlik No</label> */}
            </div>
            {goster && (
              <PatientProfile
                handleSearchPatients={handleSearchPatients}
                searchPatient={searchPatient}
                tedaviBaslik={tedaviBaslik}
                patient={patientsInfo}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDetail;
