import React from 'react'
import { useSelector } from 'react-redux';

const ReportInfo = ({ reportPatientInfo, reportTableData }) => {

    const uRegister = useSelector((state) => state.uRegister);
    return (
        <div className=''>
            <div className="overflow-x-auto w-full my-6 bg-white p-2">
                <h3 className='p-3 ml-2'>
                    YAPILAN İŞLEMLER
                </h3>
                <table className="table w-full mx-auto z-0">
                    {/* <!-- head --> */}
                    <thead>
                        <tr>
                            <th>AD SOYAD</th>
                            <th>DİŞ NO</th>
                            <th>İŞLEM</th>
                            <th>NOT</th>
                            <th className='text-end'>TARİH</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reportTableData?.tabloBilgileri[0].hasta_mudehale?.map((item, index) => (
                                <tr key={index}>
                                    <td>{reportTableData?.tabloBilgileri[0]?.hasta_adi} {reportTableData?.tabloBilgileri[0]?.hasta_soyadi}</td>
                                    <td>{item?.dis_no}</td>
                                    <td>{item?.yontem_getir?.yontem}</td>
                                    <td>{item?.doktor_notu ? item?.doktor_notu : "-"}</td>
                                    <td className='text-end'>{item?.mudehale_tarih}</td>

                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <div className="overflow-x-auto w-full mb-6 bg-white p-2  z-0">
                <h3 className='p-3 ml-2'>
                    TAHSİLATLAR
                </h3>
                <table className="table w-full mx-auto">
                    {/* <!-- head --> */}

                    <thead>
                        <tr>
                            <th>AD SOYAD</th>
                            <th colSpan={3}>ÖDEME TARİHİ</th>
                            <th className='text-end'>ÖDENEN MİKTAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reportTableData?.tabloBilgileri && //3ünü de kontrol ettim yoksa hata veriyor
                            reportTableData.tabloBilgileri.length > 0 &&
                            reportTableData.tabloBilgileri[0]?.tahsilat_getir && (
                                <>
                                    {reportTableData.tabloBilgileri[0].tahsilat_getir.map((item, index) => (
                                        <tr key={index}>
                                           <td>{reportTableData?.tabloBilgileri[0]?.hasta_adi} {reportTableData?.tabloBilgileri[0]?.hasta_soyadi}</td>
                                            <td colSpan={3}>{item.odeme_tarihi}</td>
                                            <td className='text-end'>{item.odenen_miktar} TL</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td><strong>Toplam</strong></td>
                                        <td colSpan={3}> </td>
                                        <td className='text-end'><strong>{reportTableData.tabloBilgileri[0].tahsilat_getir.reduce((acc, item) => acc + item.odenen_miktar, 0)} TL</strong></td>
                                    </tr>
                                </>
                            )
                        }


                    </tbody>
                </table>
            </div>
            <div className="overflow-x-auto w-full mb-3 bg-white p-2  z-0">
                <h3 className='p-3 ml-2'>
                    HESAPLAR
                </h3>
                <table className="table table-fixed w-full mx-auto">
                    {/* <!-- head --> */}
                    <thead className='text-left'>
                        <tr>
                            <th>AD SOYAD</th>
                            <th>ÖDEME ŞEKLİ</th>
                            <th>TARİH</th>
                            <th className='text-end'>TOPLAM ÜCRET</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reportTableData?.tabloBilgileri &&
                            reportTableData.tabloBilgileri.length > 0 &&
                            reportTableData.tabloBilgileri[0]?.hesap_getir && (
                                <>
                                    {reportTableData.tabloBilgileri[0].hesap_getir.map((item, index) => (
                                        <tr key={index}>
                                            <td>{reportTableData?.tabloBilgileri[0]?.hasta_adi} {reportTableData?.tabloBilgileri[0]?.hasta_soyadi}</td>
                                            <td>{item.odeme_sekli === 0 ? "Peşin" : "Taksitli"}</td>
                                            <td>{item.hat}</td>
                                            <td className='text-end'>{item.toplam_ucret} TL</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan="3"><strong>Toplam</strong></td>
                                        <td className='text-end'><strong>{reportTableData.tabloBilgileri[0].hesap_getir.reduce((acc, item) => acc + item.toplam_ucret, 0)} TL</strong></td>
                                    </tr>
                                </>
                            )
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ReportInfo
