import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Controller } from "swiper";
import { BsThreeDotsVertical } from "react-icons/bs";
import { SlCalender, SlClock } from "react-icons/sl";
import { AiFillEdit, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import { TbDental } from "react-icons/tb";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useSelector } from "react-redux";
import axios from "axios";
import ModalDocWorkHour from "./ModalDocWorkHour";
import { toast } from "react-toastify";
import config from "../../../config";

const DoctorsOur = ({ setOpenTab }) => {
  const uRegister = useSelector((state) => state.uRegister);

  const [doktorlar, setDoktorlar] = useState([]);
  const widthScreen = window.innerWidth;
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchDoctorDiplomaNo, setSearchDoctorDiplomaNo] = useState(null);

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  const getAppointment = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}klinik_doktorlar/${uRegister.id}`,
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );

      setDoktorlar(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteDoctor = async (dipNo) => {
    // console.log(dipNo);
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}dok_uye/doktorSil`,
        { d_diploma_no: dipNo },
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      getAppointment();
      toast.success("Doktor Pasif Hale getirildi.");
    } catch (error) {
      console.log(error);
      toast.success("Doktor Pasif Hale getirilemedi.");
    }
  };
  const confirmDelete = (dipNo) => {
    const confirmation = window.confirm(
      "Doktor verileri tamamen silinmeyecektir ancak artık kliniğinizin personeli olarak kliniğiniz üzerinde panel yetkisine sahip olmayacaktır veya gözükmeyecektir. İlişiği kesmek istediğinize emin misiniz?"
    );

    if (confirmation) {
      handleDeleteDoctor(dipNo);
    }
  };

  useEffect(() => {
    getAppointment();
  }, []);

  return (
    <div>
      <div className="flex justify-end mb-4 px-2">
        <article className="flex gap-3">
          <button
            ref={prevRef}
            className="bg-container w-10 h-10 flex items-center justify-center rounded-full"
          >
            <HiOutlineArrowLeft />
          </button>
          <button
            ref={nextRef}
            className="bg-container w-10 h-10 flex items-center justify-center rounded-full"
          >
            <HiOutlineArrowRight />
          </button>
        </article>
      </div>

      <Swiper
        className="grid grid-cols-5 gap-5 "
        spaceBetween={25}
        slidesPerView={
          widthScreen < 768
            ? 1
            : widthScreen < 1050
            ? 2
            : widthScreen < 1400
            ? 3
            : widthScreen < 1800
            ? 4
            : 5
        }
        onSwiper={setSwiper}
        modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
        navigation={{
          prevEl: prevRef?.current,
          nextEl: nextRef?.current,
        }}
        updateOnWindowResize
        observer
        observeParents
      >
        {doktorlar.length > 0 &&
          doktorlar?.map(
            (doktor, index) =>
              doktor.is_active === 1 && (
                <SwiperSlide key={index}>
                  <div
                    className="card bg-container mb-4 flex items-center h-[34rem] "
                    style={{ minWidth: "200px" }}
                  >
                    <div className="relative flex flex-col justify-center items-center mt-2">
                      <img
                        src={doktor.d_resim}
                        alt="profile"
                        className="rounded-full w-32 h-28 object-contain border border-lightGray bg-white"
                      />
                      <h2 className="text-center mt-4 font-bold">
                        {doktor.d_adi} {doktor.d_soyadi}
                      </h2>
                      <p className="text-blue2">{doktor.d_unvan}</p>
                      {/* <BsThreeDotsVertical className="absolute right-0 top-2 w-10" /> */}
                    </div>

                    <div
                      className="card-body  relative hover:opacity-70 transition-opacity  max-h-[19rem]"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      {/* <div className="flex justify-between mx-8 opacity-50">
                  <TbDental className="text-xl font-bold text-primary-focus" />
                  <TbDental
                    style={{ opacity: "0.1" }}
                    className="text-xl  font-bold"
                  />
                  <SlCalender className="ml-7" />
                  <SlClock className="mr-9" />
                </div> */}
                      <div
                        className={`flex justify-center bg-gray-200 p-2 rounded-md shadow-md  transition-opacity`}
                        // ${
                        //   isHovered ? "opacity-80" : "opacity-100"
                        // }
                        style={{
                          minWidth: "220px",
                          border: "solid 1px rgb(153, 109, 212, 0.3)",
                          borderRadius: "10px",
                        }}
                      >
                        <table
                          className="w-full"
                          style={{
                            minWidth: "220px",
                            border: "none",
                          }}
                        >
                          <tbody
                            className={` ${
                              isHovered ? "opacity-80" : "opacity-100"
                            } transition-opacity`}
                          >
                            <tr
                              className=""
                              style={{
                                borderBottom:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              <td
                                className="text-center"
                                style={{
                                  borderBottom:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                }}
                              >
                                <tr
                                  className="h-9 "
                                  style={{
                                    borderBottom:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                  }}
                                ></tr>
                                <tr
                                  className="mx-4 my-2"
                                  style={{
                                    borderTop:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    height: "0.2rem",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  Pazartesi
                                </tr>
                              </td>
                              <td className="text-center">
                                <tr
                                  style={{
                                    borderBottom:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                  }}
                                >
                                  <td
                                    className="text-center"
                                    style={{
                                      borderRight:
                                        "solid 1px rgb(153, 109, 212, 0.3)",
                                      width: "33.3%",
                                      height: "3.5rem",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                    }}
                                  >
                                    SB <br />
                                    <p
                                      className="italic "
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "lighter",
                                      }}
                                    >
                                      (09:00 - 12:00)
                                    </p>
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{
                                      borderRight:
                                        "solid 1px rgb(153, 109, 212, 0.3)",
                                      width: "33.3%",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                      height: "3.5rem",
                                    }}
                                  >
                                    ÖGL <br />
                                    <p
                                      className="italic "
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "lighter",
                                      }}
                                    >
                                      (13:00 - 16:30)
                                    </p>
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{
                                      width: "33.3%",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                      height: "3.5rem",
                                    }}
                                  >
                                    AKŞ
                                    <br />
                                    <p
                                      className="italic "
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "lighter",
                                      }}
                                    >
                                      (18:00 - 23:00)
                                    </p>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                  }}
                                >
                                  <td
                                    className="text-center"
                                    style={{
                                      borderRight:
                                        "solid 1px rgb(153, 109, 212, 0.3)",
                                      width: "33.3%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {doktor?.mesai?.["pazartesi_1"] ? (
                                      <TbDental className="text-xl font-bold text-primary-focus" />
                                    ) : (
                                      <TbDental
                                        style={{
                                          opacity: "0.1",
                                          alignItems: "center",
                                        }}
                                        className="text-xl  font-bold "
                                      />
                                    )}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{
                                      borderRight:
                                        "solid 1px rgb(153, 109, 212, 0.3)",
                                      width: "33.3%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {doktor?.mesai?.["pazartesi_2"] ? (
                                      <TbDental className="text-xl font-bold text-primary-focus" />
                                    ) : (
                                      <TbDental
                                        style={{
                                          opacity: "0.1",
                                          alignItems: "center",
                                        }}
                                        className="text-xl  font-bold "
                                      />
                                    )}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{
                                      width: "33.3%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {doktor?.mesai?.["pazartesi_3"] ? (
                                      <TbDental className="text-xl font-bold text-primary-focus h-5" />
                                    ) : (
                                      <TbDental
                                        style={{
                                          opacity: "0.1",
                                          alignItems: "center",
                                        }}
                                        className="text-xl  font-bold h-5"
                                      />
                                    )}
                                  </td>
                                </tr>
                              </td>
                            </tr>
                            <tr
                              className=""
                              style={{
                                borderBottom:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              <td
                                className=" text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                }}
                              >
                                Salı
                              </td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "33.8%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["sali_1"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "32.5%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["sali_2"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    width: "33.4%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["sali_3"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                              </div>
                            </tr>
                            <tr
                              className=""
                              style={{
                                borderBottom:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              <td
                                className=" text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                }}
                              >
                                Çarşamba
                              </td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "33.8%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["carsamba_1"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "32.5%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["carsamba_2"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    width: "33.4%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["carsamba_3"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                              </div>
                            </tr>
                            <tr
                              className=""
                              style={{
                                borderBottom:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              <td
                                className=" text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                }}
                              >
                                Perşembe
                              </td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "33.8%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["persembe_1"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "32.5%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["persembe_2"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    width: "33.4%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["persembe_3"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                              </div>
                            </tr>
                            <tr
                              className=""
                              style={{
                                borderBottom:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              <td
                                className=" text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                }}
                              >
                                Cuma
                              </td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "33.8%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["cuma_1"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "32.5%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["cuma_2"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>

                                <td
                                  className="text-center"
                                  style={{
                                    width: "33.4%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["cuma_3"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                              </div>
                            </tr>
                            <tr
                              className=""
                              style={{
                                borderBottom:
                                  "solid 1px rgb(153, 109, 212, 0.3)",
                              }}
                            >
                              <td
                                className=" text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                }}
                              >
                                Cumartesi
                              </td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "33.8%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["cumartesi_1"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "32.5%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["cumartesi_2"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    width: "33.4%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["cumartesi_3"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                              </div>
                            </tr>
                            <tr className="">
                              <td
                                className=" text-center"
                                style={{
                                  borderRight:
                                    "solid 1px rgb(153, 109, 212, 0.3)",
                                }}
                              >
                                Pazar
                              </td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "33.8%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["pazar_1"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    borderRight:
                                      "solid 1px rgb(153, 109, 212, 0.3)",
                                    width: "32.5%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["pazar_2"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                                <td
                                  className="text-center"
                                  style={{
                                    width: "33.4%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {doktor?.mesai?.["pazar_3"] ? (
                                    <TbDental className="text-xl font-bold text-primary-focus h-6" />
                                  ) : (
                                    <TbDental
                                      style={{
                                        opacity: "0.1",
                                        alignItems: "center",
                                      }}
                                      className="text-xl  font-bold h-6"
                                    />
                                  )}
                                </td>
                              </div>
                            </tr>
                          </tbody>
                        </table>
                        <button
                          className={`btn btn-ghost bg-red  bg-opacity-100 text-white capitalize hover:bg-red px-4 py-2 rounded-md absolute bottom-3  hover:opacity-100 transition-opacity duration-300 ${
                            isHovered ? "opacity-100" : "opacity-0"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpen(true);
                            setSearchDoctorDiplomaNo(doktor.d_diploma_no);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "center", // Butonu yatayda merkezlemek için
                            alignItems: "center", // Butonu dikeyde merkezlemek için
                            width: "fit-content", // İçeriğe göre genişlik
                            marginLeft: "auto", // Sağa hizalama
                            marginRight: "auto", // Sola hizalama
                          }}
                        >
                          <AiFillEdit className="mr-2" /> Mesai Güncelle
                        </button>
                      </div>
                    </div>

                    <div
                      className="flex justify-center gap-8 pb-4"
                      style={{
                        minWidth: "220px",
                      }}
                    >
                      <button
                        className="btn btn-ghost bg-blue1 bg-opacity-10 text-blue1 capitalize hover:bg-blue1 hover:text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(2);
                          setSearchDoctorDiplomaNo(doktor.d_diploma_no);
                        }}
                      >
                        <AiOutlineCheck className="mr-2" /> Performans
                      </button>
                      <button
                        className="btn btn-ghost bg-red bg-opacity-10 text-red capitalize hover:bg-red hover:text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          // setOpenTab(3);
                          // handleDeleteDoctor(doktor.d_diploma_no);
                          confirmDelete(doktor.d_diploma_no);
                        }}
                      >
                        <AiOutlineClose className="mr-2" /> İlişiği Kes
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              )
          )}
        {open && (
          <ModalDocWorkHour
            doctorDiplomaNo={searchDoctorDiplomaNo}
            setOpen={setOpen}
            getAppointment={getAppointment}
          />
        )}
      </Swiper>
    </div>
  );
};

export default DoctorsOur;
