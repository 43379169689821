import React from "react";
import { FiChevronRight } from "react-icons/fi";
import AddFinancialForm from "./AddFinancialForm";
import AddFinancialPatientInfo from "./AddFinancialPatientInfo";
import { useState } from "react";
import { useSelector } from "react-redux";

const PatientInformation = ({
  patientInfo,
  openProcess,
  endedProcess,
  tumTedaviYontemler,
}) => {
  const uRegister = useSelector((state) => state.uRegister);
  const [yontemId, setYontemId] = useState("");
  const [mudehaleId, setMudehaleId] = useState("");
  return (
    <>
      <div className="bg-container">
        {patientInfo?.id > 0 && (
          <AddFinancialPatientInfo
            yontemId={yontemId}
            patientInfo={patientInfo}
          />
        )}
      </div>
      <div className="bg-container grid grid-cols-1 lg:grid-cols-3 gap-4 py-4">
        <div className="col-span-1">
          {openProcess?.mudehale_bilgileri ? (
            <div className="col-span-1">
              <div
                style={{ minHeight: "476px" }}
                className="flex flex-col gap-1 bg-white p-4"
              >
                <h3 className="font-semibold">Devam Eden İşlemler </h3>
                <div className="flex flex-col  gap-1 h-36 w-full mt-1">
                  {openProcess?.mudehale_bilgileri
                    ?.filter((item) => item.pol_vergi_no === uRegister.id)
                    .map((item, index) => (
                      <p className="flex  items-center gap-1" key={index}>
                        <div style={{ width: "20px" }}>
                          <FiChevronRight className="text-blue1" />
                        </div>
                        {tumTedaviYontemler[item.tedavi_yontem]}
                      </p>
                    ))}
                </div>
              </div>
            </div>
          ) : patientInfo?.id > 0 ? (
            <div className="col-span-1">
              <div
                style={{ minHeight: "476px" }}
                className="flex flex-col gap-1 bg-white p-4"
              >
                <h3 className="font-semibold">Devam Eden İşlemler (Genel)</h3>
                <div className="flex flex-col flex-wrap gap-1 text-14 h-36 w-full mt-1">
                  <p>Devam Eden işlem Bulunamadı!</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {endedProcess?.mudehale_bilgileri ? (
          <div className="col-span-1">
            <div
              style={{ minHeight: "476px", overflowY: "auto" }}
              className="bg-white p-4"
            >
              <h3 className="font-semibold">Sona Eren İşlemler </h3>
              {endedProcess?.mudehale_bilgileri.length > 0 ? (
                <>
                  {/* 0 olanlar */}
                  {endedProcess?.mudehale_bilgileri
                    .filter((item) => Number(item.hesap_olustruldu) === 0)
                    .map((item, index) => (
                      <label
                        className="flex justify-start items-start mb-1"
                        key={index}
                      >
                        <div style={{ width: "20px" }}>
                          <input
                            style={{
                              marginTop: "4px",
                              marginRight: "5px",
                              width: "18px",
                              height: "18px",
                            }}
                            type="radio"
                            name="tedavi_yontem"
                            value={item.tedavi_yontem}
                            onChange={(e) => {
                              setYontemId(e.target.value);
                              setMudehaleId(item.id);
                            }}
                          />
                        </div>
                        <span>{tumTedaviYontemler[item.tedavi_yontem]}</span>
                      </label>
                    ))}

                  {/* Divider */}
                  {endedProcess?.mudehale_bilgileri?.filter(
                    (item) => Number(item.hesap_olustruldu) === 0
                  ).length > 0 && (
                    <div className="my-2 border-b border-blue-1"></div>
                  )}

                  {/* 1 olanlar */}
                  {endedProcess?.mudehale_bilgileri
                    .filter((item) => Number(item.hesap_olustruldu) === 1)
                    .map((item, index) => (
                      <label
                        className="flex justify-start items-center mb-1"
                        key={index}
                      >
                        <div style={{ width: "20px" }}>
                          {" "}
                          <FiChevronRight className="text-blue1" />
                        </div>{" "}
                        <span>{tumTedaviYontemler[item.tedavi_yontem]}</span>
                      </label>
                    ))}
                </>
              ) : (
                <p>Sona Eren işlem Bulunamadı!</p>
              )}
            </div>
          </div>
        ) : patientInfo?.id > 0 ? (
          <div className=" col-span-1">
            <div
              style={{ minHeight: "476px" }}
              className="flex flex-col gap-1 bg-white p-4"
            >
              <h3 className="font-semibold">Sona Eren İşlemler </h3>
              <div className="flex flex-col flex-wrap gap-1 text-14 h-36 w-full mt-1">
                <p>Sona Eren işlem Bulunamadı</p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="col-span-1">
          {patientInfo?.id > 0 && (
            <AddFinancialForm
              mudehaleId={mudehaleId}
              yontemId={yontemId}
              tc={patientInfo.hasta_tc}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PatientInformation;
