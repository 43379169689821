import React, { useState } from "react";
import AddFinancialPatientInfo from "../addAccount/AddFinancialPatientInfo";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "../../../../config";

const CollectionInfo = ({
  collectionPatientInfo,
  setCollectionPatientInfo,
  tc,
  collectionData,
  setCollectionData,
}) => {
  const uRegister = useSelector((state) => state.uRegister);

  const date = new Date();
  date.setHours(date.getHours() + 3); // UTC+3 saat dilimine ayarlanır
  const thisDay = date.toISOString();

  const formattedDate = thisDay.slice(0, 10);

  const [hesapAcmaTarihi, setHesapAcmaTarihi] = useState(formattedDate);
  const [amount, setAmount] = useState(0);
  const [comment, setComment] = useState("");

  const odemeBilgileri = [
    {
      tanım: "Toplam Ücret",
      miktar: collectionData?.ciro,
    },
    {
      tanım: "Toplam Ödenen",
      miktar: collectionData?.ciro - collectionData?.alacak,
    },
    {
      tanım: "Kalan Toplam",
      miktar: collectionData?.alacak,
    },
  ];

  const handleChangeAmount = (e) => {
    if (e.target.value > collectionData?.alacak) {
      setAmount(collectionData?.alacak);
      // toast.error("Ödenecek miktar bulunmuyor!");
    } else {
      setAmount(e.target.value);
    }
  };

  const handleCreateCollection = async () => {
    try {
      await axios.post(
        `${config.API_BASE_URL}tahsilat/ekle?hasta_tc=${collectionPatientInfo?.hesap_bilgileri[0].hasta_tc}&hesap_id=${collectionPatientInfo?.hesap_bilgileri[0].id}&pol_vergi_no=${uRegister.id}&odeme_tarihi=${formattedDate}&odenen_miktar=${amount}&aciklama=${comment}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );

      toast.success("Tahsilat eklendi");
      //bilgileri yenile
      const resp = await axios.post(
        `${config.API_BASE_URL}hesap_defteri/hasta_sorgula?hasta_tc=${collectionPatientInfo?.hesap_bilgileri[0].hasta_tc}&pol_vergi_no=${uRegister.id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      setCollectionPatientInfo(resp.data);
      const response = await axios.post(
        `${config.API_BASE_URL}raporlama/hasta?pol_vergi_no=${uRegister.id}&hasta_tc=${tc}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      setCollectionData(response.data);
    } catch (error) {
      // Hata durumunda burası çalışır
      console.log(error);
      // Hata mesajını veya gerekli işlemleri burada gerçekleştirebilirsiniz
    }
  };

  return (
    <>
      <div className="py-3">
        <AddFinancialPatientInfo
          patientInfo={collectionPatientInfo.hasta_bilgileri[0]}
        />{" "}
        {/* bu modal sorgulama kısmında ki ile ortak */}
      </div>
      <div className="grid  grid-cols-3 gap-4 mt-3">
        <div className="col-span-2 bg-white p-4">
          <h1 className="font-semibold mb-3 p-2">Ödeme Bilgileri</h1>
          <div className="grid grid-cols-3 gap-6">
            {odemeBilgileri.map((item, index) => (
              <div key={index} className="bg-container p-3">
                <h2 className="font-semibold uppercase">{item.tanım}</h2>
                <p
                  className={`font-bold text-3xl ${
                    item.tanım.toLocaleLowerCase() === "toplam ücret"
                      ? "text-blue1"
                      : item.tanım.toLocaleLowerCase() === "toplam ödenen"
                      ? "text-darkTurquaz"
                      : item.tanım.toLocaleLowerCase() === "kalan toplam"
                      ? "text-pink"
                      : null
                  }`}
                >
                  {item.miktar} ₺
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-1 bg-white p-4">
          <h3 className="font-semibold mb-3 p-2">Tahsilat</h3>
          <form className="grid grid-cols-8 items-end gap-2">
            <div className="col-span-4">
              <label className="label">
                <span className="label-text">ÖDEME TARİHİ</span>
              </label>
              <input
                type="text"
                className="input input-bordered w-full max-w-xs"
                value={hesapAcmaTarihi}
                onChange={(e) => setHesapAcmaTarihi(e.target.value)}
                readOnly
              />
            </div>
            <div className="col-span-4">
              <label className="label">
                <span className="label-text">ÖDEME MİKTARI</span>
              </label>
              <input
                type="text"
                placeholder="Ödeme Miktarını Giriniz"
                className="input input-bordered w-full max-w-xs"
                value={amount}
                onChange={handleChangeAmount}
              />
            </div>
            <div className="flex flex-col">
              <div className=" ">
                <label className="label">
                  <span className="label-text">AÇIKLAMA</span>
                </label>
                <textarea
                  className="input input-bordered appearance-none rounded focus:bg-white max-w-xs"
                  id="Açıklama"
                  cols="45"
                  rows="20"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div className="mt-2">
                <button
                  type="button"
                  className="w-40 btn btn-active btn-sm bg-blue1 rounded-full hover:bg-blue1 capitalize"
                  onClick={handleCreateCollection}
                >
                  Ödemeyi Kaydet
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CollectionInfo;
