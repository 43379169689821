import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import config from "../../../../config";

const schema = yup.object().shape({
  tc: yup
    .string()
    .required("TC Kimlik Numarası zorunludur.")
    .matches(
      /^[0-9]{11}$/,
      "11 haneli geçerli bir TC Kimlik Numarası giriniz."
    ),
});
const Collection = ({
  setShowInfo,
  setCollectionPatientInfo,
  tc,
  setTc,
  setCollectionData,
}) => {
  const uRegister = useSelector((state) => state.uRegister);
  // const [tc, setTc] = useState();
  const [errors, setErrors] = useState({});

  const handleGetAppointment = async (e) => {
    e.preventDefault();
    try {
      await schema.validate({ tc }, { abortEarly: false });
      const resp = await axios.post(
        `${config.API_BASE_URL}hesap_defteri/hasta_sorgula?hasta_tc=${tc}&pol_vergi_no=${uRegister.id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      const response = await axios.post(
        `${config.API_BASE_URL}raporlama/hasta?pol_vergi_no=${uRegister.id}&hasta_tc=${tc}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      setErrors({});

      if ("hasta_bilgileri" in resp.data) {
        setCollectionPatientInfo(resp.data);
        setShowInfo(2);
      } else {
        toast.error("Bu T.C No'ya kayıtlı hasta bulunmuyor!");
      }
      if (!("hesap_bilgileri" in resp.data)) {
        toast.error("Bu T.C No'ya işlem bulunmuyor!");
        setShowInfo(2);
      }

      if (response.data.status !== 400) {
        setCollectionData(response.data);
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
        // console.log(validationErrors);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div className="grid grid-cols-1 place-items-center my-9">
      {/* TC numarası ile sorgulama 👇🏻 */}
      <form className="flex justify-between items-center w-[40rem] mb-10">
        <label className="label-text text-center mb-2 sm:mb-0 sm:mr-2">
          HASTA TC KİMLİK NO
        </label>
        <input
          type="text"
          placeholder="Hasta TC Kimlik No"
          className="input input-bordered input-sm w-full sm:max-w-xs mb-2 sm:mb-0 sm:mr-2"
          value={tc}
          onChange={(e) => {
            const inputValue = e.target.value;
            // Sadece rakam girişine izin vermek için düzenli ifade kullanılır
            const numericInput = inputValue.replace(/\D/g, "");
            // En fazla 11 haneli TC Kimlik numarasına izin ver
            if (numericInput.length <= 11) {
              setTc(numericInput);
            }
          }}
        />
        <button
          type="submit"
          className="btn btn-sm w-full sm:w-auto btn-outline btn-primary rounded-full capitalize"
          onClick={handleGetAppointment}
        >
          Sorgula
        </button>
      </form>
      {errors.tc && (
        <p className="input-error-message text-red mt-2 ml-16">{errors.tc}</p>
      )}
    </div>
  );
};

export default Collection;
