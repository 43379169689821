import React, { useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "../../../features/modalSlice";
import axios from "axios";

const ThirdStepAddAppointment = ({
  handleSubmit,
  handleSubmitBackward,
  personalInfo,
  selectedDoctor,
  setSelectedDoctor,
  date,
  clock,
  polyclinicDoctors,
  handleRecordPatient,
}) => {
  const uRegister = useSelector((state) => state.uRegister);

  const [doktor, setDoktor] = useState(
    polyclinicDoctors.filter(
      (item) => Number(item.d_diploma_no) === Number(selectedDoctor)
    )[0]["d_adi"] +
      " " +
      polyclinicDoctors.filter(
        (item) => Number(item.d_diploma_no) === Number(selectedDoctor)
      )[0]["d_soyadi"]
  );
  const [datetime, setDatetime] = useState(date + "T" + clock);

  const hanleClick = () => {
    alert("Lütfen değişiklik için önceki forma geçiniz !");
  };
  return (
    <form className="w-full flex flex-col px-10 py-2 mt-1">
      <h2 className="md:hidden">Önizleme ve Onay</h2>
      <h3 className="mt-6 font-bold">Hasta Bilgileri</h3>
      <div className="grid xs:grid-cols-2 md:grid-cols-4 gap-2">
        <div className="col-span-1">
          <label className="label">
            <span className="label-text">ADI</span>
          </label>
          <input
            type="text"
            value={personalInfo.firstName}
            onClick={hanleClick}
            readOnly
            placeholder="Hastanın Adı"
            className="input input-bordered input-sm focus:input-primary w-full max-w-xs"
          />
        </div>
        <div className="col-span-1">
          <label className="label">
            <span className="label-text">SOYADI</span>
          </label>
          <input
            type="text"
            value={personalInfo.lastName}
            onClick={hanleClick}
            readOnly
            placeholder="Hastanın Soyadı"
            className="input input-bordered input-sm focus:input-primary w-full max-w-xs"
          />
        </div>
        <div className="col-span-1">
          <label className="label">
            <span className="label-text">BABA ADI</span>
          </label>
          <input
            type="text"
            value={personalInfo.dadyName}
            onClick={hanleClick}
            readOnly
            placeholder="Hastanın baba adı"
            className="input input-bordered input-sm focus:input-primary w-full max-w-xs"
          />
        </div>
        <div className="col-span-1">
          <label className="label">
            <span className="label-text">DOĞUM TARİHİ</span>
          </label>
          <input
            type="date"
            value={personalInfo.birthDay}
            onClick={hanleClick}
            placeholder="Hastanın Doğum Tarihi"
            className="input input-bordered input-sm focus:input-primary w-full max-w-xs"
          />
        </div>
      </div>
      <h3 className="mt-6 font-bold">Randevu Bilgileri</h3>
      <div className="grid xs:grid-cols-2 xs:gap-2 md:grid-cols-3 md:gap-12">
        <div className="col-span-1">
          <label className="label">
            <span className="label-text">POLİKLİNİK</span>
          </label>
          <input
            type="text"
            readOnly
            onClick={hanleClick}
            placeholder="Poliklinik Adı"
            className="input input-bordered input-sm focus:input-primary w-full max-w-xs"
            value={uRegister.pol_adi}
          />
        </div>
        <div className="col-span-1">
          <label className="label">
            <span className="label-text">DOKTOR</span>
          </label>
          <input
            type="text"
            readOnly
            onClick={hanleClick}
            placeholder="Poliklinik Adı"
            className="input input-bordered input-sm focus:input-primary w-full max-w-xs"
            value={doktor}
          />
        </div>
        <div className="col-span-1">
          <label className="label">
            <span className="label-text">TARİH & SAAT</span>
          </label>
          <input
            type="datetime-local"
            value={datetime}
            onChange={(e) => setDatetime(e.target.value)}
            className="input input-bordered input-sm focus:input-primary w-full max-w-xs"
          />
        </div>
      </div>
      {/*footer*/}
      <div className="flex items-center justify-between mx-4 mt-4 p-2 rounded-b">
        <button
          className="bg-blue1 text-white capitalize btn btn-sm rounded-3xl hover:bg-white hover:text-blue1 hover:border-blue1"
          onClick={handleSubmitBackward}
        >
          <AiOutlineLeft className="mr-4" />
          Önceki Adım
        </button>
        <button
          className="bg-blue1 text-white capitalize btn btn-sm rounded-3xl hover:bg-white hover:text-blue1 hover:border-blue1"
          type="submit"
          onClick={handleRecordPatient}
        >
          Randevuyu Onayla
        </button>
      </div>
    </form>
  );
};

export default ThirdStepAddAppointment;
