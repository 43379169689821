import React, { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import CheckBox from "./CheckBox";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import config from "../../../config";

const SecondStepAddAppointment = ({
  handleSubmit,
  handleSubmitBackward,
  setSelectedDoctor,
  selectedDoctor,
  date,
  setDate,
  clock,
  setClock,
  saatler,
  setSaatler,
  getNoDoctors,
  polyclinicDoctors,
  setClockStep,
  setSelectedSaatler,
  selectedSaatler,
}) => {
  // console.log(selectedDoctor);
  const uRegister = useSelector((state) => state.uRegister);
  const [isChecked, setIsChecked] = useState(true);
  const initialSaatler = {
    "09:00": "",
    "09:15": "",
    "09:30": "",
    "09:45": "",
    "10:00": "",
    "10:15": "",
    "10:30": "",
    "10:45": "",
    "11:00": "",
    "11:15": "",
    "11:30": "",
    "11:45": "",
    "12:00": "",
    "12:15": "",
    "13:30": "",
    "13:45": "",
    "14:00": "",
    "14:15": "",
    "14:30": "",
    "14:45": "",
    "15:00": "",
    "15:15": "",
    "15:30": "",
    "15:45": "",
    "16:00": "",
    "16:15": "",
    "18:00": "",
    "18:15": "",
    "18:30": "",
    "18:45": "",
    "19:00": "",
    "19:15": "",
    "19:30": "",
    "19:45": "",
    "20:45": "",
  };
  const sabah = [
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
  ];
  const ogle = [
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
  ];
  const aksam = [
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
  ];

  const availableTimes_1 = [
    "09:00:00", //format :00 geldiği için başta böyle aldık
    "09:15:00",
    "09:30:00",
    "09:45:00",
    "10:00:00",
    "10:15:00",
    "10:30:00",
    "10:45:00",
    "11:00:00",
    "11:15:00",
    "11:30:00",
    "11:45:00",
    "12:00:00",
    "12:15:00",
  ];

  const availableTimes_2 = [
    "13:30:00",
    "13:45:00",
    "14:00:00",
    "14:15:00",
    "14:30:00",
    "14:45:00",
    "15:00:00",
    "15:15:00",
    "15:30:00",
    "15:45:00",
    "16:00:00",
    "16:15:00",
    "16:30:00",
    "16:45:00",
  ];
  const availableTimes_3 = [
    "18:00:00",
    "18:15:00",
    "18:30:00",
    "18:45:00",
    "19:00:00",
    "19:15:00",
    "19:30:00",
    "19:45:00",
    "20:45:00",
  ];

  const handleSubmitControl = (e) => {
    if (selectedDoctor) {
      const saat = handleListeSadelestir(); //burası çoklu yanlış saat seçimlerini sadeleştirir şimdilik burada gerek yok gibi ama ileride lazım olabileceğinden devre dışı bırakılmadı, saat seçimi buraya bağlı o yüzden önce burası işlemelei ki saat seçilmiş mi kontrol edelim
      if (saat) {
        handleSubmit(e);
      } else {
        return toast.error("Lütfen saat seçimi yapınız");
      }
    } else {
      return toast.error("Lütfen Doktor seçimi yapınız");
    }
  };

  // haftanın hangi günü olduğunu aldık
  function getLocalDayOfWeek(dateStr) {
    const date = new Date(dateStr + "T00:00:00");
    const daysOfWeek = [
      "pazar",
      "pazartesi",
      "sali",
      "carsamba",
      "persembe",
      "cuma",
      "cumartesi",
    ];
    return daysOfWeek[date.getDay()];
  }

  function updateValues(tamListe, saatlerr) {
    const updatedSaatler = { ...saatlerr };

    // const mesaiVarmi = updatedSaatler.filter(item => item)

    for (const saat in updatedSaatler) {
      if (tamListe.hasOwnProperty(saat)) {
        tamListe[saat] === 1
          ? (updatedSaatler[saat] = 2)
          : (updatedSaatler[saat] = 0); // doluysa 2 boşsa 0  2 veriyoruz çünkü yeni seçeceğimiz saatler 1 olacak , 2 ler kırmızı ve disabled olacak
      } else {
        updatedSaatler[saat] = 2; // mesaisi yoksa
      }
    }

    return updatedSaatler;
  }

  //aşağıdaki fonksiyonda oluşturulan tüm saatlerden dolu olanlarına 0 boş olanlarına 1 ekler
  const createAvailabilityObject = (doctorClocks, doktorMusaitSaatler) => {
    const result = {};

    for (let i = 0; i < doktorMusaitSaatler.length; i++) {
      result[doktorMusaitSaatler[i]] = 1;
    }

    for (let i = 0; i < doctorClocks["randevular"].length; i++) {
      const randevu_baslangic_saati =
        doctorClocks["randevular"][i]["randevu_baslangic_saati"];
      const randevu_suresi = parseInt(
        doctorClocks["randevular"][i]["randevu_suresi"]
      );
      const index = doktorMusaitSaatler.indexOf(randevu_baslangic_saati);

      if (index !== -1) {
        for (let j = 0; j < randevu_suresi; j++) {
          const saatIndex = index + j;
          if (saatIndex < doktorMusaitSaatler.length) {
            result[doktorMusaitSaatler[saatIndex]] = 0;
          }
        }
      } else {
        result[randevu_baslangic_saati] = 1;
      }
    }

    return result;
  };

  const gunListele = (cloks) => {
    const doctormusaitvakitler = []; // yeni liste oluşturuluyor
    const gun = getLocalDayOfWeek(cloks["randevu_tarih"]);
    const dilim = cloks["doktor_saatleri"]["0"][gun + "_1"];
    if (dilim === 1) {
      doctormusaitvakitler.push(...availableTimes_1); // push kullanarak saatleri yeni liste ekliyoruz
    }

    const dilim2 = cloks["doktor_saatleri"]["0"][gun + "_2"];
    if (dilim2 === 1) {
      doctormusaitvakitler.push(...availableTimes_2);
    }
    const dilim3 = cloks["doktor_saatleri"]["0"][gun + "_3"];
    if (dilim3 === 1) {
      doctormusaitvakitler.push(...availableTimes_3);
    }
    let sonuc = createAvailabilityObject(cloks, doctormusaitvakitler);
    const newObj = {};
    for (const key in sonuc) {
      newObj[key.slice(0, -3)] = sonuc[key]; //:00 dan kurtulduk
    }

    const updatedObj = {};
    for (const key in newObj) {
      updatedObj[key] = newObj[key] === 1 ? 0 : 1;
    }

    setSaatler(updateValues(updatedObj, saatler)); // bizdeki boş saatleri karşılığını gelen dolu saatlerle doldurduk
  };

  const scrollLeft = (containerId) => {
    const element = document.getElementById(containerId);
    element.scrollLeft -= 200;
  };

  const scrollRight = (containerId) => {
    const element = document.getElementById(containerId);
    element.scrollLeft += 200;
  };

  const handleListeSadelestir = () => {
    for (const saat in saatler) {
      if (saatler[saat] === 1) {
        setClock(saat);
        return saat;
      }
    }
  };

  const getDoctorClocks = async (diploma_no) => {
    const { data } = await axios.post(`${config.API_BASE_URL}randevu_al_saat`, {
      d_diploma_no: diploma_no,
      alinan_tarih: date, //date uzun tarih olabilir
    });

    if ("doktor_saatleri" in data) {
      gunListele(data);
    } else {
      // mesai saatlerri yoksa direkt tüm saatleri 2 yap
      let yeniSaatler = { ...initialSaatler };
      for (let yeniSaat in yeniSaatler) {
        yeniSaatler[yeniSaat] = 2;
      }
      setSaatler(yeniSaatler);
    }
  };

  function getMaxWeekendDate() {
    var today = new Date();
    var maxWeekend = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

    var year = maxWeekend.getFullYear();
    var month = String(maxWeekend.getMonth() + 1).padStart(2, "0");
    var day = String(maxWeekend.getDate()).padStart(2, "0");

    var maxWeekendDate = year + "-" + month + "-" + day;
    return maxWeekendDate;
  }

  return (
    <div className="my-1 p-10">
      <div className="flex xs:justify-around md:justify-end py-2 mt-5 items-center sm:overflow-hidden sm:rounded-md mb-4">
        <h1 className="md:hidden">Randevu Bilgileri</h1>
        <div className="flex xs:flex-col md:flex-row justify-between items-center gap-4 w-3/5">
          <input
            type="date"
            min={new Date().toISOString().split("T")[0]} // Bugünün tarihinden önceki tarihleri seçmeyi engeller
            max={getMaxWeekendDate()}
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
              getNoDoctors(e.target.value);
              setSelectedDoctor("");
              setClock("");
            }}
            className="input input-bordered input-sm focus:input-primary w-full max-w-xs"
          />
          <select
            className="select select-bordered select-sm focus:input-primary w-full max-w-xs"
            value={selectedDoctor}
            onChange={(e) => {
              setSelectedDoctor(e.target.value);
              getDoctorClocks(e.target.value);
            }}
          >
            <option value="" selected>
              Doktor Seçiniz
            </option>
            {polyclinicDoctors.map((item, index) => (
              <option key={index} value={item.d_diploma_no}>
                {item.d_adi} {item.d_soyadi}
              </option>
            ))}
          </select>
        </div>
      </div>
      <form className="w-full px-2">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between items-center gap-1 my-2">
            <h4 style={{ fontSize: "15px" }} className="w-30 mb-5 ">
              Öğleden Önce :
            </h4>
            <div className=" flex flex-wrap gap-x-1  w-5/6">
              {/* <span className="p-2 mb-4" onClick={() => scrollLeft("scroll-container-sabah")}>
                <BsFillArrowLeftCircleFill size={30} className="text-blue1" />
              </span> */}
              <div
                id="scroll-container-sabah"
                className="flex space-x-1  w-full overflow-x-hidden "
              >
                {sabah.map((item, index) => (
                  <div className="h-10 my-1" key={item}>
                    <CheckBox
                      saat={item}
                      saatler={saatler}
                      setSaatler={setSaatler}
                      date={date}
                      selectedDoctor={selectedDoctor}
                      setSelectedSaatler={setSelectedSaatler}
                      selectedSaatler={selectedSaatler}
                    />
                  </div>
                ))}
              </div>
              {/* <span className="p-2" onClick={() => scrollRight("scroll-container-sabah")}>
                <BsFillArrowRightCircleFill size={30} className="text-blue1" />
              </span> */}
            </div>
          </div>
          <div className="flex justify-between items-center gap-1 my-2">
            <h4 style={{ fontSize: "15px" }} className="w-30 mb-5">
              Öğleden Sonra :
            </h4>
            <div className=" flex flex-wrap gap-x-1 w-5/6">
              {/* <span className="p-2 mb-4" onClick={() => scrollLeft("scroll-container-ogle")}>
                <BsFillArrowLeftCircleFill size={30} className="text-blue1" />
              </span> */}
              <div
                id="scroll-container-ogle"
                className="flex space-x-1 w-full overflow-x-hidden "
              >
                {ogle.map((item, index) => (
                  <div className="h-10 my-1" key={index}>
                    <CheckBox
                      saat={item}
                      saatler={saatler}
                      setSaatler={setSaatler}
                      date={date}
                      selectedDoctor={selectedDoctor}
                      setSelectedSaatler={setSelectedSaatler}
                      selectedSaatler={selectedSaatler}
                    />
                  </div>
                ))}
              </div>
              {/* <span className="p-2" onClick={() => scrollRight("scroll-container-ogle")}>
                <BsFillArrowRightCircleFill size={30} className="text-blue1" />
              </span> */}
            </div>
          </div>
          <div className="flex justify-between items-center gap-1 my-2">
            <h4 style={{ fontSize: "15px" }} className="w-30 mb-5">
              Akşam :
            </h4>
            <div className=" flex flex-wrap gap-x-1 w-5/6">
              {/* <span className="p-2 mb-4" onClick={() => scrollLeft("scroll-container-aksam")}>
                <BsFillArrowLeftCircleFill size={30} className="text-blue1" />
              </span> */}
              <div
                id="scroll-container-aksam"
                className="flex h-15 space-x-1 w-full overflow-x-hidden "
              >
                {aksam.map((item, index) => (
                  <div className="h-10  my-1" key={index}>
                    <CheckBox
                      saat={item}
                      saatler={saatler}
                      setSaatler={setSaatler}
                      date={date}
                      selectedDoctor={selectedDoctor}
                      setSelectedSaatler={setSelectedSaatler}
                      selectedSaatler={selectedSaatler}
                    />
                  </div>
                ))}
              </div>
              {/* <span className="p-2" onClick={() => scrollRight("scroll-container-aksam")}>
                <BsFillArrowRightCircleFill size={30} className="text-blue1" />
              </span> */}
            </div>
          </div>
        </div>
        {/*footer*/}
        <div className="flex items-center justify-between mx-4 mt-7 rounded-b">
          <button
            className="bg-blue1 text-white capitalize btn btn-sm rounded-3xl hover:bg-white hover:text-blue1 hover:border-blue1"
            onClick={handleSubmitBackward}
          >
            <AiOutlineLeft className="mr-4" />
            Önceki Adım
          </button>
          <button
            className="bg-blue1 text-white capitalize btn btn-sm rounded-3xl hover:bg-white hover:text-blue1 hover:border-blue1"
            type="button"
            onClick={(e) => {
              handleSubmitControl(e);
            }}
          >
            Sonraki Adım
            <AiOutlineRight className="ml-4" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SecondStepAddAppointment;
