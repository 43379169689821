import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import modalSlice from "../features/modalSlice";
import openReducer from "../features/sidebarSlice";
import policlinicProfileD from "../features/policlinicProfileSlice";
import userSlice from "../features/userSlice";
import registerSlice from "../features/registerSlice";

// Persist yapılandırması
const persistConfig = {
  key: "dentrey",
  storage,
  blacklist: ["open", "toggleM", "polData"], // Persist dışı bırakmak istediğiniz reducer'ları burada belirtebilirsiniz
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    open: openReducer,
    toggleM: modalSlice,
    polData: policlinicProfileD,
    user: userSlice,
    uRegister: registerSlice,
    doctorRegister: registerSlice,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
