import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "../../../../config";

const AddFinancialForm = ({ tc, yontemId, mudehaleId }) => {
  const uRegister = useSelector((state) => state.uRegister);

  const date = new Date();
  date.setHours(date.getHours() + 3); // UTC+3 saat dilimine ayarlanır
  const thisDay = date.toISOString();

  const formattedDate = thisDay.slice(0, 10);

  const [hesapAcmaTarihi, setHesapAcmaTarihi] = useState(formattedDate);
  const [odemeSekli, setOdemeSekli] = useState("");
  const [aciklama, setAciklama] = useState("");
  const [adres, setAdres] = useState("");
  const [ucret, setUcret] = useState(0);
  const [tUcret, setTUcret] = useState(0);
  const [adet, setAdet] = useState(0);

  const handleOdemeSekliChange = (e) => {
    setOdemeSekli(e.target.value);
  };

  const handleCreateAppointment = async () => {
    try {
      if (yontemId) {
        const { data } = await axios.post(
          `${config.API_BASE_URL}hesap_defteri/olustur?hasta_tc=${tc}&pol_vergi_no=${uRegister.id}&hat=${hesapAcmaTarihi}&yontem_id=${yontemId}&id=${mudehaleId}&adet=${adet}&toplam_ucret=${tUcret}&odeme_sekli=${odemeSekli}&aciklama=${aciklama}&adres=${adres}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );
        if (data.id) {
          toast.success("Hesap başarıyla eklendi");
        } else {
          if (data.message) {
            toast.error(data.message);
          } else {
            toast.error("Hesap eklenemedi!");
          }
        }
      } else {
        toast.error("Lütfen bir işlem seçiniz!");
      }

      // Gerekli işlemleri burada gerçekleştirebilirsiniz
    } catch (error) {
      // Hata durumunda burası çalışır
      console.log(error);
      // Hata mesajını veya gerekli işlemleri burada gerçekleştirebilirsiniz
    }
  };

  useEffect(() => {
    setTUcret(adet * ucret);
  }, [adet, ucret]);

  return (
    <form
      style={{ minHeight: "476px", minWidth: "345px" }}
      className="w-full bg-white pl-8 py-8"
    >
      <div className="md:flex md:items-center mb-4  gap-2">
        <div className="md:w-1/3">
          <label
            className="md:text-right mb-1 md:mb-0 text-14"
            htmlFor="Hesap Açma Tarihi"
          >
            Hesap Açma Tarihi
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="input input-bordered input-sm appearance-none rounded  focus:bg-white"
            type="text"
            id="Hesap Açma Tarihi"
            value={hesapAcmaTarihi}
            onChange={(e) => setHesapAcmaTarihi(e.target.value)}
            readOnly
          />
        </div>
      </div>
      <div className="md:flex md:items-center mb-4  gap-2">
        <div className="md:w-1/3">
          <label className="md:text-right mb-1 md:mb-0 text-14" htmlFor="Adet">
            Adet
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="input input-bordered input-sm appearance-none rounded  focus:bg-white"
            type="text"
            id="Adet"
            value={adet}
            onChange={(e) => setAdet(e.target.value)}
          />
        </div>
      </div>
      <div className="md:flex md:items-center mb-4  gap-2">
        <div className="md:w-1/3">
          <label className="md:text-right mb-1 md:mb-0 text-14" htmlFor="Ücret">
            Ücret
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="input input-bordered input-sm appearance-none rounded  focus:bg-white"
            type="text"
            id="Ücret"
            value={ucret}
            onChange={(e) => setUcret(e.target.value)}
          />
        </div>
      </div>
      <div className="md:flex md:items-center mb-4  gap-2">
        <div className="md:w-1/3">
          <label
            className="md:text-right mb-1 md:mb-0 text-14"
            htmlFor="Toplam"
          >
            Toplam
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="input input-bordered input-sm appearance-none rounded  focus:bg-white"
            type="text"
            id="Toplam"
            value={tUcret}
            onChange={(e) => setTUcret(e.target.value)}
          />
        </div>
      </div>
      <div className="md:flex md:items-center mb-4  gap-2">
        <div className="md:w-1/3">
          <label
            className="md:text-right mb-1 md:mb-0 text-14"
            htmlFor="Ödeme Şekli"
          >
            Ödeme Şekli
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            className="w-[11.5rem] input input-bordered input-sm appearance-none rounded focus:bg-white"
            id="Ödeme Şekli"
            value={odemeSekli}
            onChange={handleOdemeSekliChange}
          >
            <option value="">Seçiniz</option>
            <option value="0">Peşin</option>
            <option value="1">Taksitli</option>
          </select>
        </div>
      </div>
      <div className="md:flex md:items-center mb-4  gap-2">
        <div className="md:w-1/3">
          <label className="md:text-right mb-1 md:mb-0 text-14" htmlFor="Adres">
            Adres
          </label>
        </div>
        <div className="md:w-2/3">
          <textarea
            className="input input-bordered appearance-none rounded focus:bg-white max-w-xs"
            id="Adres"
            cols="18"
            rows="20"
            value={adres}
            onChange={(e) => setAdres(e.target.value)}
          />
        </div>
      </div>
      <div className="md:flex md:items-center mb-4 gap-2">
        <div className="md:w-1/3">
          <label
            className="md:text-right mb-1 md:mb-0 text-14"
            htmlFor="Açıklama"
          >
            Açıklama
          </label>
        </div>
        <div className="md:w-2/3">
          <textarea
            className="input input-bordered appearance-none rounded focus:bg-white max-w-xs"
            id="Açıklama"
            cols="18"
            rows="20"
            value={aciklama}
            onChange={(e) => setAciklama(e.target.value)}
          />
        </div>
      </div>
      <div className="md:flex md:items-center md:justify-between mr-4">
        <div className=""></div>
        <div className="">
          <button
            className="shadow bg-blue1 text-white hover:bg-blue2 focus:shadow-outline focus:outline-none py-1 px-4 rounded-full"
            type="button"
            onClick={handleCreateAppointment}
          >
            Kaydet
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddFinancialForm;
