import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { ilData } from "../../../helpers/ilData";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Controller,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import InciDıs from "../../../assets/InciDis.png";
import axios from "axios";
import { useSelector } from "react-redux";
import config from "../../../config";

const PolyclinicForRecord = ({
  date,
  thisDay,
  place,
  setPlace,
  setShowOzet,
  setInfo,
  setShowComplaintNote,
  setPolyclinic,
  polyclinic,
  setPolyclinicDoctors,
  polyclinicDoctors,
  setShowDate,
  setShowClock,
  setShowDoctors,
  handleScrollToTopClick,
  showDoctors,
  selectedPolyclinics,
}) => {
  const uRegister = useSelector((state) => state.uRegister);
  const [openTab, setOpenTab] = useState(0);
  const [swiper, setSwiper] = useState();
  const [polyclinics, setPolyclinics] = useState();
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  useEffect(() => {
    if (selectedPolyclinics?.id > 0) {
      setPolyclinics([selectedPolyclinics]);
      setPlace({
        ...place,
        city: selectedPolyclinics.pol_il,
        town: selectedPolyclinics.pol_ilce,
      });
    }
  }, [selectedPolyclinics]);

  /// izinli doktorları gettir ve mevcur listeden çıklar ///
  // DEFAULT OLARAK BU GÜNÜN DOKTORLARINI GETİRİP İZİNLİLERİ ELEYELİM
  // 2-İZİNLİ DOKTORLARI TÜM DOKTOR LİSTESİNDEN ELE
  function getDiplomaListForDate(date, izinListesi) {
    const tarih = new Date(date); //buradaki tarih için slice(1,10) kullanma!
    const diplomaListesi = [];

    for (const izin of izinListesi) {
      const izinBaslangicTarihi = new Date(izin.izin_baslangic_tarihi);
      const izinBitisTarihi = new Date(izin.izin_bitis_tarihi);

      if (tarih >= izinBaslangicTarihi && tarih <= izinBitisTarihi) {
        diplomaListesi.push(izin.d_diploma_no);
      }
    }

    // izinlileri çıkaralım
    if (izinListesi.length > 0) {
      setPolyclinicDoctors((polyclinicDoctors) =>
        polyclinicDoctors.filter(
          (doctor) => !diplomaListesi.includes(Number(doctor.d_diploma_no))
        )
      );
    }

    setShowDoctors(true); //doktorlar kısnmı görünür olacak
    // setNoDoctor(diplomaListesi);
  }

  // 1-İZİNLİ DOKTORLARI GETİR
  const getNoDoctors = async (dDate, polydata) => {
    setShowClock(false);
    const { data } = await axios.post(
      `${config.API_BASE_URL}izinleriGetir`,
      {
        pol_vergi_no: polydata["0"]["pol_vergi_no"],
        alinan_tarih: dDate.lengt > 10 ? dDate.slice(0, 10) : dDate,
      }
    );
    if (data.status === 400) {
      getDiplomaListForDate(dDate, []);
    } else {
      getDiplomaListForDate(dDate, data);
    }
  };

  /// izinli doktorları gettir ve mevcur listeden çıklar ** SON ///

  // POLİKLİNİK TÜM  DOKTORLARINI GETİR
  const getPolyclinicDoctors = async (poli) => {
    const { data } = await axios.get(
      `${config.API_BASE_URL}poliklinikteki_doktorlar?pol_vergi_no=${poli.pol_vergi_no}`
    );
    if (data !== "false") {
      setPolyclinicDoctors(data); // doktorları aldık
      getNoDoctors(thisDay, data); //izinli doktorları elemek için
      setShowDate(true); //recordpatient sayfasında tarih görünür olacak
      handleScrollToTopClick("doktor"); // doktor kartlarına scroll
    }
  };

  const handleChangeIl = async (e) => {
    setPlace({ ...place, [e.target.name]: e.target.value }); // ilçe kısmı için lazım
    const { data } = await axios.get(
      `${config.API_BASE_URL}il_klinikleri?pol_il=${e.target.value}`
    );

    if (data !== "false") {
      setPolyclinics(data);
      setInfo("İlçe seçiniz");
      setPolyclinicDoctors("");
    }
  };

  const handleChange = async (e) => {
    try {
      setPlace({ ...place, [e.target.name]: e.target.value });
      const { data } = await axios.get(
        `${config.API_BASE_URL}ilce_klinikleri?pol_il=${place.city}&pol_ilce=${e.target.value}`,
        {}
      );
      // console.log(data);

      if (data !== "false") {
        setPolyclinics(data);
        setInfo("Poliklinik seçiniz");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("polyclinicDoctors", polyclinicDoctors);
  return (
    <section className="">
      <article className="flex justify-between  px-1 w-full mx-auto">
        <h1>Poliklinik</h1>
        <article className="flex gap-3">
          <select
            name="city"
            id="city"
            className="select select-sm w-52 transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
            value={place.city}
            onChange={handleChangeIl}
          >
            <option value="">İl Seçiniz</option>
            {ilData.map((il, i) => (
              <option value={il["text"]} key={i}>
                {il["text"]}
              </option>
            ))}
          </select>
          <select
            name="town"
            id="town"
            className="select select-sm w-52 transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
            value={place.town}
            onChange={handleChange}
          >
            <option value="">İlçe Seçiniz</option>
            {ilData
              .filter((data) => data["text"] === place.city)
              .map((ilce) =>
                ilce["districts"].map((e, i) => (
                  <option value={e["text"]} key={i}>
                    {e["text"]}
                  </option>
                ))
              )}
          </select>
          <button
            className="bg-white w-10 h-10 flex items-center justify-center rounded-full"
            style={{ marginTop: "-4px" }}
            ref={prevRef}
          >
            <HiOutlineArrowLeft />
          </button>
          <button
            className="bg-white w-10 h-10 flex items-center justify-center rounded-full"
            style={{ marginTop: "-4px" }}
            ref={nextRef}
          >
            <HiOutlineArrowRight />
          </button>
        </article>
      </article>
      <div className="my-10 mx-auto">
        <Swiper
          className=""
          spaceBetween={15}
          // slidesPerView="auto"
          slidesPerView={4}
          slidesPerGroup={2}
          onSwiper={setSwiper}
          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
            Controller,
            Autoplay,
          ]}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          autoplay={false}
          loop={false}
          // updateOnWindowResize
          // observer
          // observeParents
        >
          {polyclinics && Array.isArray(polyclinics) ? (
            polyclinics.map((item, index) => (
              <SwiperSlide
                key={index}
                className={` ${
                  openTab === index
                    ? "border-2 rounded-2xl border-blue1 p-2"
                    : "bg-transparent"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setShowOzet(false);
                  setShowComplaintNote(false);
                  setShowClock(false);
                  setInfo("Tarih seçiniz");
                  setOpenTab(index);
                  setPolyclinic(item);
                  setShowDoctors(false);
                  getPolyclinicDoctors(item);
                }}
              >
                <div className="card card-compact bg-base-100 cursor-pointer h-56 pt-2">
                  <figure>
                    <img
                      // src={item["pol_logo"] ? item["pol_logo"] : InciDıs }
                      src={item.pol_logo}
                      className="h-32"
                      alt="InciDıs"
                    />
                  </figure>
                  <div className="border-b opacity-80 w-[96%] my-1 mx-auto"></div>
                  <div className="card-body">
                    <h2 className="card-title text-blue1 font-normal line-clamp-2">
                      {item["pol_adi"]}
                    </h2>
                  </div>
                </div>
              </SwiperSlide>
            ))
          ) : (
            <h1
              className={`font-light ${
                typeof polyclinics === "undefined" && "hidden"
              }`}
            >
              Gönderdiğiniz il-ilçeye kayıtlı poliklinik bulunamadı.
            </h1>
          )}
        </Swiper>
        {polyclinicDoctors?.status === 400 && (
          <p className="mt-5 font-bold rounded-lg text-white p-2 bg-[#5616f5]">
            Seçilen poliklinikde uygun doktor bulunamadı!
          </p>
        )}
      </div>
    </section>
  );
};

export default PolyclinicForRecord;
