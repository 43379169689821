import React, { useState } from "react";
import DateRangePickerComp from "../../appointments/DateRangePickerComp";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import * as yup from "yup";
import config from "../../../../config";

const schema = yup.object().shape({
  tc: yup
    .string()
    .required("TC Kimlik Numarası zorunludur.")
    .matches(
      /^[0-9]{11}$/,
      "11 haneli geçerli bir TC Kimlik Numarası giriniz."
    ),
});

const Report = ({
  setShowInfo,
  setReportPatientInfo,
  setReportTableData,
  setReportTableDataGeneral,
  range,
  setRange,
  handleGetAppointmentGeneral,
}) => {
  const uRegister = useSelector((state) => state.uRegister);

  const [tapOpen, setTapOpen] = useState(1);
  const [tc, setTc] = useState();
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowInfo(3);
  };

  const handleGetAppointment = async (e) => {
    e.preventDefault();
    try {
      //önce POLİKLİNİK TÜM DOKTORLARINI GETİR
      await schema.validate({ tc }, { abortEarly: false });
      const resp = await axios.post(
        `${config.API_BASE_URL}hesap_defteri/hasta_sorgula?hasta_tc=${tc}&pol_vergi_no=${uRegister.id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );

      if ("hasta_bilgileri" in resp.data) {
        setReportPatientInfo(resp.data);
        const response = await axios.post(
          `${config.API_BASE_URL}raporlama/hasta?pol_vergi_no=${uRegister.id}&hasta_tc=${tc}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );
        setReportTableData(response.data);
        setShowInfo(3);
      } else {
        return toast.success("Bu T.C No'ya kayıtlı hasta bulunmuyor.");
      }
      if (!("hesap_bilgileri" in resp.data)) {
        toast.error("Bu T.C No'ya işlem bulunmuyor!");
        setShowInfo(3);
        setReportTableData([]);
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <div className="tabs p-2">
        <p
          className={` ${
            tapOpen === 1
              ? "tab border-b-2 border-blue1 text-blue1 "
              : "tab text-black"
          }`}
          onClick={() => setTapOpen(1)}
        >
          Kimik Numarası ile Sorula
        </p>
        <p
          className={` ${
            tapOpen === 2
              ? "tab border-b-2 border-blue1 text-blue1 "
              : "tab text-black"
          }`}
          onClick={() => {
            setTapOpen(2);
            setShowInfo(4);
          }}
        >
          Genel Rapor
        </p>
        <p
          className={` ${
            tapOpen === 3
              ? "tab border-b-2 border-blue1 text-blue1 "
              : "tab text-black"
          }`}
          onClick={() => setTapOpen(3)}
        >
          Tarih ile Sorgula
        </p>
      </div>
      {tapOpen === 1 && (
        <div className="grid grid-cols-1 place-items-center my-9">
          {/* TC numarası ile sorgulama 👇🏻 */}
          <form className="flex justify-between items-center w-[40rem] mb-10">
            <label className="label-text text-center mb-2 sm:mb-0 sm:mr-2">
              HASTA TC KİMLİK NO
            </label>
            {/* <div className="flex flex-col sm:flex-row items-center justify-start"> */}
            <input
              type="text"
              placeholder="Hasta TC Kimlik No"
              className="input input-bordered input-sm w-full sm:max-w-xs mb-2 sm:mb-0 sm:mr-2"
              value={tc}
              onChange={(e) => {
                const inputValue = e.target.value;
                // Sadece rakam girişine izin vermek için düzenli ifade kullanılır
                const numericInput = inputValue.replace(/\D/g, "");
                // En fazla 11 haneli TC Kimlik numarasına izin ver
                if (numericInput.length <= 11) {
                  setTc(numericInput);
                }
              }}
            />
            <button
              type="submit"
              className="btn btn-sm w-full sm:w-auto btn-outline btn-primary rounded-full capitalize"
              onClick={handleGetAppointment}
            >
              Sorgula
            </button>
            {/* </div> */}
          </form>
          {errors.tc && (
            <p className="input-error-message text-red mt-2 ml-16">
              {errors.tc}
            </p>
          )}
        </div>
      )}
      {tapOpen === 2 && (
        <div className="grid grid-cols-1 place-items-center my-9">
          {/* TC numarası ile sorgulama 👇🏻
                <form
                    
                    className="flex justify-between items-center w-[40rem]"
                >
                    <label className="label-text text-center">HASTA TC KİMLİK NO</label>
                    <input
                        type="text"
                        placeholder="Hasta TC Kimlik No"
                        className="input input-bordered input-sm w-full max-w-xs"
                        value={tc}
                        onChange={(e) => setTc(e.target.value)}
                    />
                    <button
                        type='button'
                        className="btn btn-sm w-28 btn-outline btn-primary rounded-full capitalize"
                        onClick={handleGetAppointmentGeneral}
                        >
                        Sorgula
                    </button>
                </form> */}
        </div>
      )}
      {tapOpen === 3 && (
        <div className="grid grid-cols-1 place-items-center my-9">
          <form
            onSubmit={handleSubmit}
            className="flex justify-center items-center w-[30rem] gap-2 mb-10"
          >
            <label
              htmlFor="my-modal"
              className="btn btn-sm btn-outline btn-primary rounded-full w-36 mr-4 capitalize"
            >
              Tarih Seç
            </label>

            {/* Put this part before </body> tag */}
            <input type="checkbox" id="my-modal" className="modal-toggle" />
            <div className="modal">
              <div className="modal-box w-11/12 max-w-5xl">
                <div className="flex justify-center">
                  <DateRangePickerComp range={range} setRange={setRange} />
                </div>
                <div className="modal-action">
                  <label
                    htmlFor="my-modal"
                    className="btn btn-sm bg-blue1 hover:bg-blue2 "
                  >
                    Vazgeç
                  </label>
                  <label
                    htmlFor="my-modal"
                    className="btn btn-sm bg-blue1 hover:bg-blue2 "
                  >
                    Tamam
                  </label>
                </div>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-sm w-36 btn-outline btn-primary rounded-full capitalize self-end"
              onClick={handleGetAppointmentGeneral}
            >
              Sorgula
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Report;
