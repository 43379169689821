import React, { useState } from "react";
import { useSelector } from "react-redux";
import ClinicHeader from "../../../components/polyclinic/polyclinicDashboard/ClinicHeader";
import Layout from "../../../components/polyclinic/layout/Layout";
import AddFinancialAccount from "../../../components/polyclinic/accountFinancial/addAccount/AddFinancialAccount";
import PatientInformation from "../../../components/polyclinic/accountFinancial/addAccount/PatientInformation";
import Collection from "../../../components/polyclinic/accountFinancial/collection/Collection";
import CollectionInfo from "../../../components/polyclinic/accountFinancial/collection/CollectionInfo";
import Report from "../../../components/polyclinic/accountFinancial/report/Report";
import ReportInfo from "../../../components/polyclinic/accountFinancial/report/ReportInfo";
import ReportInfoGeneral from "../../../components/polyclinic/accountFinancial/report/ReportInfoGeneral";
import ReportInfoGeneralDate from "../../../components/polyclinic/accountFinancial/report/ReportInfoGeneralDate";
import { addDays, format } from "date-fns";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../../config";

const Account = () => {
  const open = useSelector((state) => state.open.value);
  const [collectionPatientInfo, setCollectionPatientInfo] = useState([]);
  const [collectionData, setCollectionData] = useState();
  const [reportPatientInfo, setReportPatientInfo] = useState("");
  const [reportTableData, setReportTableData] = useState("");
  const [reportTableDataGeneralDate, setReportTableDataGeneralDate] =
    useState(""); // tarihe göre sırala için
  const [showInfo, setShowInfo] = useState(0);
  const [hesap, setHesap] = useState(1);
  const [tc, setTc] = useState(); //collection kısmı için

  const uRegister = useSelector((state) => state.uRegister);

  const handleClick = (a) => {
    setHesap(a);
    setShowInfo(0);
  };

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handleGetAppointmentGeneral = async () => {
    try {
      setShowInfo(0);
      const formattedStartDate = format(range[0].startDate, "yyyy-MM-dd");
      const formattedEndDate = format(range[0].endDate, "yyyy-MM-dd");

      const resp = await axios.post(
        `${config.API_BASE_URL}raporlama/tarih?pol_vergi_no=${uRegister.id}&tarih_1=${formattedStartDate}&tarih_2=${formattedEndDate}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      if (
        resp.status !== 200 ||
        resp.data?.tabloBilgileri?.hesap_getir?.length === 0
      ) {
        setReportTableDataGeneralDate(resp.data);
        setShowInfo(5); // genel rapor tarih componentini aç
        // console.log(resp.data);
      } else {
        toast.error("Seçili tarihlerde bu polikliniğe ait rapor bulunmuyor!");
      }
    } catch (error) {
      // Hata durumunda burası çalışır
      console.log(error);
      // Hata mesajını veya gerekli işlemleri burada gerçekleştirebilirsiniz
    }
  };
  return (
    <>
      <Layout />

      <div
        className={` bg-container absolute  mt-20 right-0 p-6 min-h-[calc(100vh-80px)] ${
          open ? "w-[calc(100%-250px)]" : "w-[calc(100%-100px)]"
        } transition-w ease-in-out duration-500`}
      >
        <div className="">
          <div className="divide-y p-1 bg-container">
            <ClinicHeader />
            <div className="bg-white">
              <div className="flex">
                <h1
                  className={`text-xl ${
                    hesap === 1 && "font-semibold bg-blue2 text-white"
                  } p-2 my-4 ml-4 w-48 text-center cursor-pointer`}
                  onClick={() => handleClick(1)}
                >
                  Hesap Oluştur
                </h1>
                <h1
                  className={`text-xl ${
                    hesap === 2 && "font-semibold bg-blue2 text-white"
                  } p-2 my-4 mr-4 w-48 text-center cursor-pointer`}
                  onClick={() => handleClick(2)}
                >
                  {" "}
                  Tahsilat
                </h1>
                <h1
                  className={`text-xl ${
                    hesap === 3 && "font-semibold bg-blue2 text-white"
                  } p-2 my-4 mr-4 w-48 text-center cursor-pointer`}
                  onClick={() => handleClick(3)}
                >
                  {" "}
                  Rapor
                </h1>
              </div>
              {hesap === 1 && <AddFinancialAccount setShowInfo={setShowInfo} />}
              {hesap === 2 && (
                <Collection
                  setCollectionData={setCollectionData}
                  tc={tc}
                  setTc={setTc}
                  setShowInfo={setShowInfo}
                  collectionPatientInfo={collectionPatientInfo}
                  setCollectionPatientInfo={setCollectionPatientInfo}
                />
              )}
              {hesap === 3 && (
                <Report
                  handleGetAppointmentGeneral={handleGetAppointmentGeneral}
                  range={range}
                  setRange={setRange}
                  setShowInfo={setShowInfo}
                  setReportPatientInfo={setReportPatientInfo}
                  setReportTableData={setReportTableData}
                />
              )}
            </div>
          </div>

          {showInfo === 1 && <PatientInformation />}
          {showInfo === 2 && (
            <CollectionInfo
              setCollectionData={setCollectionData}
              collectionData={collectionData}
              tc={tc}
              collectionPatientInfo={collectionPatientInfo}
              setCollectionPatientInfo={setCollectionPatientInfo}
            />
          )}
          {showInfo === 3 && (
            <ReportInfo
              reportPatientInfo={reportPatientInfo}
              reportTableData={reportTableData}
            />
          )}
          {showInfo === 4 && <ReportInfoGeneral />}
          {showInfo === 5 && (
            <ReportInfoGeneralDate
              reportTableDataGeneralDate={reportTableDataGeneralDate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Account;
