import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { modalTogglePatient } from "../../features/modalSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { ilData } from "../../helpers/ilData";
import * as yup from "yup";
import config from "../../config";

const Schema = yup.object().shape({
  hasta_tc: yup
    .string()
    .length(11, "Tc alanı tam olarak 11 karakter olmalıdır")
    .required("Tc alanı zorunludur"),
  hasta_adi: yup.string().required("Ad alanı zorunludur."),
  hasta_soyadi: yup.string().required("Soyad alanı zorunludur."),
  hasta_baba_adi: yup.string().required("Baba adı alanı zorunludur."),
  hasta_dogum_tarihi: yup.string(),
  hasta_tel: yup
    .string()
    .matches(
      /^0\(\d{3}\) \d{3} \d{2} \d{2}$/,
      "Geçerli bir telefon numarası formatı değil. Arada boşluk olmadan 11 haneli telefon numarasını giriniz."
    ) // Telefon numarası formatını doğrulamak için regex
    .required("Telefon numarası zorunludur"),
  hasta_mail: yup.string().email("Geçerli bir e-posta adresi giriniz."),
  hasta_il: yup.string(),
  hasta_ilce: yup.string(),
  cinsiyet: yup.string(),
});
const formatPhoneNumber = (phoneNumber) => {
  // Telefon numarasını uygun formata getirme
  phoneNumber = phoneNumber.replace(/\D/g, ""); // Sadece sayısal karakterleri tutar
  const match = phoneNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  // console.log("match=>", match);
  // console.log("phoneNumber=>", phoneNumber);
  if (match) {
    return `0(${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
  }

  return phoneNumber; // Eğer format uygun değilse, inputa girilen değeri olduğu gibi geri döndürür
};
const formatTcNumber = (inputTc) => {
  inputTc = inputTc.replace(/\D/g, "").slice(0, 11); // Sadece rakam karakterlerini tutar
  const match = inputTc.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `${match[1]}${match[2]}${match[3]}${match[4]}${match[5]}`;
  }
  return inputTc; // Eğer format uygun değilse, inputa girilen değeri olduğu gibi geri döndürür
};
const AddPatientPopUp = () => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState("");

  const [personalYup, setPersonalYup] = useState({
    hasta_tc: "",
    hasta_adi: "",
    hasta_soyadi: "",
    hasta_baba_adi: "",
    hasta_dogum_tarihi: "",
    hasta_tel: "",
    hasta_mail: "",
    hasta_il: "",
    hasta_ilce: "",
    cinsiyet: "",
  });
  const [showErrorMessage, setShowErrorMessage] = useState(" ");

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const formattedYesterday = yesterday.toISOString().split("T")[0]; // ISO formatına dönüş ve sadece tarih kısmının alınması

  const handleChange = (e) => {
    const { name, value } = e.target;
    const onlyLetters = /^[A-Za-zğüşıöçĞÜŞİÖÇ\s]*$/;

    if (
      (name === "hasta_adi" ||
        name === "hasta_soyadi" ||
        name === "hasta_baba_adi") &&
      !onlyLetters.test(value)
    ) {
      // Eğer sadece harfler içermiyorsa, değeri güncelleme
      return;
    }

    let updatedInfo = { ...personalYup, [name]: value };

    // İl değiştiğinde ilçe seçeneklerini güncelle
    if (name === "hasta_il") {
      updatedInfo.hasta_ilce = ""; // İl değiştiğinde ilçe seçeneğini sıfırla
    }
    if (name === "hasta_tel") {
      let inputVal = e.target.value;
      // Sadece rakam karakterlerine izin verin
      // updatedInfo.phone = inputVal.replace(/\D/g, "");
      inputVal = inputVal.slice(0, 11); // Maksimum 11 karaktere sınırlama

      updatedInfo.hasta_tel = formatPhoneNumber(inputVal);
    }

    // TC kimlik numarası için kontrol
    else if (name === "hasta_tc") {
      const formattedTc = formatTcNumber(value);
      updatedInfo.hasta_tc = formattedTc;
    }
    setPersonalYup(updatedInfo);
    // setPersonalYup({ ...personalYup, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setErrors({});
    try {
      await Schema.validate(personalYup, { abortEarly: false });
      const data = await axios.post(`${config.API_BASE_URL}hasta_uye`, {
        hasta_tc: personalYup.hasta_tc,
        hasta_adi: personalYup.hasta_adi,
        hasta_soyadi: personalYup.hasta_soyadi,
        hasta_baba_adi: personalYup.hasta_baba_adi,
        hasta_il: personalYup.hasta_il,
        hasta_ilce: personalYup.hasta_ilce,
        hasta_tel: personalYup.hasta_tel
          .replace("(", "")
          .replace(")", "")
          .replaceAll(" ", ""),
        hasta_mail: personalYup.hasta_mail,
        hasta_dogum_tarihi: personalYup.hasta_dogum_tarihi,
        cinsiyet: personalYup.cinsiyet,
      });
      // console.log(data);
      if (data !== "400") {
        toast.success("Bilgiler kaydedildi");
      }
      closeModal();
    } catch (error) {
      // console.log(error);
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        // console.log(validationErrors);
        setErrors(validationErrors);
      } else if (error.response.data.message) {
        setShowErrorMessage(
          "Tc Kimlik Numarası ve/veya e-mail adresi daha önce kullanılmış. Kontrol edip tekrar deneyin."
        );
        setErrors({});
      }
    }
  };

  const initializeState = () => {
    setPersonalYup({
      hasta_tc: "",
      hasta_adi: "",
      hasta_soyadi: "",
      hasta_baba_adi: "",
      hasta_dogum_tarihi: "",
      hasta_tel: "",
      hasta_mail: "",
      hasta_il: "",
      hasta_ilce: "",
      cinsiyet: "",
    });
    setErrors({});
  };

  const closeModal = () => {
    initializeState();
    dispatch(modalTogglePatient(false));
  };

  return (
    <>
      <div
        style={{ zIndex: "9999999999", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        className="flex justify-start items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative mx-auto my-auto max-w-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none xs:w-[80vw] md:w-[65rem] ml-[4rem]">
            <div className="bg-blue1 rounded-t">
              <div className="flex items-center justify-between py-2">
                <h3 className="ml-4 text-white font-semibold">
                  Yeni Hasta Kaydet
                </h3>
                <button
                  className="text-xl mr-5 text-white"
                  onClick={closeModal}
                >
                  <span className="text-3xl text-white">×</span>
                </button>
              </div>
            </div>
            <form className="w-full px-6 py-6">
              <div className="grid xs:grid-cols-2 md:grid-cols-4 gap-2">
                <div className="col-span-1">
                  <label className="label">
                    <span className="label-text">TC. NO</span>
                  </label>
                  <input
                    type="text"
                    name="hasta_tc"
                    value={personalYup.hasta_tc}
                    onChange={handleChange}
                    required
                    placeholder=""
                    className={`input input-bordered input-sm focus:input-primary w-full max-w-xs ${
                      errors.hasta_tc ? "input-error" : ""
                    }`}
                  />
                  {errors.hasta_tc && (
                    <p className="input-error-message text-red-500">
                      {errors.hasta_tc}
                    </p>
                  )}
                </div>
                <div className="col-span-1">
                  <label className="label">
                    <span className="label-text">ADI</span>
                  </label>
                  <input
                    type="text"
                    name="hasta_adi"
                    value={personalYup.hasta_adi}
                    onChange={handleChange}
                    required
                    placeholder=""
                    className={`input input-bordered input-sm focus:input-primary w-full max-w-xs ${
                      errors.hasta_adi ? "input-error" : ""
                    }`}
                  />
                  {errors.hasta_adi && (
                    <p className="input-error-message text-red-500">
                      {errors.hasta_adi}
                    </p>
                  )}
                </div>
                <div className="col-span-1">
                  <label className="label">
                    <span className="label-text">SOYADI</span>
                  </label>
                  <input
                    type="text"
                    name="hasta_soyadi"
                    value={personalYup.hasta_soyadi}
                    onChange={handleChange}
                    required
                    placeholder=""
                    className={`input input-bordered input-sm focus:input-primary w-full max-w-xs ${
                      errors.hasta_soyadi ? "input-error" : ""
                    }`}
                  />
                  {errors.hasta_soyadi && (
                    <p className="input-error-message text-red-500">
                      {errors.hasta_soyadi}
                    </p>
                  )}
                </div>
                <div className="col-span-1">
                  <label className="label">
                    <span className="label-text">BABA ADI</span>
                  </label>
                  <input
                    type="text"
                    name="hasta_baba_adi"
                    value={personalYup.hasta_baba_adi}
                    onChange={handleChange}
                    required
                    placeholder=""
                    className={`input input-bordered input-sm focus:input-primary w-full max-w-xs ${
                      errors.hasta_baba_adi ? "input-error" : ""
                    }`}
                  />
                  {errors.hasta_baba_adi && (
                    <p className="input-error-message text-red-500">
                      {errors.hasta_baba_adi}
                    </p>
                  )}
                </div>
                <div className="col-span-1">
                  <label className="label">
                    <span className="label-text">DOĞUM TARİHİ</span>
                  </label>
                  <input
                    type="date"
                    name="hasta_dogum_tarihi"
                    value={personalYup.hasta_dogum_tarihi}
                    onChange={handleChange}
                    // required
                    placeholder=""
                    min="1940-01-01" // Alt sınır
                    max={formattedYesterday} // Üst sınır
                    className={`input input-bordered input-sm focus:input-primary w-full max-w-xs ${
                      errors.hasta_dogum_tarihi ? "input-error" : ""
                    }`}
                  />
                  {errors.hasta_dogum_tarihi && (
                    <p className="input-error-message text-red-500">
                      {errors.hasta_dogum_tarihi}
                    </p>
                  )}
                </div>
                <div className="col-span-1">
                  <label className="label">
                    <span className="label-text">CİNSİYET</span>
                  </label>
                  <select
                    name="cinsiyet"
                    value={personalYup?.cinsiyet}
                    onChange={handleChange}
                    // required
                    className={`select select-bordered select-sm text-14 font-normal focus:border-blue1 w-full ${
                      errors.cinsiyet ? "input-error" : ""
                    }`}
                  >
                    <option value="" disabled>
                      Seçiniz
                    </option>
                    <option value="K">Kadın</option>
                    <option value="E">Erkek</option>
                    <option value="B">Belirtmek İstemiyorum</option>
                  </select>
                  {errors.cinsiyet && (
                    <p className="input-error-message text-red-500">
                      {errors.cinsiyet}
                    </p>
                  )}
                </div>
                <div className="col-span-1">
                  <label className="label">
                    <span className="label-text">TELEFON</span>
                  </label>
                  <input
                    type="text"
                    name="hasta_tel"
                    value={personalYup.hasta_tel || "0"}
                    pattern="[0-9]{10,11}"
                    maxLength="11"
                    onChange={handleChange}
                    required
                    placeholder="0(555) 666 33 66"
                    className={`input input-bordered input-sm focus:input-primary w-full max-w-xs ${
                      errors.hasta_tel ? "input-error" : ""
                    }`}
                  />
                  {errors.hasta_tel && (
                    <p className="input-error-message text-red-500">
                      {errors.hasta_tel}
                    </p>
                  )}
                </div>
                <div className="col-span-1">
                  <label className="label">
                    <span className="label-text">EMAIL</span>
                  </label>
                  <input
                    type="email"
                    name="hasta_mail"
                    value={personalYup.hasta_mail}
                    onChange={handleChange}
                    // required
                    placeholder="example@example.com"
                    className={`input input-bordered input-sm focus:input-primary w-full max-w-xs ${
                      errors.hasta_mail ? "input-error" : ""
                    }`}
                  />
                  {errors.hasta_mail && (
                    <p className="input-error-message text-red-500">
                      {errors.hasta_mail}
                    </p>
                  )}
                </div>
                <div className="col-span-1">
                  <label className="label">
                    <span className="label-text">İL</span>
                  </label>
                  <select
                    name="hasta_il"
                    value={personalYup.hasta_il}
                    onChange={handleChange}
                    // required
                    className={`select select-bordered select-sm text-14 font-normal focus:border-blue1 w-full ${
                      errors.hasta_il ? "input-error" : ""
                    }`}
                  >
                    <option value="" disabled>
                      İl Seçiniz
                    </option>
                    {ilData.map((il, index) => (
                      <option key={index} value={il.text}>
                        {il.text}
                      </option>
                    ))}
                  </select>
                  {errors?.hasta_il && (
                    <p className="input-error-message text-red-500">
                      {errors?.hasta_il}
                    </p>
                  )}
                </div>
                <div className="col-span-1">
                  <label className="label">
                    <span className="label-text">İLÇE</span>
                  </label>
                  <select
                    name="hasta_ilce"
                    value={personalYup?.hasta_ilce}
                    onChange={handleChange}
                    // required
                    className={`select select-bordered select-sm text-14 font-normal focus:border-blue1 w-full ${
                      errors.hasta_ilce ? "input-error" : ""
                    }`}
                  >
                    <option value disabled="">
                      İlçe Seçiniz
                    </option>
                    {ilData
                      .filter((data) => data["text"] === personalYup?.hasta_il)
                      .map((ilce) =>
                        ilce["districts"].map((e, i) => (
                          <option value={e["text"]} key={i}>
                            {e["text"]}
                          </option>
                        ))
                      )}
                  </select>
                  {errors?.hasta_ilce && (
                    <p className="input-error-message text-red-500">
                      {errors?.hasta_ilce}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex justify-between mt-6">
                {showErrorMessage && (
                  <h4 className="mt-3 text-red">{showErrorMessage}</h4>
                )}
                <div>
                  <button
                    type="button"
                    className="btn btn-default mr-4"
                    onClick={closeModal}
                  >
                    İptal
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddPatientPopUp;
