import React, { useState } from "react";
import NavBar from "../../components/dashboard/NavBar";
import Footer from "../../components/dashboard/Footer";
import RegisterModal from "../../components/dashboard/RegisterModal";
import LoginModal from "../../components/dashboard/LoginModal";
import ForgotPassword from "../../components/dashboard/ForgotPassword";
import AppointmentCard from "../../components/dashboard/queryAppointment/AppointmentCard";
import AppointmentDate2 from "../../components/dashboard/recordPatient/AppointmentDate2";
import CitiySelect from "../../components/dashboard/queryAppointment/CitiySelect";
import axios from "axios";
import AddPatientPopUp from "../../components/dashboard/AddPatientPopUp";
import { useDispatch, useSelector } from "react-redux";
import { modalTogglePatient } from "../../features/modalSlice";
import { toast } from "react-toastify";
import * as yup from "yup";
import config from "../../config";

const schema = yup.object().shape({
  queryTC: yup
    .string()
    .required("TC Kimlik Numarası zorunludur.")
    .matches(
      /^[0-9]{11}$/,
      "11 haneli rakamlardan oluşan Geçerli bir TC Kimlik Numarası giriniz."
    ),
});

const date1 = new Date();
const thisDay = date1.toISOString();

const QueryAppointment = () => {
  const uRegister = useSelector((state) => state.uRegister);
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showInfos, setShowInfos] = useState(true);
  const [queryTC, setQueryTC] = useState("");
  const [queryPasaport, setQueryPasaport] = useState("");
  const [noQuery, setNoQuery] = useState(false);
  const [patientInfo, setPatientInfo] = useState();
  const [patientInfoOriginal, setPatientInfoOriginal] = useState();
  const [patient, setPatient] = useState();
  const [date, setDate] = useState(thisDay);
  const [place, setPlace] = useState({ city: "", town: "" });
  const [errors, setErrors] = useState({});

  const { toggleModalPatient } = useSelector((state) => state.toggleM);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPatientInfo([]);
    // randevu bilgileri
    try {
      setNoQuery(false);
      await schema.validate({ queryTC }, { abortEarly: false });
      const resp = await axios.post(
        `${config.API_BASE_URL}hasta_uye/sorgu`,
        {
          //hasta bilgileri
          hasta_tc: queryTC,
        }
      );
      setErrors({});
      if (resp.data.status === 400) {
        toast.error("TC kimlik numarası üzerine kayıt bulunamadı!");
        setNoQuery(true); // kayıt yoksa uyarı için
        setPatient();
      } else {
        setPatient(resp?.data[0]);

        const { data } = await axios.post(
          `${config.API_BASE_URL}randevu_sorgula`,
          {
            hasta_tc: queryTC,
          }
        );

        if (data[0]["id"]) {
          //  tarih büyükten küçüğe saatler küçükten büyüğe oldu
          const sortedAppointments = Object.values(data).sort((a, b) => {
            const dateA = new Date(a.randevu_tarih);
            const timeA = new Date(`1970-01-01T${b.randevu_baslangic_saati}`);
            const dateTimeA = new Date(dateA.getTime() + timeA.getTime());

            const dateB = new Date(b.randevu_tarih);
            const timeB = new Date(`1970-01-01T${a.randevu_baslangic_saati}`);
            const dateTimeB = new Date(dateB.getTime() + timeB.getTime());

            return dateTimeB - dateTimeA;
          });

          setPatientInfo(sortedAppointments);
          setPatientInfoOriginal(sortedAppointments);
          setShowInfos(true);
        } else {
          toast.error("Hasta Kaydı Bulunamadı!");
          setPatientInfo([]);
          setShowInfos(false);
          setNoQuery(true);
        }
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
        // console.log(validationErrors);
      } else {
        console.error(error);
        // toast.error("Bir hata oluştu");
      }
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      const { data } = await axios.post(
        `${config.API_BASE_URL}randevu_sorgula`,
        {
          hasta_tc: queryTC,
        }
      );

      const resp = await axios.post(
        `${config.API_BASE_URL}hasta_uye/sorgu`,
        {
          hasta_tc: queryTC,
        }
      );

      if (!("id" in resp.data[0])) {
        toast.error("Hasta Bulunamadı!");
      }
      setPatient(resp.data[0]);

      if (data) {
        //  tarih büyükten küçüğe saatler küçükten büyüğe oldu
        const sortedAppointments = Object.values(data).sort((a, b) => {
          const dateA = new Date(a.randevu_tarih);
          const timeA = new Date(`1970-01-01T${b.randevu_baslangic_saati}`);
          const dateTimeA = new Date(dateA.getTime() + timeA.getTime());
          const dateB = new Date(b.randevu_tarih);
          const timeB = new Date(`1970-01-01T${a.randevu_baslangic_saati}`);
          const dateTimeB = new Date(dateB.getTime() + timeB.getTime());
          return dateTimeB - dateTimeA;
        });

        setPatientInfo(sortedAppointments);
        setPatient("");
        setPatientInfoOriginal(sortedAppointments);
        setShowInfos(true);
      }
    }
  };
  // console.log("patientInfo", patientInfo);
  return (
    <div className="h-full relative pt-10 ">
      <NavBar setShowLogin={setShowLogin} />
      <div className="container m-auto w-4/5 h-full">
        <section style={{ paddingTop: "60px" }} className="pb-20 min-h-screen">
          <div className="flex justify-between w-full ">
            <h1 style={{ marginTop: "30px" }} className="text-3xl text-blue1">
              Randevu Sorgula
            </h1>
            <form
              onSubmit={handleSubmit}
              className="form-control relative w-1/4 mt-4"
              style={{ marginTop: "20px", minWidth: "300px" }}
            >
              {/* TC İle Sorgula */}
              {/* <div className="flex"> */}
              <input
                type="text"
                placeholder="TC Kimlik Numaranız"
                required
                value={queryTC}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Sadece rakam girişine izin vermek için düzenli ifade kullanılır
                  const numericInput = inputValue.replace(/\D/g, "");
                  // En fazla 11 haneli TC Kimlik numarasına izin ver
                  if (numericInput.length <= 11) {
                    setQueryTC(numericInput);
                  }
                }}
                className="input rounded-3xl w-full tracking-widest mr-2"
              />
              <button
                type="submit"
                className="btn btn-ghost btn-circle absolute right-[0]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
              {errors.queryTC && (
                <p className="input-error-message text-red mt-2">
                  {errors.queryTC}
                </p>
              )}
              {/* Pasaport İle Sorgula */}
              {/* <input
                  type="text"
                  placeholder="Pasaport Numaranız"
                  required
                  value={queryPasaport}
                  onChange={(e) => setQueryPasaport(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="input rounded-3xl w-full tracking-widest"
                />
                <button className="btn btn-ghost btn-circle absolute right-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
                {errors.queryTC && (
                  <p className="input-error-message text-red mt-2">
                    {errors.queryTC}
                  </p>
                )} */}
              {/* </div> */}
            </form>
          </div>

          {/* divider */}
          <div className="border-b mb-4 text-blue1"></div>

          {showInfos && (
            <section className="mx-auto">
              {/* silinecek bölüm */}
              {/* <InfoPatientQuery patientInfo={patientInfo} /> */}
              {patient ? (
                <div className="flex justify-between my-8 mx-2">
                  <p>
                    {" "}
                    Sayın
                    <span className="font-bold">
                      {" "}
                      {patient?.hasta_adi} {patient?.hasta_soyadi}&nbsp;
                    </span>
                    {patientInfo?.length} adet randevu kaydınız bulunmaktadır.
                    Randevu tarihinizi değiştirmek yada iptal etmek için lütfen
                    kliniğiniz ile iletişime geçiniz.{" "}
                    <span className="font-bold">{`(${patientInfo[0]?.klinik.pol_adi} Polikliniği Tel: ${patientInfo[0]?.klinik.pol_tel})`}</span>
                  </p>
                </div>
              ) : (
                <div className="flex justify-center ">
                  {noQuery ? (
                    <div>
                      <p className="capitalize w-[30rem] p-5 rounded-[39px] shadow-lg mb-4 text-center">
                        Lütfen Tc Kimlik numaranızı kontrol ederek tekrar
                        deneyin!
                        <h6 className="mt-5">Yada</h6>
                        <button
                          style={{
                            textTransform: "none",
                            boxShadow: "0 0 30px -12px #5616f5",
                          }}
                          className="h-10 w-28 p-[10px 30px] mt-5 text-14 tracking-[.5px] font-bold bg-blue1 text-white items-center border-2 border-white rounded-full hover:bg-white hover:text-blue1 hover:border-blue1"
                          type="button"
                          onClick={() => dispatch(modalTogglePatient())}
                        >
                          Kaydolun
                        </button>
                      </p>
                    </div>
                  ) : (
                    <p className="capitalize w-[30rem] p-2 rounded-[39px] shadow-lg mb-4 text-center">
                      Lütfen Tc Kimlik numaranızı giriniz
                    </p>
                  )}
                </div>
              )}

              {/* <div>
                <AppointmentDate2
                  patientInfo={patientInfo}
                  setPatientInfo={setPatientInfo}
                  patientInfoOriginal={patientInfoOriginal}
                  setDate={setDate}
                  date={date}
                />
              </div> */}
              {/* <div className=" mt-3 mb-6 flex justify-end">
                <CitiySelect setPlace={setPlace} place={place} />
              </div> */}
              {patient && (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
                  {patientInfo?.map((appointment, index) => (
                    <AppointmentCard
                      appointment={appointment}
                      patient={patient}
                      key={appointment.d_diploma_no}
                    />
                  ))}
                </div>
              )}
            </section>
          )}
        </section>
      </div>
      <Footer />
      {showRegister ? (
        <RegisterModal
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
        />
      ) : null}
      {showLogin ? (
        <LoginModal
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}
      {showForgotPassword ? (
        <ForgotPassword
          setShowLogin={setShowLogin}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}
      {toggleModalPatient && <AddPatientPopUp />}{" "}
      {/* Yeni hasta kaydetme modalını çaşırır */}
    </div>
  );
};

export default QueryAppointment;
