import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/dashboard/Footer";
import ForgotPassword from "../../components/dashboard/ForgotPassword";
import LoginModal from "../../components/dashboard/LoginModal";
import NavBar from "../../components/dashboard/NavBar";
import AppointmentDate from "../../components/dashboard/recordPatient/AppointmentDate";
import AppointmentClock from "../../components/dashboard/recordPatient/AppointmentClock";
import DoctorsForRecord from "../../components/dashboard/recordPatient/DoctorsForRecord";
import InfoOfPatient from "../../components/dashboard/recordPatient/InfoOfPatient";
import InfoPatientForm from "../../components/dashboard/recordPatient/InfoPatientForm";
import PolyclinicForRecord from "../../components/dashboard/recordPatient/PolyclinicForRecord";
import RegisterModal from "../../components/dashboard/RegisterModal";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { modalTogglePatient } from "../../features/modalSlice";
import AddPatientPopUp from "../../components/dashboard/AddPatientPopUp";
import config from "../../config";


const date = new Date();
date.setHours(date.getHours() + 3); // UTC+3 saat dilimine ayarlanır
const thisDay = date.toISOString();

const RecordPatient = () => {
  // const klinikRef = useRef(null);
  const ozetRef = useRef(null);

  // function scrollTop(ref) {
  //     if (ref && ref.current) {
  //         ref.current.scrollTop = 0;
  //     }
  // }

  // Redux

  const { toggleModalPatient } = useSelector((state) => state.toggleM);
  const dispatch = useDispatch();

  const initialInfo = {
    //* bu state ler kayıtlı olmayan hastaların bilgilerinin kayıt edilmesi için de tutulan state ler
    TC: "",
    firstName: "",
    lastName: "",
    dadyName: "",
    birthDay: "",
    phone: "",
    email: "",
    city: "",
    town: "",
    gender: "",
  };
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showInfos, setShowInfos] = useState(true);
  const [showInfoOfPatient, setShowInfoOfPatient] = useState(false);
  const [showInfoOfPatientForm, setShowInfoOfPatientForm] = useState(false);
  const [queryTC, setQueryTC] = useState();
  const [patientInfo, setPatientInfo] = useState(initialInfo);
  const [polyclinicDoctors, setPolyclinicDoctors] = useState([]);
  const [polyclinic, setPolyclinic] = useState();
  const [doctor, setDoctor] = useState();
  const [noDoctor, setNoDoctor] = useState(); //izinli doktorlar
  const [doctorClocks, setDoctorClocks] = useState();
  const [showClock, setShowClock] = useState(false);
  const [clock, setClock] = useState("");
  const [date, setDate] = useState(thisDay);
  const [place, setPlace] = useState({ city: "", town: "" });
  const [showClinic, setShowClinic] = useState(false); // poliklinik alanı
  const [showDate, setShowDate] = useState(false); // tarih seçme alanı
  const [showOnayButton, setShowOnayButton] = useState(false);
  const [showYeniRandevu, setShowYeniRandevu] = useState(false); // tarih seçme alanı
  const [showDoctors, setShowDoctors] = useState(false); // doktorlar  alanı
  const [showComplaintNote, setShowComplaintNote] = useState(false); // şikayet notu alanı  alanı
  const [showOzet, setShowOzet] = useState(false); // özet  alanı
  const [complaintNote, setComplaintNote] = useState("Belirtilmedi"); //hasta şikayet notu
  const [randevuSuresi, setRandevuSuresi] = useState(1); // doktorun randevu süre dilimi her  1 = 15 dk
  const [info, setInfo] = useState("TC Kimlik no giriniz");
  const [textAreaValue, setTextAreaValue] = useState(false);

  const handleRandevuOnay = async () => {
    try {
      const { data } = await axios.post(
        `${config.API_BASE_URL}randevu_kaydet`,
        {
          pol_vergi_no: polyclinic.pol_vergi_no,
          d_diploma_no: doctor.d_diploma_no,
          hasta_tc: patientInfo.TC,
          randevu_tarih: date.length > 0 ? date.slice(0, 10) : date,
          randevu_baslangic_saati: clock,
          sikayet_notu: complaintNote,
          randevu_suresi: randevuSuresi,
          randevu_turu: 0,
        }
      );

      if (data.id) {
        toast.success("Randvunuz Oluşturuldu.");
        setInfo("İl ve İlçe seçiniz");
        setShowClinic(false);
        setShowInfos(false);
        setShowDoctors(false);
        setShowInfoOfPatient(true);
        setShowInfoOfPatientForm(false);
        setDate(thisDay);
        handleScrollToTopClick("patient-form");
        setDoctorClocks("");
        setShowClock(false);
        setComplaintNote("Belirtilmedi");
        setShowComplaintNote(false);
        setShowDate(false);
        setShowOnayButton(false);
        setShowYeniRandevu(false);
      }
    } catch (error) {
      toast.error("Randevu Oluşturulamadı");
    }
  };

  function scrollToTarget(element) {
    // element'in sayfadaki pozisyonunu hesaplayın
    const yCoordinate =
      element.getBoundingClientRect().top + window.pageYOffset - 200;
    // istenen scroll position'a smooth bir şekilde kaydırın
    window.scrollTo({
      top: yCoordinate,
      behavior: "smooth",
    });
  }

  function handleScrollToTopClick(id) {
    const element = document.querySelector("#" + id);
    scrollToTarget(element);
  }

  // function scrollTop() {
  //   window.scrollTo(0, 0);
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowClinic(false);
    setPatientInfo(initialInfo); //infoyu temizleyelim
    const { data } = await axios.post(
      `${config.API_BASE_URL}hasta_uye/sorgu`,
      {
        hasta_tc: queryTC,
      }
    );

    if (data.status !== 400) {
      const { hasta_adi, hasta_soyadi, hasta_tc, hasta_tel } = data[0];

      setPatientInfo({
        TC: hasta_tc,
        firstName: hasta_adi,
        lastName: hasta_soyadi,
        dadyName: "",
        birthDay: "",
        phone: hasta_tel,
        email: "",
        city: "",
        town: "",
        gender: "",
      });

      setInfo("İl ve İlçe seçiniz");
      setShowClinic(true);
      setShowInfos(false);
      setShowDoctors(false);
      setShowDate(false);
      setShowOzet(false);
      setShowInfoOfPatient(true);
      setShowInfoOfPatientForm(false);
      setDate(thisDay);
      handleScrollToTopClick("patient-form");
    } else {
      setShowClinic(false);
      setShowDate(false);
      setShowDoctors(false);
      setShowOzet(false);
      setShowInfoOfPatient(false);
      // setShowInfoOfPatientForm(true);
      dispatch(modalTogglePatient());
    }
  };
  const handleYeniRandevuSubmit = async (e) => {
    e.preventDefault();
    setPatientInfo(initialInfo); //infoyu temizleyelim
    const { data } = await axios.post(
      `${config.API_BASE_URL}hasta_uye/sorgu`,
      {
        hasta_tc: queryTC,
      }
    );

    if (data !== "false") {
      const { hasta_adi, hasta_soyadi, hasta_tc, hasta_tel } = data[0];

      setPatientInfo({
        TC: hasta_tc,
        firstName: hasta_adi,
        lastName: hasta_soyadi,
        dadyName: "",
        birthDay: "",
        phone: hasta_tel,
        email: "",
        city: "",
        town: "",
        gender: "",
      });
      // toast.error("Formdaki alanlar boş bırakılamaz!")
      setInfo("İl ve İlçe seçiniz");
      setShowClinic(true);
      setShowInfos(false);
      setShowDoctors(false);
      setShowDate(false);
      setShowOzet(false);
      setShowInfoOfPatient(true);
      setShowInfoOfPatientForm(false);
      setDate(thisDay);
      handleScrollToTopClick("patient-form");
      setPlace({ city: "", town: "" });
      setTextAreaValue("");
    } else {
      setShowClinic(false);
      setShowDate(false);
      setShowDoctors(false);
      setShowOzet(false);
      setShowInfoOfPatient(false);
      setShowInfoOfPatientForm(true);
    }
  };

  const handleKeyDown = async (event) => {
    // setPlace({ city: "", town: "" });

    if (event.key === "Enter") {
      setPatientInfo(initialInfo); //infoyu temizleyelim
      const { data } = await axios.post(
        `${config.API_BASE_URL}hasta_uye/sorgu`,
        {
          hasta_tc: queryTC,
        }
      );

      if (data !== "false") {
        const { hasta_adi, hasta_soyadi, hasta_tc, hasta_tel } = data[0];

        setPatientInfo({
          TC: hasta_tc,
          firstName: hasta_adi,
          lastName: hasta_soyadi,
          dadyName: "",
          birthDay: "",
          phone: hasta_tel,
          email: "",
          city: "",
          town: "",
          gender: "",
        });
        // toast.error("Formdaki alanlar boş bırakılamaz!")
        setInfo("İl ve İlçe seçiniz");
        setShowClinic(true);
        setShowInfos(false);
        setShowDoctors(false);
        setShowDate(false);
        setShowOzet(false);
        setShowInfoOfPatient(true);
        setShowInfoOfPatientForm(false);
        setDate(thisDay);
        handleScrollToTopClick("patient-form");
        setPlace({ city: "", town: "" });
      } else {
        setShowClinic(false);
        setShowDate(false);
        setShowDoctors(false);
        setShowOzet(false);
        setShowInfoOfPatient(false);
        setShowInfoOfPatientForm(true);
        setPlace({ city: "", town: "" });
      }
    }
  };

  useEffect(() => {
    // console.log("setClock", setClock);
  }, []);

  return (
    <div className="h-full">
      <NavBar setShowLogin={setShowLogin} />
      <div className="spinner"></div>
      <div className="container w-4/5 m-auto">
        <div className="pb-40 m-auto min-h-screen">
          <section className="pt-24">
            {showInfos ? (
              <div className="flex items-center justify-center p-1 text-2xl mb-1">
                <h1 className=" rounded-[39px] bg-slate-100 shadow-lg shadow-cyan-500/50 p-2 px-3 text-center">
                  Lütfen randevu almak için TC kimlik numaranız ile kaydınızı
                  kontrol ediniz. Üye değilseniz açılan sayfadaki bilgileri
                  doldurarak üye olunuz.
                </h1>
              </div>
            ) : null}

            <div className="flex justify-between  w-full">
              <h1 style={{ marginTop: "30px" }} className="text-3xl text-blue1">
                Randevu Al
              </h1>
              <form
                onSubmit={handleSubmit}
                className="form-control relative w-1/4 mt-4 xs:hidden md:block"
                style={{ marginTop: "20px" }}
              >
                <input
                  type="text"
                  placeholder="TC Kimlik Numaranız."
                  required
                  value={queryTC}
                  // onChange={(e) => setQueryTC(e.target.value)}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Sadece rakam girişine izin vermek için düzenli ifade kullanılır
                    const numericInput = inputValue.replace(/\D/g, "");
                    // En fazla 11 haneli TC Kimlik numarasına izin ver
                    if (numericInput.length <= 11) {
                      setQueryTC(numericInput);
                    }
                  }}
                  onKeyDown={handleKeyDown}
                  className="input rounded-3xl w-full tracking-widest"
                  style={{ minWidth: "200px" }}
                />
                <button
                  type="submit"
                  onClick={() => setPlace({ city: "", town: "" })}
                  className="btn btn-ghost btn-circle absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </form>
            </div>
            {/* divider */}
            <div className="border-b mb-4 text-blue1"></div>

            {showInfoOfPatient && <InfoOfPatient patientInfo={patientInfo} />}
            <div id="patient-form"></div>
            {showInfoOfPatientForm && (
              <InfoPatientForm
                queryTC={queryTC}
                setQueryTC={setQueryTC}
                setPersonalInfo={setPatientInfo}
                personalInfo={patientInfo}
                setShowInfoOfPatientForm={setShowInfoOfPatientForm}
                setShowInfoOfPatient={setShowInfoOfPatient}
              />
            )}
          </section>
          <section>
            <h2 className="mx-auto px-1 my-4 text-22">Randevu Bilgileri:</h2>
            {showClinic && (
              <PolyclinicForRecord
                setPlace={setPlace}
                place={place}
                setPolyclinic={setPolyclinic}
                polyclinic={polyclinic}
                setShowDate={setShowDate}
                setPolyclinicDoctors={setPolyclinicDoctors}
                polyclinicDoctors={polyclinicDoctors}
                setShowDoctors={setShowDoctors}
                showDoctors={showDoctors}
                handleScrollToTopClick={handleScrollToTopClick}
                setShowClock={setShowClock}
                setShowOzet={setShowOzet}
                setInfo={setInfo}
                setShowComplaintNote={setShowComplaintNote}
                thisDay={thisDay}
                date={date}
              />
            )}

            {showDate && (
              <AppointmentDate
                setClock={setClock}
                setShowDoctors={setShowDoctors}
                setDate={setDate}
                date={date}
                polyclinic={polyclinic}
                polyclinicDoctors={polyclinicDoctors} // izinlileri elemek için güncelleyeceğiz
                setPolyclinicDoctors={setPolyclinicDoctors} // izinlileri elemek için güncelleyeceğiz
                doctor={doctor}
                setInfo={setInfo}
                setNoDoctor={setNoDoctor}
                setShowClock={setShowClock}
              />
            )}
            <div id="doktor"></div>
            {showDoctors && (
              <>
                <h1>Doktorlarımız</h1>
                <DoctorsForRecord
                  setDoctor={setDoctor}
                  setDoctorClocks={setDoctorClocks}
                  setShowOzet={setShowOzet}
                  setShowClock={setShowClock}
                  polyclinic={polyclinic}
                  polyclinicDoctors={polyclinicDoctors}
                  handleScrollToTopClick={handleScrollToTopClick}
                  date={date}
                  setInfo={setInfo}
                  setClock={setClock}
                />
              </>
            )}
            <div id="saatler"></div>
            {showClock && (
              <AppointmentClock
                setClock={setClock}
                setShowComplaintNote={setShowComplaintNote}
                date={date}
                doctor={doctor}
                doctorClocks={doctorClocks}
                setShowOnayButton={setShowOnayButton}
                setShowYeniRandevu={setShowYeniRandevu}
              />
            )}
          </section>
          <section>
            {showComplaintNote && (
              <textarea
                className="w-full h-20 resize-none rounded-md p-2"
                placeholder="Şikayetleriniz için not ekleyin."
                value={complaintNote}
                onChange={(e) => {
                  setComplaintNote(e.target.value);
                  setInfo(
                    "Randevu özetinizi kontrol edip,randevunuzu onaylayabilirsiniz."
                  );
                  setTextAreaValue(e.target.value);
                }}
              ></textarea>
            )}
          </section>
          {showOzet && (
            <section className="w-full mx-auto px-1 py-5 my-2">
              <article className="mx-auto my-8">
                <article>
                  <h2 ref={ozetRef} className="mb-4 text-22">
                    Özet:
                  </h2>
                  <h5>
                    Sayın{" "}
                    <strong>
                      {patientInfo?.firstName !== undefined
                        ? patientInfo?.firstName
                        : "..."}{" "}
                      {patientInfo?.lastName !== undefined
                        ? patientInfo?.lastName
                        : "..."}
                    </strong>{" "}
                    Randevu özetiniz aşağıdadır.
                  </h5>
                  <table className="table table-auto w-full z-0 my-3">
                    <thead className="text-blue1 border-b">
                      <tr>
                        <th className="text-[16px] bg-[#dedde7]">POLİKLİNİK</th>
                        <th className="text-[16px] bg-[#dedde7]">DOKTOR</th>
                        <th className="text-[16px] bg-[#dedde7]">
                          TARİH / SAAT
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b border-[#e9e9e9] leading-4">
                        <td>
                          <div className="">
                            <h6 className="font-semibold">
                              {polyclinic?.pol_adi}
                            </h6>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <h6 className="font-semibold">
                              {doctor?.d_unvan}: &nbsp; {doctor?.d_adi}{" "}
                              {doctor?.d_soyadi}
                            </h6>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <h6 className="font-semibold">
                              {date.slice(8, 10)}.{date.slice(5, 7)}.
                              {date.slice(0, 4)} / {clock}
                            </h6>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">
                          <div className="">
                            <h6 className="font-semibold">
                              Şikayet: {textAreaValue}
                            </h6>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </article>
                {showOnayButton ? (
                  <div>
                    <button
                      className="bg-blue1 text-white capitalize btn btn-sm rounded-3xl hover:bg-blue1"
                      onClick={handleRandevuOnay}
                    >
                      Randevuyu Onayla
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className="bg-blue1 text-white capitalize btn btn-sm rounded-3xl hover:bg-blue1 mt-2"
                      disabled={showOnayButton ? false : true}
                      onClick={handleYeniRandevuSubmit}
                    >
                      Yeni Randevu
                    </button>
                  </div>
                )}
              </article>
            </section>
          )}

          {showRegister ? (
            <RegisterModal
              setShowLogin={setShowLogin}
              setShowRegister={setShowRegister}
            />
          ) : null}
          {showLogin ? (
            <LoginModal
              setShowLogin={setShowLogin}
              setShowRegister={setShowRegister}
              setShowForgotPassword={setShowForgotPassword}
            />
          ) : null}
          {showForgotPassword ? (
            <ForgotPassword
              setShowLogin={setShowLogin}
              setShowForgotPassword={setShowForgotPassword}
            />
          ) : null}
        </div>
      </div>
      <Footer />
      <div className="fixed bottom-0 right-0 bg-blue1 text-white w-200 h-120 p-4 flex items-center justify-between">
        <span className="flex-1">{info}</span>
        {/* <img src="https://w7.pngwing.com/pngs/488/261/png-transparent-female-doctor-3d-illustration-physician-nursing-health-care-hospital-the-doctor-miscellaneous-image-file-formats-service-thumbnail.png" alt="resim" className="w-8 h-8"/> */}
      </div>
      {toggleModalPatient && <AddPatientPopUp />}{" "}
      {/* Yeni hasta kaydetme modalını çaşırır */}
    </div>
  );
};

export default RecordPatient;
