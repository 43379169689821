import React from "react";
import { useState } from "react";
import * as yup from "yup";

const schema = yup.object().shape({
  adi: yup.string().required("Ad alanı zorunludur."),
  soyadi: yup.string().required("Soyad alanı zorunludur."),
  babaAdi: yup.string().required("Baba adı alanı zorunludur."),
});

const QueryWithPersonalInfo = () => {
  const [patiensInfo, setPatiensInfo] = useState([]);
  const [errors, setErrors] = useState({});
  const [adi, setAdi] = useState();
  const [soyadi, setSoyadi] = useState();
  const [babaAdi, setBabaAdi] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // setErrors({});
    try {
      await schema.validate({ adi, soyadi, babaAdi }, { abortEarly: false });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
        // console.log(validationErrors);
      } else {
        console.error(error);
        setErrors({});
      }
    }
  };
  return (
    <div>
      <div className="grid grid-cols-1 place-items-center my-9">
        <form onSubmit={handleSubmit} className="flex flex-col w-[30rem] gap-2">
          <div className="grid grid-cols-[auto,1fr] gap-5 items-center">
            <label className="label-text">ADI</label>
            <input
              type="text"
              value={adi}
              onChange={(e) => setAdi(e.target.value)}
              placeholder="Ad giriniz"
              className="input input-bordered w-full max-w-xs ml-9"
            />
            {errors.adi && (
              <p className="input-error-message text-red">{errors.adi}</p>
            )}
          </div>
          <div className="grid grid-cols-[auto,1fr] gap-5 items-center">
            <label className="label-text">SOYADI</label>
            <input
              type="text"
              value={soyadi}
              onChange={(e) => setSoyadi(e.target.value)}
              placeholder="Soyad giriniz"
              className="input input-bordered w-full max-w-xs ml-3"
            />
            {errors.soyadi && (
              <p className="input-error-message text-red">{errors.soyadi}</p>
            )}
          </div>
          <div className="grid grid-cols-[auto,1fr] gap-5 items-center">
            <label className="label-text">BABA ADI</label>
            <input
              type="text"
              value={babaAdi}
              onChange={(e) => setBabaAdi(e.target.value)}
              placeholder="Baba adı giriniz"
              className="input input-bordered w-full max-w-xs"
            />
            {errors.babaAdi && (
              <p className="input-error-message text-red">{errors.babaAdi}</p>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-sm w-36 btn-outline btn-primary rounded-full capitalize self-end"
          >
            Sorgula
          </button>
        </form>
      </div>
      <div className="overflow-x-auto ">
        <table className="table w-full ">
          {/* <!-- head --> */}
          <thead className="text-gray">
            <tr>
              <th className="pl-20">DOKTOR</th>
              <th>HASTA</th>
              <th>TARİH</th>
              <th>İŞLEM TÜRÜ</th>
              <th>İŞLEM ÇEŞİDİ</th>
            </tr>
          </thead>
          <tbody>
            {patiensInfo.length > 0 &&
              patiensInfo[0].transaction?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="flex items-center space-x-3 gap-3">
                      <div className="avatar">
                        <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
                          <img src={item.doctorAvatar} alt="Doktor Avatar" />
                        </div>
                      </div>
                      <div className="font-bold">
                        {item.doctorFirstName} {item.doctorLastName}
                      </div>
                    </div>
                  </td>
                  <td>
                    {patiensInfo[0].firstName} {patiensInfo[0].lastName}
                  </td>
                  <td>{item.operationDate}</td>
                  <td>{item.transactionType}</td>
                  <td>{item.operationType}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QueryWithPersonalInfo;
