import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DoctorProfile from "../pages/doctor/profile/DoctorProfile";
// import Appointments from "../components/polyclinic/Appointments";
import Appointments from "../pages/doctor/appointments/Appointments";
import Auth from "../components/auth";
import PatientDetail from "../pages/doctor/patients/PatientDetail";
import DoctorSettings from "../pages/doctor/settings/DoctorSettings";
import PolyclinicProfile from "../pages/polyclinic/profile/PolyclinicProfile";
import Polyclinics from "../pages/dashboard/Polyclinics";
import PolyclinicAppointments from "../pages/polyclinic/appointments/PolyclinicAppointments";
import PolyclinicDoctors from "../pages/polyclinic/doctors/PolyclinicDoctors";
import Patients from "../pages/polyclinic/patients/Patients";
import Operations from "../pages/polyclinic/operations/Operations";
import Rontgen from "../pages/polyclinic/rontgen/Rontgen";
import Account from "../pages/polyclinic/account/Account";
import Settings from "../pages/polyclinic/settings/Settings";
import YardimPage from "../pages/dashboard/navPages/yardimPage";

const checkUserType = (currentUserType, allowedTypes) => {
  return allowedTypes.includes(currentUserType);
};

const PrivateRoute = ({ kullanici_turu, allowedTypes, ...props }) => {
  const currentUserType = useSelector((state) => state.user.kullanici_turu);

  if (!checkUserType(currentUserType, allowedTypes)) {
    // Kullanıcı türüne uygun değilse farklı bir sayfaya yönlendir
    return <Navigate to="/" replace={true} />;
  } else if (props.path === "/doktorlar") {
    return (
      <Auth>
        <DoctorProfile />
      </Auth>
    );
  } else if (props.path === "/doktorlar/randevular") {
    return (
      <Auth>
        <Appointments />
      </Auth>
    );
  } else if (props.path === "/doktorlar/hastalarım") {
    return (
      <Auth>
        <PatientDetail />
      </Auth>
    );
  } else if (props.path === "/doktorlar/ayarlar") {
    return (
      <Auth>
        <DoctorSettings />
      </Auth>
    );
  } else if (props.path === "/poliklinikler") {
    return <Polyclinics />;
  } else if (props.path === "/poliklinik") {
    return (
      <Auth>
        <PolyclinicProfile />
      </Auth>
    );
  } else if (props.path === "/poliklinik/randevular") {
    return (
      <Auth>
        <PolyclinicAppointments />
      </Auth>
    );
  } else if (props.path === "/poliklinik/doktorlarimiz") {
    return (
      <Auth>
        <PolyclinicDoctors />
      </Auth>
    );
  } else if (props.path === "/poliklinik/hastalarımız") {
    return (
      <Auth>
        <Patients />
      </Auth>
    );
  } else if (props.path === "/poliklinik/rontgen") {
    return (
      <Auth>
        <Rontgen />
      </Auth>
    );
  } else if (props.path === "/poliklinik/islemlerimiz") {
    return (
      <Auth>
        <Operations />
      </Auth>
    );
  } else if (props.path === "/poliklinik/hesabim") {
    return (
      <Auth>
        <Account />
      </Auth>
    );
  } else if (props.path === "/poliklinik/profilim") {
    return (
      <Auth>
        <Settings />
      </Auth>
    );
  } else if (props.path === "/poliklinik/ayarlar") {
    return (
      <Auth>
        <Settings />
      </Auth>
    );
  } else if (props.path === "/poliklinik/yardim") {
    return (
      <Auth>
        <YardimPage />
      </Auth>
    );
  }
};

export default PrivateRoute;
