import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../../config";

const DoctorTreatments = ({ pol, dipNo, tedaviBasliklar }) => {
  const [openTreatment, setOpenTreatment] = useState(1);
  const [treatmentName, setTreatmentName] = useState();
  const uRegister = useSelector((state) => state.uRegister);

  const [doctorTreatment, setDoctorTreatment] = useState([
    {
      id: 1,
      müdahaleKategori: "Cerrahi",
      müdahaleIstatistik: [
        { müdahaleAdı: "Diş Çekimi", müdahaleSayı: 250 },
        { müdahaleAdı: "İmplant", müdahaleSayı: 140 },
        { müdahaleAdı: "Gömülü Diş", müdahaleSayı: 80 },
        { müdahaleAdı: "Çene kırığı", müdahaleSayı: 20 },
        { müdahaleAdı: "Damak", müdahaleSayı: 40 },
      ],
    },
    {
      id: 2,
      müdahaleKategori: "Tedavi",
      müdahaleIstatistik: [
        { müdahaleAdı: "Diagnoz", müdahaleSayı: 14 },
        { müdahaleAdı: "Konservatif", müdahaleSayı: 145 },
        { müdahaleAdı: "Endodonti", müdahaleSayı: 421 },
        { müdahaleAdı: "Pedodonti", müdahaleSayı: 180 },
      ],
    },
    {
      id: 3,
      müdahaleKategori: "Ortodonti",
      müdahaleIstatistik: [
        { müdahaleAdı: "Telgerme", müdahaleSayı: 139 },
        { müdahaleAdı: "Telleme", müdahaleSayı: 215 },
        { müdahaleAdı: "Sıkma", müdahaleSayı: 751 },
        { müdahaleAdı: "Gevşetme", müdahaleSayı: 41 },
        { müdahaleAdı: "Uydurma", müdahaleSayı: 257 },
        { müdahaleAdı: "Uyarlama", müdahaleSayı: 789 },
        { müdahaleAdı: "Sallama", müdahaleSayı: 455 },
        { müdahaleAdı: "Kasnakatma", müdahaleSayı: 142 },
        { müdahaleAdı: "Entegre", müdahaleSayı: 425 },
        { müdahaleAdı: "Ondoitasyon", müdahaleSayı: 125 },
        { müdahaleAdı: "Pufrize", müdahaleSayı: 131 },
      ],
    },
    {
      id: 4,
      müdahaleKategori: "Radyoloji",
      müdahaleIstatistik: [
        { müdahaleAdı: "Çene", müdahaleSayı: 112 },
        { müdahaleAdı: "Diş", müdahaleSayı: 131 },
        { müdahaleAdı: "Ağız", müdahaleSayı: 445 },
      ],
    },
    {
      id: 5,
      müdahaleKategori: "Fenoloji",
      müdahaleIstatistik: [
        { müdahaleAdı: "Diagnoz", müdahaleSayı: 45 },
        { müdahaleAdı: "Konservatif", müdahaleSayı: 12 },
        { müdahaleAdı: "Endodonti", müdahaleSayı: 452 },
        { müdahaleAdı: "Pedodonti", müdahaleSayı: 80 },
      ],
    },
    {
      id: 6,
      müdahaleKategori: "Ağız Bakım",
      müdahaleIstatistik: [
        { müdahaleAdı: "Beyazlatma", müdahaleSayı: 73 },
        { müdahaleAdı: "Diş Taşı", müdahaleSayı: 786 },
        { müdahaleAdı: "Diş Bakım", müdahaleSayı: 454 },
      ],
    },
    {
      id: 7,
      müdahaleKategori: "Cleoronti",
      müdahaleIstatistik: [
        { müdahaleAdı: "Gevşetme", müdahaleSayı: 42 },
        { müdahaleAdı: "Uydurma", müdahaleSayı: 86 },
        { müdahaleAdı: "Telgerme", müdahaleSayı: 42 },
        { müdahaleAdı: "Telleme", müdahaleSayı: 585 },
        { müdahaleAdı: "Sıkma", müdahaleSayı: 58 },
        { müdahaleAdı: "Uyarlama", müdahaleSayı: 98 },
      ],
    },
    {
      id: 8,
      müdahaleKategori: "Ceromonti",
      müdahaleIstatistik: [
        { müdahaleAdı: "Diagnoz", müdahaleSayı: 42 },
        { müdahaleAdı: "Konservatif", müdahaleSayı: 422 },
        { müdahaleAdı: "Endodonti", müdahaleSayı: 475 },
        { müdahaleAdı: "Pedodonti", müdahaleSayı: 74 },
      ],
    },
  ]);

  const getYontem = async (id) => {
    // Uyguladığı tedavilerin  ayrıntılarını getirr
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}klinik_doktorlar/performans/yontem?baslik_id=${id}&pol_vergi_no=${pol}&d_diploma_no=${dipNo}`,
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );

      const formatliVeri = response.data.reduce((acc, current) => {
        // data formata uygun gelmediği için formata uygun hale getirdik
        if (Array.isArray(current)) {
          current.forEach((item) => {
            const { id, yontem } = item;
            acc.push({ id, müdahaleAdı: yontem, müdahaleSayı: 0 });
          });
        } else {
          Object.keys(current).forEach((key) => {
            const index = acc.findIndex((item) => item.müdahaleAdı === key);
            if (index !== -1) {
              acc[index].müdahaleSayı = current[key];
            }
          });
        }
        return acc;
      }, []);

      setDoctorTreatment(formatliVeri);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // varsayılan olarak tedavi başlıklarından ilkini seçili getirir
    getYontem(tedaviBasliklar[0].id);
    setTreatmentName(tedaviBasliklar[0].baslik);
  }, []);

  return (
    <div>
      <div className="flex flex-row justify-between py-5">
        <h1 className="text-2xl font-semibold mb-3 mr-3">
          Uyguladığı Tedaviler
        </h1>
        <div className="flex flex-wrap gap-2 ">
          {/* <-----Butonlar ----->👇🏻 */}
          {tedaviBasliklar.map((tedavi, index) => {
            return (
              <button
                className={` ${
                  openTreatment === index + 1
                    ? "btn btn-sm btn-primary rounded-full capitalize"
                    : "btn btn-sm btn-outline btn-primary rounded-full capitalize"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  getYontem(tedavi.id); // tıklanan başlığın içeriğini gtirir
                  setOpenTreatment(index + 1); //başlığa tıklandığında butonu mavi yapar
                  setTreatmentName(tedavi.baslik); // altta kartlarda başlığı gösterir
                }}
              >
                {tedavi.baslik}
              </button>
            );
          })}
        </div>
      </div>
      {/* <----- Doktor Müdahale Kartları ----->👇🏻 */}
      <div className="flex flex-wrap gap-4">
        {doctorTreatment?.map((tedavi, index) => {
          return (
            <div
              key={index}
              className=" gap-4 place-content-center py-8 w-48  max-h-full "
            >
              <div className="grid place-content-center bg-container p-8 rounded-md ">
                <div>
                  <h6 className="text-xl font-semibold capitalize h-40 text-center">
                    {tedavi.müdahaleAdı}
                  </h6>
                  <p className="text-[10px] opacity-70 uppercase text-center">
                    {treatmentName}
                  </p>
                </div>
                <h1 className="text-3xl font-bold text-blue2 text-center pt-6">
                  {tedavi.müdahaleSayı}
                </h1>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DoctorTreatments;
