import React from "react";

const InfoOfPatient = ({ patientInfo }) => {
  return (
    <section className="w-full border-1  rounded-2xl">
      <h1 className="text-22 mb-5 mx-auto">Hasta Bilgileri:</h1>
      <table className="table table-auto w-full z-0">
        {/* <!-- head --> */}
        <thead >
          <tr>
            <th className="text-[16px] bg-[#dedde7]">ADI:</th>
            <th className="text-[16px] bg-[#dedde7]">SOYADI:</th>
            <th className="text-[16px] bg-[#dedde7]">TELEFON:</th>
          </tr>
        </thead>
        <tbody>
          {/* <!-- row 1 --> */}
          <tr className="border-b border-[#e9e9e9] leading-4">
            <td>{patientInfo.firstName}</td>
            <td>{patientInfo.lastName}</td>
            <td>{patientInfo.phone}</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default InfoOfPatient;
