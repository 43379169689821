import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "../pages/dashboard/MainPage";
import RecordPatient from "../pages/dashboard/RecordPatient";
import QueryAppointment from "../pages/dashboard/QueryAppointment";
import Loading from "../components/Loader";
import { useSelector } from "react-redux";
import ClickRecordPatient from "../pages/dashboard/ClickRecordPatient";
import PrivateRoute from "./PrivateRoute";
import AboutUs from "../pages/dashboard/footerPages/AboutUs";
import OurTeam from "../pages/dashboard/footerPages/OurTeam";
import Security from "../pages/dashboard/footerPages/Security";
import Contracts from "../pages/dashboard/footerPages/Contracts";
import Documents from "../pages/dashboard/footerPages/Documents";
import Communication from "../pages/dashboard/footerPages/Communication";
import Faq from "../pages/dashboard/footerPages/Faq";
import WeinthePress from "../pages/dashboard/footerPages/WeinthePress";

const AppRouter = () => {
  const { loading } = useSelector((state) => state.user);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/ourteam" element={<OurTeam />} />
        <Route path="/security" element={<Security />} />
        <Route path="/contracts" element={<Contracts />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/communication" element={<Communication />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/weinthePress" element={<WeinthePress />} />
        <Route path="/randevu-al" element={<RecordPatient />} />
        <Route path="/randevu-al/:id" element={<ClickRecordPatient />} />
        <Route path="/randevu-sorgula" element={<QueryAppointment />} />
        <Route
          path="/doktorlar"
          element={
            <PrivateRoute
              kullanici_turu={2}
              allowedTypes={[2]}
              path="/doktorlar"
            />
          }
        />
        <Route
          path="/doktorlar/randevular"
          element={
            <PrivateRoute
              kullanici_turu={2}
              allowedTypes={[2]}
              path="/doktorlar/randevular"
            />
          }
        />
        <Route
          path="/doktorlar/hastalarım"
          element={
            <PrivateRoute
              kullanici_turu={2}
              allowedTypes={[2]}
              path="/doktorlar/hastalarım"
            />
          }
        />
        <Route
          path="/doktorlar/ayarlar"
          element={
            <PrivateRoute
              kullanici_turu={2}
              allowedTypes={[2]}
              path="/doktorlar/ayarlar"
            />
          }
        />

        {/* Dashboard */}
        <Route
          path="/poliklinikler"
          element={
            <PrivateRoute
              kullanici_turu={("", 1, 2)}
              allowedTypes={["", 1, 2]}
              path="/poliklinikler"
            />
          }
        />

        <Route
          path="/poliklinik"
          element={
            <PrivateRoute
              kullanici_turu={1}
              allowedTypes={[1]}
              path="/poliklinik"
            />
          }
        />
        <Route
          path="/poliklinik/randevular"
          element={
            <PrivateRoute
              kullanici_turu={1}
              allowedTypes={[1]}
              path="/poliklinik/randevular"
            />
          }
        />
        <Route
          path="/poliklinik/doktorlarimiz"
          element={
            <PrivateRoute
              kullanici_turu={1}
              allowedTypes={[1]}
              path="/poliklinik/doktorlarimiz"
            />
          }
        />
        <Route
          path="/poliklinik/hastalarımız"
          element={
            <PrivateRoute
              kullanici_turu={1}
              allowedTypes={[1]}
              path="/poliklinik/hastalarımız"
            />
          }
        />
        <Route
          path="/poliklinik/rontgen"
          element={
            <PrivateRoute
              kullanici_turu={1}
              allowedTypes={[1]}
              path="/poliklinik/rontgen"
            />
          }
        />
        <Route
          path="/poliklinik/islemlerimiz"
          element={
            <PrivateRoute
              kullanici_turu={1}
              allowedTypes={[1]}
              path="/poliklinik/islemlerimiz"
            />
          }
        />
        <Route
          path="/poliklinik/hesabim"
          element={
            <PrivateRoute
              kullanici_turu={1}
              allowedTypes={[1]}
              path="/poliklinik/hesabim"
            />
          }
        />
        <Route
          path="/poliklinik/profilim"
          element={
            <PrivateRoute
              kullanici_turu={1}
              allowedTypes={[1]}
              path="/poliklinik/profilim"
            />
          }
        />
        <Route
          path="/poliklinik/ayarlar"
          element={
            <PrivateRoute
              kullanici_turu={1}
              allowedTypes={[1]}
              path="/poliklinik/ayarlar"
            />
          }
        />
        <Route
          path="/poliklinik/yardim"
          element={
            <PrivateRoute
              kullanici_turu={1}
              allowedTypes={[1]}
              path="/poliklinik/yardim"
            />
          }
        />
      </Routes>
      {loading || <Loading />}
    </BrowserRouter>
  );
};

export default AppRouter;
