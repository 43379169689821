import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../../config";

const AppointmentDate = ({
  setDate,
  date,
  setShowDoctors,
  setInfo,
  setNoDoctor,
  setShowClock,
  polyclinic,
  setPolyclinicDoctors,
  polyclinicDoctors,
}) => {
  const uRegister = useSelector((state) => state.uRegister);
  const date1 = new Date();
  date1.setHours(date1.getHours() + 3); // UTC+3 saat dilimine ayarlanır
  const thisDay = date1.toISOString();
  const tomorrow = new Date(date1);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDay = tomorrow.toISOString();
  const twoWeek = new Date(date1);
  twoWeek.setDate(twoWeek.getDate() + 14);
  const nextTwoWeek = twoWeek.toISOString().slice(0, 10);
  const beforeThisDay = date1.toISOString().slice(0, 10);

  //izinli doktorların seçili  tarihe  uyanlarının diploma no  listesi ni verecek fonksiyon

  function getDiplomaListForDate(date, izinListesi, doctors) {
    const tarih = new Date(date);
    const diplomaListesi = [];
    if (izinListesi.length > 0) {
      for (const izin of izinListesi) {
        const izinBaslangicTarihi = new Date(izin.izin_baslangic_tarihi);
        const izinBitisTarihi = new Date(izin.izin_bitis_tarihi);

        if (tarih >= izinBaslangicTarihi && tarih <= izinBitisTarihi) {
          diplomaListesi.push(izin.d_diploma_no);
        }
      }

      // izinlileri çıkaralım
      setPolyclinicDoctors(
        doctors.filter(
          (doctor) => !diplomaListesi.includes(Number(doctor.d_diploma_no))
        )
      );
      // setInfo("Doktor seçiniz")
      setNoDoctor(diplomaListesi);
    } else {
      setNoDoctor([]);
      setPolyclinicDoctors([...doctors]);
    }
    setShowDoctors(true); //doktorlar kısnmı görünür olacak
  }

  const getNoDoctors = async (dDate) => {
    //önce  POLİKLİNİK TÜM  DOKTORLARINI GETİR

    const resp = await axios.get(
      `${config.API_BASE_URL}poliklinikteki_doktorlar?pol_vergi_no=${polyclinic?.pol_vergi_no}`
    );

    setShowClock(false);
    setDate(dDate);

    // şimdi İZİNLİ DOKTORLARI ALALIM
    const { data } = await axios.post(
      `${config.API_BASE_URL}izinleriGeti`,
      {
        pol_vergi_no: polyclinic["pol_vergi_no"],
        alinan_tarih: dDate,
      }
    );

    // getDiplomaListForDate(tarih, izinlidoktor listesi ,tüm doktor listesi)
    getDiplomaListForDate(dDate, data, resp.data); // ŞİMDİDE İZİNLİ DOKTORLARI DOKTORLAR LİSTESİNDE SİLELİM
  };

  useEffect(() => {
    getNoDoctors(thisDay.slice(0, 10));
  }, []);

  return (
    <section className="w-full mx-auto">
      <article className="flex justify-between mb-8 mx-2 ">
        <h4>Tarih</h4>
        <article className="flex items-center gap-2">
          <button
            onClick={(e) => {
              setDate(thisDay.slice(0, 10));
              getNoDoctors(thisDay.slice(0, 10));
            }}
            className={
              date.slice(0, 10) === thisDay.slice(0, 10)
                ? "btn-primary mr-3 btn btn-active btn-sm bg-blue1 gap-2 rounded-2 hover:bg-blue1 capitalize"
                : "btn-primary mr-3 btn btn-sm bg-white text-blue1 gap-2 rounded-2 hover:text-white capitalize"
            }
          >
            Bugün
          </button>
          <button
            onClick={(e) => {
              setDate(tomorrowDay.slice(0, 10));
              getNoDoctors(tomorrowDay.slice(0, 10));
            }}
            className={
              date.slice(0, 10) === tomorrowDay.slice(0, 10)
                ? "btn-primary mr-3 btn btn-active btn-sm bg-blue1 gap-2 rounded-2 hover:bg-blue1 capitalize "
                : "btn-primary mr-3 btn btn-sm bg-white text-blue1 gap-2 rounded-2 hover:text-white capitalize"
            }
          >
            Yarın
          </button>
          <div className="form-control">
            <label
              className="input-group input-group-vertical cursor-pointer "
              htmlFor="appointmentDate"
            >
              <input
                className="input input-sm"
                type="date"
                name="appointmentDate"
                id="appointmentDate"
                value={date.slice(0, 10)}
                min={beforeThisDay}
                // max={nextTwoWeek}
                onChange={(e) => getNoDoctors(e.target.value)}
              />
            </label>
          </div>
        </article>
      </article>
    </section>
  );
};

export default AppointmentDate;
