import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toggleModal: false,
  toggleModalDoctor: false,
  toggleModalPatient: false,
};

export const modalSlice = createSlice({
  name: "openModal",
  initialState,
  reducers: {
    modalToggle: (state) => {
      state.toggleModal = !state.toggleModal;
    },
    modalToggleDoctor: (state) => {
      state.toggleModalDoctor = !state.toggleModalDoctor;
    },
    modalTogglePatient: (state) => {
      state.toggleModalPatient = !state.toggleModalPatient;
    }
  },
});

export const { modalToggle, modalToggleDoctor, modalTogglePatient } = modalSlice.actions;

export default modalSlice.reducer;
