import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { modalTogglePatient } from "../../../features/modalSlice";
import config from "../../../config";

export default function Modal() {
  const date = new Date();
  const [showModal, setShowModal] = useState(false);
  const [addRontgen, setAddRontgen] = useState([]);
  const [imageTrue, setImageTrue] = useState(false);
  const [addNote, setAddNote] = useState("");
  const [typeRontgen, setTypeRontgen] = useState();
  const [typeRontgenList, setTypeRontgenList] = useState([]);
  const [tcNo, setTcNo] = useState("");
  const [patient, setPatient] = useState([]);
  const [rDate, setRDate] = useState(date);
  const [images, setImages] = useState([]);
  const uRegister = useSelector((state) => state.uRegister);

  const dispatch = useDispatch();

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setAddRontgen(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  function calculateAge(birthDate) {
    const birthday = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();

    if (
      today.getMonth() < birthday.getMonth() ||
      (today.getMonth() === birthday.getMonth() &&
        today.getDate() < birthday.getDate())
    ) {
      age--;
    }

    return age;
  }

  const renderImages = () => {
    return addRontgen.map((file) => (
      <img
        src={file.preview}
        key={file.name}
        alt="preview"
        className="object-cover m-2"
        height="150"
        width="150"
      />
    ));
  };

  useEffect(() => {
    setImages(renderImages());
  }, [addRontgen]);

  // hasta sorgu
  const handleQueryPatient = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}hasta_uye/sorgu?hasta_tc=${tcNo}`,
        {}
      );
      const data = response.data;

      if (data.status === 400) {
        toast.error("Hasta Bulunamadı!");
        dispatch(modalTogglePatient());
      } else {
        setPatient(data[0]);
      }
    } catch (error) {
      console.error(error);
      toast.error("Bir hata oluştu");
    }
  };

  // Röntgen Tür Seçimi
  const fetchRontgenTurleri = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}rontgenTurleri`
      );
      const data = response.data;

      if (data.status === 400) {
        toast.error("Röntgen türleri listesi bulunamadı");
      } else {
        if (data.length > 0) {
          setTypeRontgenList(data);
          setTypeRontgen(data[0].id);
        } else {
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Bir hata oluştu");
    }
  };

  // Röntgen Yükle
  const handleSubmit = async () => {
    try {
      // Tarih API için uygun format
      const rDateFormatted = new Date(rDate).toISOString().split("T")[0];

      const formData = new FormData();
      addRontgen.forEach((item) => {
        formData.append("ront_resim", item.name);
        formData.append("hasta_tc", tcNo);
        // formData.append("ront_turu_id", typeRontgen); typeRontgen  API sorunu
        formData.append("ront_turu_id", 5);
        formData.append("ront_cekim_tarihi", rDateFormatted);
        formData.append("ront_tanim", addNote);
      });
      // formData içerisinde ne var
      // for (let pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      const { data } = await axios.post(
        `${config.API_BASE_URL}rontgenKayit?pol_vergi_no${uRegister.id}`,
        formData
      );

      setAddRontgen([]);
      setImages([]);
      setPatient([]);
      // Handle successful response
      setShowModal(false);
      toast.success("Röntgen Eklendi.");
      // console.log("data", data);
    } catch (error) {
      console.error("error", error);
      toast.error("Röntgen Eklenemedi");
    }
  };

  useEffect(() => {
    if (images.length > 0) {
      setImageTrue(true);
    }
  }, [images]);

  useEffect(() => {
    fetchRontgenTurleri();
  }, []);

  return (
    <>
      <button
        className="btn btn-active btn-sm bg-blue1 gap-2 rounded-full hover:bg-blue1"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <FaPlus /> Röntgen Ekle
      </button>
      {showModal ? (
        <>
          <div className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-full">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-[70rem] bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between bg-blue1 rounded-t py-2">
                  <h3 className="text-xl p-2 ml-2 text-white font-semibold">
                    Röntgen Ekle
                  </h3>
                  <button
                    className="text-xl mr-5 text-white"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-3xl text-white">×</span>
                  </button>
                </div>
                <div className="relative p-3 flex-auto">
                  <div className="md:grid md:grid-cols-3 md:gap-3">
                    <div className="md:col-span-2">
                      <div className="px-4 sm:px-0">
                        <label className="block text-sm font-medium text-gray-700">
                          Röntgen
                        </label>
                        <div
                          {...getRootProps()}
                          className="flex items-center justify-center w-full"
                        >
                          {imageTrue ? (
                            <div className="flex flex-wrap overflow-y-auto">
                              {images}
                            </div>
                          ) : (
                            <label className="flex flex-col justify-center w-full h-[29rem] border border-gray border-dashed hover:bg-gray-100 hover:border-gray-300">
                              {/* <input {...getInputProps()} /> */}
                              <div className="flex flex-col items-center justify-center pt-7">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                  />
                                </svg>
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                  Röntgen seçimi
                                </p>
                              </div>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 md:col-span-1 md:mt-0">
                      <div className="shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-2 bg-white px-2 sm:p-6">
                          <h1 className="font-bold">Hasta Sorgula</h1>
                          <div className="flex justify-between items-center gap-4 w-[20rem]">
                            {/* <label className="label-text text-center">Hasta TC Kimlik No</label> */}
                            <input
                              type="text"
                              placeholder="Hasta TC Kimlik No"
                              className="input input-bordered w-full max-w-xs"
                              value={tcNo}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // Sadece rakam girişine izin vermek için düzenli ifade kullanılır
                                const numericInput = inputValue.replace(
                                  /\D/g,
                                  ""
                                );
                                // En fazla 11 haneli TC Kimlik numarasına izin ver
                                if (numericInput.length <= 11) {
                                  setTcNo(e.target.value);
                                }
                              }}
                            />
                            <button
                              onClick={handleQueryPatient}
                              className="btn btn-sm btn-outline btn-primary rounded-full capitalize"
                            >
                              Sorgula
                            </button>
                          </div>
                          <div className="shadow">
                            <h1 className="font-semibold">Hasta Bilgileri</h1>
                            <table className="table table-compact w-full">
                              <tbody>
                                <tr>
                                  <td className="font-bold">Ad-Soyad</td>
                                  <td className="px-2">:</td>
                                  <td>
                                    {patient.hasta_adi} {patient.hasta_soyadi}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold">Cinsiyet</td>
                                  <td className="px-2">:</td>
                                  <td>{patient?.cinsiyet}</td>
                                </tr>
                                <tr>
                                  <td className="font-bold">Yaş</td>
                                  <td className="px-2">:</td>
                                  <td>
                                    {patient?.hasta_dogum_tarihi &&
                                      calculateAge(patient?.hasta_dogum_tarihi)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-bold" colSpan={3}>
                                    Röntgen Tipini Seçiniz
                                  </td>
                                </tr>
                                <tr>
                                  <td className="" colSpan={3}>
                                    <select
                                      id="rontgentype"
                                      className="select select-ghost select-sm  w-full max-w-sm"
                                      onChange={(e) =>
                                        setTypeRontgen(e.target.value)
                                      }
                                    >
                                      {typeRontgenList.map(
                                        (rontgenCesit, i) => (
                                          <option
                                            selected
                                            value={rontgenCesit.id}
                                          >
                                            {rontgenCesit.ront_tur}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div>
                            <label
                              htmlFor="rontgenTarihi"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Röntgen Tarihi
                            </label>
                            <div className="mt-1">
                              <input
                                id="rontgenTarihi"
                                name="rontgenTarihi"
                                type="date"
                                className="input input-bordered w-full max-w-xs"
                                value={rDate}
                                onChange={(e) => setRDate(e.target.value)}
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="notlar"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Notlar
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="notlar"
                                name="notlar"
                                rows={3}
                                className="textarea mt-1 block w-full rounded-md border-gray shadow-sm focus:border-gray focus:ring-gray sm:text-sm"
                                placeholder="Hasta ile ilgili notları giriniz..."
                                defaultValue={""}
                                onChange={(e) => setAddNote(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between mx-4 p-2 rounded-b">
                  <button
                    className="bg-blue1 text-white btn btn-sm font-bold rounded-3xl w-24 hover:bg-blue1"
                    onClick={() => setShowModal(false)}
                  >
                    Kapat
                  </button>
                  <button
                    className="bg-blue1 text-white btn btn-sm font-bold w-24 rounded-3xl hover:bg-blue1"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
