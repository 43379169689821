import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { ilData } from "../../../helpers/ilData";
import { tedaviler } from "../../../helpers/tedaviler";
import axios from "axios";
import { useSelector } from "react-redux";
import config from "../../../config";

const AddProcessModal = ({
  handleSearchPatients,
  setShowModal,
  tedaviBaslik,
  disDetay,
  searchPatient,
  editInfo,
}) => {
  const date = new Date();
  date.setHours(date.getHours() + 3); // UTC+3 saat dilimine ayarlanır
  const thisDay = date.toISOString();

  const uRegister = useSelector((state) => state.uRegister);

  const [activeTab, setActiveTab] = useState(1);
  const [tedaviYontemleri, setTedaviYontemleri] = useState([]);
  const [tedaviYontemleriId, setTedaviYontemleriId] = useState([]);
  const [tedaviBaslikId, setTedaviBaslikId] = useState(1); // tedavi başlığının id sini ve value olarakta tedavi yönteminin id sini tutar bu şekilde tüm başlık ve yöntemlerin id leri burada
  const [durum, setDurum] = useState(0);
  const [doktorNotu, setDoktorNotu] = useState("");

  const fetchData = async (tabNumber) => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}klinik_doktorlar/performans/yontem?baslik_id=${tabNumber}&pol_vergi_no=${uRegister.id}&d_diploma_no=${uRegister.d_diploma_no}`,
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      setTedaviYontemleri(response.data);
    } catch (error) {
      // Hata durumunda burası çalışır
      console.error(error);
    }
  };

  const handleTabClick = (tabNumber) => {
    //tabNumber aynı zamanda tedavi başlık id oluyor
    fetchData(tabNumber);
    setActiveTab(tabNumber);
    setTedaviBaslikId(tabNumber);
  };

  const handleChange = (e) => {
    const { checked, value } = e.target;
    // checkbox lar seçildeiğinde id leri güncelleyecek fonksiyon
    if (checked) {
      setTedaviYontemleriId((prevTedaviYontemleriId) => {
        const updatedTedaviYontemleri = { ...prevTedaviYontemleriId };

        if (updatedTedaviYontemleri.hasOwnProperty(tedaviBaslikId)) {
          const uniqueValues = Array.from(
            new Set([...updatedTedaviYontemleri[tedaviBaslikId], value])
          );
          updatedTedaviYontemleri[tedaviBaslikId] = uniqueValues;
        } else {
          updatedTedaviYontemleri[tedaviBaslikId] = [value];
        }

        return updatedTedaviYontemleri;
      });
    } else {
      setTedaviYontemleriId((prevTedaviYontemleriId) => {
        const updatedTedaviYontemleri = { ...prevTedaviYontemleriId };

        if (updatedTedaviYontemleri.hasOwnProperty(tedaviBaslikId)) {
          const filteredValues = updatedTedaviYontemleri[tedaviBaslikId].filter(
            (val) => val !== value
          );
          updatedTedaviYontemleri[tedaviBaslikId] = filteredValues;
        }

        return updatedTedaviYontemleri;
      });
    }
  };
  //  3 KAYIT APİSİ
  // console.log("editInfo", editInfo);
  const handleSaveAppointment = async (tedavi_yontem, tedavi_baslik) => {
    try {
      const response = await axios({
        url: `${config.API_BASE_URL}hasta_mudehale/kayit`,
        // method: { editInfo } ? "put" : "post",
        method: "post",
        data: {
          hasta_tc: searchPatient,
          pol_vergi_no: uRegister.id,
          d_diploma_no: uRegister.d_diploma_no,
          tedavi_yontem: tedavi_yontem,
          tedavi_baslik: tedavi_baslik,
          dis_no: disDetay,
          mudehale_tarih: thisDay.slice(0, 10),
          doktor_notu: doktorNotu,
          sonlandir: durum,
        },
        headers: {
          Authorization: "Bearer " + uRegister.token, // Authorization
        },
      });

      handleSearchPatients(); //hasta bilgilerini tekrar alalım
      // İsteğin sonucunu kullanmak için burada işlemler yapabilirsiniz
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error.message);
      // Hata durumunda ne yapılacağını burada belirleyebilirsiniz
    }
  };
  // 2 İD LERİN AYIRTEDİLMESİ
  const processKeyValuePairs = (obj) => {
    // çoklu id ler  var key ve valueleri sırayla kayıt için yollayacağız
    for (const key in obj) {
      const values = obj[key];
      for (const value of values) {
        // İlgili fonksiyonu burada çağırabilirsiniz
        handleSaveAppointment(value, key); // kayıt apisine ayıklanan tedavi başlık ve tedavi yöntemi id ler igönderiliyor
      }
    }
  };
  // 1 1 KAYIT İŞLEMİNİ BAŞLATIYORUZ
  const handleSubmit = (e) => {
    e.preventDefault();
    processKeyValuePairs(tedaviYontemleriId); // keyde tedavi başlık  id leri ve valuesinde tedavi yöntemi id leri var bunları sırasıyla kayıt apisine yollayacak fonksiyon
    setShowModal(false);
  };

  useEffect(() => {
    fetchData(1); // tablarda ilk başlığı çek
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray bg-opacity-50">
        <div className="relative mx-auto max-w-fit">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-between bg-blue1 rounded-t-md text-white p-3">
              <h3 className="text-xl font=semibold">
                {disDetay} Nolu Diş Müdahale Ekle
              </h3>
              <button className="" onClick={() => setShowModal(false)}>
                <CgClose />
              </button>
            </div>
            <div className="flex">
              {tedaviBaslik &&
                tedaviBaslik.map((item, i) => (
                  <button
                    key={i}
                    className={`py-2 px-4 text-gray-500 ${
                      activeTab === item.id
                        ? "border-b-2 border-blue1 text-indigo-500 font-medium text-blue1 text-[18px]"
                        : ""
                    }`}
                    onClick={() => handleTabClick(item.id)}
                  >
                    {item.baslik}
                  </button>
                ))}
            </div>
            <div className="p-1">
              {activeTab >= 1 && (
                <div
                  className="flex flex-row  border-b-2 border-gray"
                  style={{
                    width: "45rem",
                    height: "20rem",
                  }}
                >
                  <ul
                    style={{
                      width: "auto",
                      height: "auto",
                      overflow: "auto",
                    }}
                  >
                    {tedaviYontemleri[0] &&
                      tedaviYontemleri[0]?.map((hizmet, i) => (
                        <div className="flex flex-row flex-wrap w-[50rem]">
                          <li key={i}>
                            <label>
                              <input
                                type="checkbox"
                                name="name9"
                                id="name9"
                                value={hizmet.id}
                                onChange={handleChange}
                                className="w-12"
                                checked={
                                  tedaviYontemleriId[tedaviBaslikId]?.includes(
                                    String(hizmet.id)
                                  ) || false
                                }
                              />
                              {hizmet.yontem}
                            </label>
                          </li>
                        </div>
                      ))}
                  </ul>
                  <div className="w-[20rem] border-l-2 px-4 border-gray">
                    <div className="mb-4">
                      <label for="durum">DURUM</label>
                      <br />
                      <select
                        onChange={(e) => setDurum(e.target.value)}
                        name="durum"
                        id="durum"
                        className="border w-full"
                      >
                        <option value="" disabled selected>
                          Durum Seçiniz
                        </option>
                        <option value="1">Tedavi Tamamlandı</option>
                        <option value="0">Tedavi Devam Ediyor</option>
                      </select>
                    </div>
                    <div>
                      <fieldset>
                        <legend>NOTLAR</legend>
                        <textarea
                          name="yorum"
                          id="yorum"
                          className="border w-full h-32"
                          value={doktorNotu}
                          onChange={(e) => setDoktorNotu(e.target.value)}
                        ></textarea>
                        {/* <input type="text w-42 h-32 border-2 border-black" /> */}
                      </fieldset>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-center justify-end m-3">
                <button
                  className="btn btn-primary btn-sm w-40 uppercase text-sm rounded-full shadow hover:bg-white hover:text-blue1"
                  type="submit"
                  onClick={handleSubmit}
                >
                  KAYDET
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProcessModal;
