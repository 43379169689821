import React from "react";
import { useState } from "react";
import { ilData } from "../../../../helpers/ilData";
import { CgClose } from "react-icons/cg";

const AddFinancialPatientInfo = ({ patientInfo, yontemId }) => {
  const [showModals, setShowModals] = useState(false);

  return (
    <div className="row-span-2 bg-white">
      <table className="table table-auto lg:w-full z-0 mt-5">
        {/* <!-- head --> */}
        <thead>
          <tr>
            <th className="text-[16px] ">ADI:</th>
            <th className="text-[16px] ">SOYADI:</th>
            <th className="text-[16px] ">TELEFON:</th>
            <th className="text-[16px] "> </th>
          </tr>
        </thead>
        <tbody>
          {/* <!-- row 1 --> */}
          <tr className=" border-[#e9e9e9] leading-4">
            <td>{patientInfo?.hasta_adi}</td>
            <td>{patientInfo?.hasta_soyadi}</td>
            <td>{patientInfo?.hasta_tel}</td>
            <td>
              {" "}
              <button
                type="button"
                className="shadow flex justify-end bg-blue1 text-white hover:bg-blue2 focus:shadow-outline focus:outline-none py-1 px-4 mt-1 rounded-full"
                onClick={() => setShowModals(true)}
              >
                Güncelle
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <>
        {/* Button trigger modal */}

        {/* Modal */}
        {showModals && (
          <div>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray bg-opacity-50">
              <div className="relative mx-auto max-w-fit">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between bg-blue1 rounded-t-md text-white p-3">
                    <h3 className="text-xl font=semibold">
                      Hasta Bilgilerini Güncelle
                    </h3>
                    <button className="" onClick={() => setShowModals(false)}>
                      <CgClose />
                    </button>
                  </div>
                  <h1 className="ml-5 mt-3 font-semibold">Hasta Bilgileri</h1>
                  <form
                    className="flex flex-col p-5 gap-2"
                    // onSubmit={handleSubmit}
                  >
                    <div className="md:flex justify-center items-center gap-3">
                      <div>
                        <label className="block label-text mb-1">
                          TC KİMLİK NO
                        </label>
                        <input
                          type="text"
                          name="identity"
                          // value={addPatient.identity}
                          // onChange={handleChange}
                          className="input input-bordered input-sm w-52 xs:max-w-fit md:max-w-xs"
                        />
                      </div>
                      <div>
                        <label className="block label-text mb-1">
                          DOĞUM TARİHİ
                        </label>
                        <input
                          type={"date"}
                          name="birthDay"
                          // value={addPatient.birthDay}
                          // onChange={handleChange}
                          className="input input-bordered input-sm w-52 xs:max-w-fit md:max-w-xs"
                        />
                      </div>
                      <div>
                        <label className="block label-text mb-1">EMAİL</label>
                        <input
                          type={"email"}
                          name="email"
                          // value={addPatient.email}
                          // onChange={handleChange}
                          className="input input-bordered input-sm w-52 xs:max-w-fit md:max-w-xs"
                        />
                      </div>
                    </div>
                    <div className="md:flex justify-center items-center gap-3">
                      <div>
                        <label className="block label-text mb-1">ADI</label>
                        <input
                          type={"text"}
                          name="firstName"
                          // value={addPatient.firstName}
                          // onChange={handleChange}
                          className="input input-bordered input-sm w-52 xs:max-w-fit md:max-w-xs"
                        />
                      </div>
                      <div>
                        <label className="block label-text mb-1">
                          BABA ADI
                        </label>
                        <input
                          type={"text"}
                          name="dadyName"
                          // value={addPatient.dadyName}
                          // onChange={handleChange}
                          className="input input-bordered input-sm w-52 xs:max-w-fit md:max-w-xs"
                        />
                      </div>
                      <div>
                        <label className="block label-text mb-1">İL</label>
                        <select
                          name="city"
                          id="city"
                          className="select select-sm select-bordered w-52 xs:max-w-fit md:max-w-xs"
                          // api eklenince bakılacak, value ne yazacak,
                          // value={addPatient.city}
                          // onChange={handleChange}
                        >
                          <option selected disabled>
                            İl Seçiniz
                          </option>
                          {ilData.map((il) => (
                            <option value={il["text"]}>{il["text"]}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex justify-center items-center gap-3">
                      <div>
                        <label className="block label-text mb-1">SOYADI</label>
                        <input
                          type={"text"}
                          name="lastName"
                          // value={addPatient.lastName}
                          // onChange={handleChange}
                          className="input input-bordered input-sm w-52 xs:max-w-fit md:max-w-xs"
                        />
                      </div>
                      <div>
                        <label className="block label-text mb-1">TELEFON</label>
                        <input
                          type={"text"}
                          name="phoneNumber"
                          // value={addPatient.phoneNumber}
                          // onChange={handleChange}
                          className="input input-bordered input-sm w-52 xs:max-w-fit md:max-w-xs"
                        />
                      </div>
                      <div>
                        <label className="block label-text mb-1">İLÇE</label>
                        <select
                          name="village"
                          id="village"
                          className="select select-sm select-bordered w-52 xs:max-w-fit md:max-w-xs"
                          // api eklenince bakılacak, value ne yazacak,
                          // value={addPatient.village}
                          // onChange={handleChange}
                        >
                          <option disabled selected>
                            İlçe Seçiniz
                          </option>
                          {/* {ilData
                          .filter((data) => data["text"] === addPatient.city)
                          .map((village) =>
                            village["districts"].map((e) => (
                              <option value={e["text"]}>{e["text"]}</option>
                            ))
                          )} */}
                        </select>
                      </div>
                    </div>
                    <div className="flex items-center justify-end mt-3">
                      <button
                        className="btn bg-[#dadbdd] text-[#5616f5] btn-sm w-28 uppercase text-sm border-white rounded-full shadow  outline-none focus:outline-none mr-1 hover:bg-white hover:border-blue1"
                        type="button"
                        onClick={() => setShowModals(false)}
                      >
                        Vazgeç
                      </button>
                      <button
                        className="btn  btn-primary btn-sm w-40 uppercase text-sm rounded-full outline-none focus:outline-none hover:bg-white hover:text-blue1 "
                        type="submit"
                      >
                        KAYDET
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default AddFinancialPatientInfo;
