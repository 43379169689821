import React, { useEffect, useRef, useState } from "react";
import DoctorPermitsTable from "./DoctorPermitsTable";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Controller } from "swiper";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useSelector } from "react-redux";
import axios from "axios";
import config from "../../../config";

const DoctorPermits = ({ selectedDoctor }) => {
  const uRegister = useSelector((state) => state.uRegister);

  const [doktorlar, setDoktorlar] = useState([]);
  const [openTab, setOpenTab] = useState(0);
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();
  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  useEffect(() => {
    const getAppointment = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}klinik_doktorlar/${uRegister.id}`,
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );
        setDoktorlar(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAppointment();
  }, []);

  useEffect(() => {
    setOpenTab(selectedDoctor);
  }, [selectedDoctor]);
  // console.log("doktorlar", doktorlar);
  // console.log("doktorlar", doktorlar);
  return (
    <div className="py-10">
      <div className="flex justify-end mb-4 px-2 ">
        <article className="flex gap-3">
          <button
            ref={prevRef}
            className="bg-container w-10 h-10 flex items-center justify-center rounded-full"
          >
            <HiOutlineArrowLeft />
          </button>
          <button
            ref={nextRef}
            className="bg-container w-10 h-10 flex items-center justify-center rounded-full"
          >
            <HiOutlineArrowRight />
          </button>
        </article>
      </div>
      <Swiper
        className="flex flex-row"
        spaceBetween={25}
        slidesPerView={5}
        onSwiper={setSwiper}
        modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
        navigation={{
          prevEl: prevRef?.current,
          nextEl: nextRef?.current,
        }}
        updateOnWindowResize
        observer
        observeParents
      >
        {doktorlar.length > 0 &&
          doktorlar?.map((doktor, i) => (
            <SwiperSlide
              key={i}
              className={` ${
                openTab === doktor.d_diploma_no
                  ? "card w-1/5 bg-gradient-to-r  from-blue1 to-blue2 text-white"
                  : "card w-1/5 bg-container"
              }`}
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(doktor?.d_diploma_no);
              }}
            >
              <figure className="pt-5">
                <img
                  src={doktor?.d_resim}
                  alt="Shoes"
                  className="w-36 h-36 rounded-full object-contain"
                />
              </figure>
              <div className="text-center py-3">
                <h2 className="text-center text-xl font-semibold line-clamp-2 truncate px-3">
                  {doktor?.d_adi} {doktor?.d_soyadi}
                </h2>
                <p
                  className={` ${
                    openTab === doktor?.d_diploma_no
                      ? "text-white text-center h-10"
                      : "text-blue1 text-center h-10"
                  }`}
                >
                  {doktor?.d_unvan}
                </p>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>

      {/* {doktorlar[openTab].name} */}

      {/* {doktorlar.length > 0 ? (
        <p className="my-7 p-4 text-blue1 text-2xl font-semibold bg-gray-300 rounded-lg">
          <span className="text-black font-normal">
            {openTab
              ? doktorlar?.filter((doktor) => {
                  console.log("doktor", doktor?.d_diploma_no === openTab);
                  return doktor?.d_diploma_no === openTab;
                })?.d_adi
              : doktorlar[0].d_adi + " "}
            isimli personelin izin bilgilerini görüntülüyorsunuz.
          </span>
        </p>
      ) : (
        <p className="capitalize w-[30rem] p-2 rounded-[39px] shadow-lg mb-4 text-center">
          Doktor bulunamadı!
        </p>
      )} */}
      {doktorlar.length > 0 ? (
        <p className="my-7 p-4 text-blue1 text-2xl font-semibold bg-gray-300 rounded-lg">
          İzin Bilgileri görüntülenen Doktor:
          <span className="text-black font-normal">
            {openTab
              ? doktorlar
                  ?.filter((doktor) => doktor?.d_diploma_no === openTab)
                  .map((doktor) => ` ${doktor.d_adi} ${doktor.d_soyadi}`)
              : ` ${doktorlar[0].d_adi} ${doktorlar[0].d_soyadi}`}
          </span>
        </p>
      ) : (
        <p className="capitalize w-[30rem] p-2 rounded-[39px] shadow-lg mb-4 text-center">
          Doktor bulunamadı!
        </p>
      )}
      {doktorlar.length > 0 && (
        <DoctorPermitsTable
          pol={uRegister.id}
          doktor={
            doktorlar?.filter((doktor) => {
              // console.log("doktor", doktor);
              return doktor?.d_diploma_no === openTab;
            })[0]
          }
        />
      )}
    </div>
  );
};

export default DoctorPermits;
