export const tedaviler = {
  TEŞHİSTEDAVİPLANLAMASI: [
    { hizmet: "Dişhekimi Muayenesi" },
    { hizmet: "Uzman Dişhekimi Muayenesi" },
    { hizmet: "Kontrol Hekim Muayenesi" },
    { hizmet: "Konsültasyon" },
    { hizmet: "Uzman Dişhekimi Konsültasyonu" },
    { hizmet: "Hipnoz (Seans Başına)" },
    { hizmet: "Akupunktur Uygulama (Seans Başına)" },
    { hizmet: "Teşhis ve Tedavi Planlaması" },
    { hizmet: "Oral Hijyen Eğitimi" },
    { hizmet: "Tükürük Akış Hızı ve Tamponlama Kapasitesi Tayini" },
    { hizmet: "Tükürükte Mikrobiyolojik Analiz" },
    { hizmet: "Gnatoloji, T.M.E. Kas Muayenesi (Oklüzyon Kontrolü)" },
    { hizmet: "Vitalite Kontrolü (Diş Başına)" },
    { hizmet: "Diş Röntgen Filmi (Periapikal)" },
    { hizmet: "Oklüzal Film" },
    { hizmet: "Bite - Wing Radyografi" },
    { hizmet: "Ekstra Oral Röntgen Filmi" },
    { hizmet: "Panoramik Film" },
    { hizmet: "Lateral Sefalometrik Film" },
    { hizmet: "Antero-Posterior Sefalometrik Film" },
    { hizmet: "İntra Oral Dijital Radyografi" },
    { hizmet: "El Bilek Filmi" },
    { hizmet: "Siyalografi" },
    { hizmet: "Tomografi (Bölgesel)" },
    { hizmet: "Tomografi (Tek Çene)" },
    { hizmet: "Tomografi (İki Çene Çene)" },
    { hizmet: "Eklem Tomografisi (Çift Taraflı)" },
    { hizmet: "Bilgisayarlı Büyük T.M.E. Fonksiyon Testi" },
    { hizmet: "Bilgisayarlı Kas Tonus Analizi" },
    { hizmet: "T.M.E. Filmi ve Tetkiki" },
    { hizmet: "Lokal Anestezi (Enjeksiyon - İnfiltratif)" },
    { hizmet: "Lokal Anestezi (Rejyonal)" },
    { hizmet: "Raporlama" },
  ],
  TEDAVİENDODONTİ: [
    { hizmet: "Amalgam Dolgu (Bir Yüzlü)" },
    { hizmet: "Amalgam Dolgu (İki Yüzlü)" },
    { hizmet: "Amalgam Dolgu (Üç Yüzlü)" },
    { hizmet: "Kompozit Dolgu (Bir Yüzlü)" },
    { hizmet: "Kompozit Dolgu (İki Yüzlü)" },
    { hizmet: "Kompozit Dolgu (Üç Yüzlü)" },
    { hizmet: "Direkt Kompozit Laminate Restorasyonu" },
    { hizmet: "Black V Kole Dolgusu (Kompozit)" },
    { hizmet: "Cam İonomer Dolgu" },
    { hizmet: "Black V Kole Dolgusu (Cam İonomer)" },
    { hizmet: "İnley Dolgu * (Bir Yüzlü)" },
    { hizmet: "İnley Dolgu * (İki Yüzlü)" },
    { hizmet: "İnley Dolgu * (Üç Yüzlü)" },
    { hizmet: "Kompozit İnley Dolgu (Bir Yüzlü)" },
    { hizmet: "Kompozit İnley Dolgu (İki Yüzlü)" },
    { hizmet: "Kompozit İnley Dolgu (Üç Yüzlü)" },
    { hizmet: "Seramik İnley Dolgu (Bir Yüzlü)" },
    { hizmet: "Seramik İnley Dolgu (İki Yüzlü)" },
    { hizmet: "Seramik İnley Dolgu (Üç Yüzlü)" },
    { hizmet: "Onley *" },
    { hizmet: "Onley * (Seramik)" },
    { hizmet: "Pinley *" },
    { hizmet: "Dolgu (Restorasyon) Tamiri" },
    { hizmet: "Dolgu Sökümü (Tek Diş)" },
    { hizmet: "Kuafaj (Dolgu Hariç)" },
    { hizmet: "Ekstirpasyon (Her Kanal İçin)" },
    { hizmet: "Kanal Tedavisi - Tek Kanal (Dolgu Hariç)" },
    { hizmet: "Kanal Tedavisi - İki Kanal (Dolgu Hariç)" },
    { hizmet: "Kanal Tedavisi - Üç Kanal (Dolgu Hariç)" },
    { hizmet: "Kanal Tedavisi - İlave Her Kanal İçin" },
    {
      hizmet:
        "Periapikal Lezyonlu Dişte Kanal Tedavisi - Tek Kanal (Dolgu Hariç)",
    },
    {
      hizmet:
        "Periapikal Lezyonlu Dişte Kanal Tedavisi - İki Kanal (Dolgu Hariç)",
    },
    {
      hizmet:
        "Periapikal Lezyonlu Dişte Kanal Tedavisi - Üç Kanal (Dolgu Hariç)",
    },
    { hizmet: "Kanal Dolgusu Sökümü (Her Kanal İçin)" },
    {
      hizmet:
        "Kanal Dolgusu Tekrarı (Retreatment - Her Kanal İçin - Dolgu Hariç)",
    },
    { hizmet: "Kanal Pansumanı (Seans Başı)" },
    { hizmet: "Kanal İçi Hazır Post Uygulaması (Metal) (Dolgu Ücreti Hariç)" },
    { hizmet: "Kanal İçi Fiber Post Uygulaması (Dolgu Ücreti Hariç)" },
    { hizmet: "Kanaldan Kırılmış Materyal Çıkartılması" },
    { hizmet: "Kanalda Perforasyon Tamiri (MTA vb.)" },
    { hizmet: "Dentin Pini Uygulaması (Her Pin Başına)" },
    { hizmet: "Endokron" },
    { hizmet: "Hassasiyet Tedavisi (Tek Diş)" },
    { hizmet: "Hassasiyet Tedavisi (Tam Çene)" },
    {
      hizmet:
        "Diş Ağartma (Beyazlatma Vital Tek Diş En Çok 4 Adet) (Malzeme Ücreti Hariç)",
    },
    {
      hizmet:
        "Diş Ağartma (Beyazlatma - Devital Tek Diş ) (Seans Başına - Dolgu Hariç)",
    },
    { hizmet: "Diş Ağartma (Beyazlatma - Tek Çene) (Malzeme Ücreti Hariç)" },
    { hizmet: "Rubber-Dam Uygulaması" },
  ],
  PEDODONTİ: [
    { hizmet: "Aşındırma ile Sürme Rehberliği (Seans Başına)" },
    { hizmet: "Fissür Örtülmesi (Sealant - Tek Diş)" },
    { hizmet: "Yüzeysel Flor Uygulaması (Yarım Çene)" },
    { hizmet: "Kompomer Dolgu" },
    { hizmet: "Rezin İnfilitrasyonu Tedavisi" },
    { hizmet: "Amputasyon (Dolgu-Kron Üst Yapı-Biyomateryal Hariç)" },
    { hizmet: "Süt Dişi Kanal Tedavisi" },
    {
      hizmet:
        "Açık Apeksli Dişte Kanal Tedavisi (Her Kanal İçin - Dolgu Hariç)(Pansuman Ücretleri Hariç)",
    },
    {
      hizmet:
        "Açık Apeksli Dişte Apikal Bariyer (Her Kanal İçin - Dolgu ve Pansuman Ücretleri Hariç)",
    },
    { hizmet: "Yer Tutucu (Sabit)" },
    { hizmet: "Yer Tutucu (Hareketli)" },
    { hizmet: "Prefabrike Kron *" },
    { hizmet: "Strip Kron" },
    { hizmet: "Travma Splinti" },
    { hizmet: "Çocuk Protezi" },
    { hizmet: "Çocuk Protezi (Akrilik - Bölümlü - Tek Çene)" },
    { hizmet: "Çocuk Protezi (Akrilik - Tam - Tek Çene)" },
  ],
  PROTEZ: [
    { hizmet: "Tam Protez (Akrilik - Tek Çene)" },
    { hizmet: "Bölümlü Protez (Akrilik - Tek Çene)" },
    { hizmet: "Tam Protez (Döküm Metal ile Güçlendirilmiş - Tek Çene)" },
    { hizmet: "Bölümlü Protez (Döküm Metal ile Güçlendirilmiş - Tek Çene)" },
    {
      hizmet:
        "Hassas Tutuculu Protezler (Hassas Tutucu Ücreti Hariç - Tek Çene)",
    },
    {
      hizmet:
        "İmplant Destekli Hareketli Protezler (Hassas Tutucu Ücreti Hariç - Tek Çene)",
    },
    { hizmet: "Geçici (İmmediat) Protez (Akrilik - Tek Çene)" },
    { hizmet: "Besleme (Tek Çene)" },
    { hizmet: "Kaide Yenileme (Rebazaj - Tek Çene)" },
    { hizmet: "Proteze Yumuşak Akrilik Uygulaması (Geçici Tek Çene)" },
    { hizmet: "Proteze Yumuşak Akrilik Uygulaması (Daimi Tek Çene)" },
    { hizmet: "Tamir (Akrilik Protezler, Kırık veya Çatlak)" },
    { hizmet: "Kroşe İlavesi" },
    { hizmet: "Metal İskelet Tamiri" },
    { hizmet: "Diş İlavesi (Tek Diş)" },
    { hizmet: "Roach Köprü" },
    { hizmet: "Gece Plağı (Yumuşak)" },
    { hizmet: "Gece Plağı (Sert Oklüzal Splintleme)" },
    { hizmet: "Pinley ve Çeşitleri *" },
    { hizmet: "Tek Parça Döküm Kuron" },
    { hizmet: "Veneer Kuron (Akrilik)" },
    { hizmet: "Veneer Kuron ** (Seramik)" },
    { hizmet: "İmplant Üstü Veneer Kuron (Seramik) (Abutment Ücreti Hariç)" },
    { hizmet: "Laminate Veneer Kompozit" },
    { hizmet: "Laminate Veneer (Akrilik)" },
    { hizmet: "Laminate Veneer (Seramik)" },
    { hizmet: "Jaket Kuron (Akrilik)" },
    { hizmet: "Jaket Kuron (Kompozit)" },
    { hizmet: "Tam Seramik Kuron (Metal Desteksiz)" },
    { hizmet: "Teleskop Kuron* (Koping)" },
    { hizmet: "Kuronlarda Freze Tekniği Farkı" },
    { hizmet: "Döküm Post Core (Pivo) (Üst Yapı Hariç)" },
    { hizmet: "Adeziv Köprü (Maryland vb. gibi)" },
    { hizmet: "Geçici Kuron (Tek Diş İçin)" },
    { hizmet: "Kuron Sökümü (Tek Sabit Üye İçin)" },
    { hizmet: "Düşmüş Kuron ve Köprü Simantasyonu (Her Sabit Üye İçin)" },
    { hizmet: "Kuron Köprü Tamiri * (Her Üye İçin)" },
    { hizmet: "Diş Üstü Protezi (Overdenture - Tek Çene) (**)" },
    { hizmet: "Damak Yarığı Protezi (Velum Uzantılı Aparey) (**)" },
    {
      hizmet:
        "Yeni Doğanda Preoperatif Aparey (Vida Ücreti Ayrıca Alınır) (**)",
    },
    { hizmet: "Geçici Obturatörler (**)" },
    { hizmet: "Basit Çene Defektlerinde Protetik Tedavi (**)" },
    { hizmet: "Komplike Çene Defektlerinde Protetik Tedavi (**)" },
    { hizmet: "Yüz Protezleri (Yumuşak Akrilik İle)" },
    { hizmet: "Göz Protezi (Oküler)" },
    { hizmet: "Oklüzal Aşındırmalar" },
    { hizmet: "Oklüzyon Düzeltilmesi" },
    { hizmet: "T.M.E. Stabilizasyon Splinti" },
    { hizmet: "İmplant Rehberi (Yarım Çene)" },
    { hizmet: "İmplant Rehberi (Tam Çene)" },
  ],
  AGIZDISVECENECERRAHISI: [
    { hizmet: "Diş Çekimi" },
    { hizmet: "Komplikasyonlu Diş Çekimi" },
    { hizmet: "Gömülü Diş Operasyonu" },
    { hizmet: "Gömülü Diş Operasyonu (Kemik Retansiyonlu)" },
    { hizmet: "Tek Kökte Kök Ucu Rezeksiyonu (Kanal Tedavisi ve Dolgu Hariç)" },
    { hizmet: "İki Kökte Kök Ucu Rezeksiyonu (Kanal Tedavisi ve Dolgu Hariç)" },
    { hizmet: "Üç Kökte Kök Ucu Rezeksiyonu (Kanal Tedavisi ve Dolgu Hariç)" },
    { hizmet: "Alveolit Cerrahi Tedavisi" },
    { hizmet: "Kanama Müdahalesi (Basit)" },
    { hizmet: "Kanama Müdahalesi (Dikişli)" },
    { hizmet: "Alveol Plastiği (Yarım Çene)" },
    { hizmet: "Alveol Düzeltilmesi (Yarım Çene)" },
    { hizmet: "Kist Operasyonu (Küçük)" },
    { hizmet: "Kist Operasyonu (1 Cm Büyük)" },
    { hizmet: "Epulis Operasyonu" },
    { hizmet: "Osteomyelitis veya Osteitis Operasyonu (Tek Çene Basit)" },
    { hizmet: "Çene Lüksasyonu" },
    { hizmet: "Vestibüloplasti (Yarım Çene)" },
    { hizmet: "Sinüs Plastiği" },
    { hizmet: "Sert Doku Greftleme (Greft Ücreti Hariç)" },
    { hizmet: "Yumuşak Doku Greftleme (Greft Ücreti Hariç)" },
    { hizmet: "Sinüs Lifting (Biomateryal Ücreti Hariç)" },
    { hizmet: "Biyopsi" },
    { hizmet: "Fibrom Operasyonu" },
    { hizmet: "Apse Drenajı ve Tedavisi (Extraoral)" },
    { hizmet: "Apse Drenajı ve Tedavisi (İntraoral)" },
    { hizmet: "Kapişon İzalesi - İmplant Üstü Açılması" },
    { hizmet: "Stomatit Tedavisi" },
    { hizmet: "Fizik Tedavi (İnfraruj Seansı)" },
    { hizmet: "Çene Kırığı (Basit)" },
    { hizmet: "Çene Kırığı (Komplike - Materyal Ücreti Hariç)" },
    { hizmet: "Reimplantasyon" },
    { hizmet: "Ototransplantasyon" },
    { hizmet: "Kemik İçi İmplant (Tek Silindirik İmplant Ücreti Hariç)" },
    { hizmet: "Torus Operasyonu (Yarım Çene)" },
    { hizmet: "Odontogenik Tümör Operasyonu (Küçük)" },
    { hizmet: "Odontogenik Tümör Operasyonu (Büyük)" },
    { hizmet: "Nevralji Tedavisi (Alkol Enjeksiyonu)" },
    { hizmet: "Nevralji Tedavisi Cerrahi (Nöroktomi vb.)" },
    { hizmet: "Tükürük Bezi Kanalından Taş Çıkarma (Basit)" },
    { hizmet: "Tükürük Bezi Kanalından Taş Çıkarma (Komplike)" },
    { hizmet: "Ortodontik Tedavi Amaçlı Gömük Dişlerin Üzerinin Açılması" },
    { hizmet: "T.M.E. Mekonoterapi" },
    { hizmet: "T.M.E. İçi Enjeksiyon (Tek Taraflı)" },
    { hizmet: "Artrosentez (Tek Taraflı)" },
    { hizmet: "Açık Eklem Cerrahisi (Tek Taraflı)" },
    { hizmet: "Genioplasti" },
    { hizmet: "Segmental Osteotomi" },
    { hizmet: "Osteotomi (Tek Çene)" },
    { hizmet: "Dişhekimliğinde Botulinum Toksin Uygulaması" },
    { hizmet: "İmplant Çıkartılması" },
    { hizmet: "Ankraj Amaçlı Plak Yerleştirme (Malzeme Ücreti Hariç)" },
    { hizmet: "Ankraj Amaçlı Plak Çıkarma" },
    { hizmet: "Zigoma İmplant (İmplant Ücreti Hariç)" },
  ],
  PERIODONTOLOJI: [
    { hizmet: "Detartraj (Diş Taşı Temizliği - Tek Çene)" },
    { hizmet: "Subgingival Küretaj (Tek Diş)" },
    { hizmet: "Subgingival İlaç Uygulaması" },
    { hizmet: "Gingivoplasti (Tek Diş)" },
    { hizmet: "Gingivektomi (Tek Diş)" },
    { hizmet: "Flap Operasyonu (Subgingival Küretaj Dahil - Tek Diş)" },
    { hizmet: "Tunnel Operasyonu (Tek Diş)" },
    { hizmet: "Hemiseksiyon (Kök Amputasyonu - Kanal Tedavisi Hariç)" },
    { hizmet: "Serbest Diş Eti Grefti (Tek Diş)" },
    {
      hizmet:
        "Saplı yumuşak doku grefti (Koronale Kaydırma, Yana Kaydırma,Tek Diş)",
    },
    { hizmet: "Periodontal Splint (Daimi)" },
    { hizmet: "Periodontal Splint (Geçici)" },
    { hizmet: "Periodontal Şine (Splint - Geçici - Yarım Çene)" },
    {
      hizmet:
        "Biyomateryal Uygulaması (Tek Diş - Flap Op. ve Biomateryal Ücreti Hariç)",
    },
    {
      hizmet: "Membran Uygulaması (Tek Diş - Flap Op. ve Membran Ücreti Hariç)",
    },
    { hizmet: "Vestibül Plak (Diş Eti Protezi - Çene Başına)" },
    { hizmet: "Subepitelyal Bağ Dokusu Grefti" },
    { hizmet: "Frenektomi - Frenetomi" },
    {
      hizmet:
        "Peri-İmplantitis (Cerrahi) (Biomaterial ve Membran Ücreti Hariç) (Tek İmp.)",
    },
    { hizmet: "Peri-İmplantitis (Cerrahi Olmayan) (Tek İmp.)" },
    { hizmet: "Papil Oluşturma (Cerrahi) (Tek Papil)" },
    { hizmet: "Papil Oluşturma (Cerrahi Olmayan) (Tek Papil)" },
    { hizmet: "Fiberotomi" },
  ],
  ORTODONTI: [
    { hizmet: "Lateral Sefalometrik Film Analizi" },
    {
      hizmet:
        "Antero Posterior Sefalometrik Film Analizi (Frontal Film Analizi)",
    },
    { hizmet: "Kemik Yaşı Tayini" },
    { hizmet: "Ortodontik Fotoğraf" },
    { hizmet: "Ortodontik Fotoğraf Tetkiki" },
    { hizmet: "Ortodontik Model Yapımı" },
    { hizmet: "Ortodontik Model Analizi" },
    { hizmet: "Ara Dönem Sefalometrik Film Analizi" },
    { hizmet: "Ara Dönem Model Yapımı" },
    { hizmet: "Angle Sınıf I Anomalilerinin Ortodontik Tedavisi" },
    { hizmet: "Angle Sınıf II Anomalilerinin Ortodontik Tedavisi" },
    { hizmet: "Angle Sınıf III Anomalilerinin Ortodontik Tedavisi" },
    { hizmet: "Sabit Kapanış Yükseltici Tatbiki (Kısa Süreli)" },
    {
      hizmet:
        "Lingual Teknikle Angle Sınıf I Anomalilerinin Ortodontik Tedavisi",
    },
    {
      hizmet:
        "Lingual Teknikle Angle Sınıf II Anomalilerinin Ortodontik Tedavisi",
    },
    {
      hizmet:
        "Lingual Teknikle Angle Sınıf III Anomalilerinin Ortodontik Tedavisi",
    },
    { hizmet: "Açık Kapanışın Ortodontik Tedavisi" },
    { hizmet: "Önleyici Ortodontik Tedavi" },
    { hizmet: "Kısa Süreli Ortodontik Tedavi" },
    { hizmet: "Pekiştirme Tedavisi" },
    { hizmet: "Pekiştirme Aygıtı (Hawley Aygıtı vb.)" },
    { hizmet: "Sabit Pekiştirme Aygıtı (Lingual Retainer)" },
    { hizmet: "Dudak Yastıkçığı (Lip Bumper)" },
    { hizmet: "Tek Çeneyi İlgilendiren Aparey Yapımı (Vida Hariç)" },
    {
      hizmet:
        "Çift Çeneyi İlgilendiren Aparey Yapımı (Frankel Aygıtları - Aktivatör - Bionatör)",
    },
    { hizmet: "Vida Uygulaması (Tek Vida)" },
    { hizmet: "Sabit Fonksiyonel Aygıt Uygulaması" },
    { hizmet: "Kayıp Apareyin Yeniden Yapımı (Tek Çene)" },
    { hizmet: "Aparey Tamiri" },
    { hizmet: "Ağız Dışı Aparey Tatbiki (Headgear - Chincap)" },
    { hizmet: "Reverse Headgear" },
    { hizmet: "Düz Ark Teli Tatbiki (Tek Çene NİTİ Telleri)" },
    { hizmet: "Büküm İçeren Tel Tatbiki (Tek Çene)" },
    { hizmet: "Segmental Ark veya Tork Arkı Tatbiki" },
    { hizmet: "Bant Tatbiki (Tek Diş)" },
    { hizmet: "Braket Tatbiki (Tek Diş)" },
    { hizmet: "Lingual Braket Tatbiki (Tek Diş)" },
    { hizmet: "Düşen Bant Tatbiki (Tek Diş)" },
    { hizmet: "Düşen Braket Tatbiki (Tek Diş)" },
    { hizmet: "Bant veya Braket Çıkarılması (Tek Diş)" },
    { hizmet: "Lingual Ataçman Tatbiki" },
    { hizmet: "Lingual Ark" },
    { hizmet: "Nance Apareyi" },
    { hizmet: "Hızlı Maksiller Genişletme Apareyi" },
    { hizmet: "Preoperatif Dudak Damak Yarığı (Ortodontik Tedavi)" },
    { hizmet: "Postoperatif Dudak Damak Yarığı (Ortodontik Tedavi)" },
    { hizmet: "T.M.E. Splint Yapımı" },
    { hizmet: "Model Set-Up" },
    { hizmet: "Positioner Yapımı" },
    { hizmet: "Sefalometrik Cerrahi Planı" },
    { hizmet: "Ortodontik Modellerin Face - Bow ile Artikülatöre Taşınması" },
    { hizmet: "Model Cerrahisi" },
    { hizmet: "Oklüzal Cerrahi Splint (Tek Çene)" },
    { hizmet: "Ortodontik Ameliyat Arkı (Tek Çene)" },
    { hizmet: "Sürme Rehberliği" },
    { hizmet: "Ağız İçi Distalizasyon Apareyi" },
    { hizmet: "Gömülü Dişin Diş Dizisinde Yerine Yerleştirilmesi (Tek Diş)" },
    { hizmet: "Reserve Curve'li Niti Ark Tatbiki" },
    { hizmet: "Ortognatik Cerrahi Ortodontik Tedavisi" },
    { hizmet: "Mini Vida Uygulaması" },
    { hizmet: "İnterproksimal Aşındırma (Tek Diş)" },
    {
      hizmet:
        "Şeffaf Plaklar ile Ortodontik Tedavi (Hafif) (Plak Ücreti Hariç)",
    },
    {
      hizmet: "Şeffaf Plaklar ile Ortodontik Tedavi (Orta) (Plak Ücreti Hariç)",
    },
    {
      hizmet: "Şeffaf Plaklar ile Ortodontik Tedavi (Ağır) (Plak Ücreti Hariç)",
    },
  ],
};
