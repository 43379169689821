import React, { useState } from "react";

export const CustomTooltip = ({ text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <span
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className="relative inline-block"
    >
      {text?.length > 15 ? (
        <>
          {text.substring(0, 15)}...
          {showTooltip && (
            <span className="absolute z-10 bg-white border border-gray-300 p-2 rounded-md shadow-md">
              {text}
            </span>
          )}
        </>
      ) : (
        text
      )}
    </span>
  );
};
