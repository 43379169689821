import React, { useState, useEffect } from "react";
import Footer from "../../components/dashboard/Footer";
import ForgotPassword from "../../components/dashboard/ForgotPassword";
import LoginModal from "../../components/dashboard/LoginModal";
import NavBar from "../../components/dashboard/NavBar";
import RegisterModal from "../../components/dashboard/RegisterModal";
import { ilData } from "../../helpers/ilData";
import poliklinikpp from "../../assets/Poliklinikpp.png";
import { BsTelephone } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import axios from "axios";
import ikon from "../../assets/dashboardIntro.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import config from "../../config";

const Polyclinics = () => {
  const uRegister = useSelector((state) => state.uRegister);
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [polyclinics, setPolyclinics] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filtrelenmisPolyclinics, setFiltrelenmisPolyclinics] = useState([]);
  const [registerInfo, setRegisterInfo] = useState({
    il: "",
    ilce: "",
  });
  const [polyclinicDoctors, setPolyclinicDoctors] = useState([]);
  const navigate = useNavigate();

  const poli = async () => {
    const { data } = await axios.get(`${config.API_BASE_URL}pol_uye_index`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    setPolyclinics(data);
    setFiltrelenmisPolyclinics(data);
  };

  useEffect(() => {
    poli();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "il") {
      const selectedIl = ilData.find((il) => il["text"] === value);
      if (selectedIl) {
        setRegisterInfo((prevInfo) => ({
          ...prevInfo,
          [id]: value,
          ilce: "", // Reset ilce when il changes
        }));
      }
    } else if (id === "ilce") {
      setRegisterInfo((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }));
    }
  };

  const handleSearch = () => {
    setFiltrelenmisPolyclinics(
      polyclinics.filter((polyclinic) =>
        polyclinic.pol_adi
          .toLowerCase("tr-TR")
          .includes(searchText.toLowerCase("tr-TR"))
      )
    );
  };

  useEffect(() => {
    const { il, ilce } = registerInfo;
    if (il !== "") {
      if (ilce !== "") {
        setFiltrelenmisPolyclinics(
          polyclinics.filter(
            (polyclinic) =>
              polyclinic?.pol_il === il && polyclinic?.pol_ilce === ilce
          )
        );
      } else {
        setFiltrelenmisPolyclinics(
          polyclinics.filter((polyclinic) => polyclinic?.pol_il === il)
        );
      }
    } else {
      setFiltrelenmisPolyclinics(polyclinics);
    }
  }, [registerInfo, polyclinics]);

  const getPolyclinicDoctors = async (poli) => {
    try {
      const { data } = await axios.get(
        `${config.API_BASE_URL}poliklinikteki_doktorlar?pol_vergi_no=${poli?.pol_vergi_no}`
      );
      // console.log(data);
      if (data !== "false") {
        setPolyclinicDoctors(data); // doktorları aldık
        // console.log(polyclinicDoctors);
        // getNoDoctors(thisDay, data); //izinli doktorları elemek için
        // setShowDate(true); //recordpatient sayfasında tarih görünür olacak
        // handleScrollToTopClick("doktor"); // doktor kartlarına scroll
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Tıklandığında scroll üste kaysın
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Sayfanın pürüzsüzce kaydırılmasını sağlar.
    });
  };

  // her sayfa yüklendiğinde scroll pozisyonunu başa alacaktır.
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  // console.log(ilData);
  return (
    <div className="h-full relative">
      <ScrollToTop />
      <NavBar setShowLogin={setShowLogin} />
      <div>
        <div className="bg-container h-full ">
          <section className="flex justify-center pt-24 pb-40 w-full min-h-screen">
            <div className="w-4/5">
              <div className="flex justify-between w-full">
                <h1
                  style={{ marginTop: "30px" }}
                  className="text-3xl text-blue1"
                >
                  Poliklinikler
                </h1>
                <div className="form-control relative w-1/4 mt-4 xs:hidden md:block">
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Poliklinik adı"
                    className="input rounded-3xl w-full tracking-widest"
                  />
                  <button
                    onClick={handleSearch}
                    className="btn btn-ghost btn-circle absolute right-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {/* divider */}
              <div className="border-b mb-4 text-blue1"></div>

              <div className="flex justify-between my-8 mx-2">
                <p>
                  Aradığınız kriterlere uygun{" "}
                  <span className="font-bold">
                    {filtrelenmisPolyclinics.length} adet
                  </span>{" "}
                  poliklinik bulundu.
                </p>
                <div className="grid grid-cols-2 gap-2">
                  <select
                    name="il"
                    id="il"
                    className="select select-sm text-gray"
                    onChange={handleChange}
                  >
                    <option value="" selected>
                      İl Seçiniz
                    </option>
                    {ilData.map((il, index) => (
                      <option value={il["text"]} key={index}>
                        {il["text"]}
                      </option>
                    ))}
                  </select>
                  <select
                    name="ilce"
                    id="ilce"
                    className="select select-sm text-gray"
                    onChange={handleChange}
                    value={registerInfo.ilce}
                  >
                    <option value="">İlçe Seçiniz</option>
                    {registerInfo.il !== "" &&
                      ilData
                        .find((data) => data["text"] === registerInfo.il)
                        .districts.map((ilce, index) => (
                          <option value={ilce["text"]} key={index}>
                            {ilce["text"]}
                          </option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
                {filtrelenmisPolyclinics.length > 0 ? (
                  filtrelenmisPolyclinics.map((polyclinic, index) => (
                    <div
                      className="card max-h-[23.5rem] bg-white pb-4 relative"
                      key={index}
                      style={{ maxWidth: "250px" }}
                    >
                      <div>
                        <figure className="h-32">
                          <img
                            src={polyclinic.pol_logo || ikon}
                            alt="profile"
                            className="w-4/5 h-5/6 mt-4 ml-4"
                            style={{ objectFit: "contain" }}
                          />
                        </figure>
                        <div className="border-b-2 border-gray opacity-80 w-[90%] mx-auto"></div>
                        <div
                          style={{ marginTop: "-25px" }}
                          className="card-body h-2/3 px-4"
                        >
                          <div className="h-20 line-clamp-2 overflow-hidden min-h-8">
                            <h5 className="text-blue1 text-center uppercase px-4 line-clamp-2 ">
                              {polyclinic.pol_adi}
                            </h5>
                          </div>

                          <div className="mb-6 h-20">
                            <div className="flex justify-start gap-1 mt-2">
                              <BsTelephone
                                style={{ minWidth: "50px" }}
                                className="text-xl opacity-50 mt-2"
                              />
                              <div className="text-13">
                                <p className="mt-2">{polyclinic.pol_tel}</p>
                              </div>
                            </div>
                            <div className="flex justify-start gap-1 mt-2">
                              <IoLocationOutline
                                style={{ minWidth: "50px" }}
                                className="text-2xl opacity-50 mt-1"
                              />
                              <div>
                                <p className="text-[14px]">
                                  {polyclinic.pol_il}
                                </p>
                                <p className="text-[14px]">
                                  {polyclinic.pol_ilce}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center">
                            <button
                              className="btn btn-active btn-sm bg-blue1 hover:bg-white hover:text-blue1 hover:border-blue1 capitalize"
                              onClick={() => {
                                navigate(`/randevu-al/${polyclinic.id}`, {
                                  state: polyclinic,
                                  // polyclinicDoctors,
                                  // setPolyclinicDoctors,
                                });
                                getPolyclinicDoctors(polyclinic);
                                scrollToTop();
                              }}
                            >
                              Randevu Al
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-center ">
                    <p className="capitalize w-[30rem] p-2 rounded-[39px] shadow-lg mb-4 text-center">
                      Poliklinik Bulunamadı !
                    </p>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
      {showRegister ? (
        <RegisterModal
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
        />
      ) : null}

      {showLogin ? (
        <LoginModal
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}

      {showForgotPassword ? (
        <ForgotPassword
          setShowLogin={setShowLogin}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}
    </div>
  );
};

export default Polyclinics;
