import React, { useEffect, useState } from "react";
import Checkbox from "./Checkbox";
import quadrant1 from "../../../assets/quadrant1.png";
import quadrant2 from "../../../assets/quadrant2.png";
import quadrant3 from "../../../assets/quadrant3.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../../config";

const ModalDocWorkHour = ({ doctorDiplomaNo, setOpen, getAppointment }) => {
  const uRegister = useSelector((state) => state.uRegister);
  const [searchDoctor, setSearchDoctor] = useState([]);

  const mesaiBilgileriInitial = {
    d_diploma_no: "",
    pol_vergi_no: "",
    carsamba_1: 0,
    carsamba_2: 0,
    carsamba_3: 0,
    cuma_1: 0,
    cuma_2: 0,
    cuma_3: 0,
    cumartesi_1: 0,
    cumartesi_2: 0,
    cumartesi_3: 0,
    pazar_1: 0,
    pazar_2: 0,
    pazar_3: 0,
    pazartesi_1: 0,
    pazartesi_2: 0,
    pazartesi_3: 0,
    persembe_1: 0,
    persembe_2: 0,
    persembe_3: 0,
    sali_1: 0,
    sali_2: 0,
    sali_3: 0,
  };

  useEffect(() => {
    // Seçilen Doktorun Mesai bilgilerini getir
    const handleSearchDoctor = async () => {
      try {
        const { data } = await axios.get(
          `${config.API_BASE_URL}dok_uye/${doctorDiplomaNo}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );

        if (data.status && data.status === 400) {
          toast.error(data.message);
          // setGoster(false);
        } else {
          if (data["mesai_bilgileri"].length > 0) {
            const mesaiBilgileri = data["mesai_bilgileri"][0];
            setSearchDoctor(mesaiBilgileri);
          } else {
            setSearchDoctor(mesaiBilgileriInitial); // hepsini sıfır ayarladık
            setSearchDoctor((doctors) => ({
              ...doctors,
              pol_vergi_no: data.pol_vergi_no,
              d_diploma_no: data.d_diploma_no,
            }));
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleSearchDoctor();
  }, []);

  // mesai_guncelle
  const handleUpdateHours = async () => {
    const url = `${config.API_BASE_URL}klinik_doktorlar/mesai_guncelle`;

    const requestBody = {
      id: searchDoctor["id"],
      pol_vergi_no: searchDoctor["pol_vergi_no"],
      d_diploma_no: searchDoctor["d_diploma_no"],
      pazartesi_1: searchDoctor["pazartesi_1"],
      pazartesi_2: searchDoctor["pazartesi_2"],
      pazartesi_3: searchDoctor["pazartesi_3"],
      sali_1: searchDoctor["sali_1"],
      sali_2: searchDoctor["sali_2"],
      sali_3: searchDoctor["sali_3"],
      carsamba_1: searchDoctor["carsamba_1"],
      carsamba_2: searchDoctor["carsamba_2"],
      carsamba_3: searchDoctor["carsamba_3"],
      persembe_1: searchDoctor["persembe_1"],
      persembe_2: searchDoctor["persembe_2"],
      persembe_3: searchDoctor["persembe_3"],
      cuma_1: searchDoctor["cuma_1"],
      cuma_2: searchDoctor["cuma_2"],
      cuma_3: searchDoctor["cuma_3"],
      cumartesi_1: searchDoctor["cumartesi_1"],
      cumartesi_2: searchDoctor["cumartesi_2"],
      cumartesi_3: searchDoctor["cumartesi_3"],
      pazar_1: searchDoctor["pazar_1"],
      pazar_2: searchDoctor["pazar_2"],
      pazar_3: searchDoctor["pazar_3"],
    };

    try {
      const response = await axios.post(url, requestBody, {
        headers: {
          Authorization: "Bearer " + uRegister.token, // Authorization},
        },
      });
      // Handle the response data
      if (response.data.status && response.data.status === 400) {
        toast.error(
          "Düzenleme Yapabilmek için önce mesai bilgisi girişi yapmalısınız. Doktor ekle sayfasından Mesai ekleyebilirsiniz."
        );
        // console.log(response.data.message);
      } else {
        // console.log("response.data", response.data);
        toast.success("Mesai bilgileri kaydedildi.");
        getAppointment();
        setOpen(false);
      }
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  return (
    <div className=" justify-center items-center flex  overflow-x-hidden overflow-y-auto fixed xs:inset-x-[2.5vw] sm:inset-x-[calc(50vw-14rem)] lg:inset-x-[calc(50vw-30rem)] inset-y-[calc(50vh-216px)] min-h-max min-w-max z-50 outline-none focus:outline-none">
      <div className=" relative w-auto mx-auto  p-3">
        <div className=" rounded-lg shadow-lg relative flex flex-row xs:w-[95vw] sm:w-[28rem] lg:w-[73vw]  bg-white outline-none focus:outline-none">
          <div className="overflow-x-auto pr-5">
            <h1 className="text-2xl font-semibold antialiased leading-loose ml-3">
              Mesai Durumunu Güncelle
            </h1>
            <table className="table w-full p-10">
              {/* <!-- head --> */}
              <thead className="text-center">
                <tr>
                  <th className="bg-blue1 text-white"></th>
                  <th className="bg-blue1 text-white">PAZARTESİ</th>
                  <th className="bg-blue1 text-white">SALI</th>
                  <th className="bg-blue1 text-white">ÇARŞAMBA</th>
                  <th className="bg-blue1 text-white">PERŞEMBE</th>
                  <th className="bg-blue1 text-white">CUMA</th>
                  <th className="bg-blue1 text-white">CUMAERTESİ</th>
                  <th className="bg-blue1 text-white">PAZAR</th>
                </tr>
              </thead>
              
              <tbody>
                {/* Sabah */}
                <tr>
                  <th>
                    <img src={quadrant1} alt="quadrant" className="h-10" />
                  </th>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"pazartesi_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"sali_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"carsamba_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"persembe_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"cuma_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"cumartesi_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"pazar_1"}
                    />
                  </td>
                </tr>
                {/* Öğle */}
                <tr>
                  <th>
                    <img src={quadrant2} alt="quadrant" className="h-10" />
                  </th>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"pazartesi_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"sali_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"carsamba_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"persembe_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"cuma_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"cumartesi_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"pazar_2"}
                    />
                  </td>
                </tr>
                {/* Akşam */}
                <tr>
                  <th>
                    <img src={quadrant3} alt="quadrant" className="h-10" />
                  </th>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"pazartesi_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"sali_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"carsamba_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"persembe_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"cuma_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"cumartesi_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"pazar_3"}
                    />
                  </td>
                </tr>
              </tbody>
              
            </table>
            <div className="w-full  flex justify-end align-center gap-3">
            <button
              onClick={handleUpdateHours}
              className="btn btn-active btn-sm rounded-full my-6 p-2 px-8 bg-blue1  hover:bg-white hover:text-blue1 hover:border-blue1"
            >
              KAYDET
            </button>
            <button
              onClick={() => setOpen(false)}
              className="btn btn-active btn-sm rounded-full my-6 p-2 px-8 bg-[#dadbdd] text-[#5616f5] items-center  border-white  hover:bg-white hover:text-blue1 hover:border-blue1"
            >
              VAZGEÇ
            </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        onClick={() => setOpen(false)}
        className="opacity-25 fixed inset-0 z-40 bg-black h-full w-full"
      ></div> */}
    </div>
  );
};

export default ModalDocWorkHour;
