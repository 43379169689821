import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";

const DateInput = () => {
    const [dateValue, setDateValue] = useState(new Date());

    const handleDateChange = (date) => {
        setDateValue(date);
    };

    return (
        <div className="w-full h-full flex items-center justify-center">
            <DatePicker
                selected={dateValue}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                showPopperArrow={false}
                popperModifiers={{
                    offset: {
                        enabled: true,
                        offset: "0px, 10px",
                    },
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: "viewport",
                    },
                }}
                open
                locale={tr}
                className="w-full h-full"
            />
        </div>
    );
};

export default DateInput;
