import axios from "axios";
import React, { useEffect, useState } from "react";


const AppointmentDate2 = ({ setDate, date, patientInfo, setPatientInfo,patientInfoOriginal}) => {

    const date1 = new Date();
    const thisDay = date1.toISOString();
    const tomorrow = new Date(date1);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDay = tomorrow.toISOString();
    const twoWeek = new Date(date1);
    twoWeek.setDate(twoWeek.getDate() + 14);
    const nextTwoWeek = twoWeek.toISOString().slice(0, 10);
    const beforeThisDay = date1.toISOString().slice(0, 10);

    const handleChangePatientInfo = (newDate) => {
        setPatientInfo(patientInfoOriginal.filter(patient => patient.randevu_tarih === newDate))
    }

    // useEffect(() => {
    //     setPatientInfo(patientInfoOriginal.filter(patient => patient.randevu_tarih === thisDay.slice(0, 10)))
    // }, [patientInfoOriginal])
    
    return (
        <section className="w-full mx-auto">
            <article className="flex justify-end mb-2 mx-2 ">
                {/* <h4>Tarih</h4> */}
                <article className="flex items-center gap-2">
                    <button
                        onClick={(e) => {
                            setDate(thisDay.slice(0, 10));
                            handleChangePatientInfo(thisDay.slice(0, 10))
                        }}
                        className={
                            date.slice(0, 10) === thisDay.slice(0, 10)
                                ? "btn-primary mr-3 btn btn-active btn-sm bg-blue1 gap-2 rounded-2 hover:bg-blue1 capitalize"
                                : "btn-primary mr-3 btn btn-sm bg-white text-blue1 gap-2 rounded-2 hover:text-white capitalize"
                        }
                    >
                        Bugün
                    </button>
                    <button
                        onClick={(e) => {
                            setDate(tomorrowDay.slice(0, 10));
                            handleChangePatientInfo(tomorrowDay.slice(0, 10))
                        }}
                        className={
                            date.slice(0, 10) === tomorrowDay.slice(0, 10)
                                ? "btn-primary mr-3 btn btn-active btn-sm bg-blue1 gap-2 rounded-2 hover:bg-blue1 capitalize "
                                : "btn-primary mr-3 btn btn-sm bg-white text-blue1 gap-2 rounded-2 hover:text-white capitalize"
                        }
                    >
                        Yarın
                    </button>
                    <div className="form-control">
                        <label
                            className="input-group input-group-vertical cursor-pointer "
                            htmlFor="appointmentDate"
                        >
                            <input
                                className="input input-sm"
                                type="date"
                                name="appointmentDate"
                                id="appointmentDate"
                                value={date.slice(0, 10)}
                                // min={beforeThisDay}
                                // max={nextTwoWeek}
                                 onChange={(e) =>{
                                    setDate(e.target.value);
                                    handleChangePatientInfo(e.target.value.slice(0, 10))
                                }
                            }
                            />
                        </label>
                    </div>
                </article>
            </article>

        </section>
    );
};

export default AppointmentDate2;
