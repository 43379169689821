import React from "react";
import { useSelector } from "react-redux";
import DoctorHeader from "../../../components/doctor/doctorDashboard/DoctorHeader";
import DoktorProfile from "../../../components/doctor/doctorDashboard/DoktorProfile";
import Layout from "../../../components/doctor/Layout";

const DoctorProfile = () => {
  const uRegister = useSelector((state) => state.uRegister);
  const doctorData = {
    id: uRegister.d_id,
    name: uRegister.d_adi,
    surname: uRegister.d_soyadi,
    title: uRegister.d_unvan,
    city: uRegister.pol_il,
    district: uRegister.pol_ilce,
    address: uRegister.pol_adres,
    phone: uRegister.pol_tel,
    email: uRegister.d_mail,
    graduateUniversity: uRegister.mezuniyet,
    graduateYear: uRegister.mezuniyet_tarihi,
    workingSince: uRegister.mesai_baslangic,
    workingDays: "Hafta içi hergün",
    workingHours: "09:00-17:00",
    profileImg: uRegister.d_resim, //uRegister.d_resim
  };

  return (
    <>
      <Layout />
      <div
        className={`absolute bg-container  mt-20 right-0 p-6 min-h-[calc(100vh-80px)] ${
          uRegister ? "w-[calc(100%-250px)]" : "w-[calc(100%-100px)]"
        } transition-w ease-in-out duration-500`}
      >
        <div className="bg-white">
          <div className="divide-y-2 divide-mainPageP p-1">
            <DoctorHeader />
            <DoktorProfile doctorData={doctorData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorProfile;
