export const universiteler = [
  { id: 1, name: "Adana Bilim ve Teknoloji Üniversitesi", city: "Adana" },
  { id: 2, name: "Adıyaman Üniversitesi", city: "Adıyaman" },
  {
    id: 3,
    name: "Afyonkarahisar Sağlık Bilimleri Üniversitesi",
    city: "Afyonkarahisar",
  },
  { id: 4, name: "Ağrı İbrahim Çeçen Üniversitesi", city: "Ağrı" },
  { id: 5, name: "Aksaray Üniversitesi", city: "Aksaray" },
  { id: 6, name: "Amasya Üniversitesi", city: "Amasya" },
  { id: 7, name: "Anadolu Üniversitesi", city: "Eskişehir" },
  { id: 8, name: "Ankara Hacı Bayram Veli Üniversitesi", city: "Ankara" },
  { id: 9, name: "Ankara Üniversitesi", city: "Ankara" },
  { id: 10, name: "Ardahan Üniversitesi", city: "Ardahan" },
  { id: 11, name: "Artvin Çoruh Üniversitesi", city: "Artvin" },
  { id: 12, name: "Atatürk Üniversitesi", city: "Erzurum" },
  { id: 13, name: "Balıkesir Üniversitesi", city: "Balıkesir" },
  { id: 14, name: "Bandırma Onyedi Eylül Üniversitesi", city: "Balıkesir" },
  { id: 15, name: "Bartın Üniversitesi", city: "Bartın" },
  { id: 16, name: "Batman Üniversitesi", city: "Batman" },
  { id: 17, name: "Bayburt Üniversitesi", city: "Bayburt" },
  { id: 18, name: "Bilecik Şeyh Edebali Üniversitesi", city: "Bilecik" },
  { id: 19, name: "Bingöl Üniversitesi", city: "Bingöl" },
  { id: 20, name: "Bitlis Eren Üniversitesi", city: "Bitlis" },
  { id: 21, name: "Boğaziçi Üniversitesi", city: "İstanbul" },
  { id: 22, name: "Bozok Üniversitesi", city: "Yozgat" },
  { id: 23, name: "Bülent Ecevit Üniversitesi", city: "Zonguldak" },
  { id: 24, name: "Bursa Teknik Üniversitesi", city: "Bursa" },
  { id: 25, name: "Celal Bayar Üniversitesi", city: "Manisa" },
  { id: 26, name: "Cumhuriyet Üniversitesi", city: "Sivas" },
  { id: 27, name: "Çanakkale Onsekiz Mart Üniversitesi", city: "Çanakkale" },
  { id: 28, name: "Çankırı Karatekin Üniversitesi", city: "Çankırı" },
  { id: 29, name: "Çukurova Üniversitesi", city: "Adana" },
  { id: 30, name: "Deniz Harp Okulu", city: "İstanbul" },
  { id: 31, name: "Dicle Üniversitesi", city: "Diyarbakır" },
  { id: 32, name: "Dokuz Eylül Üniversitesi", city: "İzmir" },
  { id: 33, name: "Dumlupınar Üniversitesi", city: "Kütahya" },
  { id: 34, name: "Düzce Üniversitesi", city: "Düzce" },
  { id: 35, name: "Ege Üniversitesi", city: "İzmir" },
  { id: 36, name: "Erciyes Üniversitesi", city: "Kayseri" },
  { id: 37, name: "Erzincan Binali Yıldırım Üniversitesi", city: "Erzincan" },
  { id: 38, name: "Erzurum Teknik Üniversitesi", city: "Erzurum" },
  { id: 39, name: "Eskişehir Osmangazi Üniversitesi", city: "Eskişehir" },
  { id: 40, name: "Fırat Üniversitesi", city: "Elazığ" },
  { id: 41, name: "Galatasaray Üniversitesi", city: "İstanbul" },
  { id: 42, name: "Gazi Üniversitesi", city: "Ankara" },
  { id: 43, name: "Gaziantep Üniversitesi", city: "Gaziantep" },
  { id: 44, name: "Gaziosmanpaşa Üniversitesi", city: "Tokat" },
  { id: 45, name: "Gebze Teknik Üniversitesi", city: "Kocaeli" },
  { id: 46, name: "Giresun Üniversitesi", city: "Giresun" },
  { id: 47, name: "Gümüşhane Üniversitesi", city: "Gümüşhane" },
  { id: 48, name: "Hacettepe Üniversitesi", city: "Ankara" },
  { id: 49, name: "Hakkari Üniversitesi", city: "Hakkari" },
  { id: 50, name: "Harran Üniversitesi", city: "Şanlıurfa" },
  { id: 51, name: "Hitit Üniversitesi", city: "Çorum" },
  { id: 52, name: "Iğdır Üniversitesi", city: "Iğdır" },
  { id: 53, name: "İnönü Üniversitesi", city: "Malatya" },
  { id: 54, name: "İskenderun Teknik Üniversitesi", city: "Hatay" },
  { id: 55, name: "İstanbul Medeniyet Üniversitesi", city: "İstanbul" },
  { id: 56, name: "İstanbul Teknik Üniversitesi", city: "İstanbul" },
  { id: 57, name: "İstanbul Üniversitesi", city: "İstanbul" },
  { id: 58, name: "İzmir Bakırçay Üniversitesi", city: "İzmir" },
  { id: 59, name: "İzmir Demokrasi Üniversitesi", city: "İzmir" },
  { id: 60, name: "İzmir Kâtip Çelebi Üniversitesi", city: "İzmir" },
  { id: 61, name: "İzmir Yüksek Teknoloji Enstitüsü", city: "İzmir" },
  { id: 62, name: "Kafkas Üniversitesi", city: "Kars" },
  {
    id: 63,
    name: "Kahramanmaraş Sütçü İmam Üniversitesi",
    city: "Kahramanmaraş",
  },
  { id: 64, name: "Karabük Üniversitesi", city: "Karabük" },
  { id: 65, name: "Karadeniz Teknik Üniversitesi", city: "Trabzon" },
  { id: 66, name: "Karamanoğlu Mehmetbey Üniversitesi", city: "Karaman" },
  { id: 67, name: "Kastamonu Üniversitesi", city: "Kastamonu" },
  { id: 68, name: "Kırıkkale Üniversitesi", city: "Kırıkkale" },
  { id: 69, name: "Kırklareli Üniversitesi", city: "Kırklareli" },
  { id: 70, name: "Kırşehir Ahi Evran Üniversitesi", city: "Kırşehir" },
  { id: 71, name: "Kilis 7 Aralık Üniversitesi", city: "Kilis" },
  { id: 72, name: "Kocaeli Üniversitesi", city: "Kocaeli" },
  { id: 73, name: "Konya Teknik Üniversitesi", city: "Konya" },
  { id: 74, name: "KTO Karatay Üniversitesi", city: "Konya" },
  { id: 75, name: "Maltepe Üniversitesi", city: "İstanbul" },
  { id: 76, name: "Manisa Celal Bayar Üniversitesi", city: "Manisa" },
  { id: 77, name: "Mardin Artuklu Üniversitesi", city: "Mardin" },
  { id: 78, name: "Marmara Üniversitesi", city: "İstanbul" },
  { id: 79, name: "Mehmet Akif Ersoy Üniversitesi", city: "Burdur" },
  { id: 80, name: "Mersin Üniversitesi", city: "Mersin" },
  { id: 81, name: "Mersin Teknik Üniversitesi", city: "Mersin" },
  { id: 82, name: "Mimar Sinan Güzel Sanatlar Üniversitesi", city: "İstanbul" },
  { id: 83, name: "Muğla Sıtkı Koçman Üniversitesi", city: "Muğla" },
  { id: 84, name: "Muş Alparslan Üniversitesi", city: "Muş" },
  { id: 85, name: "Namık Kemal Üniversitesi", city: "Tekirdağ" },
  { id: 86, name: "Necmettin Erbakan Üniversitesi", city: "Konya" },
  { id: 87, name: "Nevşehir Hacı Bektaş Veli Üniversitesi", city: "Nevşehir" },
  { id: 88, name: "Niğde Ömer Halisdemir Üniversitesi", city: "Niğde" },
  { id: 89, name: "Ordu Üniversitesi", city: "Ordu" },
  { id: 90, name: "Orta Doğu Teknik Üniversitesi", city: "Ankara" },
  { id: 91, name: "Osmaniye Korkut Ata Üniversitesi", city: "Osmaniye" },
  { id: 92, name: "Pamukkale Üniversitesi", city: "Denizli" },
  { id: 93, name: "Recep Tayyip Erdoğan Üniversitesi", city: "Rize" },
  { id: 94, name: "Sakarya Üniversitesi", city: "Sakarya" },
  { id: 95, name: "Sakarya Uygulamalı Bilimler Üniversitesi", city: "Sakarya" },
  { id: 96, name: "Selçuk Üniversitesi", city: "Konya" },
  { id: 97, name: "Siirt Üniversitesi", city: "Siirt" },
  { id: 98, name: "Sinop Üniversitesi", city: "Sinop" },
  { id: 99, name: "Süleyman Demirel Üniversitesi", city: "Isparta" },
  { id: 100, name: "Şırnak Üniversitesi", city: "Şırnak" },
  { id: 101, name: "Trakya Üniversitesi", city: "Edirne" },
  { id: 102, name: "Tunceli Üniversitesi", city: "Tunceli" },
  { id: 103, name: "Turizm Haftası Üniversitesi", city: "İstanbul" },
  { id: 104, name: "Türk-Alman Üniversitesi", city: "İstanbul" },
  {
    id: 105,
    name: "Türk-Japon Bilim ve Teknoloji Üniversitesi",
    city: "İstanbul",
  },
  { id: 106, name: "Uşak Üniversitesi", city: "Uşak" },
  { id: 107, name: "Yalova Üniversitesi", city: "Yalova" },
  { id: 108, name: "Yıldırım Beyazıt Üniversitesi", city: "Ankara" },
  { id: 109, name: "Yıldız Teknik Üniversitesi", city: "İstanbul" },
  { id: 110, name: "Yüzüncü Yıl Üniversitesi", city: "Van" },
];
