import React from 'react'

const date1 = new Date();
date1.setHours(date1.getHours() + 3); // UTC+3 saat dilimine ayarlanır
const thisDayIndex = date1.getDay();
const thisMonthIndex = date1.getMonth();

const daysOfWeek = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"];
const thisDayName = daysOfWeek[thisDayIndex];

const monthsOfYear = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
const thisMonthName = monthsOfYear[thisMonthIndex];

const thisDay = date1.toISOString();

const ClinicStatisticComponent = ({ data }) => {
    return (
        <div className="grid grid-cols-3 gap-3 p-4 py-6">
            <div className="bg-container p-2">
                <div className="p-2">
                    <h6 className="font-bold">BUGÜN</h6>
                    <p className="opacity-70 text-[12px] uppercase">
                        {thisDayName}
                    </p>
                </div>
                <div className="grid grid-rows-2 grid-flow-col gap-4">
                    <div
                        className="row-span-3 text-blue2 text-6xl font-bold text-center"
                        style={{ fontSize: "4rem" }}
                    >
                        {Number(data.bugun_doktor_randevu_sayisi) + Number(data.bugun_hasta_randevu_sayisi)}
                    </div>
                    <div className="row-span-2 col-span-2 opacity-90">
                        <p className="mt-4">{data.bugun_doktor_randevu_sayisi} DOKTOR RANDEVUSU</p>
                        <p>{data.bugun_hasta_randevu_sayisi} HASTA RANDEVUSU</p>
                        <p>{data.bugun_sonlanan_tedavi_sayisi} BU AY SONLANAN</p>
                    </div>
                </div>
            </div>
            <div className="bg-container p-2">
                <div className="p-2">
                    <h6 className="font-bold">BU AY</h6>
                    <p className="opacity-70 text-[12px] uppercase">
                        {thisMonthName}
                    </p>
                </div>
                <div className="grid grid-rows-2 grid-flow-col gap-4">
                    <div
                        className="row-span-3 text-Sky-500 text-6xl font-bold text-center"
                        style={{ fontSize: "4rem" }}
                    >
                        {Number(data.bu_ay_doktor_randevu_sayisi) + Number(data.bu_ay_hasta_randevu_sayisi)}
                    </div>
                    <div className="row-span-2 col-span-2 opacity-90">
                    <p className="mt-4">{data.bu_ay_doktor_randevu_sayisi} DOKTOR RANDEVUSU</p>
                        <p>{data.bu_ay_hasta_randevu_sayisi} HASTA RANDEVUSU</p>
                        <p>{data.bu_ay_sonlanan_tedavi_sayisi} BU AY SONLANAN</p>
                    </div>
                </div>
            </div>
            <div className="bg-container p-2">
                <div className="p-2">
                    <h6 className="font-bold">BU YIL</h6>
                    <p className="opacity-70 text-[12px]">{thisDay.slice(0,4)}</p>
                </div>
                <div className="grid grid-rows-2 grid-flow-col gap-4">
                    <div
                        className="row-span-3 text-Rose-400 text-6xl font-bold text-center"
                        style={{ fontSize: "4rem" }}
                    >
                         {Number(data.bu_yil_doktor_randevu_sayisi) + Number(data.bu_yil_hasta_randevu_sayisi)}
                    </div>
                    <div className="row-span-2 col-span-2 opacity-90">
                    <p className="mt-4">{data.bu_yil_doktor_randevu_sayisi} DOKTOR RANDEVUSU</p>
                        <p>{data.bu_yil_hasta_randevu_sayisi} HASTA RANDEVUSU</p>
                        <p>{data.bu_yil_sonlanan_tedavi_sayisi} BU YIL SONLANAN</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClinicStatisticComponent