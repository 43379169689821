import React, { useEffect, useState } from "react";
import quadrant1 from "../../../assets/quadrant1.png";
import quadrant2 from "../../../assets/quadrant2.png";
import quadrant3 from "../../../assets/quadrant3.png";
import doctorUser from "../../../assets/doctorUser.png";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useSelector } from "react-redux";
import Checkbox from "./Checkbox";
import { toast } from "react-toastify";
import * as yup from "yup";
import { universiteler } from "../../../helpers/universiteler";
import Select from "react-select";
import ModalDocWorkHour from "./ModalDocWorkHour";
import config from "../../../config";

const schema = yup.object().shape({
  d_adi: yup.string().required("Adınız zorunludur."),
  d_soyadi: yup.string().required("Soyadınız zorunludur."),
  d_unvan: yup.string(),
  d_diploma_no: yup
    .string()
    .required("Diploma numarası zorunludur.")
    .length(10, "Diploma numarası 10 haneli olmalıdır."),
  d_tel: yup
    .string()
    // .matches(
    //   /^0\(\d{3}\) \d{3} \d{2} \d{2}$/,
    //   "Geçerli bir telefon numarası formatı değil. Arada boşluk olmadan 11 haneli telefon numarasını giriniz."
    // ) // Telefon numarası formatını doğrulamak için regex
    .required("Telefon numarası zorunludur"),
  d_mail: yup
    .string()
    .email("Geçerli bir e-posta adresi giriniz.")
    .required("E-posta adresi zorunludur.")
    .matches(/.+\.com$/, "e-posta adresi .com uzantılı olmalı."),
  d_resim: yup.mixed().required("Doktor resmi zorunludur."),
  d_adres: yup.string().required("Adresiniz zorunludur."),
  mezuniyet: yup.string().required("Mezuniyet bilgisi zorunludur."),
  mezuniyet_tarihi: yup.date().required("Mezuniyet tarihi zorunludur."),
  mesai_baslangic: yup.date().required("Mesai başlangıç tarihi zorunludur."),
  password: yup
    .string()
    .required("Şifre zorunludur.")
    .min(8, "Şifre en az 8 karakter olmalıdır.")
    .max(16, "Şifre en fazla 16 karakter olmalıdır.")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[+*/-])[a-zA-Z\d]+[^+*/-]$/, {
      message: "Geçersiz şifre formatı",
    }),
  passwordTwo: yup
    .string()
    .oneOf([yup.ref("password"), null], "Şifreler eşleşmiyor.")
    .required("Şifre tekrarı zorunludur."),
  // .nullable(), // Null değerine izin ver
});
const formatPhoneNumber = (phoneNumber) => {
  // Telefon numarasını uygun formata getirme
  phoneNumber = phoneNumber.replace(/\D/g, ""); // Sadece sayısal karakterleri tutar
  const match = phoneNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    return `0(${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
  }
  return phoneNumber; // Eğer format uygun değilse, inputa girilen değeri olduğu gibi geri döndürür
};

const NewDoctorAddPage = () => {
  const mesaiBilgileriInitial = {
    d_diploma_no: "",
    pol_vergi_no: "",
    carsamba_1: 0,
    carsamba_2: 0,
    carsamba_3: 0,
    cuma_1: 0,
    cuma_2: 0,
    cuma_3: 0,
    cumartesi_1: 0,
    cumartesi_2: 0,
    cumartesi_3: 0,
    pazar_1: 0,
    pazar_2: 0,
    pazar_3: 0,
    pazartesi_1: 0,
    pazartesi_2: 0,
    pazartesi_3: 0,
    persembe_1: 0,
    persembe_2: 0,
    persembe_3: 0,
    sali_1: 0,
    sali_2: 0,
    sali_3: 0,
  };

  const dateNow = new Date();
  const uRegister = useSelector((state) => state.uRegister);

  const [myDataInitial, setMyDataInitial] = useState({
    d_adi: "",
    d_soyadi: "",
    d_unvan: "",
    d_diploma_no: "",
    pol_vergi_no: "",
    cinsiyet: "",
    d_tel: "",
    d_mail: "",
    d_resim: "",
    d_adres: "",
    mezuniyet: "",
    mezuniyet_tarihi: dateNow,
    mesai_baslangic: dateNow,
    password: "",
    passwordTwo: "",
  });

  const [selectedUniversities, setSelectedUniversities] = useState([]);
  const [goster, setGoster] = useState(false);
  const [files, setFiles] = useState([]);
  const [imageTrue, setImageTrue] = useState(false);
  const [searchDoctorDiplomaNo, setSearchDoctorDiplomaNo] = useState("");
  const [searchDoctor, setSearchDoctor] = useState([]);
  const [passwordTwo, setPasswordTwo] = useState();

  const [setMyData, setSetMyData] = useState(myDataInitial);
  const today = new Date().toISOString().split("T")[0];

  const [errors, setErrors] = useState({});

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        // const imagePath = file ? URL.createObjectURL(file) : ''; // blob için burayı kullan
        setSetMyData((prevState) => ({
          ...prevState,
          d_resim: file, // d_resim: imagePath  blob için bunu kullan
        }));

        setFiles([
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ]);
      }
    },
  });

  const images = files.map((file) => (
    <img
      src={file.preview}
      key={file.name}
      alt="preview"
      className="object-cover"
    />
  ));

  useEffect(() => {
    if (images.length > 0) {
      setImageTrue(true);
    }
  }, [images]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedInfo = { ...setMyData, [name]: value };

    const onlyLetters = /^[A-Za-zğüşıöçĞÜŞİÖÇ\s]*$/;

    if (
      (name === "d_adi" || name === "d_soyadi" || name === "d_unvan") &&
      !onlyLetters.test(value)
    ) {
      // Eğer sadece harfler içermiyorsa, değeri güncelleme
      return;
    }

    if (name === "d_tel") {
      let inputVal = e.target.value;
      // Sadece rakam karakterlerine izin verin
      updatedInfo.d_tel = inputVal.replace(/\D/g, "");
      inputVal = inputVal.slice(0, 11); // Maksimum 11 karaktere sınırlama

      updatedInfo.d_tel = formatPhoneNumber(inputVal);
    }
    setSetMyData(
      updatedInfo
      // (updatedInfo) => ({
      //   ...updatedInfo,
      //   [name]: value,
      // })
    );
    setMyDataInitial((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearchDoctor = async () => {
    try {
      const { data } = await axios.get(
        `${config.API_BASE_URL}dok_uye/${searchDoctorDiplomaNo}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );

      if (data.status && data.status === 400) {
        toast.error(data.message);
        setGoster(false);
      } else {
        if (data["mesai_bilgileri"].length > 0) {
          const mesaiBilgileri = data["mesai_bilgileri"][0];
          setSearchDoctor(mesaiBilgileri);
          setGoster(true);
        } else {
          setSearchDoctor(mesaiBilgileriInitial); // hepsini sıfır ayarladık
          setSearchDoctor((doctors) => ({
            ...doctors,
            pol_vergi_no: data.pol_vergi_no,
            d_diploma_no: data.d_diploma_no,
          }));
          setGoster(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Doktor Ekle
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    try {
      // try {
      const logoData = new FormData();
      logoData.append("file", setMyData.d_resim);
      logoData.append("upload_preset", "v1ouupsn");
      // await schema.validate(myDataInitial.d_resim, { abortEarly: false });
      const resp = await axios.post(
        "https://api.cloudinary.com/v1_1/dxrgehpzq/image/upload",
        logoData
      );
      setSetMyData({ ...setMyData, d_resim: resp.data.url });
      // } catch (error) {

      // }

      // const formData = new FormData();
      // formData.append("d_adi", setMyData.d_adi);
      // formData.append("d_soyadi", setMyData.d_soyadi);
      // formData.append("d_unvan", setMyData.d_unvan);
      // formData.append("d_diploma_no", setMyData.d_diploma_no);
      // formData.append("pol_vergi_no", uRegister.pol_vergi_no);
      // formData.append("cinsiyet", setMyData.cinsiyet);
      // formData.append(
      //   "d_tel",
      //   setMyData.d_tel.replace("(", "").replace(")", "").replaceAll(" ", "")
      // );
      // formData.append("d_mail", setMyData.d_mail);
      // formData.append("d_resim", resp.data?.url);
      // formData.append("d_adres", setMyData.d_adres);
      // formData.append("mezuniyet", setMyData.mezuniyet);
      // formData.append("mezuniyet_tarihi", setMyData.mezuniyet_tarihi);
      // formData.append("mesai_baslangic", setMyData.mesai_baslangic);
      // formData.append("password", setMyData.password);
      // formData.append("passwordTwo", setMyData.passwordTwo);

      const sendData = {
        d_adi: setMyData.d_adi,
        d_soyadi: setMyData.d_soyadi,
        d_unvan: setMyData.d_unvan,
        d_diploma_no: setMyData.d_diploma_no,
        pol_vergi_no: uRegister.pol_vergi_no,
        d_adres: setMyData.d_adres,
        cinsiyet: setMyData.cinsiyet,
        d_tel: setMyData.d_tel
          .replace("(", "")
          .replace(")", "")
          .replaceAll(" ", ""),
        d_mail: setMyData.d_mail,
        d_resim: resp.data?.url,
        mezuniyet: setMyData.mezuniyet,
        mezuniyet_tarihi: setMyData.mezuniyet_tarihi,
        mesai_baslangic: setMyData.mesai_baslangic,
        password: setMyData.password,
        passwordTwo: setMyData.passwordTwo,
      };
      // setMyDataInitial(formData);

      await schema.validate(myDataInitial, { abortEarly: false });
      const data = await axios.post(
        `${config.API_BASE_URL}dok_uye`,
        sendData,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            // "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );
      // console.log("data=>", data);
      if (data?.data.doktor_bilgileri) {
        toast.success("Kayıt bilgileri eklendi.");
        setGoster(false);
        setSetMyData(myDataInitial);
        setSearchDoctorDiplomaNo(setMyData.d_diploma_no);
      } else {
        toast.error("Kayıt bilgileri eklenemedi!");
      }
    } catch (error) {
      if (
        error.response?.data.error?.message ===
        "Missing required parameter - file"
      ) {
        toast.error("Lütfen Resim Ekleyiniz!");
      } else if (
        error.response?.data?.message ===
        "Girdiğiniz mail adresi sistemde kayıtlıdır."
      ) {
        toast.error("Girdiğiniz mail adresi sistemde kayıtlıdır!");
      }
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      }
      console.log(error);
    }
  };

  //mesai kayıtta doktor adınıda yansın
  //   doktor_bilgileri: {…}, token: {…}}doktor_bilgileri: {pol_vergi_no: '2', d_diploma_no: 23, d_adi: 'yenidoktor', d_soyadi: 'asd', cinsiyet: 'E', …}cinsiyet: "E"created_at: "2023-06-15T13:47:12.000000Z"d_adi: "yenidoktor"d_adres: "ankara"d_diploma_no: 23d_mail: "admin436@admin.com"d_resim: "YOes7iYAOkkykQI3PH4dpoknCJ59XaWhyCcIfa5e.jpg"d_soyadi: "asd"d_tel: "1234567890"d_unvan: "doktor"mesai_baslangic: "2023-06-24"mezuniyet: "ankara"mezuniyet_tarihi: "2023-06-22"pol_vergi_no: "2"updated_at: "2023-06-15T13:47:12.000000Z"[[Prototype]]: Objecttoken: {token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1I…haFruuSuX4a1PczybaUNaPzo9sJ3m0o1z1h_oBfLT5NZciezg'}[[Prototype]]: Object

  const handleSaveHours = async () => {
    const url = `${config.API_BASE_URL}dok_uye/mesaiKayit`;

    const requestBody = {
      pol_vergi_no: searchDoctor["pol_vergi_no"],
      d_diploma_no: searchDoctor["d_diploma_no"],
      pazartesi_1: searchDoctor["pazartesi_1"],
      pazartesi_2: searchDoctor["pazartesi_2"],
      pazartesi_3: searchDoctor["pazartesi_3"],
      sali_1: searchDoctor["sali_1"],
      sali_2: searchDoctor["sali_2"],
      sali_3: searchDoctor["sali_3"],
      carsamba_1: searchDoctor["carsamba_1"],
      carsamba_2: searchDoctor["carsamba_2"],
      carsamba_3: searchDoctor["carsamba_3"],
      persembe_1: searchDoctor["persembe_1"],
      persembe_2: searchDoctor["persembe_2"],
      persembe_3: searchDoctor["persembe_3"],
      cuma_1: searchDoctor["cuma_1"],
      cuma_2: searchDoctor["cuma_2"],
      cuma_3: searchDoctor["cuma_3"],
      cumartesi_1: searchDoctor["cumartesi_1"],
      cumartesi_2: searchDoctor["cumartesi_2"],
      cumartesi_3: searchDoctor["cumartesi_3"],
      pazar_1: searchDoctor["pazar_1"],
      pazar_2: searchDoctor["pazar_2"],
      pazar_3: searchDoctor["pazar_3"],
    };

    try {
      const response = await axios.post(url, requestBody, {
        headers: {
          Authorization: "Bearer " + uRegister.token, // Authorization},
        },
      });
      // Handle the response data
      if (response.data.status && response.data.status === 400) {
        toast.error(response.data.message);
      } else {
        // console.log("response.data", response.data);
        toast.success("Mesai bilgileri kaydedildi.");
      }
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };
  // console.log("setMyData==>", setMyData);

  return (
    <div className="py-3 px-5">
      {/* <----- KİŞİSEL BİLGİLER -----> */}
      <p className="text-xl font-semibold antialiased leading-loose">
        Kişisel Bilgi
      </p>
      <form onSubmit={(e) => handleSubmit(e)} action="">
        <div className="mt-5">
          <div className="flex flex-row gap-3">
            <div
              {...getRootProps()}
              className="flex justify-center w-1/3 h-[340px] transition bg-container rounded-sm  cursor-pointer hover:border border-gray object-cover"
            >
              {imageTrue ? (
                <div className="object-contain overflow-hidden">{images}</div>
              ) : (
                <>
                  <input {...getInputProps()} />
                  <figure className="relative max-w-sm">
                    <img
                      src={doctorUser}
                      alt="users"
                      className="object-cover opacity-25 mt-5"
                    />
                    <p className="absolute bottom-36 text-center text-xl">
                      Görseli buraya sürükleyin veya yüklemek için tıklayın.
                    </p>
                  </figure>
                </>
              )}
            </div>
            {errors.d_resim && (
              <p className="input-error-message text-red">{errors.d_resim}</p>
            )}

            <div className="form-control w-1/3">
              <div className="form-control w-full">
                <label className="label-text mb-1">ADINIZ *</label>
                <input
                  name="d_adi"
                  value={setMyData?.d_adi}
                  onChange={handleChange}
                  type="text"
                  placeholder="Adınız"
                  className="input input-bordered w-full"
                  required
                />
                {errors.d_adi && (
                  <p className="input-error-message text-red">{errors.d_adi}</p>
                )}
              </div>
              <div className="form-control w-full mt-4">
                <label className="label-text mb-1">E-POSTA *</label>
                <input
                  name="d_mail"
                  value={setMyData?.d_mail}
                  onChange={handleChange}
                  type="email"
                  placeholder="****@*****.com"
                  className="input input-bordered w-full "
                  required
                />
                {errors.d_mail && (
                  <p className="input-error-message text-red">
                    {errors.d_mail}
                  </p>
                )}
              </div>
              <div className="form-control w-full mt-4">
                <label className="label-text mb-1">CİNSİYET</label>
                <select
                  name="cinsiyet"
                  value={setMyData?.cinsiyet}
                  onChange={handleChange}
                  className="select select-bordered w-full"
                >
                  <option disabled value="">
                    Lütfen cinsiyet seçiniz
                  </option>
                  <option value="K">Kadın</option>
                  <option value="E">Erkek</option>
                </select>
              </div>
              <div className="form-control w-full mt-4">
                <label className="label-text mb-1">ŞİFRE *</label>
                <input
                  name="password"
                  value={setMyData?.password}
                  onChange={handleChange}
                  type="password"
                  placeholder="Şifreniz"
                  className="input input-bordered w-full"
                  required
                />
                {errors.password && (
                  <p className="input-error-message text-red">
                    {errors.password}
                  </p>
                )}
              </div>
            </div>
            <div className="form-control w-1/3">
              <div className="form-control w-full">
                <label className="label-text mb-1">SOYADI *</label>
                <input
                  name="d_soyadi"
                  value={setMyData?.d_soyadi}
                  onChange={handleChange}
                  type="text"
                  placeholder="Soyadınız"
                  className="input input-bordered w-full"
                  required
                />
                {errors.d_soyadi && (
                  <p className="input-error-message text-red">
                    {errors.d_soyadi}
                  </p>
                )}
              </div>
              <div className="form-control w-full mt-4">
                <label className="label-text mb-1">TELEFON *</label>
                <input
                  name="d_tel"
                  value={setMyData?.d_tel || "0"}
                  pattern="[0-9]{10,11}"
                  maxLength="11"
                  onChange={handleChange}
                  type="text"
                  className="input input-bordered w-full"
                  placeholder="0(555) 555 55 55"
                  required
                />
                {errors.d_tel && (
                  <p className="input-error-message text-red">{errors.d_tel}</p>
                )}
              </div>
              <div className="form-control w-full mt-4">
                <label className="label-text mb-1">ÜNVAN</label>
                <input
                  name="d_unvan"
                  value={setMyData?.d_unvan}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ünvanınız"
                  className="input input-bordered w-full"
                />
                {errors.d_unvan && (
                  <p className="input-error-message text-red">
                    {errors.d_unvan}
                  </p>
                )}
              </div>
              <div className="form-control w-full mt-4">
                <label className="label-text mb-1">ŞİFRE (TEKRAR) *</label>
                <input
                  name="passwordTwo"
                  value={setMyData?.passwordTwo}
                  onChange={
                    handleChange
                    // (e) => setPasswordTwo(e.target.value)
                  }
                  type="password"
                  placeholder="Şifreniz tekar "
                  className="input input-bordered w-full"
                  required
                />
                {errors.passwordTwo && (
                  <p className="input-error-message text-red">
                    {errors.passwordTwo}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="form-control w-full mt-5">
            <label className="label-text mb-1">ADRES *</label>
            <input
              name="d_adres"
              value={setMyData?.d_adres}
              onChange={handleChange}
              type="text"
              placeholder="Adresiniz"
              className="input input-bordered w-full"
              required
            />
            {errors.d_adres && (
              <p className="input-error-message text-red">{errors.d_adres}</p>
            )}
          </div>
          <div className="flex flex-row gap-3 mt-4">
            <div className="form-control w-1/3">
              <label className="label-text mb-1">DİPLOMA NO *</label>
              <input
                name="d_diploma_no"
                value={setMyData?.d_diploma_no}
                onChange={handleChange}
                type="text"
                placeholder="Diploma numaranız"
                className="input input-bordered w-full"
                maxLength={10}
                required
              />
              {errors.d_diploma_no && (
                <p className="input-error-message text-red">
                  {errors.d_diploma_no}
                </p>
              )}
            </div>
            <div className="form-control w-1/3">
              <label className="label-text mb-1">MEZUNİYET *</label>
              <select
                name="mezuniyet"
                value={setMyData?.mezuniyet}
                onChange={handleChange}
                className="select select-bordered w-full"
                required
              >
                <option disabled value="">
                  Mezuniyet Seçiniz
                </option>
                {universiteler.map((university) => (
                  <option key={university.id} value={university.name}>
                    {university.name}
                  </option>
                ))}
              </select>
              {errors.mezuniyet && (
                <p className="input-error-message text-red">
                  {errors.mezuniyet}
                </p>
              )}
            </div>
            <div className="form-control w-1/3">
              <label className="label-text mb-1">MEZUNİYET TARİHİ *</label>
              <input
                name="mezuniyet_tarihi"
                onChange={handleChange}
                value={setMyData?.mezuniyet_tarihi || today}
                type="date"
                placeholder="Mezuniyet tarihiniz"
                className="input input-bordered w-full"
                required
              />
              {errors.mezuniyet_tarihi && (
                <p className="input-error-message text-red">
                  {errors.mezuniyet_tarihi}
                </p>
              )}
            </div>
            <div className="form-control w-1/3">
              <label className="label-text mb-1">
                MESAİ BAŞLANGIÇ TARİHİ *
              </label>
              <input
                name="mesai_baslangic"
                onChange={handleChange}
                value={setMyData?.mesai_baslangic || today}
                type="date"
                placeholder="Mesai başlangıç tarihiniz"
                className="input input-bordered w-full"
                required
              />
              {errors.mesai_baslangic && (
                <p className="input-error-message text-red">
                  {errors.mesai_baslangic}
                </p>
              )}
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-active btn-sm rounded-full my-6 p-2 px-8 bg-blue1 hover:bg-blue1"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            DOKTOR EKLE
          </button>
        </div>
      </form>

      {/* <----- ÇALIŞMA GÜNLERİ -----> */}
      <div>
        <p className="text-xl font-semibold antialiased leading-loose">
          Çalışma Günleri
        </p>
        <div className="flex justify-start items-center">
          <div className="form-control w-1/3  mr-2">
            <input
              name="diplomaNo"
              onChange={(e) => setSearchDoctorDiplomaNo(e.target.value)}
              value={searchDoctorDiplomaNo}
              type="text"
              placeholder="Diploma No giriniz"
              className="input input-bordered w-full"
              maxLength={10}
            />
          </div>

          <button
            onClick={handleSearchDoctor}
            type="button"
            className="btn btn-active btn-sm rounded-full my-6 p-2 px-8 bg-blue1 hover:bg-blue1"
          >
            DOKTOR ARA
          </button>
        </div>
        <div className="overflow-x-auto pr-5">
          <table className="table w-full">
            {/* <!-- head --> */}
            <thead className="text-center">
              <tr>
                <th className="bg-blue1 text-white"></th>
                <th className="bg-blue1 text-white">PAZARTESİ</th>
                <th className="bg-blue1 text-white">SALI</th>
                <th className="bg-blue1 text-white">ÇARŞAMBA</th>
                <th className="bg-blue1 text-white">PERŞEMBE</th>
                <th className="bg-blue1 text-white">CUMA</th>
                <th className="bg-blue1 text-white">CUMAERTESİ</th>
                <th className="bg-blue1 text-white">PAZAR</th>
              </tr>
            </thead>
            {goster && (
              <tbody>
                {/* Sabah */}
                <tr>
                  <th>
                    <img src={quadrant1} alt="quadrant" className="h-10" />
                  </th>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"pazartesi_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"sali_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"carsamba_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"persembe_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"cuma_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"cumartesi_1"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"09:00 - 12:00"}
                      gun={"pazar_1"}
                    />
                  </td>
                </tr>
                {/* Öğle */}
                <tr>
                  <th>
                    <img src={quadrant2} alt="quadrant" className="h-10" />
                  </th>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"pazartesi_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"sali_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"carsamba_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"persembe_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"cuma_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"cumartesi_2"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"13:00 - 16:30"}
                      gun={"pazar_2"}
                    />
                  </td>
                </tr>
                {/* Akşam */}
                <tr>
                  <th>
                    <img src={quadrant3} alt="quadrant" className="h-10" />
                  </th>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"pazartesi_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"sali_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"carsamba_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"persembe_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"cuma_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"cumartesi_3"}
                    />
                  </td>
                  <td>
                    <Checkbox
                      searchDoctor={searchDoctor}
                      setSearchDoctor={setSearchDoctor}
                      saat={"18:00 - 22:00"}
                      gun={"pazar_3"}
                    />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {goster && (
            <button
              onClick={handleSaveHours}
              className="btn btn-active btn-sm rounded-full my-6 p-2 px-8 bg-blue1 hover:bg-blue1"
            >
              KAYDET
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewDoctorAddPage;
