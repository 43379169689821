import React, { useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../../components/polyclinic/layout/Layout";
import { TfiPencil } from "react-icons/tfi";
import { ilData } from "../../../helpers/ilData";
import axios from "axios";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { userRegister } from "../../../features/registerSlice";
import config from "../../../config";


const schema = yup.object().shape({
  // logo: yup.mixed().required("Logo seçilmedi."),
  pol_adi: yup.string().required("Klinik adı zorunludur."),
  // pol_vergi_no: yup.string().required("Vergi no zorunludur."),
  // .matches(/^[0-9]{10}$/, "Vergi no 10 haneli olmalıdır."),
  // pol_mail: yup
  //   .string()
  //   .email("Geçerli bir email adresi giriniz.")
  //   .required("Email zorunludur."),
  pol_tel: yup.string().required("Telefon numarası zorunludur."),
  // .matches(/^[0-9]{10,11}$/, "Geçerli bir telefon numarası giriniz."),
  pol_adres: yup.string().required("Adres zorunludur."),
  pol_il: yup.string().required("İl zorunludur."),
  pol_ilce: yup.string().required("İlçe zorunludur."),
  // password: yup
  //   .string()
  //   .required("Şifre zorunludur.")
  //   .min(8, "Şifre en az 8 karakter olmalıdır.")
  //   .max(16, "Şifre en fazla 16 karakter olmalıdır.")
  //   .matches(/\d+/, "Şifre en az bir sayı içermelidir.")
  //   .matches(/[a-z]+/, "Şifre en az bir küçük harf içermelidir.")
  //   .matches(/[A-Z]+/, "Şifre en az bir büyük harf içermelidir.")
  //   .matches(
  //     /[!,?{}><%&$#£+-.]+/,
  //     "Şifre en az bir özel karakter içermelidir."
  //   ),
  // password2: yup
  //   .string()
  //   .oneOf([yup.ref("password"), null], "Şifreler uyuşmuyor.")
  //   .required("Şifre tekrarı zorunludur."),
});
const formatPhoneNumber = (phoneNumber) => {
  // Telefon numarasını uygun formata getirme
  phoneNumber = phoneNumber.replace(/\D/g, ""); // Sadece sayısal karakterleri tutar
  const match = phoneNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  // console.log("match=>", match);
  // console.log("phoneNumber=>", phoneNumber);
  if (match) {
    return `0(${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
  }
  return phoneNumber; // Eğer format uygun değilse, inputa girilen değeri olduğu gibi geri döndürür
};

// Kullanıcının girdiği telefon numarasını temizler
const cleanPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/\D/g, ""); // Sadece sayısal karakterleri tutar
};

const preparePhoneNumberForAPI = (phoneNumber) => {
  const cleanedNumber = cleanPhoneNumber(phoneNumber); // Temizlenmiş telefon numarası

  // Eğer telefon numarası 11 karakterden oluşuyorsa ve 0 ile başlıyorsa,
  // başındaki sıfırı kaldırarak API uygun formatı oluştur
  if (cleanedNumber.length === 11 && cleanedNumber.startsWith("0")) {
    return cleanedNumber.slice(1);
  }

  return cleanedNumber; // Diğer durumlarda doğrudan temizlenmiş numarayı kullan
};

// Geri kalan cleanPhoneNumber ve formatPhoneNumber fonksiyonları burada aynı kalsın

const Settings = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.open.value);
  const uRegister = useSelector((state) => state.uRegister);
  // console.log(uRegister);
  const [registerInfo, setRegisterInfo] = useState({});
  const [imagePreview, setImagePreview] = useState(uRegister.pol_logo);
  const [errors, setErrors] = useState({});
  const [backendErrors, setBackendErrors] = useState({});
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const allowedExtensions = [
    //   "image/jpeg",
    //   "image/png",
    //   "image/gif",
    //   "image/bmp",
    //   "image/tiff",
    //   "image/webp",
    //   "image/x-icon",
    //   "image/svg+xml",
    // ];

    // const fileExtension = imagePreview?.type;
    // console.log(fileExtension);
    // if (!allowedExtensions.includes(fileExtension)) {
    //   return toast.success("Lütfen logo için geçerli bir dosya seçin.");
    // }

    try {
      const inputData = {
        pol_logo: imagePreview,
        pol_adi: registerInfo.pol_adi,
        pol_tel: registerInfo.api_pol_tel,
        pol_adres: registerInfo.pol_adres,
        pol_il: registerInfo.pol_il,
        pol_ilce: registerInfo.pol_ilce,
      };
      // console.log("inputData", inputData);

      const response = await axios.post(
        `${config.API_BASE_URL}pol_uye/klinikGuncelle?pol_vergi_no=${uRegister.pol_vergi_no}`,
        inputData,
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );

      // console.log("response", response);
      if (response.data) {
        dispatch(
          userRegister({
            ...uRegister,
            // id: pol_vergi_no,
            token: uRegister.token,
            // pol_vergi_no: pol_vergi_no,
            pol_adi: registerInfo.pol_adi || uRegister.pol_adi,
            pol_adres: registerInfo.pol_adres || uRegister.pol_adres,
            pol_tel: registerInfo.pol_tel || uRegister.pol_tel,
            pol_mail: registerInfo.pol_mail || uRegister.pol_mail,
            pol_il: registerInfo.pol_il || uRegister.pol_il,
            pol_ilce: registerInfo.pol_ilce || uRegister.pol_ilce,
            pol_logo: imagePreview, //usestate
          })
          // userLogin({
          //   // id: uRegister.id,
          //   email: registerInfo.pol_mail || uRegister.pol_mail,
          //   username: registerInfo.pol_adi || uRegister.pol_adi,
          //   // token: uRegister.token,
          //   // kullanici_turu: response.data.kullanici_turu,
          // })
        );
      }
      toast.success("Güncelleme işlemi başarılı.");
      setBackendErrors({});
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      }
      setBackendErrors(error.response.data.errors);
      console.log(error);
      // console.log("backendErrors", backendErrors);
      return toast.error("Lütfen geçerli bilgiler girin!");
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    try {
      toast.success("Şifre değişim İşlemi Başarılı!");
    } catch (error) {
      toast.error("Şifre değiştirilemedi!");
    }
  };

  const handleChange = (e) => {
    // setRegisterInfo({ ...registerInfo, [e.target.id]: e.target.value });
    // setErrors({});
    const { name, value } = e.target;
    let updatedInfo = { ...registerInfo, [name]: value };

    if (name === "pol_tel") {
      let inputVal = e.target.value;
      // console.log(inputVal);
      // Sadece rakam karakterlerine izin verin
      inputVal = inputVal.replace(/\D/g, "");

      // Maksimum 11 karaktere sınırlama
      inputVal = inputVal.slice(0, 11);
      updatedInfo.api_pol_tel = preparePhoneNumberForAPI(inputVal); // API için uygun hale getirilmiş numara
      // Telefon numarasını belirtilen formata dönüştürme
      updatedInfo.pol_tel = formatPhoneNumber(inputVal);
    }

    setRegisterInfo(updatedInfo);
    // Şimdi errors nesnesinden ilgili alanı temizleyebilirsiniz
    // if (name in errors) {
    //   setErrors(name, ""); // Hata mesajını temizle
    // }
    // setErrors({ ...errors });
    console.log(errors);
  };

  return (
    <>
      <Layout />
      <div
        className={`absolute bg-container  mt-20 right-0 p-6 min-h-[calc(100vh-80px)] ${
          open ? "w-[calc(100%-250px)]" : "w-[calc(100%-100px)]"
        } transition-w ease-in-out duration-500`}
      >
        <div className=" bg-white flex justify-center items-center ">
          <div className="grid grid-cols-1 w-1/2  my-20">
            <h1 className="text-3xl mb-4">Ayarlar</h1>
            <form
              action="#"
              // className="px-10 xs:py-2 lg:py-10 xs:w-full lg:w-1/2 grid grid-cols-6 xs:gap-1 lg:gap-4"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="h-1/5 w-full my-4 relative">
                <div className="border-2 border-gray border-opacity-30">
                  <figure>
                    {imagePreview && (
                      <img
                        src={imagePreview}
                        alt="Selected Image"
                        className="object-cover h-24"
                      />
                    )}
                  </figure>
                </div>
                <div className="avatar placeholder absolute top-4 right-4 ">
                  <div className="border-4 border-gray rounded-full w-12 hover:border-blue1 text-2xl text-gray  hover:text-blue1">
                    <input
                      type="file"
                      name="file"
                      id="logofile"
                      className="hidden"
                      onChange={handleFileChange} // Dosya değiştiğinde işlenecek işlemi belirledik
                    />
                    <label htmlFor="logofile">
                      <TfiPencil />
                    </label>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1">
                <div className="form-control w-full">
                  <label className="label-text mb-3">POLİKLİNİK ADI</label>
                  <input
                    type="text"
                    name="pol_adi"
                    placeholder="Type here"
                    onChange={handleChange}
                    className="input input-bordered w-full mb-5"
                    value={registerInfo?.pol_adi || uRegister.pol_adi}
                  />
                  {errors.pol_adi && (
                    <p className="input-error-message text-red">
                      {errors.pol_adi}
                    </p>
                  )}
                </div>
                <div className="flex flex-row gap-5">
                  <div className="form-control w-1/2">
                    <div className="form-control w-full mb-5">
                      <label className="label-text mb-3">ADRES</label>
                      <input
                        type="text"
                        name="pol_adres"
                        onChange={handleChange}
                        placeholder="Type here"
                        className="input input-bordered w-full mb-2"
                        value={
                          registerInfo?.pol_adres
                            ? registerInfo?.pol_adres
                            : uRegister.pol_adres
                        }
                      />
                      {errors.pol_adres && (
                        <p className="input-error-message text-red">
                          {errors.pol_adres}
                        </p>
                      )}
                      <div className="grid grid-cols-2 gap-3 w-full">
                        <select
                          name="pol_il"
                          id="il"
                          className="select select-bordered"
                          onChange={handleChange}
                        >
                          <option className="">{uRegister.pol_il}</option>
                          {ilData.map((il) => (
                            <option key={il.value} value={il["text"]}>
                              {il["text"]}
                            </option>
                          ))}
                        </select>
                        {errors.pol_il && (
                          <p className="input-error-message text-red">
                            {errors.pol_il}
                          </p>
                        )}
                        <select
                          name="pol_ilce"
                          id="ilce"
                          className="select select-bordered"
                          onChange={handleChange}
                        >
                          {ilData
                            .filter(
                              (data) =>
                                data["text"] ===
                                (registerInfo?.pol_il || uRegister.pol_il)
                            )
                            .map((ilce) =>
                              ilce["districts"].map((e) =>
                                registerInfo.pol_il ? (
                                  <option value={e["text"]}>{e["text"]}</option>
                                ) : (
                                  <option className="">
                                    {uRegister.pol_ilce}
                                  </option>
                                )
                              )
                            )}{" "}
                        </select>
                        {errors.pol_ilce && (
                          <p className="input-error-message text-red">
                            {errors.pol_ilce}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-control w-full ">
                      <label className="label-text mb-3">EMAİL</label>
                      <input
                        type="email"
                        name="pol_mail"
                        onChange={handleChange}
                        disabled="true"
                        placeholder="****@*****.com"
                        className="input input-bordered w-full "
                        value={registerInfo?.pol_mail || uRegister.pol_mail}
                      />
                      {errors.pol_mail && (
                        <p className="input-error-message text-red">
                          {errors.pol_mail}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-control w-1/2">
                    <div className="form-control w-full mb-5">
                      <label className="label-text mb-3">TELEFON</label>
                      <input
                        type="text"
                        name="pol_tel"
                        onChange={handleChange}
                        className="input input-bordered w-full mb-2"
                        placeholder="Type here"
                        value={registerInfo?.pol_tel || uRegister?.pol_tel}
                      />
                      {errors.pol_tel && (
                        <p className="input-error-message text-red">
                          {errors.pol_tel}
                        </p>
                      )}
                    </div>
                    <div className="form-control w-full">
                      <label className="label-text mb-3">VERGİ NO</label>
                      <input
                        type="text"
                        name="pol_vergi_no"
                        onChange={handleChange}
                        className="input input-bordered w-full"
                        placeholder="Type here"
                        disabled="true"
                        value={
                          registerInfo.pol_vergi_no || uRegister.pol_vergi_no
                        }
                      />
                      {errors.pol_vergi_no && (
                        <p className="input-error-message text-red">
                          {errors.pol_vergi_no}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <ul>
                    {backendErrors?.pol_tel &&
                      backendErrors?.pol_tel.map((err, id) => (
                        <li className="text-red" key={id}>
                          {err}
                        </li>
                      ))}
                  </ul>
                  <ul>
                    {backendErrors?.pol_mail &&
                      backendErrors?.pol_mail.map((err, id) => (
                        <li className="text-red" key={id}>
                          {err}
                        </li>
                      ))}
                  </ul>
                </div>
                <button className="btn btn-active btn-sm rounded-full my-6 p-2 px-8 place-self-end bg-blue1 hover:bg-blue1">
                  DÜZENLE
                </button>
              </div>
            </form>
            <form action="" onSubmit={(e) => handleChangePassword(e)}>
              <div className="grid grid-cols-1 mt-5">
                <h1 className="text-2xl mb-4">Şifre Yenile</h1>
                <div className="grid grid-cols-3 gap-3">
                  <div className="form-control w-full mt-4">
                    <label className="label-text mb-1">MEVCUT ŞİFRE</label>
                    <input
                      type="password"
                      placeholder="Type here"
                      className="input input-bordered w-full"
                    />
                  </div>
                  <div className="form-control w-full mt-4">
                    <label className="label-text mb-1">YENİ ŞİFRE</label>
                    <input
                      type="password"
                      placeholder="Type here"
                      className="input input-bordered w-full"
                    />
                  </div>
                  <div className="form-control w-full mt-4">
                    <label className="label-text mb-1">
                      YENİ ŞİFRE (TEKRAR)
                    </label>
                    <input
                      type="password"
                      placeholder="Type here"
                      className="input input-bordered w-full"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-active btn-sm rounded-full my-6 p-2 px-8 place-self-end bg-blue1 hover:bg-blue1"
                >
                  DÜZENLE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
