import { useEffect, useState } from "react";
import DateRangePickerComp from "../../polyclinic/appointments/DateRangePickerComp";
import { addDays, format } from "date-fns";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import config from "../../../config";
// import DateRangePickerComp from "./DateRangePickerComp";

const DoctorDateRange = () => {
  const uRegister = useSelector((state) => state.uRegister);

  const [doktorDateAppointment, setDoktorDateAppointment] = useState([]);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const getAppointment = async () => {
      const formattedStartDate = format(range[0].startDate, "yyyy-MM-dd");
      const formattedEndDate = format(range[0].endDate, "yyyy-MM-dd");

      try {
        const response = await axios.get(
          `${config.API_BASE_URL}randevular/tarih?tarih_1=${formattedStartDate}&tarih_2=${formattedEndDate}&pol_vergi_no=${uRegister.id}&d_diploma_no=${uRegister.d_diploma_no}`
        );

        if (!response.data.message) {
          setDoktorDateAppointment(response.data);
        } else {
          //   toast.error("Bu tarih aralığında kayıt bulunamadı!");
          setDoktorDateAppointment([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAppointment();
  }, [range, uRegister]);

  return (
    <div>
      <div className="flex justify-center my-8">
        {/* The button to open modal */}
        <label
          htmlFor="my-modal"
          className="btn btn-sm btn-outline btn-primary rounded-full place-self-end my-3 capitalize"
        >
          Tarih Seç
        </label>

        {/* Put this part before </body> tag */}
        <input type="checkbox" id="my-modal" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box w-11/12 max-w-5xl">
            <div className="flex justify-center">
              <DateRangePickerComp range={range} setRange={setRange} />
            </div>
            <div className="modal-action">
              <label
                htmlFor="my-modal"
                className="btn-primary mr-3 btn btn-active btn-sm bg-blue1 gap-2 rounded-full hover:bg-white hover:text-blue1 capitalize"
              >
                İptal
              </label>
              <label
                htmlFor="my-modal"
                className="btn-primary mr-3 btn btn-active btn-sm bg-blue1 gap-2 rounded-full hover:bg-white hover:text-blue1 capitalize"
              >
                Onayla
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* patients appointments table 👇🏻 */}
      <div className="overflow-x-auto ">
        <table className="table w-full z-0">
          {/* <!-- head --> */}
          <thead className="text-gray">
            <tr>
              <th className="text-[16px]">HASTAdd</th>
              <th className="text-[16px]">CİNSİYET</th>
              <th className="text-[16px]">TARİH</th>
              <th className="text-[16px]">SAAT</th>
              <th className="text-[16px]">AÇIKLAMALAR</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {doktorDateAppointment?.length > 0 ? (
            doktorDateAppointment?.map((randevu, i) => (
              <tbody key={i}>
                {/* <!-- row 1 --> */}
                <tr className="border-b border-[#e9e9e9] leading-10">
                  <td>
                    {randevu.hasta.hasta_adi} {randevu.hasta.hasta_soyadi}
                  </td>
                  <td>{randevu.hasta.cinsiyet}</td>
                  <td>{randevu.randevu_tarih}</td>
                  <td>{randevu.randevu_baslangic_saati}</td>
                  <td>{randevu.sikayet_notu}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-[24px]">...</td>
                </tr>
              </tbody>
            ))
          ) : (
            <tr>
              <td
                className="capitalize p-2 shadow-lg mb-4 text-center"
                colSpan={5}
              >
                Bu tarih aralığında kayıt bulunamadı!
              </td>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
};

export default DoctorDateRange;
