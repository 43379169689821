export const ilData =[
{
    "value": 1,
    "text": "ADANA",
    "districts": [
    {
        "value": 1757,
        "text": "ALADAĞ"
    },
    {
        "value": 1219,
        "text": "CEYHAN"
    },
    {
        "value": 2033,
        "text": "ÇUKUROVA"
    },
    {
        "value": 1329,
        "text": "FEKE"
    },
    {
        "value": 1806,
        "text": "İMAMOĞLU"
    },
    {
        "value": 1437,
        "text": "KARAİSALI"
    },
    {
        "value": 1443,
        "text": "KARATAŞ"
    },
    {
        "value": 1486,
        "text": "KOZAN"
    },
    {
        "value": 1580,
        "text": "POZANTI"
    },
    {
        "value": 1588,
        "text": "SAİMBEYLİ"
    },
    {
        "value": 2032,
        "text": "SARIÇAM"
    },
    {
        "value": 1104,
        "text": "SEYHAN"
    },
    {
        "value": 1687,
        "text": "TUFANBEYLİ"
    },
    {
        "value": 1734,
        "text": "YUMURTALIK"
    },
    {
        "value": 1748,
        "text": "YÜREĞİR"
    }
    ]
},
{
    "value": 2,
    "text": "ADIYAMAN",
    "districts": [
    {
        "value": 1182,
        "text": "BESNİ"
    },
    {
        "value": 1246,
        "text": "ÇELİKHAN"
    },
    {
        "value": 1347,
        "text": "GERGER"
    },
    {
        "value": 1354,
        "text": "GÖLBAŞI"
    },
    {
        "value": 1425,
        "text": "KAHTA"
    },
    {
        "value": 1105,
        "text": "MERKEZ"
    },
    {
        "value": 1592,
        "text": "SAMSAT"
    },
    {
        "value": 1985,
        "text": "SİNCİK"
    },
    {
        "value": 1989,
        "text": "TUT"
    }
    ]
},
{
    "value": 3,
    "text": "AFYONKARAHİSAR",
    "districts": [
    {
        "value": 1771,
        "text": "BAŞMAKÇI"
    },
    {
        "value": 1773,
        "text": "BAYAT"
    },
    {
        "value": 1200,
        "text": "BOLVADİN"
    },
    {
        "value": 1239,
        "text": "ÇAY"
    },
    {
        "value": 1906,
        "text": "ÇOBANLAR"
    },
    {
        "value": 1267,
        "text": "DAZKIRI"
    },
    {
        "value": 1281,
        "text": "DİNAR"
    },
    {
        "value": 1306,
        "text": "EMİRDAĞ"
    },
    {
        "value": 1923,
        "text": "EVCİLER"
    },
    {
        "value": 1944,
        "text": "HOCALAR"
    },
    {
        "value": 1404,
        "text": "İHSANİYE"
    },
    {
        "value": 1809,
        "text": "İSCEHİSAR"
    },
    {
        "value": 1961,
        "text": "KIZILÖREN"
    },
    {
        "value": 1108,
        "text": "MERKEZ"
    },
    {
        "value": 1594,
        "text": "SANDIKLI"
    },
    {
        "value": 1626,
        "text": "SİNANPAŞA"
    },
    {
        "value": 1664,
        "text": "ŞUHUT"
    },
    {
        "value": 1639,
        "text": "SULTANDAĞI"
    }
    ]
},
{
    "value": 4,
    "text": "AĞRI",
    "districts": [
    {
        "value": 1283,
        "text": "DİYADİN"
    },
    {
        "value": 1287,
        "text": "DOĞUBAYAZIT"
    },
    {
        "value": 1301,
        "text": "ELEŞKİRT"
    },
    {
        "value": 1379,
        "text": "HAMUR"
    },
    {
        "value": 1111,
        "text": "MERKEZ"
    },
    {
        "value": 1568,
        "text": "PATNOS"
    },
    {
        "value": 1667,
        "text": "TAŞLIÇAY"
    },
    {
        "value": 1691,
        "text": "TUTAK"
    }
    ]
},
{
    "value": 68,
    "text": "AKSARAY",
    "districts": [
    {
        "value": 1860,
        "text": "AĞAÇÖREN"
    },
    {
        "value": 1921,
        "text": "ESKİL"
    },
    {
        "value": 1932,
        "text": "GÜLAĞAÇ"
    },
    {
        "value": 1861,
        "text": "GÜZELYURT"
    },
    {
        "value": 1120,
        "text": "MERKEZ"
    },
    {
        "value": 1557,
        "text": "ORTAKÖY"
    },
    {
        "value": 1866,
        "text": "SARIYAHŞİ"
    },
    {
        "value": 2106,
        "text": "SULTANHANI"
    }
    ]
},
{
    "value": 5,
    "text": "AMASYA",
    "districts": [
    {
        "value": 1363,
        "text": "GÖYNÜCEK"
    },
    {
        "value": 1368,
        "text": "GÜMÜŞHACIKÖY"
    },
    {
        "value": 1938,
        "text": "HAMAMÖZÜ"
    },
    {
        "value": 1134,
        "text": "MERKEZ"
    },
    {
        "value": 1524,
        "text": "MERZİFON"
    },
    {
        "value": 1641,
        "text": "SULUOVA"
    },
    {
        "value": 1668,
        "text": "TAŞOVA"
    }
    ]
},
{
    "value": 6,
    "text": "ANKARA",
    "districts": [
    {
        "value": 1872,
        "text": "AKYURT"
    },
    {
        "value": 1130,
        "text": "ALTINDAĞ"
    },
    {
        "value": 1157,
        "text": "AYAŞ"
    },
    {
        "value": 1167,
        "text": "BALA"
    },
    {
        "value": 1187,
        "text": "BEYPAZARI"
    },
    {
        "value": 1227,
        "text": "ÇAMLIDERE"
    },
    {
        "value": 1231,
        "text": "ÇANKAYA"
    },
    {
        "value": 1260,
        "text": "ÇUBUK"
    },
    {
        "value": 1302,
        "text": "ELMADAĞ"
    },
    {
        "value": 1922,
        "text": "ETİMESGUT"
    },
    {
        "value": 1924,
        "text": "EVREN"
    },
    {
        "value": 1744,
        "text": "GÖLBAŞI"
    },
    {
        "value": 1365,
        "text": "GÜDÜL"
    },
    {
        "value": 1387,
        "text": "HAYMANA"
    },
    {
        "value": 1815,
        "text": "KAHRAMANKAZAN"
    },
    {
        "value": 1427,
        "text": "KALECİK"
    },
    {
        "value": 1745,
        "text": "KEÇİÖREN"
    },
    {
        "value": 1473,
        "text": "KIZILCAHAMAM"
    },
    {
        "value": 1746,
        "text": "MAMAK"
    },
    {
        "value": 1539,
        "text": "NALLIHAN"
    },
    {
        "value": 1578,
        "text": "POLATLI"
    },
    {
        "value": 2034,
        "text": "PURSAKLAR"
    },
    {
        "value": 1658,
        "text": "ŞEREFLİKOÇHİSAR"
    },
    {
        "value": 1747,
        "text": "SİNCAN"
    },
    {
        "value": 1723,
        "text": "YENİMAHALLE"
    }
    ]
},
{
    "value": 7,
    "text": "ANTALYA",
    "districts": [
    {
        "value": 1121,
        "text": "AKSEKİ"
    },
    {
        "value": 2035,
        "text": "AKSU"
    },
    {
        "value": 1126,
        "text": "ALANYA"
    },
    {
        "value": 1811,
        "text": "DEMRE"
    },
    {
        "value": 2036,
        "text": "DÖŞEMEALTI"
    },
    {
        "value": 1303,
        "text": "ELMALI"
    },
    {
        "value": 1333,
        "text": "FİNİKE"
    },
    {
        "value": 1337,
        "text": "GAZİPAŞA"
    },
    {
        "value": 1370,
        "text": "GÜNDOĞMUŞ"
    },
    {
        "value": 1946,
        "text": "İBRADI"
    },
    {
        "value": 1451,
        "text": "KAŞ"
    },
    {
        "value": 1959,
        "text": "KEMER"
    },
    {
        "value": 2037,
        "text": "KEPEZ"
    },
    {
        "value": 2038,
        "text": "KONYAALTI"
    },
    {
        "value": 1483,
        "text": "KORKUTELİ"
    },
    {
        "value": 1492,
        "text": "KUMLUCA"
    },
    {
        "value": 1512,
        "text": "MANAVGAT"
    },
    {
        "value": 2039,
        "text": "MURATPAŞA"
    },
    {
        "value": 1616,
        "text": "SERİK"
    }
    ]
},
{
    "value": 75,
    "text": "ARDAHAN",
    "districts": [
    {
        "value": 1252,
        "text": "ÇILDIR"
    },
    {
        "value": 2008,
        "text": "DAMAL"
    },
    {
        "value": 1356,
        "text": "GÖLE"
    },
    {
        "value": 1380,
        "text": "HANAK"
    },
    {
        "value": 1144,
        "text": "MERKEZ"
    },
    {
        "value": 1579,
        "text": "POSOF"
    }
    ]
},
{
    "value": 8,
    "text": "ARTVİN",
    "districts": [
    {
        "value": 1145,
        "text": "ARDANUÇ"
    },
    {
        "value": 1147,
        "text": "ARHAVİ"
    },
    {
        "value": 1202,
        "text": "BORÇKA"
    },
    {
        "value": 1395,
        "text": "HOPA"
    },
    {
        "value": 2105,
        "text": "KEMALPAŞA"
    },
    {
        "value": 1152,
        "text": "MERKEZ"
    },
    {
        "value": 1828,
        "text": "MURGUL"
    },
    {
        "value": 1653,
        "text": "ŞAVŞAT"
    },
    {
        "value": 1736,
        "text": "YUSUFELİ"
    }
    ]
},
{
    "value": 9,
    "text": "AYDIN",
    "districts": [
    {
        "value": 1206,
        "text": "BOZDOĞAN"
    },
    {
        "value": 1781,
        "text": "BUHARKENT"
    },
    {
        "value": 1256,
        "text": "ÇİNE"
    },
    {
        "value": 2000,
        "text": "DİDİM"
    },
    {
        "value": 2076,
        "text": "EFELER"
    },
    {
        "value": 1348,
        "text": "GERMENCİK"
    },
    {
        "value": 1807,
        "text": "İNCİRLİOVA"
    },
    {
        "value": 1435,
        "text": "KARACASU"
    },
    {
        "value": 1957,
        "text": "KARPUZLU"
    },
    {
        "value": 1479,
        "text": "KOÇARLI"
    },
    {
        "value": 1968,
        "text": "KÖŞK"
    },
    {
        "value": 1497,
        "text": "KUŞADASI"
    },
    {
        "value": 1498,
        "text": "KUYUCAK"
    },
    {
        "value": 1542,
        "text": "NAZİLLİ"
    },
    {
        "value": 1637,
        "text": "SÖKE"
    },
    {
        "value": 1640,
        "text": "SULTANHİSAR"
    },
    {
        "value": 1724,
        "text": "YENİPAZAR"
    }
    ]
},
{
    "value": 10,
    "text": "BALIKESİR",
    "districts": [
    {
        "value": 2077,
        "text": "ALTIEYLÜL"
    },
    {
        "value": 1161,
        "text": "AYVALIK"
    },
    {
        "value": 1169,
        "text": "BALYA"
    },
    {
        "value": 1171,
        "text": "BANDIRMA"
    },
    {
        "value": 1191,
        "text": "BİGADİÇ"
    },
    {
        "value": 1216,
        "text": "BURHANİYE"
    },
    {
        "value": 1291,
        "text": "DURSUNBEY"
    },
    {
        "value": 1294,
        "text": "EDREMİT"
    },
    {
        "value": 1310,
        "text": "ERDEK"
    },
    {
        "value": 1928,
        "text": "GÖMEÇ"
    },
    {
        "value": 1360,
        "text": "GÖNEN"
    },
    {
        "value": 1384,
        "text": "HAVRAN"
    },
    {
        "value": 1418,
        "text": "İVRİNDİ"
    },
    {
        "value": 2078,
        "text": "KARESİ"
    },
    {
        "value": 1462,
        "text": "KEPSUT"
    },
    {
        "value": 1514,
        "text": "MANYAS"
    },
    {
        "value": 1824,
        "text": "MARMARA"
    },
    {
        "value": 1608,
        "text": "SAVAŞTEPE"
    },
    {
        "value": 1619,
        "text": "SINDIRGI"
    },
    {
        "value": 1644,
        "text": "SUSURLUK"
    }
    ]
},
{
    "value": 74,
    "text": "BARTIN",
    "districts": [
    {
        "value": 1761,
        "text": "AMASRA"
    },
    {
        "value": 1496,
        "text": "KURUCAŞİLE"
    },
    {
        "value": 1172,
        "text": "MERKEZ"
    },
    {
        "value": 1701,
        "text": "ULUS"
    }
    ]
},
{
    "value": 72,
    "text": "BATMAN",
    "districts": [
    {
        "value": 1184,
        "text": "BEŞİRİ"
    },
    {
        "value": 1345,
        "text": "GERCÜŞ"
    },
    {
        "value": 1941,
        "text": "HASANKEYF"
    },
    {
        "value": 1487,
        "text": "KOZLUK"
    },
    {
        "value": 1174,
        "text": "MERKEZ"
    },
    {
        "value": 1607,
        "text": "SASON"
    }
    ]
},
{
    "value": 69,
    "text": "BAYBURT",
    "districts": [
    {
        "value": 1767,
        "text": "AYDINTEPE"
    },
    {
        "value": 1788,
        "text": "DEMİRÖZÜ"
    },
    {
        "value": 1176,
        "text": "MERKEZ"
    }
    ]
},
{
    "value": 11,
    "text": "BİLECİK",
    "districts": [
    {
        "value": 1210,
        "text": "BOZÜYÜK"
    },
    {
        "value": 1359,
        "text": "GÖLPAZARI"
    },
    {
        "value": 1948,
        "text": "İNHİSAR"
    },
    {
        "value": 1192,
        "text": "MERKEZ"
    },
    {
        "value": 1559,
        "text": "OSMANELİ"
    },
    {
        "value": 1571,
        "text": "PAZARYERİ"
    },
    {
        "value": 1636,
        "text": "SÖĞÜT"
    },
    {
        "value": 1857,
        "text": "YENİPAZAR"
    }
    ]
},
{
    "value": 12,
    "text": "BİNGÖL",
    "districts": [
    {
        "value": 1750,
        "text": "ADAKLI"
    },
    {
        "value": 1344,
        "text": "GENÇ"
    },
    {
        "value": 1446,
        "text": "KARLIOVA"
    },
    {
        "value": 1475,
        "text": "KİĞI"
    },
    {
        "value": 1193,
        "text": "MERKEZ"
    },
    {
        "value": 1633,
        "text": "SOLHAN"
    },
    {
        "value": 1855,
        "text": "YAYLADERE"
    },
    {
        "value": 1996,
        "text": "YEDİSU"
    }
    ]
},
{
    "value": 13,
    "text": "BİTLİS",
    "districts": [
    {
        "value": 1106,
        "text": "ADİLCEVAZ"
    },
    {
        "value": 1112,
        "text": "AHLAT"
    },
    {
        "value": 1798,
        "text": "GÜROYMAK"
    },
    {
        "value": 1394,
        "text": "HİZAN"
    },
    {
        "value": 1196,
        "text": "MERKEZ"
    },
    {
        "value": 1537,
        "text": "MUTKİ"
    },
    {
        "value": 1669,
        "text": "TATVAN"
    }
    ]
},
{
    "value": 14,
    "text": "BOLU",
    "districts": [
    {
        "value": 1916,
        "text": "DÖRTDİVAN"
    },
    {
        "value": 1346,
        "text": "GEREDE"
    },
    {
        "value": 1364,
        "text": "GÖYNÜK"
    },
    {
        "value": 1466,
        "text": "KIBRISCIK"
    },
    {
        "value": 1522,
        "text": "MENGEN"
    },
    {
        "value": 1199,
        "text": "MERKEZ"
    },
    {
        "value": 1531,
        "text": "MUDURNU"
    },
    {
        "value": 1610,
        "text": "SEBEN"
    },
    {
        "value": 1997,
        "text": "YENİÇAĞA"
    }
    ]
},
{
    "value": 15,
    "text": "BURDUR",
    "districts": [
    {
        "value": 1109,
        "text": "AĞLASUN"
    },
    {
        "value": 1874,
        "text": "ALTINYAYLA"
    },
    {
        "value": 1211,
        "text": "BUCAK"
    },
    {
        "value": 1899,
        "text": "ÇAVDIR"
    },
    {
        "value": 1903,
        "text": "ÇELTİKÇİ"
    },
    {
        "value": 1357,
        "text": "GÖLHİSAR"
    },
    {
        "value": 1813,
        "text": "KARAMANLI"
    },
    {
        "value": 1816,
        "text": "KEMER"
    },
    {
        "value": 1215,
        "text": "MERKEZ"
    },
    {
        "value": 1672,
        "text": "TEFENNİ"
    },
    {
        "value": 1728,
        "text": "YEŞİLOVA"
    }
    ]
},
{
    "value": 16,
    "text": "BURSA",
    "districts": [
    {
        "value": 1783,
        "text": "BÜYÜKORHAN"
    },
    {
        "value": 1343,
        "text": "GEMLİK"
    },
    {
        "value": 1935,
        "text": "GÜRSU"
    },
    {
        "value": 1799,
        "text": "HARMANCIK"
    },
    {
        "value": 1411,
        "text": "İNEGÖL"
    },
    {
        "value": 1420,
        "text": "İZNİK"
    },
    {
        "value": 1434,
        "text": "KARACABEY"
    },
    {
        "value": 1457,
        "text": "KELES"
    },
    {
        "value": 1960,
        "text": "KESTEL"
    },
    {
        "value": 1535,
        "text": "M.KEMALPAŞA"
    },
    {
        "value": 1530,
        "text": "MUDANYA"
    },
    {
        "value": 1829,
        "text": "NİLÜFER"
    },
    {
        "value": 1553,
        "text": "ORHANELİ"
    },
    {
        "value": 1554,
        "text": "ORHANGAZİ"
    },
    {
        "value": 1832,
        "text": "OSMANGAZİ"
    },
    {
        "value": 1725,
        "text": "YENİŞEHİR"
    },
    {
        "value": 1859,
        "text": "YILDIRIM"
    }
    ]
},
{
    "value": 17,
    "text": "ÇANAKKALE",
    "districts": [
    {
        "value": 1160,
        "text": "AYVACIK"
    },
    {
        "value": 1180,
        "text": "BAYRAMİÇ"
    },
    {
        "value": 1190,
        "text": "BİGA"
    },
    {
        "value": 1205,
        "text": "BOZCAADA"
    },
    {
        "value": 1229,
        "text": "ÇAN"
    },
    {
        "value": 1293,
        "text": "ECEABAT"
    },
    {
        "value": 1326,
        "text": "EZİNE"
    },
    {
        "value": 1340,
        "text": "GELİBOLU"
    },
    {
        "value": 1408,
        "text": "GÖKÇEADA"
    },
    {
        "value": 1503,
        "text": "LAPSEKİ"
    },
    {
        "value": 1230,
        "text": "MERKEZ"
    },
    {
        "value": 1722,
        "text": "YENİCE"
    }
    ]
},
{
    "value": 18,
    "text": "ÇANKIRI",
    "districts": [
    {
        "value": 1765,
        "text": "ATKARACALAR"
    },
    {
        "value": 1885,
        "text": "BAYRAMÖREN"
    },
    {
        "value": 1248,
        "text": "ÇERKEŞ"
    },
    {
        "value": 1300,
        "text": "ELDİVAN"
    },
    {
        "value": 1399,
        "text": "ILGAZ"
    },
    {
        "value": 1817,
        "text": "KIZILIRMAK"
    },
    {
        "value": 1963,
        "text": "KORGUN"
    },
    {
        "value": 1494,
        "text": "KURŞUNLU"
    },
    {
        "value": 1232,
        "text": "MERKEZ"
    },
    {
        "value": 1555,
        "text": "ORTA"
    },
    {
        "value": 1649,
        "text": "ŞABANÖZÜ"
    },
    {
        "value": 1718,
        "text": "YAPRAKLI"
    }
    ]
},
{
    "value": 19,
    "text": "ÇORUM",
    "districts": [
    {
        "value": 1124,
        "text": "ALACA"
    },
    {
        "value": 1177,
        "text": "BAYAT"
    },
    {
        "value": 1778,
        "text": "BOĞAZKALE"
    },
    {
        "value": 1911,
        "text": "DODURGA"
    },
    {
        "value": 1414,
        "text": "İSKİLİP"
    },
    {
        "value": 1445,
        "text": "KARGI"
    },
    {
        "value": 1972,
        "text": "LAÇİN"
    },
    {
        "value": 1520,
        "text": "MECİTÖZÜ"
    },
    {
        "value": 1259,
        "text": "MERKEZ"
    },
    {
        "value": 1976,
        "text": "OĞUZLAR"
    },
    {
        "value": 1556,
        "text": "ORTAKÖY"
    },
    {
        "value": 1558,
        "text": "OSMANCIK"
    },
    {
        "value": 1642,
        "text": "SUNGURLU"
    },
    {
        "value": 1850,
        "text": "UĞURLUDAĞ"
    }
    ]
},
{
    "value": 20,
    "text": "DENİZLİ",
    "districts": [
    {
        "value": 1102,
        "text": "ACIPAYAM"
    },
    {
        "value": 1769,
        "text": "BABADAĞ"
    },
    {
        "value": 1881,
        "text": "BAKLAN"
    },
    {
        "value": 1774,
        "text": "BEKİLLİ"
    },
    {
        "value": 1888,
        "text": "BEYAĞAÇ"
    },
    {
        "value": 1889,
        "text": "BOZKURT"
    },
    {
        "value": 1214,
        "text": "BULDAN"
    },
    {
        "value": 1224,
        "text": "ÇAL"
    },
    {
        "value": 1226,
        "text": "ÇAMELİ"
    },
    {
        "value": 1233,
        "text": "ÇARDAK"
    },
    {
        "value": 1257,
        "text": "ÇİVRİL"
    },
    {
        "value": 1371,
        "text": "GÜNEY"
    },
    {
        "value": 1803,
        "text": "HONAZ"
    },
    {
        "value": 1426,
        "text": "KALE"
    },
    {
        "value": 2079,
        "text": "MERKEZEFENDİ"
    },
    {
        "value": 1871,
        "text": "PAMUKKALE"
    },
    {
        "value": 1597,
        "text": "SARAYKÖY"
    },
    {
        "value": 1840,
        "text": "SERİNHİSAR"
    },
    {
        "value": 1670,
        "text": "TAVAS"
    }
    ]
},
{
    "value": 21,
    "text": "DİYARBAKIR",
    "districts": [
    {
        "value": 2040,
        "text": "BAĞLAR"
    },
    {
        "value": 1195,
        "text": "BİSMİL"
    },
    {
        "value": 1249,
        "text": "ÇERMİK"
    },
    {
        "value": 1253,
        "text": "ÇINAR"
    },
    {
        "value": 1263,
        "text": "ÇÜNGÜŞ"
    },
    {
        "value": 1278,
        "text": "DİCLE"
    },
    {
        "value": 1791,
        "text": "EĞİL"
    },
    {
        "value": 1315,
        "text": "ERGANİ"
    },
    {
        "value": 1381,
        "text": "HANİ"
    },
    {
        "value": 1389,
        "text": "HAZRO"
    },
    {
        "value": 2041,
        "text": "KAYAPINAR"
    },
    {
        "value": 1962,
        "text": "KOCAKÖY"
    },
    {
        "value": 1490,
        "text": "KULP"
    },
    {
        "value": 1504,
        "text": "LİCE"
    },
    {
        "value": 1624,
        "text": "SİLVAN"
    },
    {
        "value": 2042,
        "text": "SUR"
    },
    {
        "value": 2043,
        "text": "YENİŞEHİR"
    }
    ]
},
{
    "value": 81,
    "text": "DÜZCE",
    "districts": [
    {
        "value": 1116,
        "text": "AKÇAKOCA"
    },
    {
        "value": 1905,
        "text": "ÇİLİMLİ"
    },
    {
        "value": 1784,
        "text": "CUMAYERİ"
    },
    {
        "value": 1794,
        "text": "GÖLYAKA"
    },
    {
        "value": 2017,
        "text": "GÜMÜŞOVA"
    },
    {
        "value": 2031,
        "text": "KAYNAŞLI"
    },
    {
        "value": 1292,
        "text": "MERKEZ"
    },
    {
        "value": 1730,
        "text": "YIĞILCA"
    }
    ]
},
{
    "value": 22,
    "text": "EDİRNE",
    "districts": [
    {
        "value": 1307,
        "text": "ENEZ"
    },
    {
        "value": 1385,
        "text": "HAVSA"
    },
    {
        "value": 1412,
        "text": "İPSALA"
    },
    {
        "value": 1464,
        "text": "KEŞAN"
    },
    {
        "value": 1502,
        "text": "LALAPAŞA"
    },
    {
        "value": 1523,
        "text": "MERİÇ"
    },
    {
        "value": 1295,
        "text": "MERKEZ"
    },
    {
        "value": 1988,
        "text": "SÜLOĞLU"
    },
    {
        "value": 1705,
        "text": "UZUNKÖPRÜ"
    }
    ]
},
{
    "value": 23,
    "text": "ELAZIĞ",
    "districts": [
    {
        "value": 1110,
        "text": "AĞIN"
    },
    {
        "value": 1873,
        "text": "ALACAKAYA"
    },
    {
        "value": 1762,
        "text": "ARICAK"
    },
    {
        "value": 1173,
        "text": "BASKİL"
    },
    {
        "value": 1438,
        "text": "KARAKOÇAN"
    },
    {
        "value": 1455,
        "text": "KEBAN"
    },
    {
        "value": 1820,
        "text": "KOVANCILAR"
    },
    {
        "value": 1506,
        "text": "MADEN"
    },
    {
        "value": 1298,
        "text": "MERKEZ"
    },
    {
        "value": 1566,
        "text": "PALU"
    },
    {
        "value": 1631,
        "text": "SİVRİCE"
    }
    ]
},
{
    "value": 24,
    "text": "ERZİNCAN",
    "districts": [
    {
        "value": 1243,
        "text": "ÇAYIRLI"
    },
    {
        "value": 1406,
        "text": "İLİÇ"
    },
    {
        "value": 1459,
        "text": "KEMAH"
    },
    {
        "value": 1460,
        "text": "KEMALİYE"
    },
    {
        "value": 1318,
        "text": "MERKEZ"
    },
    {
        "value": 1977,
        "text": "OTLUKBELİ"
    },
    {
        "value": 1583,
        "text": "REFAHİYE"
    },
    {
        "value": 1675,
        "text": "TERCAN"
    },
    {
        "value": 1853,
        "text": "ÜZÜMLÜ"
    }
    ]
},
{
    "value": 25,
    "text": "ERZURUM",
    "districts": [
    {
        "value": 1153,
        "text": "AŞKALE"
    },
    {
        "value": 1945,
        "text": "AZİZİYE"
    },
    {
        "value": 1235,
        "text": "ÇAT"
    },
    {
        "value": 1392,
        "text": "HINIS"
    },
    {
        "value": 1396,
        "text": "HORASAN"
    },
    {
        "value": 1416,
        "text": "İSPİR"
    },
    {
        "value": 1812,
        "text": "KARAÇOBAN"
    },
    {
        "value": 1444,
        "text": "KARAYAZI"
    },
    {
        "value": 1967,
        "text": "KÖPRÜKÖY"
    },
    {
        "value": 1540,
        "text": "NARMAN"
    },
    {
        "value": 1550,
        "text": "OLTU"
    },
    {
        "value": 1551,
        "text": "OLUR"
    },
    {
        "value": 2044,
        "text": "PALANDÖKEN"
    },
    {
        "value": 1567,
        "text": "PASİNLER"
    },
    {
        "value": 1865,
        "text": "PAZARYOLU"
    },
    {
        "value": 1657,
        "text": "ŞENKAYA"
    },
    {
        "value": 1674,
        "text": "TEKMAN"
    },
    {
        "value": 1683,
        "text": "TORTUM"
    },
    {
        "value": 1851,
        "text": "UZUNDERE"
    },
    {
        "value": 2045,
        "text": "YAKUTİYE"
    }
    ]
},
{
    "value": 26,
    "text": "ESKİŞEHİR",
    "districts": [
    {
        "value": 1759,
        "text": "ALPU"
    },
    {
        "value": 1777,
        "text": "BEYLİKOVA"
    },
    {
        "value": 1255,
        "text": "ÇİFTELER"
    },
    {
        "value": 1934,
        "text": "GÜNYÜZÜ"
    },
    {
        "value": 1939,
        "text": "HAN"
    },
    {
        "value": 1808,
        "text": "İNÖNÜ"
    },
    {
        "value": 1508,
        "text": "MAHMUDİYE"
    },
    {
        "value": 1973,
        "text": "MİHALGAZİ"
    },
    {
        "value": 1527,
        "text": "MİHALIÇÇIK"
    },
    {
        "value": 2046,
        "text": "ODUNPAZARI"
    },
    {
        "value": 1599,
        "text": "SARICAKAYA"
    },
    {
        "value": 1618,
        "text": "SEYİTGAZİ"
    },
    {
        "value": 1632,
        "text": "SİVRİHİSAR"
    },
    {
        "value": 2047,
        "text": "TEPEBAŞI"
    }
    ]
},
{
    "value": 27,
    "text": "GAZİANTEP",
    "districts": [
    {
        "value": 1139,
        "text": "ARABAN"
    },
    {
        "value": 1415,
        "text": "İSLAHİYE"
    },
    {
        "value": 1956,
        "text": "KARKAMIŞ"
    },
    {
        "value": 1546,
        "text": "NİZİP"
    },
    {
        "value": 1974,
        "text": "NURDAĞI"
    },
    {
        "value": 1549,
        "text": "OĞUZELİ"
    },
    {
        "value": 1841,
        "text": "ŞAHİNBEY"
    },
    {
        "value": 1844,
        "text": "ŞEHİTKAMİL"
    },
    {
        "value": 1720,
        "text": "YAVUZELİ"
    }
    ]
},
{
    "value": 28,
    "text": "GİRESUN",
    "districts": [
    {
        "value": 1133,
        "text": "ALUCRA"
    },
    {
        "value": 1212,
        "text": "BULANCAK"
    },
    {
        "value": 1893,
        "text": "ÇAMOLUK"
    },
    {
        "value": 1894,
        "text": "ÇANAKÇI"
    },
    {
        "value": 1272,
        "text": "DERELİ"
    },
    {
        "value": 1912,
        "text": "DOĞANKENT"
    },
    {
        "value": 1320,
        "text": "ESPİYE"
    },
    {
        "value": 1324,
        "text": "EYNESİL"
    },
    {
        "value": 1361,
        "text": "GÖRELE"
    },
    {
        "value": 1930,
        "text": "GÜCE"
    },
    {
        "value": 1465,
        "text": "KEŞAP"
    },
    {
        "value": 1352,
        "text": "MERKEZ"
    },
    {
        "value": 1837,
        "text": "PİRAZİZ"
    },
    {
        "value": 1654,
        "text": "ŞEBİNKARAHİSAR"
    },
    {
        "value": 1678,
        "text": "TİREBOLU"
    },
    {
        "value": 1854,
        "text": "YAĞLIDERE"
    }
    ]
},
{
    "value": 29,
    "text": "GÜMÜŞHANE",
    "districts": [
    {
        "value": 1458,
        "text": "KELKİT"
    },
    {
        "value": 1822,
        "text": "KÖSE"
    },
    {
        "value": 1971,
        "text": "KÜRTÜN"
    },
    {
        "value": 1369,
        "text": "MERKEZ"
    },
    {
        "value": 1660,
        "text": "ŞİRAN"
    },
    {
        "value": 1684,
        "text": "TORUL"
    }
    ]
},
{
    "value": 30,
    "text": "HAKKARİ",
    "districts": [
    {
        "value": 1261,
        "text": "ÇUKURCA"
    },
    {
        "value": 2107,
        "text": "DERECİK"
    },
    {
        "value": 1377,
        "text": "MERKEZ"
    },
    {
        "value": 1656,
        "text": "ŞEMDİNLİ"
    },
    {
        "value": 1737,
        "text": "YÜKSEKOVA"
    }
    ]
},
{
    "value": 31,
    "text": "HATAY",
    "districts": [
    {
        "value": 1131,
        "text": "ALTINÖZÜ"
    },
    {
        "value": 2080,
        "text": "ANTAKYA"
    },
    {
        "value": 2081,
        "text": "ARSUZ"
    },
    {
        "value": 1887,
        "text": "BELEN"
    },
    {
        "value": 2082,
        "text": "DEFNE"
    },
    {
        "value": 1289,
        "text": "DÖRTYOL"
    },
    {
        "value": 1792,
        "text": "ERZİN"
    },
    {
        "value": 1382,
        "text": "HASSA"
    },
    {
        "value": 1413,
        "text": "İSKENDERUN"
    },
    {
        "value": 1468,
        "text": "KIRIKHAN"
    },
    {
        "value": 1970,
        "text": "KUMLU"
    },
    {
        "value": 2083,
        "text": "PAYAS"
    },
    {
        "value": 1585,
        "text": "REYHANLI"
    },
    {
        "value": 1591,
        "text": "SAMANDAĞ"
    },
    {
        "value": 1721,
        "text": "YAYLADAĞI"
    }
    ]
},
{
    "value": 76,
    "text": "IĞDIR",
    "districts": [
    {
        "value": 1142,
        "text": "ARALIK"
    },
    {
        "value": 2011,
        "text": "KARAKOYUNLU"
    },
    {
        "value": 1398,
        "text": "MERKEZ"
    },
    {
        "value": 1692,
        "text": "TUZLUCA"
    }
    ]
},
{
    "value": 32,
    "text": "ISPARTA",
    "districts": [
    {
        "value": 1755,
        "text": "AKSU"
    },
    {
        "value": 1154,
        "text": "ATABEY"
    },
    {
        "value": 1297,
        "text": "EĞİRDİR"
    },
    {
        "value": 1341,
        "text": "GELENDOST"
    },
    {
        "value": 1929,
        "text": "GÖNEN"
    },
    {
        "value": 1456,
        "text": "KEÇİBORLU"
    },
    {
        "value": 1401,
        "text": "MERKEZ"
    },
    {
        "value": 1651,
        "text": "ŞARKİKARAAĞAÇ"
    },
    {
        "value": 1615,
        "text": "SENİRKENT"
    },
    {
        "value": 1648,
        "text": "SÜTÇÜLER"
    },
    {
        "value": 1699,
        "text": "ULUBORLU"
    },
    {
        "value": 1717,
        "text": "YALVAÇ"
    },
    {
        "value": 2001,
        "text": "YENİŞARBADEMLİ"
    }
    ]
},
{
    "value": 34,
    "text": "İSTANBUL",
    "districts": [
    {
        "value": 1103,
        "text": "ADALAR"
    },
    {
        "value": 2048,
        "text": "ARNAVUTKÖY"
    },
    {
        "value": 2049,
        "text": "ATAŞEHİR"
    },
    {
        "value": 2003,
        "text": "AVCILAR"
    },
    {
        "value": 2004,
        "text": "BAĞCILAR"
    },
    {
        "value": 2005,
        "text": "BAHÇELİEVLER"
    },
    {
        "value": 1166,
        "text": "BAKIRKÖY"
    },
    {
        "value": 2050,
        "text": "BAŞAKŞEHİR"
    },
    {
        "value": 1886,
        "text": "BAYRAMPAŞA"
    },
    {
        "value": 1183,
        "text": "BEŞİKTAŞ"
    },
    {
        "value": 1185,
        "text": "BEYKOZ"
    },
    {
        "value": 2051,
        "text": "BEYLİKDÜZÜ"
    },
    {
        "value": 1186,
        "text": "BEYOĞLU"
    },
    {
        "value": 1782,
        "text": "BÜYÜKÇEKMECE"
    },
    {
        "value": 1237,
        "text": "ÇATALCA"
    },
    {
        "value": 2052,
        "text": "ÇEKMEKÖY"
    },
    {
        "value": 2016,
        "text": "ESENLER"
    },
    {
        "value": 2053,
        "text": "ESENYURT"
    },
    {
        "value": 1325,
        "text": "EYÜPSULTAN"
    },
    {
        "value": 1327,
        "text": "FATİH"
    },
    {
        "value": 1336,
        "text": "GAZİOSMANPAŞA"
    },
    {
        "value": 2010,
        "text": "GÜNGÖREN"
    },
    {
        "value": 1421,
        "text": "KADIKÖY"
    },
    {
        "value": 1810,
        "text": "KAĞITHANE"
    },
    {
        "value": 1449,
        "text": "KARTAL"
    },
    {
        "value": 1823,
        "text": "KÜÇÜKÇEKMECE"
    },
    {
        "value": 2012,
        "text": "MALTEPE"
    },
    {
        "value": 1835,
        "text": "PENDİK"
    },
    {
        "value": 2054,
        "text": "SANCAKTEPE"
    },
    {
        "value": 1604,
        "text": "SARIYER"
    },
    {
        "value": 1659,
        "text": "ŞİLE"
    },
    {
        "value": 1622,
        "text": "SİLİVRİ"
    },
    {
        "value": 1663,
        "text": "ŞİŞLİ"
    },
    {
        "value": 2014,
        "text": "SULTANBEYLİ"
    },
    {
        "value": 2055,
        "text": "SULTANGAZİ"
    },
    {
        "value": 2015,
        "text": "TUZLA"
    },
    {
        "value": 1852,
        "text": "ÜMRANİYE"
    },
    {
        "value": 1708,
        "text": "ÜSKÜDAR"
    },
    {
        "value": 1739,
        "text": "ZEYTİNBURNU"
    }
    ]
},
{
    "value": 35,
    "text": "İZMİR",
    "districts": [
    {
        "value": 1128,
        "text": "ALİAĞA"
    },
    {
        "value": 2006,
        "text": "BALÇOVA"
    },
    {
        "value": 1178,
        "text": "BAYINDIR"
    },
    {
        "value": 2056,
        "text": "BAYRAKLI"
    },
    {
        "value": 1181,
        "text": "BERGAMA"
    },
    {
        "value": 1776,
        "text": "BEYDAĞ"
    },
    {
        "value": 1203,
        "text": "BORNOVA"
    },
    {
        "value": 1780,
        "text": "BUCA"
    },
    {
        "value": 1251,
        "text": "ÇEŞME"
    },
    {
        "value": 2007,
        "text": "ÇİĞLİ"
    },
    {
        "value": 1280,
        "text": "DİKİLİ"
    },
    {
        "value": 1334,
        "text": "FOÇA"
    },
    {
        "value": 2009,
        "text": "GAZİEMİR"
    },
    {
        "value": 2018,
        "text": "GÜZELBAHÇE"
    },
    {
        "value": 2057,
        "text": "KARABAĞLAR"
    },
    {
        "value": 1432,
        "text": "KARABURUN"
    },
    {
        "value": 1448,
        "text": "KARŞIYAKA"
    },
    {
        "value": 1461,
        "text": "KEMALPAŞA"
    },
    {
        "value": 1467,
        "text": "KINIK"
    },
    {
        "value": 1477,
        "text": "KİRAZ"
    },
    {
        "value": 1819,
        "text": "KONAK"
    },
    {
        "value": 1826,
        "text": "MENDERES"
    },
    {
        "value": 1521,
        "text": "MENEMEN"
    },
    {
        "value": 2013,
        "text": "NARLIDERE"
    },
    {
        "value": 1563,
        "text": "ÖDEMİŞ"
    },
    {
        "value": 1611,
        "text": "SEFERİHİSAR"
    },
    {
        "value": 1612,
        "text": "SELÇUK"
    },
    {
        "value": 1677,
        "text": "TİRE"
    },
    {
        "value": 1682,
        "text": "TORBALI"
    },
    {
        "value": 1703,
        "text": "URLA"
    }
    ]
},
{
    "value": 46,
    "text": "KAHRAMANMARAŞ",
    "districts": [
    {
        "value": 1107,
        "text": "AFŞİN"
    },
    {
        "value": 1136,
        "text": "ANDIRIN"
    },
    {
        "value": 1785,
        "text": "ÇAĞLAYANCERİT"
    },
    {
        "value": 2084,
        "text": "DULKADİROĞLU"
    },
    {
        "value": 1919,
        "text": "EKİNÖZÜ"
    },
    {
        "value": 1299,
        "text": "ELBİSTAN"
    },
    {
        "value": 1353,
        "text": "GÖKSUN"
    },
    {
        "value": 1975,
        "text": "NURHAK"
    },
    {
        "value": 2085,
        "text": "ONİKİŞUBAT"
    },
    {
        "value": 1570,
        "text": "PAZARCIK"
    },
    {
        "value": 1694,
        "text": "TÜRKOĞLU"
    }
    ]
},
{
    "value": 78,
    "text": "KARABÜK",
    "districts": [
    {
        "value": 1296,
        "text": "EFLANİ"
    },
    {
        "value": 1321,
        "text": "ESKİPAZAR"
    },
    {
        "value": 1433,
        "text": "MERKEZ"
    },
    {
        "value": 1561,
        "text": "OVACIK"
    },
    {
        "value": 1587,
        "text": "SAFRANBOLU"
    },
    {
        "value": 1856,
        "text": "YENİCE"
    }
    ]
},
{
    "value": 70,
    "text": "KARAMAN",
    "districts": [
    {
        "value": 1768,
        "text": "AYRANCI"
    },
    {
        "value": 1884,
        "text": "BAŞYAYLA"
    },
    {
        "value": 1316,
        "text": "ERMENEK"
    },
    {
        "value": 1862,
        "text": "KAZIMKARABEKİR"
    },
    {
        "value": 1439,
        "text": "MERKEZ"
    },
    {
        "value": 1983,
        "text": "SARIVELİLER"
    }
    ]
},
{
    "value": 36,
    "text": "KARS",
    "districts": [
    {
        "value": 1756,
        "text": "AKYAKA"
    },
    {
        "value": 1149,
        "text": "ARPAÇAY"
    },
    {
        "value": 1279,
        "text": "DİGOR"
    },
    {
        "value": 1424,
        "text": "KAĞIZMAN"
    },
    {
        "value": 1447,
        "text": "MERKEZ"
    },
    {
        "value": 1601,
        "text": "SARIKAMIŞ"
    },
    {
        "value": 1614,
        "text": "SELİM"
    },
    {
        "value": 1645,
        "text": "SUSUZ"
    }
    ]
},
{
    "value": 37,
    "text": "KASTAMONU",
    "districts": [
    {
        "value": 1101,
        "text": "ABANA"
    },
    {
        "value": 1867,
        "text": "AĞLI"
    },
    {
        "value": 1140,
        "text": "ARAÇ"
    },
    {
        "value": 1162,
        "text": "AZDAVAY"
    },
    {
        "value": 1208,
        "text": "BOZKURT"
    },
    {
        "value": 1238,
        "text": "ÇATALZEYTİN"
    },
    {
        "value": 1221,
        "text": "CİDE"
    },
    {
        "value": 1264,
        "text": "DADAY"
    },
    {
        "value": 1277,
        "text": "DEVREKANİ"
    },
    {
        "value": 1915,
        "text": "DOĞANYURT"
    },
    {
        "value": 1940,
        "text": "HANÖNÜ"
    },
    {
        "value": 1805,
        "text": "İHSANGAZİ"
    },
    {
        "value": 1410,
        "text": "İNEBOLU"
    },
    {
        "value": 1499,
        "text": "KÜRE"
    },
    {
        "value": 1450,
        "text": "MERKEZ"
    },
    {
        "value": 1836,
        "text": "PINARBAŞI"
    },
    {
        "value": 1845,
        "text": "ŞENPAZAR"
    },
    {
        "value": 1984,
        "text": "SEYDİLER"
    },
    {
        "value": 1666,
        "text": "TAŞKÖPRÜ"
    },
    {
        "value": 1685,
        "text": "TOSYA"
    }
    ]
},
{
    "value": 38,
    "text": "KAYSERİ",
    "districts": [
    {
        "value": 1752,
        "text": "AKKIŞLA"
    },
    {
        "value": 1218,
        "text": "BÜNYAN"
    },
    {
        "value": 1275,
        "text": "DEVELİ"
    },
    {
        "value": 1330,
        "text": "FELAHİYE"
    },
    {
        "value": 1936,
        "text": "HACILAR"
    },
    {
        "value": 1409,
        "text": "İNCESU"
    },
    {
        "value": 1863,
        "text": "KOCASİNAN"
    },
    {
        "value": 1864,
        "text": "MELİKGAZİ"
    },
    {
        "value": 1978,
        "text": "ÖZVATAN"
    },
    {
        "value": 1576,
        "text": "PINARBAŞI"
    },
    {
        "value": 1603,
        "text": "SARIOĞLAN"
    },
    {
        "value": 1605,
        "text": "SARIZ"
    },
    {
        "value": 1846,
        "text": "TALAS"
    },
    {
        "value": 1680,
        "text": "TOMARZA"
    },
    {
        "value": 1715,
        "text": "YAHYALI"
    },
    {
        "value": 1727,
        "text": "YEŞİLHİSAR"
    }
    ]
},
{
    "value": 79,
    "text": "KİLİS",
    "districts": [
    {
        "value": 2023,
        "text": "ELBEYLİ"
    },
    {
        "value": 1476,
        "text": "MERKEZ"
    },
    {
        "value": 2024,
        "text": "MUSABEYLİ"
    },
    {
        "value": 2025,
        "text": "POLATELİ"
    }
    ]
},
{
    "value": 71,
    "text": "KIRIKKALE",
    "districts": [
    {
        "value": 1880,
        "text": "BAHŞİLİ"
    },
    {
        "value": 1882,
        "text": "BALIŞEYH"
    },
    {
        "value": 1901,
        "text": "ÇELEBİ"
    },
    {
        "value": 1268,
        "text": "DELİCE"
    },
    {
        "value": 1954,
        "text": "KARAKEÇİLİ"
    },
    {
        "value": 1463,
        "text": "KESKİN"
    },
    {
        "value": 1469,
        "text": "MERKEZ"
    },
    {
        "value": 1638,
        "text": "SULAKYURT"
    },
    {
        "value": 1992,
        "text": "YAHŞİHAN"
    }
    ]
},
{
    "value": 39,
    "text": "KIRKLARELİ",
    "districts": [
    {
        "value": 1163,
        "text": "BABAESKİ"
    },
    {
        "value": 1270,
        "text": "DEMİRKÖY"
    },
    {
        "value": 1480,
        "text": "KOFÇAZ"
    },
    {
        "value": 1505,
        "text": "LÜLEBURGAZ"
    },
    {
        "value": 1471,
        "text": "MERKEZ"
    },
    {
        "value": 1572,
        "text": "PEHLİVANKÖY"
    },
    {
        "value": 1577,
        "text": "PINARHİSAR"
    },
    {
        "value": 1714,
        "text": "VİZE"
    }
    ]
},
{
    "value": 40,
    "text": "KIRŞEHİR",
    "districts": [
    {
        "value": 1869,
        "text": "AKÇAKENT"
    },
    {
        "value": 1754,
        "text": "AKPINAR"
    },
    {
        "value": 1890,
        "text": "BOZTEPE"
    },
    {
        "value": 1254,
        "text": "ÇİÇEKDAĞI"
    },
    {
        "value": 1429,
        "text": "KAMAN"
    },
    {
        "value": 1472,
        "text": "MERKEZ"
    },
    {
        "value": 1529,
        "text": "MUCUR"
    }
    ]
},
{
    "value": 41,
    "text": "KOCAELİ",
    "districts": [
    {
        "value": 2058,
        "text": "BAŞİSKELE"
    },
    {
        "value": 2059,
        "text": "ÇAYIROVA"
    },
    {
        "value": 2060,
        "text": "DARICA"
    },
    {
        "value": 2030,
        "text": "DERİNCE"
    },
    {
        "value": 2061,
        "text": "DİLOVASI"
    },
    {
        "value": 1338,
        "text": "GEBZE"
    },
    {
        "value": 1355,
        "text": "GÖLCÜK"
    },
    {
        "value": 2062,
        "text": "İZMİT"
    },
    {
        "value": 1430,
        "text": "KANDIRA"
    },
    {
        "value": 1440,
        "text": "KARAMÜRSEL"
    },
    {
        "value": 2063,
        "text": "KARTEPE"
    },
    {
        "value": 1821,
        "text": "KÖRFEZ"
    }
    ]
},
{
    "value": 42,
    "text": "KONYA",
    "districts": [
    {
        "value": 1868,
        "text": "AHIRLI"
    },
    {
        "value": 1753,
        "text": "AKÖREN"
    },
    {
        "value": 1122,
        "text": "AKŞEHİR"
    },
    {
        "value": 1760,
        "text": "ALTINEKİN"
    },
    {
        "value": 1188,
        "text": "BEYŞEHİR"
    },
    {
        "value": 1207,
        "text": "BOZKIR"
    },
    {
        "value": 1902,
        "text": "ÇELTİK"
    },
    {
        "value": 1222,
        "text": "CİHANBEYLİ"
    },
    {
        "value": 1262,
        "text": "ÇUMRA"
    },
    {
        "value": 1907,
        "text": "DERBENT"
    },
    {
        "value": 1789,
        "text": "DEREBUCAK"
    },
    {
        "value": 1285,
        "text": "DOĞANHİSAR"
    },
    {
        "value": 1920,
        "text": "EMİRGAZİ"
    },
    {
        "value": 1312,
        "text": "EREĞLİ"
    },
    {
        "value": 1933,
        "text": "GÜNEYSINIR"
    },
    {
        "value": 1375,
        "text": "HADİM"
    },
    {
        "value": 1937,
        "text": "HALKAPINAR"
    },
    {
        "value": 1804,
        "text": "HÜYÜK"
    },
    {
        "value": 1400,
        "text": "ILGIN"
    },
    {
        "value": 1422,
        "text": "KADINHANI"
    },
    {
        "value": 1441,
        "text": "KARAPINAR"
    },
    {
        "value": 1814,
        "text": "KARATAY"
    },
    {
        "value": 1491,
        "text": "KULU"
    },
    {
        "value": 1827,
        "text": "MERAM"
    },
    {
        "value": 1598,
        "text": "SARAYÖNÜ"
    },
    {
        "value": 1839,
        "text": "SELÇUKLU"
    },
    {
        "value": 1617,
        "text": "SEYDİŞEHİR"
    },
    {
        "value": 1848,
        "text": "TAŞKENT"
    },
    {
        "value": 1990,
        "text": "TUZLUKÇU"
    },
    {
        "value": 1994,
        "text": "YALIHÜYÜK"
    },
    {
        "value": 1735,
        "text": "YUNAK"
    }
    ]
},
{
    "value": 43,
    "text": "KÜTAHYA",
    "districts": [
    {
        "value": 1132,
        "text": "ALTINTAŞ"
    },
    {
        "value": 1764,
        "text": "ASLANAPA"
    },
    {
        "value": 1898,
        "text": "ÇAVDARHİSAR"
    },
    {
        "value": 1288,
        "text": "DOMANİÇ"
    },
    {
        "value": 1790,
        "text": "DUMLUPINAR"
    },
    {
        "value": 1304,
        "text": "EMET"
    },
    {
        "value": 1339,
        "text": "GEDİZ"
    },
    {
        "value": 1802,
        "text": "HİSARCIK"
    },
    {
        "value": 1500,
        "text": "MERKEZ"
    },
    {
        "value": 1979,
        "text": "PAZARLAR"
    },
    {
        "value": 1843,
        "text": "ŞAPHANE"
    },
    {
        "value": 1625,
        "text": "SİMAV"
    },
    {
        "value": 1671,
        "text": "TAVŞANLI"
    }
    ]
},
{
    "value": 44,
    "text": "MALATYA",
    "districts": [
    {
        "value": 1114,
        "text": "AKÇADAĞ"
    },
    {
        "value": 1143,
        "text": "ARAPGİR"
    },
    {
        "value": 1148,
        "text": "ARGUVAN"
    },
    {
        "value": 1772,
        "text": "BATTALGAZİ"
    },
    {
        "value": 1265,
        "text": "DARENDE"
    },
    {
        "value": 1286,
        "text": "DOĞANŞEHİR"
    },
    {
        "value": 1914,
        "text": "DOĞANYOL"
    },
    {
        "value": 1390,
        "text": "HEKİMHAN"
    },
    {
        "value": 1953,
        "text": "KALE"
    },
    {
        "value": 1969,
        "text": "KULUNCAK"
    },
    {
        "value": 1582,
        "text": "PÜTÜRGE"
    },
    {
        "value": 1995,
        "text": "YAZIHAN"
    },
    {
        "value": 1729,
        "text": "YEŞİLYURT"
    }
    ]
},
{
    "value": 45,
    "text": "MANİSA",
    "districts": [
    {
        "value": 1751,
        "text": "AHMETLİ"
    },
    {
        "value": 1118,
        "text": "AKHİSAR"
    },
    {
        "value": 1127,
        "text": "ALAŞEHİR"
    },
    {
        "value": 1269,
        "text": "DEMİRCİ"
    },
    {
        "value": 1793,
        "text": "GÖLMARMARA"
    },
    {
        "value": 1362,
        "text": "GÖRDES"
    },
    {
        "value": 1470,
        "text": "KIRKAĞAÇ"
    },
    {
        "value": 1965,
        "text": "KÖPRÜBAŞI"
    },
    {
        "value": 1489,
        "text": "KULA"
    },
    {
        "value": 1590,
        "text": "SALİHLİ"
    },
    {
        "value": 1600,
        "text": "SARIGÖL"
    },
    {
        "value": 1606,
        "text": "SARUHANLI"
    },
    {
        "value": 2086,
        "text": "ŞEHZADELER"
    },
    {
        "value": 1613,
        "text": "SELENDİ"
    },
    {
        "value": 1634,
        "text": "SOMA"
    },
    {
        "value": 1689,
        "text": "TURGUTLU"
    },
    {
        "value": 2087,
        "text": "YUNUSEMRE"
    }
    ]
},
{
    "value": 47,
    "text": "MARDİN",
    "districts": [
    {
        "value": 2088,
        "text": "ARTUKLU"
    },
    {
        "value": 1787,
        "text": "DARGEÇİT"
    },
    {
        "value": 1273,
        "text": "DERİK"
    },
    {
        "value": 1474,
        "text": "KIZILTEPE"
    },
    {
        "value": 1519,
        "text": "MAZIDAĞI"
    },
    {
        "value": 1526,
        "text": "MİDYAT"
    },
    {
        "value": 1547,
        "text": "NUSAYBİN"
    },
    {
        "value": 1564,
        "text": "ÖMERLİ"
    },
    {
        "value": 1609,
        "text": "SAVUR"
    },
    {
        "value": 2002,
        "text": "YEŞİLLİ"
    }
    ]
},
{
    "value": 33,
    "text": "MERSİN",
    "districts": [
    {
        "value": 2064,
        "text": "AKDENİZ"
    },
    {
        "value": 1135,
        "text": "ANAMUR"
    },
    {
        "value": 1766,
        "text": "AYDINCIK"
    },
    {
        "value": 1779,
        "text": "BOZYAZI"
    },
    {
        "value": 1892,
        "text": "ÇAMLIYAYLA"
    },
    {
        "value": 1311,
        "text": "ERDEMLİ"
    },
    {
        "value": 1366,
        "text": "GÜLNAR"
    },
    {
        "value": 2065,
        "text": "MEZİTLİ"
    },
    {
        "value": 1536,
        "text": "MUT"
    },
    {
        "value": 1621,
        "text": "SİLİFKE"
    },
    {
        "value": 1665,
        "text": "TARSUS"
    },
    {
        "value": 2066,
        "text": "TOROSLAR"
    },
    {
        "value": 2067,
        "text": "YENİŞEHİR"
    }
    ]
},
{
    "value": 48,
    "text": "MUĞLA",
    "districts": [
    {
        "value": 1197,
        "text": "BODRUM"
    },
    {
        "value": 1742,
        "text": "DALAMAN"
    },
    {
        "value": 1266,
        "text": "DATÇA"
    },
    {
        "value": 1331,
        "text": "FETHİYE"
    },
    {
        "value": 1958,
        "text": "KAVAKLIDERE"
    },
    {
        "value": 1488,
        "text": "KÖYCEĞİZ"
    },
    {
        "value": 1517,
        "text": "MARMARİS"
    },
    {
        "value": 2089,
        "text": "MENTEŞE"
    },
    {
        "value": 1528,
        "text": "MİLAS"
    },
    {
        "value": 1831,
        "text": "ORTACA"
    },
    {
        "value": 2090,
        "text": "SEYDİKEMER"
    },
    {
        "value": 1695,
        "text": "ULA"
    },
    {
        "value": 1719,
        "text": "YATAĞAN"
    }
    ]
},
{
    "value": 49,
    "text": "MUŞ",
    "districts": [
    {
        "value": 1213,
        "text": "BULANIK"
    },
    {
        "value": 1801,
        "text": "HASKÖY"
    },
    {
        "value": 1964,
        "text": "KORKUT"
    },
    {
        "value": 1510,
        "text": "MALAZGİRT"
    },
    {
        "value": 1534,
        "text": "MERKEZ"
    },
    {
        "value": 1711,
        "text": "VARTO"
    }
    ]
},
{
    "value": 50,
    "text": "NEVŞEHİR",
    "districts": [
    {
        "value": 1749,
        "text": "ACIGÖL"
    },
    {
        "value": 1155,
        "text": "AVANOS"
    },
    {
        "value": 1274,
        "text": "DERİNKUYU"
    },
    {
        "value": 1367,
        "text": "GÜLŞEHİR"
    },
    {
        "value": 1374,
        "text": "HACIBEKTAŞ"
    },
    {
        "value": 1485,
        "text": "KOZAKLI"
    },
    {
        "value": 1543,
        "text": "MERKEZ"
    },
    {
        "value": 1707,
        "text": "ÜRGÜP"
    }
    ]
},
{
    "value": 51,
    "text": "NİĞDE",
    "districts": [
    {
        "value": 1876,
        "text": "ALTUNHİSAR"
    },
    {
        "value": 1201,
        "text": "BOR"
    },
    {
        "value": 1225,
        "text": "ÇAMARDI"
    },
    {
        "value": 1904,
        "text": "ÇİFTLİK"
    },
    {
        "value": 1544,
        "text": "MERKEZ"
    },
    {
        "value": 1700,
        "text": "ULUKIŞLA"
    }
    ]
},
{
    "value": 52,
    "text": "ORDU",
    "districts": [
    {
        "value": 1119,
        "text": "AKKUŞ"
    },
    {
        "value": 2103,
        "text": "ALTINORDU"
    },
    {
        "value": 1158,
        "text": "AYBASTI"
    },
    {
        "value": 1891,
        "text": "ÇAMAŞ"
    },
    {
        "value": 1897,
        "text": "ÇATALPINAR"
    },
    {
        "value": 1900,
        "text": "ÇAYBAŞI"
    },
    {
        "value": 1328,
        "text": "FATSA"
    },
    {
        "value": 1358,
        "text": "GÖLKÖY"
    },
    {
        "value": 1795,
        "text": "GÜLYALI"
    },
    {
        "value": 1797,
        "text": "GÜRGENTEPE"
    },
    {
        "value": 1947,
        "text": "İKİZCE"
    },
    {
        "value": 1950,
        "text": "KABADÜZ"
    },
    {
        "value": 1951,
        "text": "KABATAŞ"
    },
    {
        "value": 1482,
        "text": "KORGAN"
    },
    {
        "value": 1493,
        "text": "KUMRU"
    },
    {
        "value": 1525,
        "text": "MESUDİYE"
    },
    {
        "value": 1573,
        "text": "PERŞEMBE"
    },
    {
        "value": 1696,
        "text": "ULUBEY"
    },
    {
        "value": 1706,
        "text": "ÜNYE"
    }
    ]
},
{
    "value": 80,
    "text": "OSMANİYE",
    "districts": [
    {
        "value": 1165,
        "text": "BAHÇE"
    },
    {
        "value": 1743,
        "text": "DÜZİÇİ"
    },
    {
        "value": 2027,
        "text": "HASANBEYLİ"
    },
    {
        "value": 1423,
        "text": "KADİRLİ"
    },
    {
        "value": 1560,
        "text": "MERKEZ"
    },
    {
        "value": 2028,
        "text": "SUMBAS"
    },
    {
        "value": 2029,
        "text": "TOPRAKKALE"
    }
    ]
},
{
    "value": 53,
    "text": "RİZE",
    "districts": [
    {
        "value": 1146,
        "text": "ARDEŞEN"
    },
    {
        "value": 1228,
        "text": "ÇAMLIHEMŞİN"
    },
    {
        "value": 1241,
        "text": "ÇAYELİ"
    },
    {
        "value": 1908,
        "text": "DEREPAZARI"
    },
    {
        "value": 1332,
        "text": "FINDIKLI"
    },
    {
        "value": 1796,
        "text": "GÜNEYSU"
    },
    {
        "value": 1943,
        "text": "HEMŞİN"
    },
    {
        "value": 1405,
        "text": "İKİZDERE"
    },
    {
        "value": 1949,
        "text": "İYİDERE"
    },
    {
        "value": 1428,
        "text": "KALKANDERE"
    },
    {
        "value": 1586,
        "text": "MERKEZ"
    },
    {
        "value": 1569,
        "text": "PAZAR"
    }
    ]
},
{
    "value": 54,
    "text": "SAKARYA",
    "districts": [
    {
        "value": 2068,
        "text": "ADAPAZARI"
    },
    {
        "value": 1123,
        "text": "AKYAZI"
    },
    {
        "value": 2069,
        "text": "ARİFİYE"
    },
    {
        "value": 2070,
        "text": "ERENLER"
    },
    {
        "value": 1925,
        "text": "FERİZLİ"
    },
    {
        "value": 1351,
        "text": "GEYVE"
    },
    {
        "value": 1391,
        "text": "HENDEK"
    },
    {
        "value": 1955,
        "text": "KARAPÜRÇEK"
    },
    {
        "value": 1442,
        "text": "KARASU"
    },
    {
        "value": 1453,
        "text": "KAYNARCA"
    },
    {
        "value": 1818,
        "text": "KOCAALİ"
    },
    {
        "value": 1833,
        "text": "PAMUKOVA"
    },
    {
        "value": 1595,
        "text": "SAPANCA"
    },
    {
        "value": 2071,
        "text": "SERDİVAN"
    },
    {
        "value": 1986,
        "text": "SÖĞÜTLÜ"
    },
    {
        "value": 1847,
        "text": "TARAKLI"
    }
    ]
},
{
    "value": 55,
    "text": "SAMSUN",
    "districts": [
    {
        "value": 1830,
        "text": "19MAYIS"
    },
    {
        "value": 1125,
        "text": "ALAÇAM"
    },
    {
        "value": 1763,
        "text": "ASARCIK"
    },
    {
        "value": 2072,
        "text": "ATAKUM"
    },
    {
        "value": 1879,
        "text": "AYVACIK"
    },
    {
        "value": 1164,
        "text": "BAFRA"
    },
    {
        "value": 2073,
        "text": "CANİK"
    },
    {
        "value": 1234,
        "text": "ÇARŞAMBA"
    },
    {
        "value": 1386,
        "text": "HAVZA"
    },
    {
        "value": 2074,
        "text": "İLKADIM"
    },
    {
        "value": 1452,
        "text": "KAVAK"
    },
    {
        "value": 1501,
        "text": "LADİK"
    },
    {
        "value": 1838,
        "text": "SALIPAZARI"
    },
    {
        "value": 1849,
        "text": "TEKKEKÖY"
    },
    {
        "value": 1676,
        "text": "TERME"
    },
    {
        "value": 1712,
        "text": "VEZİRKÖPRÜ"
    },
    {
        "value": 1993,
        "text": "YAKAKENT"
    }
    ]
},
{
    "value": 63,
    "text": "ŞANLIURFA",
    "districts": [
    {
        "value": 1115,
        "text": "AKÇAKALE"
    },
    {
        "value": 1194,
        "text": "BİRECİK"
    },
    {
        "value": 1209,
        "text": "BOZOVA"
    },
    {
        "value": 1220,
        "text": "CEYLANPINAR"
    },
    {
        "value": 2091,
        "text": "EYYÜBİYE"
    },
    {
        "value": 1378,
        "text": "HALFETİ"
    },
    {
        "value": 2092,
        "text": "HALİLİYE"
    },
    {
        "value": 1800,
        "text": "HARRAN"
    },
    {
        "value": 1393,
        "text": "HİLVAN"
    },
    {
        "value": 2093,
        "text": "KARAKÖPRÜ"
    },
    {
        "value": 1630,
        "text": "SİVEREK"
    },
    {
        "value": 1643,
        "text": "SURUÇ"
    },
    {
        "value": 1713,
        "text": "VİRANŞEHİR"
    }
    ]
},
{
    "value": 56,
    "text": "SİİRT",
    "districts": [
    {
        "value": 1179,
        "text": "BAYKAN"
    },
    {
        "value": 1317,
        "text": "ERUH"
    },
    {
        "value": 1495,
        "text": "KURTALAN"
    },
    {
        "value": 1620,
        "text": "MERKEZ"
    },
    {
        "value": 1575,
        "text": "PERVARİ"
    },
    {
        "value": 1662,
        "text": "ŞİRVAN"
    },
    {
        "value": 1878,
        "text": "TİLLO"
    }
    ]
},
{
    "value": 57,
    "text": "SİNOP",
    "districts": [
    {
        "value": 1156,
        "text": "AYANCIK"
    },
    {
        "value": 1204,
        "text": "BOYABAT"
    },
    {
        "value": 1910,
        "text": "DİKMEN"
    },
    {
        "value": 1290,
        "text": "DURAĞAN"
    },
    {
        "value": 1314,
        "text": "ERFELEK"
    },
    {
        "value": 1349,
        "text": "GERZE"
    },
    {
        "value": 1627,
        "text": "MERKEZ"
    },
    {
        "value": 1981,
        "text": "SARAYDÜZÜ"
    },
    {
        "value": 1693,
        "text": "TÜRKELİ"
    }
    ]
},
{
    "value": 73,
    "text": "ŞIRNAK",
    "districts": [
    {
        "value": 1189,
        "text": "BEYTÜŞŞEBAP"
    },
    {
        "value": 1223,
        "text": "CİZRE"
    },
    {
        "value": 1931,
        "text": "GÜÇLÜKONAK"
    },
    {
        "value": 1403,
        "text": "İDİL"
    },
    {
        "value": 1661,
        "text": "MERKEZ"
    },
    {
        "value": 1623,
        "text": "SİLOPİ"
    },
    {
        "value": 1698,
        "text": "ULUDERE"
    }
    ]
},
{
    "value": 58,
    "text": "SİVAS",
    "districts": [
    {
        "value": 1870,
        "text": "AKINCILAR"
    },
    {
        "value": 1875,
        "text": "ALTINYAYLA"
    },
    {
        "value": 1282,
        "text": "DİVRİĞİ"
    },
    {
        "value": 1913,
        "text": "DOĞANŞAR"
    },
    {
        "value": 1342,
        "text": "GEMEREK"
    },
    {
        "value": 1927,
        "text": "GÖLOVA"
    },
    {
        "value": 1373,
        "text": "GÜRÜN"
    },
    {
        "value": 1376,
        "text": "HAFİK"
    },
    {
        "value": 1407,
        "text": "İMRANLI"
    },
    {
        "value": 1431,
        "text": "KANGAL"
    },
    {
        "value": 1484,
        "text": "KOYULHİSAR"
    },
    {
        "value": 1628,
        "text": "MERKEZ"
    },
    {
        "value": 1650,
        "text": "ŞARKIŞLA"
    },
    {
        "value": 1646,
        "text": "SUŞEHRİ"
    },
    {
        "value": 1991,
        "text": "ULAŞ"
    },
    {
        "value": 1731,
        "text": "YILDIZELİ"
    },
    {
        "value": 1738,
        "text": "ZARA"
    }
    ]
},
{
    "value": 59,
    "text": "TEKİRDAĞ",
    "districts": [
    {
        "value": 1250,
        "text": "ÇERKEZKÖY"
    },
    {
        "value": 1258,
        "text": "ÇORLU"
    },
    {
        "value": 2094,
        "text": "ERGENE"
    },
    {
        "value": 1388,
        "text": "HAYRABOLU"
    },
    {
        "value": 2095,
        "text": "KAPAKLI"
    },
    {
        "value": 1511,
        "text": "MALKARA"
    },
    {
        "value": 1825,
        "text": "MARMARAEREĞLİSİ"
    },
    {
        "value": 1538,
        "text": "MURATLI"
    },
    {
        "value": 1596,
        "text": "SARAY"
    },
    {
        "value": 1652,
        "text": "ŞARKÖY"
    },
    {
        "value": 2096,
        "text": "SÜLEYMANPAŞA"
    }
    ]
},
{
    "value": 60,
    "text": "TOKAT",
    "districts": [
    {
        "value": 1129,
        "text": "ALMUS"
    },
    {
        "value": 1151,
        "text": "ARTOVA"
    },
    {
        "value": 1883,
        "text": "BAŞÇİFTLİK"
    },
    {
        "value": 1308,
        "text": "ERBAA"
    },
    {
        "value": 1679,
        "text": "MERKEZ"
    },
    {
        "value": 1545,
        "text": "NİKSAR"
    },
    {
        "value": 1834,
        "text": "PAZAR"
    },
    {
        "value": 1584,
        "text": "REŞADİYE"
    },
    {
        "value": 1987,
        "text": "SULUSARAY"
    },
    {
        "value": 1690,
        "text": "TURHAL"
    },
    {
        "value": 1858,
        "text": "YEŞİLYURT"
    },
    {
        "value": 1740,
        "text": "ZİLE"
    }
    ]
},
{
    "value": 61,
    "text": "TRABZON",
    "districts": [
    {
        "value": 1113,
        "text": "AKÇAABAT"
    },
    {
        "value": 1141,
        "text": "ARAKLI"
    },
    {
        "value": 1150,
        "text": "ARSİN"
    },
    {
        "value": 1775,
        "text": "BEŞİKDÜZÜ"
    },
    {
        "value": 1896,
        "text": "ÇARŞIBAŞI"
    },
    {
        "value": 1244,
        "text": "ÇAYKARA"
    },
    {
        "value": 1909,
        "text": "DERNEKPAZARI"
    },
    {
        "value": 1917,
        "text": "DÜZKÖY"
    },
    {
        "value": 1942,
        "text": "HAYRAT"
    },
    {
        "value": 1966,
        "text": "KÖPRÜBAŞI"
    },
    {
        "value": 1507,
        "text": "MAÇKA"
    },
    {
        "value": 1548,
        "text": "OF"
    },
    {
        "value": 2097,
        "text": "ORTAHİSAR"
    },
    {
        "value": 1842,
        "text": "ŞALPAZARI"
    },
    {
        "value": 1647,
        "text": "SÜRMENE"
    },
    {
        "value": 1681,
        "text": "TONYA"
    },
    {
        "value": 1709,
        "text": "VAKFIKEBİR"
    },
    {
        "value": 1732,
        "text": "YOMRA"
    }
    ]
},
{
    "value": 62,
    "text": "TUNCELİ",
    "districts": [
    {
        "value": 1247,
        "text": "ÇEMİŞGEZEK"
    },
    {
        "value": 1397,
        "text": "HOZAT"
    },
    {
        "value": 1518,
        "text": "MAZGİRT"
    },
    {
        "value": 1688,
        "text": "MERKEZ"
    },
    {
        "value": 1541,
        "text": "NAZIMİYE"
    },
    {
        "value": 1562,
        "text": "OVACIK"
    },
    {
        "value": 1574,
        "text": "PERTEK"
    },
    {
        "value": 1581,
        "text": "PÜLÜMÜR"
    }
    ]
},
{
    "value": 64,
    "text": "UŞAK",
    "districts": [
    {
        "value": 1170,
        "text": "BANAZ"
    },
    {
        "value": 1323,
        "text": "EŞME"
    },
    {
        "value": 1436,
        "text": "KARAHALLI"
    },
    {
        "value": 1704,
        "text": "MERKEZ"
    },
    {
        "value": 1629,
        "text": "SİVASLI"
    },
    {
        "value": 1697,
        "text": "ULUBEY"
    }
    ]
},
{
    "value": 65,
    "text": "VAN",
    "districts": [
    {
        "value": 1770,
        "text": "BAHÇESARAY"
    },
    {
        "value": 1175,
        "text": "BAŞKALE"
    },
    {
        "value": 1786,
        "text": "ÇALDIRAN"
    },
    {
        "value": 1236,
        "text": "ÇATAK"
    },
    {
        "value": 1918,
        "text": "EDREMİT"
    },
    {
        "value": 1309,
        "text": "ERCİŞ"
    },
    {
        "value": 1350,
        "text": "GEVAŞ"
    },
    {
        "value": 1372,
        "text": "GÜRPINAR"
    },
    {
        "value": 2098,
        "text": "İPEKYOLU"
    },
    {
        "value": 1533,
        "text": "MURADİYE"
    },
    {
        "value": 1565,
        "text": "ÖZALP"
    },
    {
        "value": 1980,
        "text": "SARAY"
    },
    {
        "value": 2099,
        "text": "TUŞBA"
    }
    ]
},
{
    "value": 77,
    "text": "YALOVA",
    "districts": [
    {
        "value": 2019,
        "text": "ALTINOVA"
    },
    {
        "value": 2020,
        "text": "ARMUTLU"
    },
    {
        "value": 2022,
        "text": "ÇİFTLİKKÖY"
    },
    {
        "value": 2021,
        "text": "ÇINARCIK"
    },
    {
        "value": 1716,
        "text": "MERKEZ"
    },
    {
        "value": 2026,
        "text": "TERMAL"
    }
    ]
},
{
    "value": 66,
    "text": "YOZGAT",
    "districts": [
    {
        "value": 1117,
        "text": "AKDAĞMADENİ"
    },
    {
        "value": 1877,
        "text": "AYDINCIK"
    },
    {
        "value": 1198,
        "text": "BOĞAZLIYAN"
    },
    {
        "value": 1895,
        "text": "ÇANDIR"
    },
    {
        "value": 1242,
        "text": "ÇAYIRALAN"
    },
    {
        "value": 1245,
        "text": "ÇEKEREK"
    },
    {
        "value": 1952,
        "text": "KADIŞEHRİ"
    },
    {
        "value": 1733,
        "text": "MERKEZ"
    },
    {
        "value": 1982,
        "text": "SARAYKENT"
    },
    {
        "value": 1602,
        "text": "SARIKAYA"
    },
    {
        "value": 1655,
        "text": "ŞEFAATLİ"
    },
    {
        "value": 1635,
        "text": "SORGUN"
    },
    {
        "value": 1998,
        "text": "YENİFAKILI"
    },
    {
        "value": 1726,
        "text": "YERKÖY"
    }
    ]
},
{
    "value": 67,
    "text": "ZONGULDAK",
    "districts": [
    {
        "value": 1758,
        "text": "ALAPLI"
    },
    {
        "value": 1240,
        "text": "ÇAYCUMA"
    },
    {
        "value": 1276,
        "text": "DEVREK"
    },
    {
        "value": 1313,
        "text": "EREĞLİ"
    },
    {
        "value": 1926,
        "text": "GÖKÇEBEY"
    },
    {
        "value": 2100,
        "text": "KİLİMLİ"
    },
    {
        "value": 2101,
        "text": "KOZLU"
    },
    {
        "value": 1741,
        "text": "MERKEZ"
    }
    ]
}
]