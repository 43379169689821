import React, { useState } from "react";
import { BsCheck2 } from "react-icons/bs";
import { ilData } from "../../helpers/ilData";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userRegister } from "../../features/registerSlice";
import { userLogin } from "../../features/userSlice";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const schema = yup.object().shape({
  pol_logo: yup.mixed().nullable().required("Logo seçilmedi."),
  pol_adi: yup.string().required("Klinik adı zorunludur."),
  pol_vergi_no: yup
    .string()
    .required("Vergi no zorunludur.")
    .matches(/^[0-9]{10}$/, "Vergi no 10 haneli ve rakamlardan oluşmalıdır."),
  pol_mail: yup
    .string()
    .email("Geçerli bir email adresi giriniz.")
    .required("Email zorunludur."),
  pol_tel: yup.string().required("Telefon numarası zorunludur."),
  // .matches(/^[0-9]{10,11}$/, "Geçerli bir telefon numarası giriniz."),
  pol_adres: yup.string().required("Adres zorunludur."),
  pol_il: yup.string().required("İl zorunludur."),
  pol_ilce: yup.string().required("İlçe zorunludur."),
  password: yup
    .string()
    .required("Şifre zorunludur.")
    .min(8, "Şifre en az 8 karakter olmalıdır.")
    .max(16, "Şifre en fazla 16 karakter olmalıdır.")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[+*/-])[a-zA-Z\d]+[^+*/-]$/, {
      message: "Geçersiz şifre formatı",
    }),
  password2: yup
    .string()
    .oneOf([yup.ref("password"), null], "Şifreler uyuşmuyor.")
    .required("Şifre tekrarı zorunludur."),
});

const formatPhoneNumber = (phoneNumber) => {
  // Telefon numarasını uygun formata getirme
  phoneNumber = phoneNumber.replace(/\D/g, ""); // Sadece sayısal karakterleri tutar
  const match = phoneNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  // console.log("match=>", match);
  // console.log("phoneNumber=>", phoneNumber);
  if (match) {
    return `0(${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
  }
  return phoneNumber; // Eğer format uygun değilse, inputa girilen değeri olduğu gibi geri döndürür
};

// Kullanıcının girdiği telefon numarasını temizler
const cleanPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/\D/g, ""); // Sadece sayısal karakterleri tutar
};

// Kullanıcının girdiği telefon numarasını API için uygun hale getirir
const preparePhoneNumberForAPI = (phoneNumber) => {
  const cleanedNumber = cleanPhoneNumber(phoneNumber); // Temizlenmiş telefon numarası
  return `${cleanedNumber}`; // Verilen örnekteki gibi telefon numarasını uygun formata dönüştürme
};

// Kullanıcının telefon numarasını formatlamak için
// const formatPhoneNumber = (phoneNumber) => {
//   // Sadece sayısal karakterleri tutar
//   phoneNumber = phoneNumber.replace(/\D/g, "");

//   // Telefon numarasını belirtilen formata dönüştürme
//   const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
//   if (match) {
//     return `0(${match[1]}) ${match[2]} ${match[3]} ${match[4]}`;
//   }
//   return phoneNumber; // Eğer format uygun değilse, inputa girilen değeri olduğu gibi geri döndürür
// };
const RegisterModal = ({ setShowRegister, setShowLogin }) => {
  const dispatch = useDispatch();
  const uRegister = useSelector((state) => state.uRegister);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logo, setLogo] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [registerInfo, setRegisterInfo] = useState({
    pol_vergi_no: "",
    pol_adi: "",
    pol_adres: "",
    pol_tel: "",
    pol_mail: "",
    pol_il: "",
    pol_ilce: "",
    password: "",
    password2: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name);
    // console.log(value);
    let updatedInfo = { ...registerInfo, [name]: value };

    if (name === "pol_tel") {
      let inputVal = e.target.value;
      // Sadece rakam karakterlerine izin verin
      inputVal = inputVal.replace(/\D/g, "");

      // Maksimum 11 karaktere sınırlama
      inputVal = inputVal.slice(0, 11);
      updatedInfo.api_pol_tel = preparePhoneNumberForAPI(inputVal); // API için uygun hale getirilmiş numara
      // Telefon numarasını belirtilen formata dönüştürme
      updatedInfo.pol_tel = formatPhoneNumber(inputVal);
    }
    if (name === "pol_vergi_no") {
      let inputVal = e.target.value;
      // Sadece rakam karakterlerine izin verin
      inputVal = inputVal.replace(/\D/g, "");

      // Maksimum 10 karaktere sınırlama
      inputVal = inputVal.slice(0, 10);
      // Telefon numarasını belirtilen formata dönüştürme
      updatedInfo.pol_vergi_no = inputVal;
    }
    setRegisterInfo(updatedInfo);
    // console.log("registerInfo:", registerInfo);
  };

  const handleChangeLogo = (file) => {
    file === undefined && setLogo(false);
    setLogo(file);
    setRegisterInfo({ ...registerInfo, pol_logo: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (registerInfo.password !== registerInfo.password2) {
    //   return toast.error("Girilen parolalar uyuşmuyor!");
    // }
    // if (registerInfo.password === "" || registerInfo.password2 === "") {
    //   return toast.error("Lütfen parola alanlarını  boş bırakmayınız!");
    // }
    if (logo === null || logo === undefined) {
      setLogo(false);
      return toast.error("Lütfen logo seçiniz!");
    }

    const allowedExtensions = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/tiff",
      "image/webp",
      "image/x-icon",
      "image/svg+xml",
    ];

    const fileExtension = logo?.type;
    // if (!allowedExtensions.includes(fileExtension)) {
    //   return toast.success("Lütfen logo için geçerli bir dosya seçin.");
    // }

    try {
      const inputData = {
        logo, // Logo state
        pol_adi: registerInfo.pol_adi,
        pol_vergi_no: registerInfo.pol_vergi_no,
        pol_mail: registerInfo.pol_mail,
        pol_tel: registerInfo.pol_tel,
        pol_adres: registerInfo.pol_adres,
        pol_il: registerInfo.pol_il,
        pol_ilce: registerInfo.pol_ilce,
        pol_logo: logo,
        password: registerInfo.password,
        password2: registerInfo.password2,
      };
      const logoData = new FormData();
      logoData.append("file", logo);
      logoData.append("upload_preset", "v1ouupsn");
      const resp = await axios.post(
        "https://api.cloudinary.com/v1_1/dxrgehpzq/image/upload",
        logoData
      );
      // console.log("resplogo", resp);

      await schema.validate(inputData, { abortEarly: false });
      const response = await axios.post(
        `${config.API_BASE_URL}pol_uye`,
        // formData,
        {
          pol_vergi_no: registerInfo.pol_vergi_no,
          pol_logo: resp.data.url,
          pol_adi: registerInfo.pol_adi,
          pol_adres: registerInfo.pol_adres,
          pol_tel: registerInfo.api_pol_tel,
          pol_mail: registerInfo.pol_mail,
          pol_il: registerInfo.pol_il,
          pol_ilce: registerInfo.pol_ilce,
          password: registerInfo.password,
        },
        {
          headers: {
            Authorization: "Bearer " + uRegister.token, // Authorization
          },
        }
      );

      if (response.data) {
        // console.log("response.data", response.data);
        setLogo(null);
        const {
          id,
          pol_vergi_no,
          pol_adi,
          pol_adres,
          pol_tel,
          pol_mail,
          pol_il,
          pol_ilce,
          pol_logo,
        } = response.data.poliklinik_bilgileri;

        dispatch(
          userRegister({
            id: pol_vergi_no,
            token: response.data.token.token,
            pol_vergi_no: registerInfo.pol_vergi_no,
            pol_adi: pol_adi,
            pol_adres: pol_adres,
            pol_tel: pol_tel,
            pol_mail: pol_mail,
            pol_il: pol_il,
            pol_ilce: pol_ilce,
            pol_logo: pol_logo,
          })
        );
        const token = response.data.token.token;

        if (token) {
          axios
            .post(`${config.API_BASE_URL}loginPost`, {
              email: registerInfo.pol_mail,
              password: registerInfo.password,
            })
            .then((respons) => {
              // console.log("respons", respons);
              dispatch(
                userLogin({
                  id: respons.data.id,
                  email: respons.data.email,
                  username: respons.data.username,
                  token: token,
                  kullanici_turu: respons.data.kullanici_turu,
                })
              );

              setTimeout(() => {
                navigate("/poliklinik");
              }, 500);
            })
            .catch((error) => {
              console.error(error);
            });
        }

        toast.success("Kayıt işlemi Başarılı. Hoşgeldiniz.");
      }
      setShowRegister(false);
      // setShowLogin(true);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        // console.log("logo", logo);
        // if (logo === undefined) {
        //   validationErrors["pol_logo"] = "Logo seçilmedi.";
        // }
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      }
      // console.error(errors);
      return toast.error("Lütfen geçerli bilgiler girin!");
    }
  };

  const close = () => {
    setShowRegister(false);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed xs:inset-x-[2.5vw] sm:inset-x-[calc(50vw-14rem)] lg:inset-x-[calc(50vw-30rem)] xs:inset-y-[calc(50vh-180px)] lg:inset-y-[calc(50vh-300px)] min-h-max min-w-max z-50 outline-none focus:outline-none  bg-gray bg-opacity-50">
        <div className="relative w-auto  mx-auto max-w-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-row xs:w-[95vw] sm:w-[28rem] lg:w-[60rem]  bg-white outline-none focus:outline-none">
            <form
              action="#"
              className="px-10 xs:py-2 lg:py-10 xs:w-full lg:w-1/2 grid grid-cols-6 xs:gap-1 lg:gap-4"
              onSubmit={(e) => handleSubmit(e)}
            >
              <h2 className="text-[24px] font-bold col-span-6 text-black">
                Poliklinik Kaydol
              </h2>
              <div className="flex-row col-span-6">
                <label
                  htmlFor="logo"
                  className="form-label text-formGray text-14"
                >
                  Logo
                </label>
                <div className="flex items-center">
                  {logo && (
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src={URL.createObjectURL(logo)}
                      alt="Logo"
                      className="w-100 h-100"
                    />
                  )}

                  <input
                    id="logo"
                    type="file"
                    name="pol_logo"
                    accept=".jpg,.jpeg,.png,.svg,image/svg+xml,image/webp"
                    // value={registerInfo.pol_logo}
                    onChange={(e) => {
                      // setLogo(e.target.files[0]);
                      handleChangeLogo(e.target.files[0]);
                    }}
                    className="hidden"
                  />
                  <label
                    htmlFor="logo"
                    className="h-10 w-28 text-center text-14 tracking-[.5px] font-bold bg-blue1 text-white items-center border-2 border-white rounded-full mr-1 hover:bg-white hover:text-blue1 hover:border-blue1 p-2"
                  >
                    {logo ? "Logo Seçildi" : "Logo Seç"}
                  </label>
                </div>
                {/* {errors.pol_logo && (
                  <p className="input-error-message text-red">
                    {errors.pol_logo}
                  </p>
                )} */}
                {logo === false && (
                  <p className="input-error-message text-red">Logo giriniz</p>
                )}
              </div>

              <div className="flex-row col-span-4">
                <label
                  htmlFor="klinik"
                  className="form-label text-formGray text-14"
                >
                  Klinik Adı
                </label>
                <input
                  id="pol_adi"
                  name="pol_adi"
                  type="text"
                  value={registerInfo.pol_adi}
                  onChange={handleChange}
                  // required
                  placeholder=""
                  className=" w-full border-b-formGray border-b-[1.5px] transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
                />
                {errors.pol_adi && (
                  <p className="input-error-message text-red">
                    {errors.pol_adi}
                  </p>
                )}
              </div>
              <div className="flex-row col-span-2">
                <label
                  htmlFor="vergiNo"
                  className="form-label text-formGray text-14"
                >
                  Vergi No
                </label>
                <input
                  id="pol_vergi_no"
                  name="pol_vergi_no"
                  type="text"
                  required
                  maxLength="10"
                  minLength="10"
                  value={registerInfo.pol_vergi_no}
                  onChange={handleChange}
                  className=" w-full border-b-formGray border-b-[1.5px] transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
                />
                {errors.pol_vergi_no && (
                  <p className="input-error-message text-red">
                    {errors.pol_vergi_no}
                  </p>
                )}
              </div>
              <div className="flex-row col-span-3">
                <label
                  htmlFor="email"
                  className="form-label text-formGray text-14"
                >
                  Email
                </label>
                <input
                  id="pol_mail"
                  name="pol_mail"
                  type="email"
                  required
                  value={registerInfo.pol_mail}
                  onChange={handleChange}
                  className=" w-full border-b-formGray border-b-[1.5px] transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
                />
                {errors.pol_mail && (
                  <p className="input-error-message text-red">
                    {errors.pol_mail}
                  </p>
                )}
              </div>
              <div className="flex-row col-span-3">
                <label
                  htmlFor="phone"
                  className="form-label text-formGray text-14"
                >
                  Telefon
                </label>
                <input
                  id="pol_tel"
                  name="pol_tel"
                  type="text"
                  required
                  pattern="[0-9]{10,11}"
                  value={registerInfo.pol_tel || "0"}
                  onChange={handleChange}
                  className=" w-full border-b-formGray border-b-[1.5px] transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
                  placeholder="05551234567"
                />
                {errors.pol_tel && (
                  <p className="input-error-message text-red">
                    {errors.pol_tel}
                  </p>
                )}
              </div>
              <div className="flex-row col-span-6">
                <label
                  htmlFor="address"
                  className="form-label text-formGray text-14"
                >
                  Adres
                </label>
                <input
                  id="pol_adres"
                  name="pol_adres"
                  type="text"
                  required
                  value={registerInfo.pol_adres}
                  onChange={handleChange}
                  className=" w-full border-b-formGray border-b-[1.5px] transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
                />
                {errors.pol_adres && (
                  <p className="input-error-message text-red">
                    {errors.pol_adres}
                  </p>
                )}
              </div>
              <div className="flex-row col-span-3">
                <label
                  htmlFor="il"
                  className="form-label text-formGray text-14"
                >
                  İl
                </label>
                <select
                  name="pol_il"
                  id="pol_il"
                  className="w-full border-b-formGray border-b-[1.5px] transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
                  value={registerInfo.pol_il}
                  onChange={handleChange}
                >
                  <option disabled value="">
                    İl Seçiniz
                  </option>
                  {ilData.map((il, index) => {
                    // console.log(il["text"]);
                    return (
                      <option value={il["text"]} key={index}>
                        {il["text"]}
                      </option>
                    );
                  })}
                </select>
                {errors.pol_il && (
                  <p className="input-error-message text-red">
                    {errors.pol_il}
                  </p>
                )}
              </div>
              <div className="flex-row col-span-3">
                <label
                  htmlFor="pol_ilce"
                  className="form-label text-formGray text-14"
                >
                  İlçe
                </label>
                <select
                  name="pol_ilce"
                  id="pol_ilce"
                  className=" w-full border-b-formGray border-b-[1.5px] transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
                  value={registerInfo.pol_ilce}
                  onChange={handleChange}
                >
                  <option disabled value="">
                    İlçe Seçiniz
                  </option>
                  {ilData
                    .filter((data) => data["text"] === registerInfo.pol_il)
                    .map((ilce) =>
                      ilce["districts"].map((e, index) => (
                        <option value={e["text"]} key={index}>
                          {e["text"]}
                        </option>
                      ))
                    )}
                </select>
                {errors.pol_ilce && (
                  <p className="input-error-message text-red">
                    {errors.pol_ilce}
                  </p>
                )}
              </div>
              <div className="flex-row col-span-3">
                <label
                  htmlFor="password"
                  className="form-label text-formGray text-14"
                >
                  Şifre
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={registerInfo.password}
                  onChange={handleChange}
                  required
                  className=" w-full border-b-formGray border-b-[1.5px] transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
                />
                {errors.password && (
                  <p className="input-error-message text-red">
                    {errors.password}
                  </p>
                )}
              </div>
              <div className="flex-row col-span-3">
                <label
                  htmlFor="password2"
                  className="form-label text-formGray text-14"
                >
                  Şifre (Tekrar)
                </label>
                <input
                  id="password2"
                  type="password"
                  name="password2"
                  // name={registerInfo.password2}
                  onChange={handleChange}
                  required
                  className=" w-full border-b-formGray border-b-[1.5px] transition-all duration-1000 shadow-sm text-base outline-none focus:border-blue1"
                />
                {errors.password2 && (
                  <p className="input-error-message text-red">
                    {errors.password2}
                  </p>
                )}
              </div>
              <div className="flex col-span-6 text-black text-[10px]">
                <ul className="flex-row col-span-3 ">
                  <li>
                    Şifre en az 8 karakter, en fazla 16 karakterden oluşmalı.
                  </li>
                  <li>
                    En az bir rakam, bir küçük harf, bir büyük harf içermelidir.
                    Bu (+, -, *, /)Özel Karakterleri İçermemelidir."
                  </li>
                </ul>
              </div>
              <button
                style={{ textTransform: "none" }}
                className="col-span-2 h-10 w-28   text-14 tracking-[.5px] font-bold bg-blue1 text-white items-center border-2 border-white rounded-full mr-3 hover:bg-white hover:text-blue1 hover:border-blue1"
                type="submit"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Kaydol
              </button>
              <button
                style={{ textTransform: "none" }}
                className="h-10 w-28 p-[10px 30px] text-14 tracking-[.5px] font-bold bg-[#dadbdd] text-[#5616f5] items-center border-2 border-white rounded-full mr-3 hover:bg-white hover:text-blue1 hover:border-blue1"
                type="submit"
                onClick={close}
              >
                Vazgeç
              </button>
              <button
                style={{
                  textTransform: "none",
                  boxShadow: "0 0 30px -12px #5616f5",
                }}
                className="lg:hidden col-span-2 h-10 w-28  p-[10px 30px] text-14 tracking-[.5px] font-bold text-white bg-pink items-center border-2 border-white rounded-full mr-3 hover:bg-white hover:text-pink hover:border-pink"
                type="button"
                onClick={() => {
                  setShowLogin(true);
                  setShowRegister(false);
                }}
              >
                Giriş Yap
              </button>
            </form>

            <section className="w-1/2 bg-blue1 text-white p-10 xs:hidden lg:block rounded-r-lg">
              <h2 className="text-[24px] font-bold pb-5">Zaten üye misiniz?</h2>
              <div className="text-[38px] font-light py-5">
                <p className="">Hemen giriş yapın</p>
                <p>
                  ve size<span className="font-bold"> Özel Paneli </span>
                </p>
                {/* <br /> */}
                <p>kullanın.</p>
              </div>
              <ul className="font-light text-14 list-inside ]">
                <li>
                  <BsCheck2 className="inline-block mr-2" />
                  Hasta Randevu Sistemi
                </li>
                <li>
                  <BsCheck2 className="inline-block mr-2" />
                  Hasta Kayıt Altyapısı
                </li>
                <li>
                  <BsCheck2 className="inline-block mr-2" />
                  Poliklinik Yönetimi
                </li>
              </ul>
              <button
                style={{
                  textTransform: "none",
                  boxShadow: "0 0 30px -12px #5616f5",
                }}
                className="h-10 w-28 mt-10 p-[10px 30px] text-14 tracking-[.5px] font-bold text-white items-center border-2 border-white rounded-full mr-3 hover:bg-white hover:text-pink"
                type="button"
                onClick={() => {
                  setShowLogin(true);
                  setShowRegister(false);
                }}
              >
                Giriş Yap
              </button>
            </section>
          </div>
        </div>
      </div>
      <div
        onClick={() => close()}
        className="opacity-25 fixed inset-0 z-40 bg-black h-full w-full"
      ></div>
    </>
  );
};

export default RegisterModal;
