import React, { useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../../components/polyclinic/layout/Layout";
import poliklinikpp from "../../../assets/Poliklinikpp.png";
import { TfiPencil } from "react-icons/tfi";
import { ilData } from "../../../helpers/ilData";
import axios from "axios";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useDispatch } from "react-redux";

const YardimPage = () => {
  return (
    <>
      <Layout />
      <div className=" bg-white flex justify-center items-center ">
        <div className="grid grid-cols-1 w-1/2  my-20">
          <h1 className="text-3xl mb-4">Yardım</h1>
        </div>
      </div>
    </>
  );
};

export default YardimPage;
