import React, { useEffect } from "react";
import Layout from "../../../components/polyclinic/layout/Layout";
import MiddleLink from "../../../components/polyclinic/polyclinicDashboard/MiddleLink";
import { useSelector } from "react-redux";
import ClinicHeader from "../../../components/polyclinic//polyclinicDashboard/ClinicHeader";
import ClinicStatisticComponent from "../../../components/polyclinic/ClinicStatisticComponent";
import DoctorsTitle from "../../../components/polyclinic/polyclinicDashboard/DoctorsTitle";
import axios from "axios";
import { useState } from "react";
import config from "../../../config";



const PolyclinicProfile = () => {
  const uRegister = useSelector((state) => state.uRegister);
  const open = useSelector((state) => state.open.value);
  const [data, setData] = useState([]);
  // console.log(uRegister);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          `${config.API_BASE_URL}pol_uye/${uRegister.id}`,
          {
            headers: {
              Authorization: "Bearer " + uRegister.token, // Authorization
            },
          }
        );

        // console.log(data);
        if (data.status !== 400) {
          setData(data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);

  return (
    <>
      <Layout />

      <div
        className={`absolute bg-container  mt-20 right-0 p-6 min-h-[calc(100vh-80px)] ${
          open ? "w-[calc(100%-250px)]" : "w-[calc(100%-100px)]"
        } transition-w ease-in-out duration-500`}
      >
        <div className="bg-white">
          <div className=" p-1">
            <ClinicHeader />

            <div className="border border-b border-gray w-[96%] m-auto"></div>
            {data && <ClinicStatisticComponent data={data} />}
          </div>
        </div>
        <MiddleLink />
        <DoctorsTitle data={data} />
      </div>
    </>
  );
};

export default PolyclinicProfile;
